import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import classnames from "classnames";
import SVG from "react-inlinesvg";


import outlineInfo from "@ekenta/assets/icons/outline-info.svg";
import outlineCheck from "@ekenta/assets/icons/outline-check.svg";

import styles from "./CustomAlert.module.scss";

interface CustomAlertPropType {
    show: boolean;
    variant: string;
    message?: string | JSX.Element | JSX.Element[];
    children?: React.ReactNode;

}

const CustomAlert = (props: CustomAlertPropType) => {
    const [show, setShow] = useState(props.show);

    const AlertClass = classnames(styles.Alert, {
        [styles.Alert_Danger]: props.variant === "danger",
        [styles.Alert_Success]: props.variant === "success",
    })

    return (
        <>
            {show ? (
                <div className={styles.Custom__Alert}>
                    <div className={styles.Container__Alert}>
                        <Alert className={AlertClass}>
                            <span className={styles.AlertIcon}>
                                {props.variant === "danger" ? <SVG src={outlineInfo} /> :
                                    props.variant === "success" ? <SVG src={outlineCheck} /> : null}
                            </span>
                            {props.message ? (
                                <span className={styles.Alert__Span}>{props.message}</span>
                            ) : null}
                            {props.children ?
                                <span className={styles.Alert__Span}>{props.children}</span>
                                : null}
                        </Alert>
                    </div>
                </div>
            ) : ''}
        </>
    );
}

export default CustomAlert;

