import React from 'react'
import voucherImg from '@ekenta/assets/images/voucher-img.png'
import { formatCurrency } from '@ekenta/utils'
import { TemplateWrapper } from './style'

const VoucherTemplate = (props: { price: number; voucherName: string }) => {
  const { price, voucherName } = props
  return (
    <TemplateWrapper>
      <img src={voucherImg} className="voucher-gift-temp" alt={voucherName} />
      <span className="voucher-gift-price">{formatCurrency(price || 0)}</span>
    </TemplateWrapper>
  )
}

export default VoucherTemplate
