import React, { useCallback, useState } from 'react'
import { BASE_URL } from '@ekenta/constants';
import { toast } from "react-toastify";
import { useAppSelector } from '@ekenta/redux/store/hook';
import axios from 'axios';
import "./RemoveSaved.scss";
import { useFetchUserSavedItemsQuery } from '@ekenta/redux/store/services/accountApi';
import { ReactComponent as FavoriteIcon } from "@ekenta/assets/icons/favorite_icon.svg";
import { Button, Loader } from '@ekenta/components';


interface RemoveSavedPropsType {
    productId: string;
}

const RemoveSaved = (props: RemoveSavedPropsType) => {
    const { productId } = props

    const { ek_isAuth, token } = useAppSelector((state) => state.auth.authentication);
    const getSavedItem = useFetchUserSavedItemsQuery(token);

    const [isLoading, setIsLoading] = useState(false);

    const removeSavedHandler = (productId: string) => {
        if (token && productId) {
            toggleFunction(productId);
        }
        if (!ek_isAuth && !token) {
            toast.error(`Log in to save product`, {
                toastId: "-signin-error-400-id-toast-error",
            });
        }
    };

    const toggleFunction = useCallback((prodId: string) => {
        const config = {
            method: 'get',
            url: `${BASE_URL}/api/favorite-item/${prodId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: ""
        };
        setIsLoading(true);
        axios(config)
            .then(function (response) {
                setIsLoading(false);
                toast.success(`${response.data.message}`, {
                    toastId: "oast-product-toggle-success-id-toast",
                });
                getSavedItem?.refetch();
            })
            .catch(function () {
                setIsLoading(false);
                toast.error(`Something went wrong`, {
                    toastId: "-toggles-error-400-id-toast-error",
                });
                getSavedItem?.refetch();
            });
    }, [getSavedItem]);

    return (
        <Button className="save-item-btn active--saved"
            onClick={() => removeSavedHandler(productId)}>
            {isLoading ? <Loader /> : <FavoriteIcon />}
        </Button>
        // <Button
        //     as="text"
        //     name="X Remove"
        //     shadow={false}
        //     type="button"
        //     isLoadingText="Remove"
        //     isLoading={isLoading}
        //     disabled={isLoading}
        //     className="RemoveSaved__Button"
        //     onClick={() => removeSavedHandler(productId)}
        // />
    )
}

export default RemoveSaved