import { device } from "@ekenta/utils/devices";
import styled from "styled-components";

export const  EmailVerifyAsideStyle = styled.div`
	display: none;
	width: 746px;
	height: 100vh;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-shrink: 1;
	gap: 0.8em;
	background-color: var(--eke-color-white);

	h1{
		margin:0;
		color: var(--greyscale-900, #212121);
		text-align: center;
		font-size: 24px;
		font-family: var(--eke-body-font-family);
		font-weight: var(--eke-font-weight-bold);
		line-height: 120%;

	}

	p{
		margin:0;
		width: 554px;
		text-align: center;
		color: var(--greyscale-800, #424242);
		font-size: 16px;
		font-family: var(--eke-body-font-family);
		font-weight: var(--eke-font-weight-normal);
		line-height: 140%;
		letter-spacing: 0.2px;
	}

	@media ${device.lg}{
		display: flex;
    }
`;

export const EmailVerifyMainContent = styled.div`
    flex: 1;
	display: flex;
	height: 100vh;
	justify-content: center;
	align-items: center;
	padding: 110px 23px 168px 23px;

	.ContentChildGroup__Style{
		width: 100%;
		height: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: 0 auto;
		zoom: 0.95;

		.logo{
			margin-right: 4rem;
		}

		.OTP_Text_Style{
			margin-top: 25px;
			margin-bottom: 2px;
			text-align: center;
			margin-right: 2rem;

			h1{
				color: var(--greyscale-900, #212121);
				text-align: center;
				font-size: 23px;
				font-family: var(--eke-body-font-family);
		        font-weight: var(--eke-font-weight-bold);
				line-height: 120%;
			}
			p{
				color: var(--greyscale-700, #616161);
				text-align: center;
				font-size: 15px;
				font-family: var(--eke-body-font-family);
		        font-weight: var(--eke-font-weight-normal);
				line-height: 140%;
				letter-spacing: 0.2px;
				margin: 0;
			}
		}


		.ContentForm__Style{
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.7em;
		margin-top: 16px;
		zoom: 0.99;

		form{
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 1em;


			.Button-Call-to-action{
				width: 100% !important;
				color: var(--eke-color-white);
				text-align: center;
				text-shadow: 4px 8px 24px 0px #EFDCC7 !important;
				font-family: var(--eke-body-font-family);
				padding: 18px 16px !important;
				line-height: 140%;
				letter-spacing: 0.2px;
				margin-top: 0.8em;
				
				span{
					font-weight: var(--eke-font-weight-bold) !important;
					font-size: 16px !important;
				}
			}

			
		}
	}
	}

	@media ${device.lg}{
		padding: 169px 183px 168px 183px;
    }
`;