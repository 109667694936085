import React from 'react';
import styled from 'styled-components';
import { Popover } from '@ekenta/components';

export default function PopOver(props: {
  show: boolean;
  type: string;
  children?: React.ReactNode;
  setOutside: () => void;
  render?: ((onClick: () => void) => JSX.Element) | undefined;
}) {
  return (
    <Popover
      show={props.show}
      setOutside={props.setOutside}
      position={'bottom'}
      round={'15'}
      width={'100'}
      height={'450'}
      render={(onClose) => (
        <Container className="display__products__product">
          {props.render ? props.render(onClose) : props.children}
        </Container>
      )}
    />
  );
}

const Container = styled.div`
  display: block;
  width: 100%;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 5px;
  //margin-top: 19.5px;
`;
