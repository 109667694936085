import { device } from '@ekenta/utils/devices';
import styled from 'styled-components';

export const OnboardingMainStyle = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 5em;
  padding-top: 0%.2em;

  @media ${device.lg} {
    padding-top: 2em;
  }
`;

export const OnboardingContainerStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto;
  padding-top: 30px;
  max-width: var(--eke-container-maxWidth);

  @media ${device.lg} {
    width: 95%;
    padding-top: 0px;
    margin: 0 auto;
  }
  @media ${device.xl} {
    width: var(--eke-container-width);
    margin: 0 auto;
  }

  .TopOnboarding__Style {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;

    @media ${device.lg} {
      display: block;
      text-align: left;
    }

    h1 {
      color: var(--greyscale-900, #212121);
      font-family: var(--eke-body-font-family);
      font-weight: var(--eke-font-weight-bold);
      font-size: 32px;
      line-height: 120%; /* 38.4px */
    }

    p {
      color: var(--greyscale-700, #616161);
      font-family: var(--eke-body-font-family);
      font-weight: var(--eke-font-weight-normal);
      font-size: 18px;
      line-height: 140%; /* 25.2px */
      letter-spacing: 0.2px;
    }
  }
`;

export const OnboardingRowStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;

  @media ${device.lg} {
    flex-direction: row;
  }
`;

export const OnboardingAsideStyle = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto;
  display: inline-flex;
  padding: 35px 0px 42px 0px !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: var(--eke-color-white);
  min-height: 200px;

  @media ${device.md} {
    width: 55%;
  }

  @media ${device.lg} {
    position: sticky;
    width: auto;
    top: 20%;
    flex: 0.9;
  }
`;

export const OnboardingContentWidth = styled.div`
  padding: 0px;

  @media (min-width: 1200px) and (max-width: 1300px) {
    width: 100% !important;
  }

  @media ${device.xl} {
    padding: 0 80px;
  }
`;

export const OnboardingFormStyle = styled.div`
  width: 90%;
  margin: 0 auto;
  display: inline-flex;
  padding: 57px 17px 65px 17px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  background-color: var(--eke-color-white);

  @media ${device.md} {
    width: 55%;
    padding: 57px 54px 65px 54px;
  }

  @media ${device.lg} {
    padding: 57px 144px 65px 144px;
    width: auto;
    flex: 1.2;
  }

  @media ${device.xl} {
    padding: 57px 144px 65px 144px;
  }

  label {
    color: var(--greyscale-700, #616161) !important;
    font-family: var(--eke-body-font-family);
    line-height: 140%;
    font-weight: var(--eke-font-weight-medium) !important;
    letter-spacing: 0.2px;

    span {
      font-size: 14px !important;
      font-weight: var(--eke-font-weight-medium) !important;
    }
  }

  input.InputField__input,
  select {
    width: 100%;
    padding: 12px;
    font-size: 14px !important;
    border-radius: 8px !important;
    border: 1px solid var(--gray-300, #d0d5dd) !important;
    background: var(--eke-color-white) !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;

    &::placeholder {
      color: var(--greyscale-500, #9e9e9e) !important;
    }
  }

  input.InputField__input {
    padding: 10px 14px !important;
  }

  ._top_header {
    h5 {
      text-align: center;
      color: var(--greyscale-900, #212121) !important;
      text-align: center;
      font-family: var(--eke-body-font-family) !important;
      font-weight: var(--eke-font-weight-bold) !important;
      font-size: 24px;
    }

    p {
      text-align: center;
      color: var(--greyscale-700, #616161);
      text-align: center;
      font-family: var(--eke-body-font-family);
      font-size: 16px;
      font-weight: var(--eke-font-weight-normal);
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.2px;
    }
  }

  .dropdiv {
    margin-top: 2px !important;

    img {
      filter: grayscale(0) !important;
      opacity: 1 !important;
    }
  }

  button.PageStep__Btn {
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 1em;
    background-color: var(--eke-color-primary) !important;
    padding: 13px 16px !important;

    &:disabled {
      background-color: var(--eke-color-primary-300) !important;
    }

    span {
      text-shadow: 4px 8px 24px 0px #efdcc7;
      font-family: var(--eke-body-font-family) !important;
      font-size: 16px !important;
      font-weight: var(--eke-font-weight-bold) !important;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.2px;
    }
  }
`;

export const AlertNoteStyle = styled.div`
  width: 100%;
  display: flex;
  padding: 14px 25px 13px 18px;
  margin-top: 0.5em;
  align-items: flex-start;
  border-radius: 16px;
  gap: 0.6em;
  background: var(--background-blue, #f5fbff);

  .IconStyle {
  }

  span {
    color: var(--greyscale-700, #616161);
    font-family: var(--eke-body-font-family);
    font-weight: var(--eke-font-weight-normal);
    font-size: 11px;
    line-height: 139%; /* 13.9px */
    letter-spacing: 0.2px;
  }
`;

export const SpinnerStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 55px;
    height: auto;
  }

  .spin {
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const SuccessPopStyle = styled.div`
  display: flex;
  padding: 40px 22px 32px 22px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.8em;
  font-family: var(--eke-body-font-family);

  .SuccessIcon__Style {
    svg {
      width: 110px !important;
      height: auto !important;
    }
  }

  h5 {
    color: var(--greyscale-900, #212121);
    text-align: center;
    font-size: 22px;
    font-weight: var(--eke-font-weight-bold) !important;
    line-height: 120%;
    margin: 0;
  }

  p {
    color: var(--greyscale-900, #212121);
    font-size: 14px;
    font-weight: var(--eke-font-weight-normal) !important;
    line-height: 140%;
    letter-spacing: 0.2px;
    margin-bottom: 5px;
  }

  .Button-redirect-action {
    width: 100% !important;
    color: var(--eke-color-white);
    text-align: center;
    text-shadow: 4px 8px 24px 0px #efdcc7 !important;
    font-family: var(--eke-body-font-family);
    padding: 18px 16px !important;
    line-height: 140%;
    letter-spacing: 0.2px;

    span {
      font-weight: var(--eke-font-weight-bold) !important;
      font-size: 16px !important;
    }
  }
`;

export const UploadLogoStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;

  .uploader {
    width: 136px;
    height: 136px;
    flex-shrink: 0;
    border-radius: 136px;
    background-color: #f5fbff;
    border: 1px solid rgb(221, 221, 221);

    .file-uploader-container {
      width: 100%;
      height: 100%;

      .file-uploader-label {
        display: none !important;
      }
    }

    .dropdiv {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 136px !important;
      padding: 2.5rem 2rem !important;

      .upload-btn-wrapper {
        width: 100%;
        position: static !important;
        margin-bottom: 0 !important;
        button {
          display: none !important;
        }
        input {
          bottom: 0 !important;
          width: 100% !important;
        }
      }
    }
  }

  .add-logo-identifier {
    color: var(--eke-color-primary);
    text-align: center;
    font-family: var(--eke-body-font-family);
    font-size: 15px;
    font-weight: var(--eke-font-weight-medium);
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.2px;
    padding-top: 17px;
    margin: 0;
  }

  .description {
    color: var(--neutral-body-text, #747a8b);
    text-align: center;
    font-family: var(--eke-body-font-family);
    font-size: 15px;
    font-weight: var(--eke-font-weight-medium);
    letter-spacing: 0.2px;
    margin: 5px 0 0 0;
  }
`;

export const LogoPreview = styled.div`
position: relative;
  width: 136px;
  height: 136px;
  display: flex;
  align-items: center;
  background-color: #f5fbff;
  border: 1px solid rgb(221, 221, 221);
  justify-content: center;
  border-radius: 136px;
  transition: all 0.5s ease-in-out;

  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    min-height: 219px !important;
    border-radius: 10px;
    object-fit: cover;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  span{
    position: absolute;
    width: 30px;
    height: 30px;
    background: #eee;
    border-radius: 50%;
    right: 8%;
    bottom: 13%;
    z-index: 999;
    cursor: pointer;

    svg{
      font-size: 0.2rem;
    }
  }
`;
