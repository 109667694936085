import React from 'react'

const SvgMail = ({
	width = "1rem",
	height = "1rem",
	className
}: {
	className?: string,
	width?: string;
	height?: string
}) => (
	<svg
		width={width}
		height={height}
		className={className}
		viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Color/Icon">
			<g id="vuesax/linear/sms">
				<g id="sms">
					<path id="Vector" d="M11.333 14.1668H4.66634C2.66634 14.1668 1.33301 13.1668 1.33301 10.8335V6.16683C1.33301 3.8335 2.66634 2.8335 4.66634 2.8335H11.333C13.333 2.8335 14.6663 3.8335 14.6663 6.16683V10.8335C14.6663 13.1668 13.333 14.1668 11.333 14.1668Z" stroke="" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
					<path id="Vector_2" d="M11.3337 6.5L9.24699 8.16667C8.56032 8.71333 7.43366 8.71333 6.74699 8.16667L4.66699 6.5" stroke="" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
				</g>
			</g>
		</g>
	</svg>


)

export default SvgMail;