/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import * as queryString from "query-string";
import {
    FiChevronDown,
    FiExternalLink,
} from "react-icons/fi";
import { useLocation, useNavigate } from "react-router";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { useFetchAllBlockedSellersQuery } from "@ekenta/redux/store/services/adminSellerApi";
import {
    AdminSellerProfileId,
    DashboardSellers,
} from "@ekenta/routes/constant";
import { Pagination, DataTable, HelmetHeader } from "@ekenta/components";
import Dropdown from "@ekenta/components/Dropdown/Dropdown3";
import PageTitle from "@ekenta/components/PageTitle/PageTitle";
import { VscArrowSmallLeft } from "react-icons/vsc";
import withDashboardLayout from "@ekenta/hoc/Layout/withDashboardLayout";
import { StatusIndicator, TextTruncate } from "../../common/Resuable";

const AllBlockedSellers = () => {
    const query = queryString.parse(window.location.search);
    const _page = query.page;

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { token } = useAppSelector((state) => state.auth.authentication);

    const [pageno, setPageno] = useState(1);
    const [search, setSearch] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

    const result = useFetchAllBlockedSellersQuery(
        {
            pageno,
            keyword: debouncedSearchTerm,
            token,
        },
        {
            refetchOnMountOrArgChange: true,
            refetchOnReconnect: true,
            keepPreviousData: false,
        }
    );

    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
            sortType: 'basic',
        },
        {
            Header: "Store Name",
            accessor: "store",
            sortType: "basic",
        },
        {
            Header: 'Phone No',
            accessor: 'phone',
            sortType: 'basic',
        },
        {
            Header: 'Email',
            accessor: 'email',
            sortType: 'basic',
        },
        // {
        //   Header: 'Date',
        //   accessor: 'date',
        //   sortType: 'basic',
        // },
        {
            Header: 'Status',
            accessor: 'status',
            disableSortBy: true,
        },
        {
            Header: "Action",
            accessor: "option",
            disableSortBy: true,

        },
    ];

    useEffect(() => {
        const delay = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 1000);

        return () => {
            clearTimeout(delay);
        };
    }, [search]);

    const onSearch = (e: any) => {
        setSearch(e.target.value);
    };



    const mapData = (data: any) => {
        return data.length > 0 ? data.map((item: any) => ({
            ...item,
            name: (
                <TextTruncate>{item.user.first_name + " " + item.user.last_name}</TextTruncate>
            ),
            store: (
                <TextTruncate>{item.business_name}</TextTruncate>
            ),
            phone: item.business_phone_number,
            email: item.user.email,
            status: (
                item.seller_request_status ? (
                    <StatusIndicator status={item.seller_request_status} />
                ) : ""
            ),
            option: (
                <Dropdown
                    name={"more"}
                    suffixIcon={<FiChevronDown />}
                    placement="bottom"
                    menus={[
                        {
                            name: "Seller Profile",
                            icon: <FiExternalLink />,
                            active: false,
                            key: "seller-profile",
                            handler: () =>
                                navigate(`${AdminSellerProfileId}/${item.user?.id}`),
                        },
                        {
                            name: "View details",
                            icon: <FiExternalLink />,
                            active: false,
                            key: "view-detail",
                            handler: () =>
                                navigate(`/admin/seller-review/${item.id}?type=blocked`),
                        },
                    ]}
                    tooltipContent={"More Option"}
                />
            ),
        })) : [];
    };


    return (
        <>
            <HelmetHeader
                title="All Blocked Sellers"
                description="Qlason All Blocked Sellers Page"
            />
            <Col className=" col-lg-12 mx-auto _listing_product_ dash_top px-2">
                <div className="mt-0">
                    <Col className="AppEvents_col mx-1 mx-lg-0">
                        <PageTitle
                            title=" Blocked Sellers"
                            subTitle={

                                <span
                                    className="cursor-pointer d-flex w-lg-50 align-items-center text-muted"
                                    onClick={() => navigate(DashboardSellers)}>
                                    <VscArrowSmallLeft />
                                    Back to Dashboard
                                </span>
                            }
                        >
                        </PageTitle>
                    </Col >

                    <Col className="mt-4 tab__ position-relative">
                        <DataTable
                            theme="simple"
                            data={result?.data ? mapData(result?.data?.sellers.data) : []}
                            loading={result?.isLoading || result?.isFetching}
                            trClassName="w_Tr"
                            tdClassName={`Row__col`}
                            isSelectable={true}
                            columns={columns}
                            showPagination={false}
                            hasLink={true}
                            showSearch={true}
                            searchValue={search}
                            onSearch={onSearch}
                            renderPagnation={
                                <>
                                    {result?.data
                                        ? (
                                            <Pagination
                                                pathname={pathname}
                                                itemPerPage={result?.data.sellers.per_page}
                                                itemsLength={result?.data.sellers.total}
                                                currentPage={pageno}
                                                queryNo={_page}
                                                callback={(value) => setPageno(value)}
                                            />
                                        )
                                        : null}
                                </>
                            }
                        />

                        <br />
                    </Col>
                </div >
            </Col >


        </>
    );
};

export default withDashboardLayout(AllBlockedSellers);
