/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ListGroup } from 'react-bootstrap';
import { FiChevronRight } from 'react-icons/fi';
import InnerCollection from './InnerCollection';
import Scrollbars from 'react-custom-scrollbars';
import TopSection from './TopSection';
import { Link } from 'react-router-dom';
import { slugifyText } from '@ekenta/utils';
import { collectionBaseUrl } from '@ekenta/routes/constant';
import RefreshLink from '@ekenta/components/RefreshLink/RefreshLink';


export default function CollectionGroupItem(props: any) {
    const [activeMenu, setActiveMenu] = useState('all');
    const [innerData, setInnerData] = useState({
        subcollectioncategory_id: '',
        subcollectioncategory_name: '',
        childcollections: []
    });
    return (
        <div className="listgroup__container">
            <CSSTransition className="d-block d-lg-none" in={activeMenu == `all`}
                unmountOnExit
                timeout={500}
                classNames="menu-pq">
                <TopSection />
            </CSSTransition>


            {props.data ? props.data.map((collection: any) => {
                const _slug = slugifyText(collection.name.toLowerCase(), collection.id);
                return (
                    <CSSTransition key={'coll_' + collection.id} in={activeMenu == `all`}
                        unmountOnExit
                        timeout={500}
                        classNames="menu-pq">

                        <ListGroup className="list__general_ug">
                            <div className="_header__group">
                                <div className="collection___title">
                                    <h4>
                                        <RefreshLink
                                            to={`${collectionBaseUrl}/${_slug}`}>
                                            {collection.name}
                                        </RefreshLink>
                                    </h4>
                                </div>
                            </div>
                            {collection && collection.sub_collection_category.length > 0
                                ? collection.sub_collection_category.map((subcollection: any) => (
                                    <ListGroup.Item
                                        key={'subcoll' + subcollection.id}
                                        as="li"
                                        onClick={() => {
                                            setInnerData({
                                                ...innerData,
                                                subcollectioncategory_id: subcollection.id,
                                                subcollectioncategory_name: subcollection.name,
                                                childcollections: subcollection.sub_collection
                                            })
                                            setActiveMenu(`inner`)
                                        }
                                        }
                                        className="">
                                        <div className="d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto sub_collection">
                                                <span>{subcollection.name}</span>
                                            </div>
                                            <FiChevronRight className="icon__right" fontSize={22} />
                                        </div>
                                    </ListGroup.Item>
                                ))
                                : null}
                        </ListGroup>
                    </CSSTransition>
                )
            }) : null}
            <InnerCollection closeHandler={props.closeHandler} data={innerData} activeMenu={activeMenu} goToMain={() => setActiveMenu(`all`)} />
        </div>
    )
}
