import React from 'react'
import { Col } from 'react-bootstrap'
import Typography from '../Typography/Typography'
import {
  VoucherCardItem,
  VStack,
  WrapContainer,
  Image,
  Contents,
  Title,
  WrapRatings,
  FlexRates,
  Divider,
  ConditionBadge,
  PricePerKg,
} from './style'
import { ReactComponent as Ratings } from '@ekenta/assets/icons/ratings_icon.svg'
import VoucherTemplate from './VoucherTemplate'
import { formatCurrency } from '@ekenta/utils'
import BuyVoucher from './BuyVoucher'

const VoucherCard = (props: {
  cost: number
  voucher_id: number
  index: number
}) => {
  const { cost, voucher_id, index } = props
  return (
    <WrapContainer
      as={Col}
      xs={6}
      sm={6}
      md={4}
      lg={3}
      className=""
      style={{ height: '100%' }}
    >
      <VoucherCardItem>
        <BuyVoucher {...{ cost, voucher_id }} />
        <VStack>
          <Image>
            <VoucherTemplate price={cost} voucherName="Gift Voucher1" />
          </Image>
          <Contents>
            <Title as={Typography}>Gift Voucher {index}</Title>
            <WrapRatings>
              <FlexRates>
                <Ratings />
                <span>{0}</span>
              </FlexRates>
              <Divider />
              <ConditionBadge>
                <div className="pr_condition_sec">
                  <span className="span__new">New</span>
                </div>
              </ConditionBadge>
            </WrapRatings>
            <PricePerKg>
              <span className="main-price">{formatCurrency(cost || 0)}</span>
            </PricePerKg>
          </Contents>
        </VStack>
      </VoucherCardItem>
    </WrapContainer>
  )
}

export default VoucherCard
