import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { VscArrowSmallLeft } from 'react-icons/vsc';
import { useNavigate } from 'react-router';
import { HelmetHeader } from '@ekenta/components';

import PageTitle from '../../../components/PageTitle/PageTitle';
import withDefaultLayout from '../../../hoc/Layout/withDefaultLayout';
import { useFetchAllCollectionsQuery } from '../../../redux/store/services/collectionApi';
import { homePathUrl } from '../../../routes/constant';
import SingleCollectionCard from '../component/SingleCollectionCard';
import SingleMobileCollectionDrop from '../component/SingleMobileCollectionDrop';

import './AllCollection.scss';
import { slugifyText } from '@ekenta/utils';

const AllCollection = () => {
  const navigate = useNavigate();
  const { data, isLoading, isSuccess } = useFetchAllCollectionsQuery();

  return (
    <>
      <HelmetHeader
        title="Browse All Categories"
        description="Qlason Categories Page"
      />
      <div className="d_collection_wrapper browse__collection ">
        <Container>
          <div className="all_collection_heading d-flex align-items-center">
            <PageTitle
              title="Browse Categories"
              subTitle={
                <span
                  className="cursor-pointer  d-flex d-lg-none w-auto align-items-center
                        text-muted"
                  onClick={() => navigate(homePathUrl)}
                >
                  <VscArrowSmallLeft />
                  Back Home
                </span>
              }
            />
          </div>
          <div className="main__all_collection_">
            {isLoading ? (
              <>Loading...</>
            ) : (
              <>
                <Row>
                  {isSuccess &&
                    data.collections.map((item: any) => {
                      const _collectionSlug = slugifyText(item.name.toLowerCase(), item.id);
                      return (
                        <SingleCollectionCard
                          key={item.id}
                          collectionName={item.name}
                          collectionSlug={_collectionSlug}
                          subCatgories={item.sub_collection_category}
                        />
                      );
                    })}
                </Row>
                <SingleMobileCollectionDrop data={data} />
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default withDefaultLayout(AllCollection);
