import React from 'react'

 const SingleSellerProduct = ({image,title}:{image:string,title:string}) => {
  return (
    <li className="vid__li_">
        <button className="vid__product__btn">
            <div className="vid__image_prod">
                <img src={image} alt="" />
            </div>
            <div className="vid__product_col">
                <h5 className="title_Qts " role="button" aria-label="title">
                    {title}
                </h5>
            </div>
        </button>
  </li>
  )
}

export default SingleSellerProduct;
