import { device } from "@ekenta/utils/devices";
import styled,{css} from "styled-components";




export const  StepContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100% !important;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
`;

export const StepHeading = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;

    h3{
        color: var(--greyscale-900, #212121) !important;
        font-family: var(--eke-body-font-family) !important;
        font-size: 24px !important;
        font-weight: var(--eke-font-weight-bold) !important;
        line-height: 120%; /* 28.8px */
        margin-bottom: 5px;
    }
    
    p{
        color: var(--greyscale-700, #616161) !important;
        font-family: var(--eke-body-font-family) !important;
        font-size: 15px !important;
        font-weight: var(--eke-font-weight-normal) !important;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.2px;
    }
`;

export const StepContentStyle = styled.div<{h?:string; mb?:string}>`
    width: 100%;
    flex-grow: 1;
    height: auto;
    min-height: ${props => props.h ? props.h : "250px"};
    margin-bottom: ${props => props.mb ? props.mb : "0px"};
    padding-bottom: 10px;

`;

export const HStack = styled.div<{pt?:string; gap?:string}>`
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding-top: ${props => props.pt ? props.pt : "2rem"};
    gap: ${props => props.gap ? props.gap : "10px"};
`;

export const StepFormGroup = styled.div`
    width: 100% !important;
    margin-bottom: 10px;

    @media ${device.lg} {
        width: 49% !important;
    }

    label{
        color: var(--greyscale-700, #616161) !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 140%; /* 19.6px */
        letter-spacing: 0.2px;
    }
`;

export const InnerContainerStyle = styled.div`
    width: 100%;
    padding-bottom: 15px !important;
    padding-right: 10px !important;

   
`;

export const StepFooter = styled.div`
    display: flex;
    width: 100%;
    align-content: flex-end;
    justify-content: flex-end;
    border-top: 0.5px solid #f2f2f2;
    padding-top: 15px;
    //margin-bottom: -100px;

    button{
        width: fit-content !important;
        min-width: 150px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
`;

export const CancelBtn = styled.button<{pos?:string}>`
    position: ${props => props.pos ? props.pos : "absolute"};
    right: ${props => props.pos ? "-1%" : "-4%"};
    top: -5%;
    outline: none;
    border: none !important;
    width: fit-content !important;
    background-color: transparent !important;
    cursor: pointer;
    z-index: 999 !important;

    svg{
        width: 15px !important;
        height: 15px;
    }
`;

export const AddCard = styled.button<{mt?:string}>`
    outline: none;
    border: none !important;
    width: fit-content !important;
    background-color: transparent !important;
    padding: 8px 15px;
    padding-left: 0 !important;
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;

    ${props => props.mt && css`
        margin-top: ${props.mt}
    `}


    &:hover{
        background-color: #eee;
    }

    svg{
        width: 18px !important;
        height: 18px;
        fill: var(--eke-color-primary) !important;
    }

    span{
        color: var(--eke-color-primary);
        text-align: center;
        font-family: var(--eke-body-font-family);
        font-size: 15px;
        font-weight: var(--eke-font-weight-semibold);
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.2px;
    }
`;


