import React from 'react'
import { Accordion, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { categoryBaseUrl } from '../../../routes/constant';
import { slugifyText } from '../../../utils';

interface SingleMobileCollectionDropPropsType {
    data: any;
}

export default function SingleMobileCollectionDrop(props: SingleMobileCollectionDropPropsType) {
    return (
        <Col className="single__col_mobile d-block d-lg-none">
            <Accordion>
                {props.data && props.data.collections.map((item: any, index: number) => {
                    return (
                        <Accordion.Item key={item.id} eventKey={`${index}`}>
                            <Accordion.Header>
                                <div className="accord__header_sub">
                                    <div></div>
                                    <h5>{item.name}</h5>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className="pt5 pt _list">
                                    {item.sub_collection_category.length > 0 && item.sub_collection_category.map((_item: any, index: number) => {
                                        const _slugCategory = slugifyText(_item.name.toLowerCase(), _item.id);
                                        return (
                                            <li key={_item.id + '__'} className="pvm s_list">
                                                <Link to={`${categoryBaseUrl}/${_slugCategory}`} >{_item.name}</Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })}
            </Accordion>
        </Col>
    )
}
