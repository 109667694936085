import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { CircleIcon, PlayIcon } from '../../icons/icons';
export default function PausedOverlay({
  posterUrl,
  title,
  videoUrl,
}: {
  posterUrl: string;
  title: string | undefined;
  videoUrl: string | null;
}) {
  return (
    <div
      className="paused--overlay"
      style={{ zIndex: 0, backgroundColor: '#F5FBFF' }}
    >
      <div className="image-wrapper-div">
        <LazyLoadImage
          className="image-paused-poster"
          effect="blur"
          height={'100%'}
          width={'100%'}
          src={posterUrl}
          alt={title ? title : ''}
        />
      </div>
      {videoUrl ? (
        <div className="center-div">
          <span className="center-button play-btn">
            <PlayIcon />
            <CircleIcon />
          </span>
        </div>
      ) : null}

    </div>
  );
}
