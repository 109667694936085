/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Button, Field, Typography } from '@ekenta/components';
import { PriceRangeContainer } from './style';

import './PriceRange.scss';

const PriceRange = (props: {
    configureFilterUrl: (value: any) => void;
    setShowPopover?: any;
}) => {
    //const { setFilter } = props;

    const [priceRange, setPriceRange] = React.useState({
        min: '',
        max: '',
    });

    useEffect(() => {
        if (priceRange.min === '' && priceRange.max === '') {
            const data = { key: 'min_max', value: '', set: false };
            props.configureFilterUrl(data);
        }
    }, [priceRange]);

    const handlePriceRangeCheck = () => {
        if (
            parseInt(priceRange.min.toString()) > 0 &&
            parseInt(priceRange.max.toString()) > 0
        ) {
            const price_ = `${priceRange.min.toString()}-${priceRange.max.toString()}`;
            const data = { key: 'min_max', value: price_, set: true };
            props.configureFilterUrl(data);
            //props.setShowPopover(() => ({ type: "", show: false }));
        }
    };

    return (
        <PriceRangeContainer className="price_container_">
            <div className="mb-1 price_wrap">
                <Typography as="h3" heading className="filtersHeading">
                    Price
                </Typography>
                <div className="price_component_right">
                    <div className="price_range_div">
                        <Field
                            id="minimumprice"
                            name="minimumprice"
                            type="number"
                            numberFieldPrefix="₦ "
                            required
                            size="large"
                            thousandSeparator={true}
                            childOutsideLabel={false}
                            placeholder="₦Min"
                            fieldClassName=""
                            autoComplete="off"
                            value={priceRange.min}
                            onChange={(value: any) => {
                                setPriceRange({ ...priceRange, min: value.value });
                            }}
                        />
                        -
                        <Field
                            id="maximumprice"
                            name="maximumprice"
                            type="number"
                            required
                            size="large"
                            numberFieldPrefix="₦ "
                            thousandSeparator={true}
                            childOutsideLabel={false}
                            placeholder="₦Max"
                            fieldClassName=""
                            autoComplete="off"
                            value={priceRange.max}
                            onChange={(value: any) => {
                                setPriceRange({ ...priceRange, max: value.value });
                            }}
                        />
                        <Button
                            onClick={handlePriceRangeCheck}
                            bgVariant="primary"
                            textVariant="white"
                            disabled={
                                parseInt(priceRange.min.toString()) <= 0 ||
                                parseInt(priceRange.max.toString()) <= 0 ||
                                priceRange.min === '' ||
                                priceRange.max === ''
                            }
                        >
                            GO
                        </Button>
                    </div>
                </div>
            </div>
        </PriceRangeContainer>
    );
};

export default PriceRange;
