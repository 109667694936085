import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import ProfileSideBar from './component/AccountSideBar/AccountSideBar';
import BreadCrumb from './component/BreadCrumb';
import { withDefaultLayout } from '@ekenta/hoc';


const WalletSystem = () => {
  return (
    <>
      <Helmet>
        <title>Manage Account - Wallet - Qlason</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Qlason Wallet Page" />
        <meta name="description"
          content="Qlason Wallet Page"
        />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>
      <Container className="account-user-container">
        <><BreadCrumb activePage="Wallet" /></>

        <Row className="account-pro-row g-2">
          <Col xs lg={3} className="d-none d-lg-block account-col-left">
            <ProfileSideBar isLoading={null} />
          </Col>
          <Col className="account-profile-col-right px-0">
            <div className="mb-0 ">
              <h5 className="display_lg_header d-flex align-items-center">
                <span>Wallet</span>
              </h5>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default withDefaultLayout(WalletSystem);