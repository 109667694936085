import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Stars } from '../icons/stars.svg'
import { ReactComponent as Stars5 } from '../icons/star5.svg'
import { ReactComponent as Stars4 } from '../icons/star4.svg'
import { ReactComponent as Stars3 } from '../icons/star3.svg'
import { ReactComponent as Stars2 } from '../icons/star2.svg'

const Rating = () => {
  return (
    <Container>
      <div className="averageRating">
        <h5>4.9/5.0</h5>
        <HStack>
          <Stars />
          <p>(5 reviews)</p>
        </HStack>
      </div>
      <VStack>
        <HStack>
          <Stars5 />
          <Progress>
            <div className="progress-bar" />
          </Progress>
        </HStack>
        <HStack>
          <Stars4 />
          <Progress>
            <div className="progress-bar" />
          </Progress>
        </HStack>
        <HStack>
          <Stars3 />
          <Progress>
            <div className="progress-bar" />
          </Progress>
        </HStack>
        <HStack>
          <Stars2 />
          <Progress>
            <div className="progress-bar" />
          </Progress>
        </HStack>
      </VStack>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 14px;

  .averageRating {
    display: flex;
    height: 170px;
    flex-direction: column;
    padding: 28px 50px 28px 50px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #f3faff;
    gap: 8px;

    h5 {
      color: var(--secondary-500, #1d4057);
      font-family: var(--eke-body-font-family);
      text-align: center;
      font-size: 40.4px;
      font-weight: 700;
      letter-spacing: -0.504px;
    }
  }
`

const HStack = styled.div`
  display: flex;
  align-items: center;
  p {
    color: var(--greyscale-800, #424242);
    font-family: var(--eke-body-font-family);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    margin: 0 !important;
    padding-left: 8px;
  }

  svg {
    width: 150px !important;
  }
`

const VStack = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`

const Progress = styled.div`
  display: flex;
  height: 12px;
  padding: 0px 159px 0px 0.423px;
  align-items: center;
  flex: 1 0 0;
  border-radius: 17px;
  background: #fff9f3;
  margin-left: 8px;

  .progress-bar {
    width: 50px;
    height: 12px;
    flex: 1 0 0;
    border-radius: 17px;
    background: var(--primary-500, #f8880a);
  }
`

export default Rating
