/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../../constants/index';
import { changeUserPasswordType } from '../../../interfaces/Authentication';
import { NewAddressType } from '@ekenta/interfaces/AccountApi';


const accountApiHeaders = (token: string) => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`

});

const createMutationRequest = ({ url, method, body, token }: { url: string, method: string, body: any, token: string }) => ({
    url: url,
    headers: accountApiHeaders(token),
    method: method,
    body: body
});

const createQueryRequest = ({ url, method, token }: { url: string, method: string, token: string }) => ({
    url: url,
    headers: accountApiHeaders(token),
    method: method
});

const createMutationRequestWithoutBody = ({ url, method, token }: { url: string, method: string, token: string }) => {
    return {
        url: url,
        headers: accountApiHeaders(token),
        method: method,
    }
};

const createQueryRequestWithoutToken = ({ url, method }: { url: string, method: string }) => ({
    url: url,
    headers: {
        'Content-Type': 'application/json',
    },
    method: method
});

// /api/seller-merchant-details
// /api/admin/fetch-all-seller-daily-total-order-accounting
// add weight to the ui of product listing 
export const accountApi: any = createApi({
    reducerPath: 'accountApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['Address', 'Account', 'Request'],
    endpoints: (builder) => ({
        fetchUserProfileDetails: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `/api/user`, method: 'GET', token: token }),
            providesTags: ['Account']
        }),
        fetchUserSavedItems: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `/api/get-favorite-items`, method: 'GET', token: token })
        }),
        fetchAllState: builder.query<void, string>({
            query: () => createQueryRequestWithoutToken({ url: `/api/states`, method: 'GET' })
        }),
        fetchCitiesByStateId: builder.query<void, { stateId: string }>({
            query: ({ stateId }) => createQueryRequestWithoutToken({ url: `/api/cities-by-state/${stateId}`, method: 'GET' })
        }),
        fetchUserDefaultAddress: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `/api/address/defaulted`, method: 'GET', token: token }),
            providesTags: ['Address']
        }),
        getReferralAnalytics: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `/api/get-referrals`, method: 'GET', token: token })
        }),
        fetchUserActiveOrder: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `api/orderHistory/active`, method: 'GET', token: token })
        }),
        fetchUserClosedOrder: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `api/orderHistory/closed`, method: 'GET', token: token })
        }),
        fetchUserCompletedOrder: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `api/orderHistory/completed`, method: 'GET', token: token })
        }),
        changeUserPassword: builder.mutation<void, { data: changeUserPasswordType; token: string; }>({
            query: ({ data, token }) => createMutationRequest({ url: `/api/update-password`, method: 'POST', body: data, token: token })
        }),
        addNewUserAddress: builder.mutation<void, NewAddressType>({
            query: ({ data, token }) => createMutationRequest({ url: `/api/add-address`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['Address']
        }),
        addNewReturnRequest: builder.mutation<void, { data: { comment: string; action: string; reason: string; }; token: string, orderItemId: string }>({
            query: ({ data, token, orderItemId }) => createMutationRequest({ url: `/api/productReturnRequest/${orderItemId}`, method: 'POST', body: data, token: token })
        }),
        fetchProductToReturn: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `/api/fetch-orders-within-policy-window`, method: 'GET', token: token })
        }),
        updateProfile: builder.mutation({
            query: ({ data, token }) => createMutationRequest({ url: '/api/update-user', method: 'POST', body: data, token: token }),
            invalidatesTags: ['Account']
        }),
        changeDefaultAddress: builder.mutation<void, { token: string, id: string }>({
            query: ({ id, token }) => createMutationRequest({ url: `/api/address/change-default/${id}`, method: 'PUT', body: null, token: token }),
            invalidatesTags: ['Address']
        }),
        updateUserAddress: builder.mutation<void, NewAddressType>({
            query: ({ id, data, token }) => createMutationRequest({ url: `/api/update-address/${id}`, method: 'PUT', body: data, token: token }),
            invalidatesTags: ['Address']
        }),
        deleteUserAddress: builder.mutation<void, { id: string; token: string }>({
            query: ({ id, token }) => createMutationRequestWithoutBody({ url: `/api/delete-address/${id}`, method: 'PUT', token: token }),
            invalidatesTags: ['Address']
        }),
        fetchAllAddresses: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `/api/addresses`, method: 'GET', token: token }),
            providesTags: ['Address']
        }),
        fetchUserAllOrder: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `/api/buyer-orders`, method: 'GET', token: token })
        }),

        fetchUserReturnRequest: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `/api/buyer-return-request`, method: 'GET', token: token }),
            providesTags: ['Request']
        }),

        fetchProductReturnRequest: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `/api/product-return-request`, method: 'GET', token: token }),
            providesTags: ['Request']
        }),

        createProductReturnRequest: builder.mutation<void, any>({
            query: ({ data, token }) => createMutationRequest({ url: `/api/product-return-request`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['Request']
        }),

        fetchPendingReview: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `/api/get-product-pending-review`, method: 'GET', token: token }),
        }),

        rateProductItem: builder.mutation<void, any>({
            query: ({ data, token }) => createMutationRequest({ url: `/api/rate-product`, method: 'POST', body: data, token: token }),
        }),

        subscribeToNewsletter: builder.mutation<void, NewAddressType>({
            query: ({ data, token }) => createMutationRequest({ url: `/api/subscribe`, method: 'POST', body: data, token: token }),
        }),



    })
});

export const {
    useFetchUserProfileDetailsQuery,
    useGetReferralAnalyticsQuery,
    useFetchUserSavedItemsQuery,
    useFetchUserActiveOrderQuery,
    useFetchUserClosedOrderQuery,
    useFetchUserCompletedOrderQuery,
    useFetchUserDefaultAddressQuery,
    useFetchAllStateQuery,
    useFetchCitiesByStateIdQuery,
    useFetchProductToReturnQuery,
    useUpdateUserProfileMutation,
    useAddNewUserAddressMutation,
    useChangeUserPasswordMutation,
    useAddNewReturnRequestMutation,
    useUpdateProfileMutation,
    useChangeDefaultAddressMutation,
    useUpdateUserAddressMutation,
    useDeleteUserAddressMutation,
    useFetchAllAddressesQuery,
    useFetchUserAllOrderQuery,
    useFetchUserReturnRequestQuery,
    useFetchProductReturnRequestQuery,
    useFetchPendingReviewQuery,
    useCreateProductReturnRequestMutation,
    useRateProductItemMutation,
    useSubscribeToNewsletterMutation
} = accountApi;