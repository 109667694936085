import React from 'react'
import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LiveIconComp from '../../components/LiveCommerce/LiveIconComp';
import { singLiveBaseUrl } from '../../routes/constant';
import LiveLoveComp from './LiveLoveComp';
import default_livestream  from '../../assets/images/default_livestream.webp';

interface LiveProductCardPropType {
    id: string;
    className: string;
    imageUrl: string;
    title:string;
    type: string;
}


export default function LiveProductCard(props:LiveProductCardPropType) {

  
// const Div = styled.div`
//   background: url(`${props.imagUrl}`);
//   background-repeat: no-repeat;
//   background-size: cover;
//   `;

  return (
    <Col sm={12} md={6}  className={props.className} lg={3} >
      <Card as={Link} to={`${singLiveBaseUrl}/spm-${props.id}?type=${props.type}`}>
        <Card.Body className="position-relative">
          <div className="">
            <LiveIconComp />
            <LiveLoveComp />
            <div className="col-112 image__wrapper">
                <img src={props.imageUrl ? props.imageUrl : default_livestream} className="img-fluid rounded-start" alt="..." />
            </div>
          </div>
            <div  className="col- desc__lives-tream"
             style={{backgroundImage: `url(${props.imageUrl})`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
              <div className="col-absolute">
                    <h5>{props.title}</h5> <span>live deals</span>
              </div>
            </div>
        </Card.Body>
      </Card>
  </Col>
  )
}
