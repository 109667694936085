import React from 'react'
import { Carousel } from 'react-bootstrap'
import styled from 'styled-components'
import SVG from "react-inlinesvg";
import AuthBannerSVG from "@ekenta/assets/illustrators/livecomerce_banner.svg";
import EasyBannerSVG from "@ekenta/assets/illustrators/easy_ordering.svg";
import FastBannerSVG from "@ekenta/assets/illustrators/fast_reliable_banner.svg";


const AsideSlider = () => {
	return (
		<Container>
			<Carousel indicators={false}>
				<Carousel.Item>
					<div className="authAImage__Style">
						<SVG src={AuthBannerSVG} />
					</div>
					<h1>Live Commerce</h1>
					<p>
						Sellers or influencers host live video sessions where they demonstrate and promote products, answer questions,
						engage with viewers, and provide exclusive offers and discounts.
					</p>
				</Carousel.Item>
				<Carousel.Item>
					<div className="authAImage__Style">
						<SVG src={EasyBannerSVG} />
					</div>
					<h1>Easy Ordering</h1>
					<p>
						Ordering has never been easier. Add products to your cart, customize options, and choose your preferred payment method.
						Sit back and relax while we take care of the rest.
					</p>
				</Carousel.Item>
				<Carousel.Item>
					<div className="authAImage__Style">
						<SVG src={FastBannerSVG} />
					</div>
					<h1>Fast and Reliable Delivery</h1>
					<p>
						We understand the importance of timely deliveries. Experience fast and reliable delivery to your doorstep.
						Track your package in real-time and receive updates every step of the way.
					</p>
				</Carousel.Item>

			</Carousel>
		</Container>
	)
}


const Container = styled.div`
	width: 100%;

.carousel-item{

	p{
		text-align: center;
	}
		
	}
`


export default AsideSlider