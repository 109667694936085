import React, { memo } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import Loader from '../Loader/Loader'
import classnames from 'classnames'
import './style.scss'
import { FiX } from 'react-icons/fi'

const CustomModal = (props: {
  show: boolean
  size?: 'sm' | 'lg' | 'xl'
  title?: string
  colorScheme?: 'light' | 'dark'
  subtitle?: string
  onHide?: () => void
  centered?: boolean
  render?: React.ReactNode
  showFooter?: boolean
  onSubmit?: () => void
  isLoading?: boolean
  isDisabled?: boolean
  modalClass?: string
  modalPadding?: string
  headerClass?: string
  contentClass?: string
}) => {
  const {
    size = 'lg',
    headerClass,
    contentClass,
    colorScheme = 'light',
    modalClass,
    onHide,
    modalPadding = '10px',
  } = props

  const modeClass = classnames({
    ['modal-darkColor']: colorScheme === 'dark' ? true : false,
    ['modal-lightColor']: colorScheme === 'light' ? true : false,
  })

  return (
    <Modal
      className={classnames('modal_component shadow', modalClass, modeClass)}
      centered={props.centered}
      show={props.show}
      onHide={onHide}
      backdrop={true}
      size={size}
    >
      {props.title ? (
        <Modal.Header className={classnames('modal-header-div', headerClass)}>
          <Column>
            <Modal.Title
              className="display_lg_header p-0"
              style={{ fontSize: '16px !important', margin: '0 !important' }}
            >
              {props.title}
            </Modal.Title>
            {props.subtitle ? <p>{props.subtitle}</p> : null}
          </Column>
          <button onClick={onHide}>
            <FiX fontSize="24px" color="var(--eke-color-primary)" />
          </button>
        </Modal.Header>
      ) : null}

      <Modal.Body
        style={{ padding: `${modalPadding}` }}
        className={contentClass}
      >
        {props.render}
      </Modal.Body>
      {props.showFooter && (
        <Footer
          isLoading={props.isLoading}
          isDisabled={props.isDisabled}
          onSave={props.onSubmit}
          onClose={props.onHide}
        />
      )}
    </Modal>
  )
}

const Footer = (props: {
  isLoading?: boolean
  isDisabled?: boolean
  onSave?: () => void
  onClose?: () => void
}) => {
  return (
    <Container>
      <ButtonGroup>
        <button
          type="button"
          className="btn__cancel-Button_child"
          onClick={props.onClose}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn__submit-Button_child"
          onClick={props.onSave}
          disabled={props.isDisabled ? props.isDisabled : false}
        >
          {props.isLoading ? (
            <div className="d-flex align-items-center">
              <Loader color="white" />
              <span className="ms-1">Saving</span>
            </div>
          ) : (
            'Save'
          )}
        </button>
      </ButtonGroup>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 20px;
  padding-left: 20px;
  border-top: 1px solid #f5f5f5;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  button {
    font-size: 13px;
    padding: 7px 15px;
    border-radius: 5px;
    transition: all 0.3 ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    outline: none;

    &:hover {
      opacity: 0.8;
    }
  }

  .btn__cancel-Button_child {
    border: 1px solid #ddd;
  }

  .btn__submit-Button_child {
    border: 1px solid #e04826;
    background-color: #e04826;
    color: #fff;
  }
`

const Column = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  p {
    color: var(--greyscale-700, #616161);
    font-family: var(--eke-body-font-family);
    font-size: 16px;
    font-weight: var(--eke-font-weight-normal);
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.2px;
    margin-top: 8px;
  }
`

export default memo(CustomModal)
