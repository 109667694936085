/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { Accordion } from "@ekenta/components";
import { toast } from "react-toastify";
import { useCancelOrderQuery } from "@ekenta/redux/store/services/productsApi";
import { useFetchUserAllOrderQuery } from "@ekenta/redux/store/services/accountApi";

export default function MainOrder({ itemData }: any) {
  const { token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const [show, setShow] = useState(false);
  const [orderId, setOrderId] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const cancelOrder =

  const result = useCancelOrderQuery({ orderId, token }, { skip: !orderId });
  const allorder = useFetchUserAllOrderQuery(token, { skip: !token });


  const handleCancelOrder = async () => {
    setOrderId(itemData?.id);
  };

  useEffect(() => {
    if (result?.isSuccess) {
      handleClose();
      allorder?.refetch();
      toast.success(`${result?.data.message}`, {
        toastId: "result-mess-toast-success",
      });
    }
  }, [
    result?.isSuccess
  ]);

  return (
    <>
      <Accordion
        eventKey={itemData?.order_number}
        title={
          <div className="col-lg-12 row">
            <div className="col-lg-6">
              <p>Order Number: #{itemData?.order_number}</p>
              <p>Delivery Status: {itemData?.delivery_status}</p>
            </div>
            <div className="col-lg-6">
              <p>
                {" "}
                Total Amount: ₦{" "}
                {new Intl.NumberFormat().format(itemData?.total_order_amount)}
              </p>
              <p>Order Status: {itemData?.order_status}</p>
            </div>
          </div>
        }
      >
        <div className="float-end mb-2">
          <Badge
            className="px-3 py-2"
            style={{ cursor: "pointer" }}
            onClick={handleShow}
          >
            Cancel Order
          </Badge>
          <br />
        </div>

        {itemData?.order_item.map((e: any,) => (
          <div key={e.id} className="col-lg-12 card order--card-div mb-3">
            <div className="row g-0">
              <div
                className="col-3 col-md-3 pe-0 card--image_div"
                style={{ backgroundColor: "#f2f2f2" }}
              >
                <img
                  src={e?.product?.images[0]?.image_url}
                  className="img-fluid rounded-start"
                  alt="..."
                />
              </div>
              <div className="col-9 col-md-8 ps-0">
                <div className="card-body">
                  <div className="top--section__d">
                    <p className="headings">{e?.product?.name}</p>
                    <p className="body-text_p">Price: ₦{new Intl.NumberFormat().format(e?.price)}</p>
                    <p className="body-text_p">
                      Total Amount:{' '}
                      <span>₦
                        {new Intl.NumberFormat().format(
                          e?.price * e?.quantity
                        )}
                      </span>
                    </p>
                    <p className="body-text_p">
                      Quantity: <span>{e?.quantity}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Accordion>
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row className="p-3">
            <div>
              Are you sure you want to cancel order {itemData?.order_number} ?{" "}
              <br />
              <br />
            </div>
            <Col>
              <Button
                variant="danger"
                className="text-white text-center mx-auto"
                onClick={handleClose}
              >
                No
              </Button>{" "}
              {
                itemData?.delivery_status !== "COMPLETED" ?
                  <Button
                    variant="success"
                    className="text-white text-center mx-auto"
                    onClick={handleCancelOrder}
                  >
                    {result?.isLoading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner animation="grow" />
                        <span className="ms-1 text-sm">
                          <small>Please Wait...</small>
                        </span>
                      </div>
                    ) : (
                      <span>Yes</span>
                    )}
                  </Button> : null
              }

            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
