import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { withDashboardLayout } from '@ekenta/hoc'
import {
  Button,
  DataTable,
  HelmetHeader,
  PageTitle,
  Typography,
} from '@ekenta/components'
import { ReactComponent as SendIcon } from '@ekenta/assets/icons/send-square-icon.svg'
import { ReactComponent as DocIcon } from '@ekenta/assets/icons/doc-square-icon.svg'
import { ReactComponent as Doc2Icon } from '@ekenta/assets/icons/doc-icon.svg'
import { ReactComponent as RedeemIcon } from '@ekenta/assets/icons/redeem-icon.svg'
import { RCardGroup, RenderCard } from '@ekenta/components/reusable/RenderCard'
import {
  AdminAllVouchersPathUrl,
  AdminCreatedVouchersPathUrl,
} from '@ekenta/routes/constant'
import {
  useGetAllVoucherForAdminQuery,
  useGetAnalyticsAdminVoucherQuery,
} from '@ekenta/redux/store/features/admin/vouchersApiSlice'
import { TypographyWrapper } from '../../common/common'
import { voucherHistoryColumn } from './column'
import { VoucherStyle } from './style'

const Voucher = () => {
  const navigate = useNavigate()
  const { data: analytics, isLoading: loading } =
    useGetAnalyticsAdminVoucherQuery()
  const { data, isLoading } = useGetAllVoucherForAdminQuery()
  const voucher = data?.data?.data ?? []

  return (
    <>
      <HelmetHeader title="Voucher" description="Qlason Voucher Page" />
      <VoucherStyle>
        <div className="h-stacked">
          <PageTitle
            title="Voucher"
            subTitle={
              <span
                className="cursor-pointer d-flex w-lg-50 align-items-center 
						text-muted"
              >
                Manage and view voucher usage trends
              </span>
            }
          />

          <Button
            name="Create Voucher"
            type="button"
            bgVariant="primary"
            disabled={false}
            defaultClass={false}
            onClick={() => navigate(AdminCreatedVouchersPathUrl)}
          />
        </div>

        <RCardGroup>
          <RenderCard
            icon={<SendIcon width="51px" height="51px" />}
            loading={loading}
            text="No, of Vouchers"
            amount={`${analytics?.voucherCount || 0}`}
          />
          <RenderCard
            icon={<Doc2Icon />}
            text="Total Voucher Value"
            loading={loading}
            amount={`₦${new Intl.NumberFormat().format(
              analytics?.totalVoucherValue || 0
            )}`}
          />
          <RenderCard
            icon={<DocIcon width="51px" height="51px" />}
            loading={loading}
            text="Total Purchased Vouchers"
            amount={`${analytics?.totalPurchased || 0}`}
          />
          <RenderCard
            icon={<RedeemIcon width="51px" height="51px" />}
            loading={loading}
            text="Total Redeemed Vouchers"
            amount={`${analytics?.totalRedeemed || 0}`}
          />
        </RCardGroup>

        <div className="chart-container">
          <div className="chart-header">
            <Typography as="h5" size="medium">
              Voucher Redemption Frequency
            </Typography>
          </div>
          <div className="chart"></div>
        </div>
        <div className="datatable-wrapper">
          <DataTable
            theme="simple"
            data={voucher}
            shadow={true}
            loading={isLoading}
            isSelectable={true}
            columns={voucherHistoryColumn}
            showPagination={true}
            hasLink={true}
            thClassName=""
            trClassName="w-25"
            theadClassName="DataTable_THead"
            tdClassName={`Row__col`}
            cardClassName="DataTable_Card"
            showSearch={false}
            renderTop={
              <TypographyWrapper>
                <div className="table-title-wrapper">
                  <Typography as="h3" color="default" size="xlarge" heading>
                    All Voucher History
                  </Typography>
                  <Link to={AdminAllVouchersPathUrl} className="voucher-more">
                    See All
                  </Link>
                </div>
              </TypographyWrapper>
            }
          />
        </div>
      </VoucherStyle>
    </>
  )
}

export default withDashboardLayout(Voucher)
