/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useCallback, useState } from 'react';
import { BASE_URL } from '@ekenta/constants';
import axios from 'axios';
import { toast } from "react-toastify";
import { useEffect } from 'react';
import * as queryString from "query-string";
import { useLocation, useNavigate, createSearchParams, useParams, useSearchParams } from 'react-router-dom';



interface CollectionContextPropsType {
    isLoading: boolean;
    pageNo: number;
    setPageNo: any;
    filterData: any;
    configureFilterUrl: any;
}


const defaultState = {
    isLoading: false,
    pageNo: 1,
    filterData: null,
    setPageNo: () => false,
    configureFilterUrl: () => false,

};

export const CollectionContext = createContext<CollectionContextPropsType>(defaultState);

export const CollectionProvider = ({ children }: any) => {
    const navigate = useNavigate();
    const params = useParams();
    const { pathname } = useLocation();
    const query = queryString.parse(window.location.search);
    //const newUrl = new URL(window.location.href);
    // const newParams = new URLSearchParams(newUrl.search);
    const [searchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [filterData, setFilterData] = useState(null);
    const queryKeys = ["discounted", "min_max", "sort", "brands", "rating", "is_live"];
    const allData = {
        discounted: false,
        min_max: false,
        min: 0,
        max: 0,
        sort: "popular",
        sub_collection_id: "",
        sub_collection_category_id: "",
        keyword: "",
        brands: [],
        rating: 0,
        is_live: false,
        merchant_id: null,
    };


    useEffect(() => {
        const list = paramsToObject(searchParams.entries());
        const isMatch = queryKeys.some(key => list.includes(key));
        if (isMatch) {
            const data = handleQueryParams();
            handleRequest(data);
        } else {
            setFilterData(null)
        }
    }, [searchParams]);


    const configureFilterUrl = useCallback((data: { key: string; value: any; set: boolean }) => {
        if (data.set) {
            searchParams.set(data.key, data.value);
        } else {
            searchParams.delete(data.key);
        }
        navigate({
            pathname: pathname,
            search: `?${createSearchParams(searchParams)}`,
        });
    }, [searchParams]);

    function paramsToObject(entries: any) {
        const result: any = []
        for (const [key] of entries) { // each 'entry' is a [key, value] tupple
            result.push(key);
        }
        return result;
    }

    function handleQueryParams() {
        const data: any = { ...allData };
        if (query.brands) {
            data["brands"] = query.brands.split(",");
        } else {
            data["brands"] = []
        }
        if (query.discounted) {
            data["discounted"] = true
        } else {
            data["discounted"] = false
        }
        if (query.is_live) {
            data["is_live"] = true
        } else {
            data["is_live"] = false
        }
        if (query.sort) {
            data["sort"] = query.sort;
        } else {
            data["sort"] = "popular"
        }
        if (query.rating) {
            data["rating"] = parseInt(query.rating.toString());
        } else {
            data["rating"] = 0
        }
        if (query.min_max) {
            data["min"] = parseInt(query.min_max.split("-")[0]);
            data["max"] = parseInt(query.min_max.split("-")[1]);
            data["min_max"] = true;
        } else {
            data["min"] = 0;
            data["max"] = 0;
            data["min_max"] = false;
        }

        if (query.q) {
            data["keyword"] = query.q;
        } else {
            data["keyword"] = "";
        }

        const colId = setCollectionIds();
        data["sub_collection_category_id"] = colId[0];
        data["sub_collection_id"] = colId[1];

        const merchantId = setMerchantId();
        data["merchant_id"] = merchantId
        return data;
    }


    const setMerchantId = () => {
        const merchantId = params.id ? parseInt(params.id) : null
        return merchantId
    };

    function setCollectionIds() {
        const subCollectionId = params["subslug"]
            ? params.subslug!.slice(params.subslug!
                .lastIndexOf("-") + 1)
            : "";
        const categoryId = params["categoryslug"]
            ? params.categoryslug!.slice(params.categoryslug!.lastIndexOf("-") + 1)
            : "";

        return [categoryId, subCollectionId];
    }


    const handleRequest = useCallback((data: any) => {
        setIsLoading(true);
        const config = {
            method: 'POST',
            url: `${BASE_URL}/api/product-filter?page=${pageNo}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
                setFilterData(response.data.result);
            })
            .catch(function () {
                setIsLoading(false);
                toast.error(`Something went wrong`, {
                    toastId: "-filter-error-400-id-toast-error",
                });
            });
    }, []);

    return (
        <CollectionContext.Provider value={{
            isLoading,
            filterData,
            pageNo,
            setPageNo,
            configureFilterUrl
        }}>
            {children}
        </CollectionContext.Provider>
    )
};
