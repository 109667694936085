import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import SVG from "react-inlinesvg";

import styles from "./EmptyState.module.scss";

import EmptyStateSVG from "@ekenta/assets/illustrators/empty.svg";
import { EmptyStatePropsType } from "@ekenta/interfaces";

function EmptyState({
  title,
  description,
  buttonRender,
  containerWidth,
  hasBorder = false,
  hasPadding = true,
  centerGradient = false,
  renderIcon,
  faded = true,
}: EmptyStatePropsType) {
  const wrapperClasses = classnames(styles.main, {
    [styles.hasBorder]: hasBorder,
    [styles.hasPadding]: hasPadding,
    [styles.centerGradient]: centerGradient,
  });
  return (
    <section className={wrapperClasses}>
      <div className={styles.graphic}>
        <span>
          {renderIcon ? (
            renderIcon()
          ) : (
            <SVG src={EmptyStateSVG} />
          )}
        </span>
      </div>
      <div
        className={styles.textContainer}
        style={{ maxWidth: containerWidth }}
        data-testid="empty-state-text-container"
      >
        <h5 className={classnames(styles.heading, { [styles.faded]: faded })}>{title}</h5>
        <p className={`${styles.text} ${styles.faded}`}>{description}</p>
        {buttonRender ? buttonRender() : null}
      </div >
    </section >
  );
}

EmptyState.propTypes = {
  /**
   * The empty state title
   */
  title: PropTypes.string,
  /**
   * Content to display inside empty state
   */
  description: PropTypes.string,
  /**
   * Button element to display inside empty state
   */
  buttonRender: PropTypes.func,
  /**
   * Whether the border is show or not
   */
  hasBorder: PropTypes.bool,
  /**
   * Whether empty state component has component
   */
  hasPadding: PropTypes.bool,
  /**
   * Whether the background gradient is show or not
   */
  centerGradient: PropTypes.bool,
  /**
   * The custom element of the image to display. Either image or svg element
   */
  renderIcon: PropTypes.func,
};

export default EmptyState;
