import React from "react";
import styled, { css } from "styled-components";

export const StatusIndicator = ({ status, text }: { status: string, text?: string; }) => {
    return (
        <StatusStyle status={status} className="status_tag">
            <span className="indicator"></span>
            <span className="status_Text">{text ? text : status}</span>
        </StatusStyle>
    )
}


export const TextTruncates = styled.div<{ link?: boolean }>`
    width: 200px;
    height: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    a{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
        color: inherit;
        text-decoration: none;

        svg{
           position: absolute;
            right: 11%;
        }

        &:hover{
            text-decoration: underline;
        }
    }
`;

const StatusStyle = styled.div<{ status: string }>`
    display: flex;
    padding: 2px 8px 2px 6px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    ${props => props.status === "PENDING"
        ? css`
        background: var(--warning-50, #FFFAEB);
        `: props.status === "APPROVED" || props.status === "COMPLETED"
            ? css`background: var(--success-50, #ECFDF3);` :
            props.status === "PROCESSING" ?
                css`background: var(--proccess-50, #F0F9FF);` :
                props.status === "BLOCKED" ?
                    css`background: var(--error-50, #FEF3F2);`
                    : null
    }
            

    .indicator{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        ${props => props.status === "PENDING"
        ? css`
       background: var(--warning-500, #F79009);
        `: props.status === "APPROVED" || props.status === "COMPLETED"
            ? css`background: var(--success-500, #12B76A);` :
            props.status === "PROCESSING" ?
                css`background: var(--process-500, #0BA5EC);` :
                props.status === "BLOCKED" ?
                    css`background: var(--error-500, #F04438);`
                    : null
    }
        
    }

    .status_Text{
        text-align: center;
        font-family: var(--eke-body-font-family);
        font-size: 12px !important;
        text-transform: capitalize !important;
        font-weight: var(--eke-font-weight-medium);
        line-height: 18px; /* 150% */
        letter-spacing: 0.024px;
        ${props => props.status === "PENDING"
        ? css`
       color: var(--warning-700, #B54708);
        `: props.status === "APPROVED" || props.status === "COMPLETED"
            ? css`color: var(--success-700, #027A48);` :
            props.status === "PROCESSING" ?
                css`color: var(--process-500, #026AA2);` :
                props.status === "BLOCKED" ?
                    css`color: var(--error-700, #B42318);`
                    : null
    }
        
    }

`;
