import React from 'react'
import CardBox from '../CardBox/CardBox';

import styles from "./EmptyRequest.module.scss";
import Typography from '../Typography/Typography';

interface EmptyRequestPropsType {
    title: string;
    subtitle?: string;
    imageUrl?: string;
    padding?: boolean;
}

const EmptyRequest = (props: EmptyRequestPropsType) => {
    const { title, subtitle, imageUrl, padding } = props;
    return (
        <CardBox shadow={false}
            padding={padding}
            className={styles.CardBox_Parent}>
            <div className={styles.Empty__div_centered}>
                <div className={styles.Image}>
                    <img src={imageUrl ? imageUrl : "https://i5.walmartimages.com/dfw/63fd9f59-e0d6/65ab57af-59d6-423a-9500-1fa5ab36d1c7/v1/empty-cart.svg"}
                        alt="" aria-hidden="true" />
                </div>
                <Typography
                    as="h4"
                    size="large"
                    color="dark"
                    className={styles.CardBox_ParaText}
                    heading>
                    {title}
                </Typography>
                <Typography
                    as="span"
                    size="base"
                    color="dark"
                    className={styles.CardBox_ParaText}
                    uiText>
                    {subtitle}
                </Typography>
            </div>
        </CardBox>
    )
}

export default EmptyRequest;