import React, { Fragment } from 'react'
import { withDefaultLayout } from '@ekenta/hoc'
import { HelmetHeader, Loader } from '@ekenta/components'
import TopHeader from './TopHeader'
import Content from './Content'
import { BlogStyle, PostStyle } from '../style'
import LastestPost from './LastestPost'
import { useGetBlogPostBySlugQuery } from '@ekenta/redux/store/features/blog/blogApiSlice'
import { useParams } from 'react-router'
import { BlogContentType } from '@ekenta/redux/store/features/blog/blogDataType'

const BlogPostDetail = () => {
  const params = useParams()
  const slug = params?.slug ?? ''
  const { data, isLoading } = useGetBlogPostBySlugQuery(slug)
  const postData = data?.blog ?? ({} as BlogContentType)

  const pageUrl = window.location.href
  return (
    <>
      <HelmetHeader
        title="Blog - Online Shopping Made Easy"
        description="Qlason Blog Page"
        url={pageUrl}
      />
      <BlogStyle>
        <PostStyle>
          {isLoading ? (
            <Loader size="xlarge" isCenter withWrapper color="black" />
          ) : (
            <Fragment>
              <TopHeader 
                 title={postData?.title}
                 summary={postData?.summary}
                 written_by={postData?.written_by}
                 date={postData?.updated_at}
                 image_url={postData?.image_url}
              />
              <Content
                body={postData?.body}
              />
            </Fragment>
          )}
          <LastestPost />
        </PostStyle>
      </BlogStyle>
    </>
  )
}

export default withDefaultLayout(BlogPostDetail)
