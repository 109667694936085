import React from 'react'
import { HStack, InnerContainerStyle, StepContentStyle, StepFormGroup, StepHeading } from '../../../style'
import { Field, Typography } from '@ekenta/components'
import { useFormikContext } from 'formik';
import { NewProductListingFormType } from '@ekenta/interfaces/ProductListing';
import BrandSelect from './BrandSelect';

const AddProductDetails = () => {
    const { values, errors, touched, handleBlur, handleChange } = useFormikContext<NewProductListingFormType>();

    return (
        <InnerContainerStyle className="innercontain">
            <StepHeading>
                <Typography
                    as="h3"
                    color="default"
                    size="xlarge"
                    heading >
                    Add Product Details
                </Typography>
                <Typography
                    as="p"
                    color="default"
                    size="small">
                    Add your product details
                </Typography>
            </StepHeading>
            <StepContentStyle>
                <HStack pt="1rem">
                    <StepFormGroup>
                        <Field
                            id="productname"
                            name="productname"
                            label="Product Name"
                            disabled={false}
                            type="text"
                            required
                            size="large"
                            childOutsideLabel={false}
                            placeholder="Enter Product Name"
                            fieldClassName=""
                            autoComplete="off"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.productname}
                            isError={touched.productname && errors.productname ? true : false}
                            error={errors.productname}
                        />
                    </StepFormGroup>
                    <StepFormGroup>
                        <BrandSelect />
                    </StepFormGroup>
                </HStack>
                <>
                    <Field
                        id="productshortDescription"
                        name="productshortDescription"
                        label="Description"
                        disabled={false}
                        type="textarea"
                        required
                        size="large"
                        childOutsideLabel={false}
                        placeholder="Enter Product Description"
                        fieldClassName=""
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.productshortDescription}
                        isError={touched.productshortDescription && errors.productshortDescription ? true : false}
                        error={errors.productshortDescription}
                    />
                </>
            </StepContentStyle>
        </InnerContainerStyle>
    )
}

export default AddProductDetails