/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, lazy, Suspense } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import AuthRoute from './hook/authRoute'
import { useAnalytics } from 'use-analytics'
import {
  AuthenticationRoutes,
  AuthorizeDashboardRoutes,
  AuthorizeUserRoutes,
  LandingPageRoutes,
  OnlyOnboardingPageRoute,
} from './common'
import LandingPgRoute from './hook/landingPageRoute'
import ProtectedRouteWithRoles from './hook/protectedRouteWithRoles'
import OnboardingRoute from './hook/onboardingRoute'
import withNetworkDetector from '../hoc/withNetworkDetetor'
import { FACEBOOK_PIXEL_ID, REACT_APP_ENV } from '@ekenta/constants'
import { initializeFacebookPixel } from '@ekenta/utils/initializeFbPixel'

const PageNotFound = lazy(() => import('../pages/ErrorPage/PageNotFound'))

const AllRoutes = () => {
  const analytics = useAnalytics()
  const location = useLocation()

  useEffect(() => {
    analytics.page()
  }, [location])

  useEffect(() => {
    if (REACT_APP_ENV === 'production')
      initializeFacebookPixel(FACEBOOK_PIXEL_ID)
  }, [REACT_APP_ENV, FACEBOOK_PIXEL_ID])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [location])

  return (
    <Suspense fallback={'Loading..'}>
      <Routes>
        {LandingPageRoutes.map((route: any) => (
          <Route
            key={route.path}
            path={route.path}
            element={<LandingPgRoute component={route.component} />}
          />
        ))}

        {OnlyOnboardingPageRoute.map((route: any) => (
          <Route
            key={route.path}
            path={route.path}
            element={<OnboardingRoute component={route.component} />}
          />
        ))}

        {AuthenticationRoutes.map(
          ({
            path,
            component,
          }: {
            path: string
            component: () => JSX.Element
          }) => (
            <Route
              key={path}
              path={path}
              element={<AuthRoute component={component} />}
            />
          )
        )}

        {AuthorizeUserRoutes.map(
          ({
            path,
            roles,
            component,
          }: {
            path: string
            roles: string[]
            component: () => JSX.Element
          }) => (
            <Route
              key={path}
              path={path}
              element={
                <ProtectedRouteWithRoles component={component} roles={roles} />
              }
            />
          )
        )}

        {AuthorizeDashboardRoutes.map(
          ({
            path,
            roles,
            component,
          }: {
            path: string
            roles: string[]
            component: () => JSX.Element
          }) => (
            <Route
              key={path}
              path={path}
              element={
                <ProtectedRouteWithRoles component={component} roles={roles} />
              }
            />
          )
        )}

        {/* onBoarding Route */}

        {/* { ERROR  route} */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  )
}

export default withNetworkDetector(AllRoutes)
