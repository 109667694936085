/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { CustomModal, Field, Label } from '@ekenta/components';
import CustomSelect from '@ekenta/components/Select/CustomSelect';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { useFormikContext } from 'formik';
import { NewProductListingFormType } from '@ekenta/interfaces/ProductListing';
import { useAddBrandRequestMutation, useFetchBrandsByCollectionIdQuery, useMapBrandToCollectionMutation } from '@ekenta/redux/store/services/vendorProductApi';
import { SquarePlus } from '@ekenta/components/icons';


const mapCollectionData = (data: any) => {
    return data && data.map((item: any) => (
        {
            value: item.id,
            label: item.name
        }
    ));

}


const BrandSelect = () => {
    const { values, errors, handleBlur, setFieldValue, setFieldTouched } = useFormikContext<NewProductListingFormType>();

    const { token } = useAppSelector(state => state.auth.authentication);

    const [options, setOptions] = useState<any>([]);
    const [show, setShow] = useState(false);
    const [brand, setBrand] = useState({
        name: "",
    });
    const [iserror, setisError] = useState("");



    const collectionId = values.collectionId;
    const { data, isLoading, isError, isSuccess } = useFetchBrandsByCollectionIdQuery(collectionId, { skip: !collectionId });
    const [addBrandRequest, results] = useAddBrandRequestMutation();
    const [mapBrandToCollection, mappedResult] = useMapBrandToCollectionMutation();
    const reference = useRef<any>(null)


    useEffect(() => {
        if (data && isSuccess) {
            const obj = mapCollectionData(data.collections.brand);
            setOptions(obj);
        }

        if (reference.current && collectionId) {
            if (reference.current.getValue().length > 0) {
                reference.current.setValue({
                    value: "",
                    label: ""
                });
            }
        }
    }, [data, isSuccess, collectionId]);


    useEffect(() => {
        if (results?.isSuccess && values.collectionId) {
            const data = {
                brandId: results?.data.id,
                collectionId: values.collectionId
            };
            mapBrandToCollection({
                data,
                token
            });
            handleHideModal();
        }
    }, [results?.isSuccess, values.collectionId]);


    const defaultValue = (options: any, val: string) => {
        return options ? options.find((option: any) => option.value === parseInt(val)) : "";
    }

    const handleHideModal = () => {
        setisError("");
        setBrand({
            name: "",
        });
        setShow(false);
    }

    const handleShowModal = () => setShow(true);

    const handleSubmit = () => {
        setisError("");

        if (brand.name.trim()) {
            const data = {
                name: brand.name,
                description: "",
                collection_id: collectionId
            }

            addBrandRequest({
                data,
                token,
            });

        } else {
            setisError("*Field is required");
        }
    }



    return (
        <Fragment>
            <Label
                type="text"
                text="Brand"
                required={true}
            />
            <Wrapper>
                <CustomSelect
                    reference={reference}
                    isDisabled={!values.collectionId ? true : false}
                    isLoading={isLoading}
                    className="custom-select"
                    placeholder="Select Brands..."
                    noOptionsMessage={() => isError ? "Could not load, refresh" : "No Brands Found"}
                    value={defaultValue(options, values.brandId)}
                    options={options}
                    isError={errors.brandId ? true : false}
                    error={errors.brandId}
                    onBlur={handleBlur}
                    onChanged={(value: any) => {
                        //reference.current.clearValue();
                        setFieldValue("brandId", value.value);
                        setFieldTouched("brandId", true);

                    }}
                />
                <AddBtn
                    type="button"
                    disabled={!values.collectionId ? true : false}
                    onClick={handleShowModal}>
                    <SquarePlus />
                </AddBtn>
            </Wrapper>

            <CustomModal
                title="Add Brand"
                show={show}
                centered={false}
                onHide={handleHideModal}
                showFooter={true}
                isLoading={results?.isLoading || mappedResult?.isLoading}
                isDisabled={false}
                onSubmit={handleSubmit}
                render={
                    <FormWrapperStyle>
                        <form>
                            <Field
                                id="addbrand"
                                name="addbrand"
                                label="Add Brand"
                                disabled={results?.isLoading || mappedResult?.isLoading}
                                type="text"
                                required
                                size="large"
                                childOutsideLabel={false}
                                placeholder="Enter Brand Name"
                                fieldClassName=""
                                autoComplete="off"
                                isError={iserror && !brand.name ? true : false}
                                error={iserror ? iserror : ""}
                                value={brand.name}
                                onChange={(e) => setBrand(prevState => ({ ...prevState, name: e.target.value }))}
                            />
                        </form>
                    </FormWrapperStyle>
                }
            />
        </Fragment>
    )
}

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: start;

    .custom-select{
        flex: 1 1 auto !important;

        .css-pr5hct-control{
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
        }
    }
    


`;

const FormWrapperStyle = styled.div`
    form{

    }
`;

const AddBtn = styled.button`
    outline: none;
    border: none;
    padding: 8px 5px;
    width: 40px;
    height: 48px;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: var(--eke-color-primary);



    svg{
        width: 24px;
        height: 24px;
        fill: var(--eke-color-white) !important;
    }
`;
export default BrandSelect