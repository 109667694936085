export const allProductColumns = [
  {
    Header: 'Picture',
    accessor: 'image',
    sortType: 'basic',
    disableSortBy: true,
  },

  {
    Header: 'Name',
    accessor: 'name',
    sortType: 'basic',
  },
  {
    Header: 'Price',
    accessor: 'price',
    sortType: 'basic',
    disableSortBy: true,
  },
  {
    Header: 'Subcollection',
    accessor: 'subcollection',
    sortType: 'basic',
  },

  {
    Header: 'Quantity',
    accessor: 'stock',
    disableSortBy: true,
  },
  // {
  //   Header: 'Published',
  //   accessor: 'visiblity',
  //   disableSortBy: true,
  // },
  // {
  //   Header: 'Action',
  //   accessor: 'option',
  //   disableSortBy: true,
  // },
];


