/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import * as queryString from "query-string";
import {
  FiChevronDown,
  FiExternalLink,
} from "react-icons/fi";
import { useLocation, useNavigate } from "react-router";
import csvDownload from "json-to-csv-export";
import { toast } from "react-toastify";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { useFetchAllActiveSellersQuery } from "@ekenta/redux/store/services/adminSellerApi";
import {
  AdminSellerProfileId,
  DashboardSellers,
} from "@ekenta/routes/constant";
import Export from "../common/ExportButton/Export";
import { Pagination, DataTable, HelmetHeader } from "@ekenta/components";
import Dropdown from "@ekenta/components/Dropdown/Dropdown3";
import PageTitle from "@ekenta/components/PageTitle/PageTitle";
import { VscArrowSmallLeft } from "react-icons/vsc";
import withDashboardLayout from "@ekenta/hoc/Layout/withDashboardLayout";
import { StatusIndicator, TextTruncate } from "../common/Resuable";
import { Switch } from "@mui/material";
import { BASE_URL } from "@ekenta/constants";
import axios from "axios";

const AllActiveSellers = () => {
  const query = queryString.parse(window.location.search);
  const _page = query.page;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { token } = useAppSelector((state) => state.auth.authentication);

  const [pageno, setPageno] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [SellerData, setSellerData] = useState([]);
  const [search, setSearch] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');


  const result = useFetchAllActiveSellersQuery(
    {
      pageno,
      keyword: debouncedSearchTerm,
      token
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      keepPreviousData: false,
    }
  );

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      sortType: "basic",
    },
    {
      Header: "Store Name",
      accessor: "store",
      sortType: "basic",
    },
    {
      Header: "Live code",
      accessor: "liveLink",
      disableSortBy: true,
    },
    {
      Header: "Live key",
      accessor: "liveKey",
      disableSortBy: true,
    },
    {
      Header: 'is_Live',
      accessor: 'is_live',
      disableSortBy: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
    },
    {
      Header: "Action",
      accessor: "option",
      disableSortBy: true,

    },
  ];


  useEffect(() => {
    const delay = setTimeout(() => {
      setDebouncedSearchTerm(search);
      setPageno(1)
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [search]);


  useEffect(() => {
    if (result?.data) {
      setSellerData(result?.data?.sellers.data);
    }
  }, [result?.data]);



  const onSearch = (e: any) => {
    setSearch(e.target.value);
  };



  const mapData = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      name: (
        <TextTruncate>{item.user.first_name + " " + item.user.last_name}</TextTruncate>
      ),
      store: (
        <TextTruncate>{item.business_name}</TextTruncate>
      ),
      liveKey: item.live_key,
      liveLink: item.live_link,
      is_live: (
        <Switch
          readOnly={!item?.user?.is_seller_live}
          size="small"
          color="warning"
          defaultChecked={item?.user?.is_seller_live}
          disabled={!item?.user?.is_seller_live ? true : false}
          onChange={(e) => item?.user?.is_seller_live
            ? toggleOffLive(item?.user?.id, e.target.checked)
            : console.log('')
          }
        />
      ),
      status: (
        item.seller_request_status ? (
          <StatusIndicator status={item.seller_request_status} />
        ) : ""
      ),
      option: (
        <Dropdown
          name={"more"}
          suffixIcon={<FiChevronDown />}
          placement="bottom"
          menus={[
            {
              name: "Seller Profile",
              icon: <FiExternalLink />,
              active: false,
              key: "seller-profile",
              handler: () =>
                navigate(`${AdminSellerProfileId}/${item.user?.id}`),
            },
            {
              name: "View details",
              icon: <FiExternalLink />,
              active: false,
              key: "view-detail",
              handler: () =>
                navigate(`/admin/seller-review/${item.id}?type=active`),
            },
          ]}
          tooltipContent={"More Option"}
        />
      ),
    }));
  };


  const toggleOffLive = useCallback(
    (id: string, checked: boolean) => {
      const config = {
        method: 'get',
        url: `${BASE_URL}/api/admin/stop-seller-live/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: '',
      };
      setIsLoading(true);
      axios(config)
        .then(function (data) {
          const response = data?.data;
          setSellerData((prevState: any) =>
            prevState.map((item: any) =>
              item?.user?.id === id
                ? {
                  ...item,
                  user: {
                    ...item.user,
                    is_seller_live: checked
                  }
                } : item
            )
          );

          setIsLoading(false);
          toast.success(`${response?.message}`, {
            toastId: '--toggle-live-id-toast',
          });
        })
        .catch(function () {
          setSellerData((prevState: any) =>
            prevState.map((item: any) =>
              item.id === id
                ? {
                  ...item,
                  user: {
                    ...item.user,
                    is_seller_live: !checked
                  }
                }
                : item
            )
          );
          setIsLoading(false);
          toast.error(`Something went wrong`, {
            toastId: '-toggle-error-400-live-toast-error',
          });
        });
    },
    [],
  );

  const handleDownloadData = useCallback(
    (type: string) => {
      if (result?.data) {
        if (type === "csv") {
          try {
            const data: any = [];
            result?.data?.sellers.data.map((item: any) => {
              data.push({
                firstname: item.user.first_name,
                lastname: item.user.last_name,
                email: item.user.email,
                Address: item.address,
                businessName: item.business_name,
                businessPhoneNumber: item.business_phone_number,
              });
            });
            const name = `${`Activesellers`}-ekenta.csv`;
            csvDownload(data, name);
            toast.success("Exported csv file successfully", {
              toastId: "export-csv-id-toast-success",
            });
          } catch (err) {
            toast.error("Unable to export to csv file", {
              toastId: "export-to-csv-toast-error",
            });
          }
        }
      }
    },
    [result?.data],
  );

  return (
    <>
      <HelmetHeader
        title="All Active Sellers"
        description="Qlason All Active Sellers Page"
      />
      <div className="mt-0">
        <Col className="AppEvents_col mx-1 mx-lg-0">
          <PageTitle
            title=" Active Sellers"
            subTitle={

              <span
                className="cursor-pointer d-flex w-lg-50 align-items-center 
                    text-muted"
                onClick={() => navigate(DashboardSellers)}>
                <VscArrowSmallLeft />
                Back to Dashboard
              </span>
            }
          >
            <Export downloadData={handleDownloadData} name={"Export"} />
          </PageTitle>
        </Col >

        <Col className="mt-4 tab__ position-relative">
          <DataTable
            theme="simple"
            data={result?.data ? mapData(SellerData) : []}
            loading={result?.isLoading || result?.isFetching || isLoading}
            isSelectable={true}
            columns={columns}
            showPagination={true}
            hasLink={true}
            thClassName=""
            trClassName="w-25"
            tdClassName={`Row__col`}
            showSearch={true}
            searchValue={search}
            onSearch={onSearch}
            renderPagnation={
              <div className="mt-4">
                {result?.data && result?.data?.sellers?.total > 10 ? (
                  <Pagination
                    pathname={pathname}
                    itemPerPage={result?.data?.sellers?.per_page}
                    itemsLength={result?.data?.sellers?.total}
                    currentPage={pageno}
                    queryNo={_page}
                    callback={(value) => setPageno(value)}
                  />
                )
                  : null}
              </div>
            }
          />

          <br />
        </Col>
      </div >

    </>
  );
};

export default withDashboardLayout(AllActiveSellers);
