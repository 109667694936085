/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import classnames from "classnames";
import { useLocation } from "react-router";
import AppContent from "@ekenta/components/AppContent";
import ErrorBoundaries from "@ekenta/components/ErrorBoundary/ErrorBoundaries";
import { useMatchPath } from "@ekenta/utils";
import {
  mobileAccountIndexPath,
  singleLivePathUrl,
} from "@ekenta/routes/constant";
import Footer from "@ekenta/components/Footer";
import CollectionsOffCanvas from "@ekenta/components/OffCanvas/CollectionSidCanvas/CollectionSideCanvas";
import HelpSideCanvas from "@ekenta/components/OffCanvas/CollectionSidCanvas/HelpSideCanvas";
import Navigation from "@ekenta/components/NavigationBar";
import BannerAds from "@ekenta/components/NavigationBar/BannerAds";

export default function withDefaultLayout(
  WrappedComponent: () => JSX.Element,
  className?: string,
) {
  const DefaultLayout = (props: any) => {
    const { pathname } = useLocation();
    return (
      <>
        <BannerAds />
        <Navigation />
        <ErrorBoundaries externalError={false} wrapContent={true}>
          <AppContent
            full={true}
            isPad={false}
            noStyle={true}
            className={classnames(
              "main--container parent px-0 pt-lg-0",
              pathname === useMatchPath(singleLivePathUrl, pathname) &&
              "view_live_pad",
              className,
            )}
          >
            <WrappedComponent {...props} />
          </AppContent>
          {
            pathname === useMatchPath(mobileAccountIndexPath, pathname)
              || pathname === useMatchPath(singleLivePathUrl, pathname)
              ? null : (
                <Footer />
              )}
        </ErrorBoundaries>
        <CollectionsOffCanvas />
        <HelpSideCanvas />
        {/* <div className="mobile_search_window">
          <SearchMobileOffCanavs />{" "}
        </div> */}
      </>
    );
  };

  DefaultLayout.displayName = "DefaultLayout";
  return DefaultLayout;
}
