import React from 'react'
import styled from 'styled-components'

export const SellerNotLive = (props:{businessName: string}) => {
  return (
    <Container>
        <div className="container-rs Q">
        <div className="image-container">
            {/* <img src={props.image} alt="" /> */}
        </div>
        <div className="container-content">
            <h5>Seller ({props.businessName}) is not currently live </h5>
            <p>Check out seller&apos;s product</p>
        </div>
        </div>
  </Container>
  )
}


const Container = styled.div`
 width: 100%;
 height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba( 35, 47, 62, 0.55 );
//box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
  
  .container-rs {
    padding: 2rem 1rem;
    width: 100%;
    
    text-align: center;
    color: whitesmoke;

    @media (min-width: 1200px) { 
        width: 45%;
        padding: 2rem 2rem;
    }

     .image-container{
        img{
          margin-bottom: 8px;
          margin-top: 36px;
          max-width: 256px;
        }
     }

     .container-content{
       h5{
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 10px;
        font-family: 'Noto Sans', sans-serif;
        color: whitesmoke;
       }
       p{
        font-size: 14px;
        font-weight: 300;
        max-width: 400px;
        margin: 0 auto;
        margin-bottom: 36px;
        text-align: center;
        font-family: 'Noto Sans', sans-serif;
       }
     }

  }
  
`