/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Card, Col, Form, ListGroup } from 'react-bootstrap'
import { Button, Loader } from '@ekenta/components'
import Header from '../Header/Header'

import flutterwave from '@ekenta/assets/images/Flutterwave_002316.png'
import paystack from '@ekenta/assets/images/Paystack_100234.png'
import thepeer from '@ekenta/assets/images/thepeer2.jpg'
import cash_on_delivery from '@ekenta/assets/images/cash_on_delivery.png'

import './PaymentOption.scss'
import PaymentOptionItem from './PaymentOptionItem'
import { VscArrowSmallRight } from 'react-icons/vsc'

interface PaymentOptionPropType {
  isLoading: boolean
  values: any
  errors: any
  handleBlur: any
  isSuccess: boolean
  orderLoading: boolean
  handleSubmit: () => void
  setFieldValue: any
  paymentOptionEnums: {
    PAYSTACK: string
    FLUTTERWAVE: string
    POD: string
    THEPEER: string
  }
}

const PaymentOption: React.FC<PaymentOptionPropType> = ({
  isLoading,
  values,
  errors,
  handleBlur,
  isSuccess,
  orderLoading,
  handleSubmit,
  setFieldValue,
  paymentOptionEnums,
}) => {
  return (
    <Col className="checkout__payment__option">
      <Form noValidate onSubmit={handleSubmit}>
        <Card>
          <Card.Body>
            {isLoading ? (
              <Loader isCenter />
            ) : (
              <>
                <Header
                  title="Payment Option"
                  active={
                    values.deliveryChannel && values.deliveryCharge !== null
                  }
                  isBorder={
                    values.deliveryChannel && values.deliveryCharge !== null
                  }
                />
                {isSuccess ? (
                  <Col className="Payment_option__Col pt-0">
                    <ListGroup>
                      <PaymentOptionItem
                        id="paymentOption"
                        onlyLabel={true}
                        imageClass="w-auto"
                        label={`
                                 ${
                                   values.paymentChannel ===
                                   paymentOptionEnums.PAYSTACK
                                     ? 'Pay With Paystack'
                                     : values.paymentChannel ===
                                       paymentOptionEnums.FLUTTERWAVE
                                     ? 'Pay With Flutterwave'
                                     : values.paymentChannel ===
                                       paymentOptionEnums.THEPEER
                                     ? 'Pay With ThePeer'
                                     : values.paymentChannel ===
                                       paymentOptionEnums.POD
                                     ? 'Pay On Delivery'
                                     : null
                                 } 
                                `}
                        renderImage={
                          values.paymentChannel === paymentOptionEnums.PAYSTACK
                            ? paystack
                            : values.paymentChannel ===
                              paymentOptionEnums.FLUTTERWAVE
                            ? flutterwave
                            : values.paymentChannel ===
                              paymentOptionEnums.THEPEER
                            ? thepeer
                            : values.paymentChannel === paymentOptionEnums.POD
                            ? cash_on_delivery
                            : null
                        }
                      />
                    </ListGroup>
                  </Col>
                ) : (
                  <>
                    {values.deliveryChannel &&
                    values.deliveryCharge !== null ? (
                      <>
                        <Col className="Payment_option__Col pt-0">
                          <ListGroup>
                            {/* <PaymentOptionItem
                              id="paystack"
                              label="Pay With Paystack"
                              paymentChannel={paymentOptionEnums.PAYSTACK}
                              values={values}
                              errors={errors}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              renderImage={paystack}
                            />
                            <PaymentOptionItem
                              id="flutterwave"
                              label="Pay with Flutterwave"
                              paymentChannel={paymentOptionEnums.FLUTTERWAVE}
                              values={values}
                              errors={errors}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              renderImage={flutterwave}
                            /> */}
                            <PaymentOptionItem
                              id="flutterwave"
                              label="Pay with Flutterwave"
                              paymentChannel={paymentOptionEnums.FLUTTERWAVE}
                              values={values}
                              errors={errors}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              renderImage={flutterwave}
                            />
                            {/* <PaymentOptionItem
                              id="thepeer"
                              label="Pay with ThePeer"
                              paymentChannel={paymentOptionEnums.THEPEER}
                              values={values}
                              errors={errors}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              renderImage={thepeer}
                            /> */}
                            {/* Note: POD should be only available when Pickup option is selected */}
                            {/* {
                              values.deliveryChannel == "PICKUP_STATION" || values.logistics == "EDS" ?
                                <PaymentOptionItem
                                  id="POD"
                                  // disabled={values.logistics === "EDS" ? false : true}
                                  label="Pay on Delivery"
                                  paymentChannel={paymentOptionEnums.POD}
                                  values={values}
                                  errors={errors}
                                  imageClass="w-auto"
                                  handleBlur={handleBlur}
                                  setFieldValue={setFieldValue}
                                  renderImage={cash_on_delivery}
                                /> : null
                            } */}
                          </ListGroup>
                        </Col>

                        {values.paymentChannel ? (
                          <>
                            {isSuccess ? null : (
                              <div className="mt-3 w-100 ButtonOp_Wrapper">
                                <Button
                                  name="Proceed"
                                  bgVariant="success"
                                  textVariant="light"
                                  disabled={orderLoading}
                                  suffixIcon={
                                    <VscArrowSmallRight fontSize={15} />
                                  }
                                  width="w-100"
                                  isLoadingText="Proceed"
                                  isLoading={orderLoading}
                                  shadow={false}
                                  type="submit"
                                />
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Form>
    </Col>
  )
}

export default PaymentOption
