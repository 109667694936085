import { combineReducers } from '@reduxjs/toolkit'
import authenticationReducer from './reducerSlice/authenticationSlice'
import cartReducer from './reducerSlice/cartSlice'
import newCartReducer from '../features/cart/cartSlice'
import loginReducer from './reducerSlice/loginSlice'
import LiveComReducer from './reducerSlice/livecomSlice'
import SellerdetailReducer from './reducerSlice/sellerSlice'
import savedItemReducer from './reducerSlice/savedItemSlice'
import feedBackReducer from './reducerSlice/feedBackSlice'
import ChatCount from '../features/chat/chatSlice'

export const authReducer = combineReducers({
  authentication: authenticationReducer,
  login: loginReducer,
  livecom: LiveComReducer,
  sellerdetail: SellerdetailReducer,
  popUp: feedBackReducer,
  chat: ChatCount,
})

export const cartsReducer = combineReducers({
  cart: cartReducer,
  newCart: newCartReducer,
})

export const savedItemsReducer = combineReducers({
  savedItem: savedItemReducer,
})
