import React from 'react';
import { Helmet } from 'react-helmet-async';

export const HelmetHeader = (props: {
  title: string;
  description: string;
  url?: string;
  keywords?: string;
}) => {
  const { title, description, keywords, url = '/' } = props;
  return (
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>{title + '- Qlason'}</title>
      <meta charSet="utf-8" />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={url} />
      <meta property="og:locale" content="en_NG" />
      <meta property="og:title" content={title} />
      <meta
        property="og:site_name"
        content="Qlason - Online Shopping Made Easy"
      />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="" />
      <meta property="og:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="" />
      
    </Helmet>
  );
};

export default HelmetHeader;
