import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';

export default function ProfileLoader(props: any) {
    return (
        <div className="skeleton-loader-bases">
            <Row className="account-row">
                <Col className='' xs={12} lg={6}>
                    <ContentLoader
                        speed={2}
                        width={500}
                        height={260}
                        viewBox="0 0 500 260"
                        backgroundColor="#e0e0e0"
                        foregroundColor="#ecebeb"
                        {...props}
                    >
                        <rect x="2" y="34" rx="5" ry="5" width="140" height="10" />
                        <rect x="328" y="34" rx="5" ry="35" width="72" height="10" />
                        <rect x="2" y="65" rx="5" ry="5" width="400" height="250" />
                    </ContentLoader>
                </Col>
                <Col xs={12} lg={6}>
                    <ContentLoader
                        speed={2}
                        width={500}
                        height={260}
                        viewBox="0 0 500 260"
                        backgroundColor="#e0e0e0"
                        foregroundColor="#ecebeb"
                        {...props}
                    >
                        <rect x="2" y="34" rx="5" ry="5" width="140" height="10" />
                        <rect x="328" y="34" rx="5" ry="35" width="72" height="10" />
                        <rect x="2" y="65" rx="5" ry="5" width="400" height="250" />
                    </ContentLoader>
                </Col>

            </Row>
        </div>
    )
}
