import React from "react"
import { Container, Navbar } from "react-bootstrap"
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom"

import { cartPathUrl } from "@ekenta/routes/constant";
import { Logo, RefreshLink } from "@ekenta/components";
import styled from "styled-components";


export default function CheckoutNavBar() {
  const navigate = useNavigate();
  return (
    <>
      <StyledNavBar className={`nav-header_ checkout-nav-bg`}>
        {/* collapseOnSelect */}
        <Navbar className="menubar checkout_menubar p-0" variant="dark" expand="lg">
          <div className="back__icon_div">
            <button title="Back To Cart" onClick={() => navigate(cartPathUrl)}>
              <FiChevronLeft />
            </button>
          </div>
          <Container className="Checkoutcontainer_wrapper">
            <div className="brand_check_wrapper">
              {/* <span><FiChevronLeft fontSize={45} color={"orange"} cursor={"pointer"}  onClick={() => navigate("/cart")}/></span> */}
              <Navbar.Brand as={RefreshLink} to="/" title="Qlason logo">
                <Logo />
              </Navbar.Brand>
            </div>
          </Container>
        </Navbar>
      </StyledNavBar>
    </>
  )
}


const StyledNavBar = styled.div`
  background-color: var(--eke-bg-secondary-color);
`