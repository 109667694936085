import { device } from '@ekenta/utils/devices'
import styled from 'styled-components'

export const OverlayFixed = styled.div`
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9); /* Black background with opacity */
  //z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  display: block;
`

export const HoveringComponent = styled.li`
  list-style: none !important;
  width: auto;
  height: auto;
  padding: 0.3rem 0 !important;

  margin: 0 !important;
`

export const NavigationStyle = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  min-height: 86px;
  background: var(--eke-color-white);
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.12);
  transition: position 0.3s ease-in-out;
`

export const RightOptionStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding-right: 0.5rem;

  @media ${device.lg} {
    padding-right: 0rem;
  }
`

export const SearchStyle = styled.div`
  flex-basis: 100%;
  width: 100%;
  order: 3;
  margin-top: 18px;

  @media ${device.md} {
    flex: 1;
    margin-top: 0px;
    order: 0;
  }
`

export const DropdownStyle = styled.div<{ isOpen: boolean }>`
  button {
    position: relative;
    z-index: ${(props) => (props.isOpen ? 99999 : 1)} !important;
    margin: 0 !important;
  }

  a,
  button {
    display: flex;
    min-width: auto;
    height: 3rem;
    padding: 7.5px 15px !important;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: var(--eke-color-primary-100);
    gap: 8px;
    font-size: 14px !important;
    color: var(--eke-color-primary) !important;
    font-family: var(--eke-body-font-family) !important;
    font-weight: var(--eke-font-weight-semibold) !important;
    line-height: 140%;
    letter-spacing: 0.0125rem;
    border: none;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border: none;

    span {
      font-weight: var(--eke-font-weight-semibold) !important;
    }

    span:nth-child(2),
    .CartText_Style {
      display: none;
    }

    &:hover {
      background: var(--eke-color-primary-200);
      color: var(--eke-color-primary) !important;
    }

    svg {
      stroke: var(--eke-color-primary);
    }

    @media ${device.lg} {
      width: auto;
      padding: 0.625rem 1.25rem !important;
      span:nth-child(2),
      span:nth-child(3) {
        display: inline-flex;
      }
    }
  }
`

export const ContainerTopStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem 0rem;
  text-decoration: none !important;
  max-width: var(--eke-container-maxWidth);

  .LogoFrame__Style {
    display: flex;
    align-items: center;
    gap: 0.2em;
    padding-left: 0.5rem;

    .qlason-logo {
      svg {
        width: 35px;
        @media ${device.lg} {
          width: 40px;
        }
      }
    }

    span {
      display: inline-flex;
      cursor: pointer;
      svg {
        fill: var(--eke-color-primary);
      }
    }

    @media ${device.md} {
      padding: 0rem;
      span {
        display: none;
      }
    }

    @media ${device.lg} {
      padding: 0rem;

      .qlason-logo {
        svg {
          width: 65px;
        }
      }
      span {
        display: none;
      }
    }
  }

  .HelpStyle {
    display: none;
    @media ${device.lg} {
      display: inline-flex;
    }
  }

  @media ${device.lg} {
    width: 95%;
    flex-wrap: nowrap;
    margin: 0 auto;
  }

  @media ${device.xl} {
    width: var(--eke-container-width);
    margin: 0 auto;
  }
`

export const ContainerBottomStyle = styled.div`
  display: none;
  width: 100%;
  height: 3rem;
  padding: 8px 2px 8px 2px;
  align-items: center;
  border-top: 1px solid #eee;

  @media ${device.md} {
    display: flex;
    padding: 8px 2px 8px 0px;
  }
`

export const ListItemStyle = styled.ul`
  width: 100vw;
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0 0 0 3px;
  gap: 1.8em;
  margin: 0 auto !important;
  max-width: var(--eke-container-maxWidth);
  overflow-x: auto;
  overflow-y: hidden;

  @media ${device.lg} {
    width: 95%;
    margin: 0 auto;
    padding: 0px;
  }

  @media ${device.xl} {
    width: var(--eke-container-width);
    margin: 0 auto;
  }
`

export const ItemStyle = styled.li`
  min-width: fit-content;
  a,
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px !important;
    font-size: 13.4px;
    color: var(--eke-color-secondary) !important;
    font-family: var(--eke-body-font-family);
    font-weight: var(--eke-font-weight-semibold);
    line-height: 140%;
    letter-spacing: 0.2px;
    cursor: pointer;
    text-decoration: none;
    border: none;
    background: transparent;
    outline: none;
    margin: 0 !important;
    padding-right: 0 !important;

    span {
      font-weight: var(--eke-font-weight-semibold) !important;
      gap: 10px !important;
    }

    &:focus {
      border: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  @media ${device.lg} {
    min-width: 100px;
  }
`

export const SelectStyle = styled.div`
  .Select__Container {
    border-radius: 30px 0px 0px 30px !important;

    button {
      width: 100% !important;
      padding: 13px 15px 13px 19px !important;
      height: 44px !important;
      display: flex;
      align-items: center;
      gap: 0.8em !important;
      border-radius: 30px 0px 0px 30px !important;
      background: var(--eke-color-white) !important;

      .pointer-svg-name {
        position: relative !important;
        top: 0 !important;
        right: 2px !important;
        svg {
          font-size: 1rem !important;
          stroke: var(--eke-color-secondary) !important;
        }
      }
    }
  }
`

export const SearchBtnStyle = styled.div`
  button {
    display: flex;
    padding: 11.172px 28px;
    justify-content: flex-end;
    align-items: center;
    gap: 6.207px;
    flex-shrink: 0;
    border-radius: 62.069px;
    color: var(--eke-color-white);
    text-align: center;
    text-shadow: 2.4827585220336914px 4.965517044067383px 14.896551132202148px
      0px #efdcc7;
    font-size: 14px;
    font-weight: var(--eke-font-weight-bold);
    line-height: 140%;
    letter-spacing: 0.124px;
    box-shadow: 2.4827585220336914px 4.965517044067383px 14.896551132202148px
      0px #efdcc7;
    background: var(--eke-color-primary);
  }
`

export const ContentPopStyle = styled.div<{ isOpen: boolean }>`
  position: relative;
  z-index: 99999; // ${(props) => (props.isOpen ? 999999 : 1)} !important;
  padding: 20px 5px 20px 5px;
  min-width: 190px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  background-color: var(--eke-color-white);
  box-shadow: 0px 20px 100px 0px rgba(4, 6, 15, 0.08);
`

export const ListPopItem = styled.ul<{ stroke: boolean }>`
  width: 100%;
  list-style: none;
  padding: 0 10px;
  margin: 0 !important;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 7px;
  justify-content: center;

  .Button_iL_Style,
  .Button_Signup_Style {
    width: 100px !important;
    display: flex;
    align-items: center;
    padding: 0;
    text-decoration: none !important;

    span {
      font-size: 13.5px !important;
      font-weight: var(--eke-font-weight-bold) !important;
    }

    button {
      padding: 11.172px 9.931px;
      width: 100% !important;
      margin-right: 0 !important;
      border-radius: 62.069px;
      text-align: center;
      line-height: 140%;
      letter-spacing: 0.124px;
      color: var(--eke-color-white);
      font-family: var(--eke-body-font-family);
      background: var(--eke-color-primary);
      //box-shadow: 2.4827585220336914px 4.965517044067383px 14.896551132202148px 0px #EFDCC7;
    }
  }

  .Button_Logout {
    width: 90% !important;
    margin: 0 auto;
    align-self: center;
    justify-self: center;
  }

  .Button_Signup_Style button {
    color: var(--eke-color-primary) !important;
    background: var(--eke-color-primary-100) !important;
  }

  .Divider_iL_Style {
    width: 80%;
    height: 1px;
    background-color: #eee;
    margin: 10px auto;
  }

  li {
    width: 100%;

    a {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 5px;
      color: #212121;
      font-size: 14px;
      font-family: var(--eke-body-font-family);
      font-weight: var(--eke-font-weight-semibold);
      line-height: 140%;
      letter-spacing: 0.2px;
      text-decoration: none;
      padding: 8px 22px;
      border-radius: 62.069px;
      transition: background-color 0.5s ease-in-out;

      svg {
        width: 1.1rem !important;
        height: 1.1rem !important;
        stroke: ${(props) =>
          props.stroke ? `var(--eke-color-primary)` : `none`} !important;
      }

      &:hover {
        color: var(--eke-color-primary);
        background-color: var(--eke-color-primary-100);
      }
    }
  }
`

export const PopOverlayStyle = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  transform: all 0.3s ease-in-out;
`

export const BadgeStyle = styled.span`
  display: flex;
  height: 18px;
  padding: 8px !important;
  padding-top: 9px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 19px;
  background: var(--alerts-status-error, #f75555) !important;
  color: var(--eke-color-white);
  font-family: var(--eke-body-font-family);
  text-align: center;
  font-size: 13px;
  font-weight: var(--eke-font-weight-semibold);
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.2px;
`

export const FlexStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
