import { device } from '@ekenta/utils/devices'
import styled from 'styled-components'

export const DetailsStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 0em;
  padding-bottom: 8em;

  @media ${device.lg} {
    padding-top: 2em;
  }
`

export const DetailsContainer = styled.div`
  width: 100%;
  height: auto;
  max-width: var(--eke-container-maxWidth);
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @media ${device.lg} {
    width: 95%;
    margin: 0 auto;
  }

  @media ${device.xl} {
    width: var(--eke-container-width);
    margin: 0 auto;
  }
`
export const Stack = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;
  gap: 30px;

  @media ${device.md} {
    flex-direction: row;
  }
`

