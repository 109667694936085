/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import classnames from 'classnames';
import { Form, ListGroup } from 'react-bootstrap'

const PaymentOptionItem = (props: {
  id: string;
  label: string;
  paymentChannel?: string;
  disabled?: boolean;
  values?: any;
  errors?: any;
  onlyLabel?: boolean;
  handleBlur?: any;
  setFieldValue?: any;
  imageClass?: string;
  renderImage?: string | null;
}) => {
  const imgClass = classnames(props.imageClass, {
    "rendering_Image": !props.imageClass,
  })
  return (
    <ListGroup.Item className="px-0 py-0 mb-2">
      <div className="d__checkbox_ mt-2">
        {props.onlyLabel ? (
          <span className="label__Only">{props.label}</span>
        ) : (
          <Form.Check
            id={props.id}
            type="radio"
            disabled={props.disabled}
            name="paymentChannel"
            label={props.label}
            checked={props.values.paymentChannel === props.paymentChannel}
            onBlur={props.handleBlur}
            onChange={() => {
              props.setFieldValue("paymentChannel", props.paymentChannel);
            }}
          />
        )}
        {props.renderImage ? (
          <div className="rendering_Image__Div">
            <img className={imgClass} src={props.renderImage} />
          </div>
        ) : null}
      </div>
    </ListGroup.Item>
  )
}

export default PaymentOptionItem;