import React from 'react'
import styled from 'styled-components'
import Description from './Description'
import Features from './Features'
import { device } from '@ekenta/utils/devices'
import { ProductType } from '@ekenta/redux/store/features/product/productDataType'

interface PropsType {
  product: ProductType
}

const ProductDesc: React.FC<PropsType> = (props) => {
  const { product } = props
  return (
    <Container>
      <Stack>
        <Description description={product?.description} />
        <Features features={product?.features} skus={product?.skus} />
      </Stack>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: auto;
`

export const Stack = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;
  gap: 24px;

  @media ${device.md} {
    flex-direction: row;
  }
`

export default ProductDesc
