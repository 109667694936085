/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Field, Label, Typography } from "@ekenta/components";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import addNewAddressFormValidation from "@ekenta/validations/schemas/newaddress-form.schema";
import CustomSelect from "@ekenta/components/Select/CustomSelect";

import { useAddNewUserAddressMutation, useFetchAllStateQuery, useFetchCitiesByStateIdQuery, useUpdateUserAddressMutation } from "@ekenta/redux/store/services/accountApi";
import { useAppSelector } from "@ekenta/redux/store/hook";

import styles from "./AddressForm.module.scss";

interface AddressFormPropsType {
    isEdit: any;
    variant?: string;
    setEdit: (obj: any) => void;
    onCancel: () => void;
}

const mapStateData = (data: any) => {
    return (
        data &&
        data.map((item: any) => ({
            value: item.id,
            label: item.name,
        }))
    );
};

const AddressForm: React.FC<AddressFormPropsType> = (props) => {
    const { isEdit, setEdit, onCancel, variant = "primary" } = props;
    const { token } = useAppSelector(
        (state) => state.auth.authentication,
    );

    const [stateId, setStateId] = useState<null | string>(null);
    const [stateOptions, setStateOption] = useState<any>(null);
    const [cityOptions, setCityOption] = useState(null);

    const { account } = useAppSelector((state) => state.auth.authentication);

    const getAllStates = useFetchAllStateQuery();
    const getAllCities = useFetchCitiesByStateIdQuery({ stateId }, { skip: !stateId });

    const [addNewUserAddress, addAddress] = useAddNewUserAddressMutation();
    const [updateUserAddress, updateAddress] = useUpdateUserAddressMutation();

    const defaultValue = (options: any, val: string) => {
        return options ? options.find((option: any) => option.value === parseInt(val)) : "";
    };


    useEffect(() => {
        if (getAllStates?.data) {
            const obj = mapStateData(getAllStates?.data.states);
            setStateOption(obj);
        }

        if (getAllCities?.data) {
            const resObj =
                mapStateData(getAllCities?.data.states.cities);
            setCityOption(resObj);
        }

    }, [
        getAllStates?.data,
        getAllCities?.data
    ]);


    const handleStateChange = (value: string) => {
        //setStateOption(null);
        setStateId(value);
    };

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            streetAddress: "",
            phoneNumber: "",
            stateId: "",
            cityId: "",
            isDefault: false,
        },
        validationSchema: addNewAddressFormValidation(),
        onSubmit: values => {
            const data = {
                first_name: values.firstName,
                last_name: values.lastName,
                home_address: values.streetAddress,
                phone_number: values.phoneNumber,
                state_id: values.stateId,
                city_id: values.cityId,
                is_default: values.isDefault,
            };
            if (isEdit.show) {
                const id = isEdit.data.id;
                updateUserAddress({ id, data, token });
            } else {
                addNewUserAddress({ data, token });
            }
        }
    });


    useEffect(() => {
        if (!isEdit.show) {
            formik.setFieldValue("firstName", account.user.firstName);
            formik.setFieldValue("lastName", account.user.lastName);
            formik.setFieldValue("phoneNumber", account.user.phoneNumber ? account.user.phoneNumber : "");
        }
        if (isEdit.show) {
            formik.setFieldValue("firstName", isEdit.data.first_name);
            formik.setFieldValue("lastName", isEdit.data.last_name);
            formik.setFieldValue("phoneNumber", isEdit.data.phone_number ? isEdit.data.phone_number : "");
            formik.setFieldValue("streetAddress", isEdit.data.home_address);
            formik.setFieldValue("stateId", isEdit.data.state_id);
            setStateId(isEdit.data.state_id);
            formik.setFieldValue("cityId", isEdit.data.city_id);
            formik.setFieldValue("isDefault", isEdit.data.is_default);
        }
    }, [
        isEdit.show
    ]);

    useEffect(() => {
        if (addAddress?.isSuccess) {
            toast.success(`${addAddress?.data.message}`, {
                toastId: "status-success-id-toast",
            });
            onCancel();
            window.scrollTo(0, 0);
        }

        if (addAddress?.isError) {
            toast.error(`Something went wrong`, {
                toastId: "add-status-id-toast-error",
            });
            onCancel();
            window.scrollTo(0, 0);
        }
    }, [
        addAddress?.isSuccess,
        addAddress?.isError,

    ]);


    useEffect(() => {
        if (updateAddress?.isSuccess) {
            toast.success(`${updateAddress?.data.message}`, {
                toastId: "update-status-id-toast-success",
            });
            onCancel();
            window.scrollTo(0, 0);
        }

        if (updateAddress?.isError) {
            toast.error(`Something went wrong`, {
                toastId: "u-status-id-toast-error",
            });
            onCancel();
            window.scrollTo(0, 0);
        }

    }, [
        updateAddress?.isSuccess,
        updateAddress?.isError
    ]);


    return (
        <div>
            <Typography
                as="h5"
                size="medium"
                color="secondary"
                margin="top"
                className={styles.FormTypography}
                heading>
                Add address
            </Typography>

            <div className="mt-3">
                {/* <Typography
                    as="span"
                    size="smallest"
                    className="mb-2"
                    color="light"
                    uiText>*Required fields</Typography> */}
                <Field
                    id="first_name"
                    name="firstName"
                    label="First name"
                    disabled={
                        addAddress?.isLoading ||
                        updateAddress?.isLoading
                    }
                    type="text"
                    required
                    size="large"
                    childOutsideLabel={false}
                    placeholder="First name"
                    fieldClassName={styles.FieldControl__Input}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    isError={formik.touched.firstName && formik.errors.firstName ? true : false}
                    error={formik.errors.firstName}
                />
            </div>
            <div className="mt-2">
                <Field
                    id="last-name"
                    name="lastName"
                    label="Last name"
                    disabled={
                        addAddress?.isLoading ||
                        updateAddress?.isLoading
                    }
                    type="text"
                    size="large"
                    required
                    childOutsideLabel={false}
                    placeholder="Last name"
                    fieldClassName={styles.FieldControl__Input}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    isError={formik.touched.lastName && formik.errors.lastName ? true : false}
                    error={formik.errors.lastName}
                />
            </div>
            <div className="mt-2">
                <Field
                    id="address"
                    name="streetAddress"
                    label="Street address"
                    disabled={
                        addAddress?.isLoading ||
                        updateAddress?.isLoading
                    }
                    type="text"
                    size="large"
                    required
                    childOutsideLabel={false}
                    placeholder="Street address"
                    fieldClassName={styles.FieldControl__Input}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.streetAddress}
                    isError={formik.touched.streetAddress && formik.errors.streetAddress ? true : false}
                    error={formik.errors.streetAddress}
                />
            </div>
            <div className="mt-2">
                <Field
                    id="phone-number"
                    name="phoneNumber"
                    label="Phone number"
                    disabled={
                        addAddress?.isLoading ||
                        updateAddress?.isLoading
                    }
                    type="number"
                    required
                    childOutsideLabel={false}
                    placeholder="Phone number"
                    numberFieldPrefix={"+234"}
                    fieldClassName={styles.FieldControl__Input}
                    autoComplete="off"
                    onChange={(e) =>
                        formik.setFieldValue("phoneNumber", e.formattedValue)
                    }
                    inline={true}
                    help={"We'll contact you in case anything comes up with your order."}
                    value={formik.values.phoneNumber}
                    isError={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false}
                    error={formik.errors.phoneNumber}
                />
            </div>
            <div className="mt-2">
                <Label
                    text="State"
                    required
                />
                <CustomSelect
                    isDisabled={
                        addAddress?.isLoading ||
                        updateAddress?.isLoading
                    }
                    className="custom-select zI"
                    placeholder="State"
                    noOptionsMessage={() =>
                        getAllStates?.isError ?
                            "Could not load, refresh"
                            : "No State Found"
                    }
                    isLoading={getAllStates?.isLoading}
                    value={
                        stateOptions
                            ? defaultValue(stateOptions, formik.values.stateId)
                            : ""
                    }
                    isClearable={false}
                    options={stateOptions ? stateOptions : []}
                    onBlur={formik.handleBlur}
                    onChanged={(value: any) => {
                        formik.setFieldValue("stateId", value.value);
                        handleStateChange(value.value);
                    }}
                />
            </div>
            <div className="mt-2">
                <Label
                    text="City"
                    required
                />
                <CustomSelect
                    isDisabled={
                        addAddress?.isLoading ||
                        updateAddress?.isLoading
                    }
                    className="custom-select"
                    placeholder="City"
                    noOptionsMessage={() =>
                        getAllCities?.isError
                            ? "Could not load, refresh"
                            : !formik.values.stateId ? "Select a state" : "No city found"
                    }
                    isLoading={getAllCities?.isLoading}
                    value={
                        cityOptions
                            ? defaultValue(cityOptions, formik.values.cityId)
                            : ""
                    }
                    options={cityOptions ? cityOptions : []}
                    onBlur={formik.handleBlur}
                    onChanged={(value: any) => {
                        formik.setFieldValue("cityId", value.value)
                    }}
                    isClearable={false}
                />
            </div>
            <div className="mt-3">
                <Field
                    id="isDefault"
                    name="isDefault"
                    label="Set as my preferred delivery address"
                    disabled={
                        addAddress?.isLoading ||
                        updateAddress?.isLoading
                    }
                    type="checkbox"
                    isChecked={formik.values.isDefault}
                    size="large"
                    childOutsideLabel={false}
                    placeholder="Set default"
                    fieldClassName={styles.FieldControl__Input}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isError={formik.touched.isDefault && formik.errors.isDefault ? true : false}
                    error={formik.errors.isDefault}
                />
            </div>
            <ButtonGroup pos="right">
                <Button
                    as="text"
                    name="Cancel"
                    shadow={false}
                    type="button"
                    disabled={
                        addAddress?.isLoading ||
                        updateAddress?.isLoading
                    }
                    className={styles.Cancel__Button}
                    onClick={() => {
                        setEdit({});
                        onCancel();
                        window.scrollTo(0, 0);
                    }}
                />
                <Button
                    name="Save"
                    bgVariant={variant}
                    textVariant="light"
                    className="py-2"
                    disabled={
                        addAddress?.isLoading ||
                        updateAddress?.isLoading
                    }
                    isLoadingText="Save"
                    isLoading={
                        addAddress?.isLoading ||
                        updateAddress?.isLoading
                    }
                    shadow={false}
                    type="submit"
                    onClick={formik.handleSubmit}
                />
            </ButtonGroup>
        </div>
    )
}

export default AddressForm;