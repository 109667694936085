import { formatCurrency } from '@ekenta/utils'
import React from 'react'
import styled from 'styled-components'

const ChatProduct = (props: {
  id: string
  price: number
  name: string
  imageUrl: string
}) => {
  return (
    <ProductCard>
      <HStack>
        <img src={props.imageUrl} />
        <div>
          <h5>{props.name}</h5>
          {props.price && <p>{formatCurrency(props.price || 0)}</p>}
        </div>
      </HStack>
    </ProductCard>
  )
}

const ProductCard = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 70px;
  background-color: var(--eke-bg-light-color);
  display: inline-flex;
  padding: 12px 14px 13px 14px;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  margin-bottom: 2px;
  border: 1px solid #eee;
`

const HStack = styled.div`
  display: flex;
  width: 100%;
  max-width: 90%;
  align-items: center;
  justify-content: flex-start;
  img {
    width: 53px;
    height: 53px;
    border-radius: 8px;
    background: #f1f7ff;
  }

  h5 {
    color: #000;
    font-family: var(--eke-body-font-family);
    font-size: 16px;
    font-style: normal;
    width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 700;
    margin-bottom: 3px !important;
  }
  p {
    color: #434242;
    font-family: var(--eke-body-font-family);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    margin: 0 !important;
    padding: 0 !important;
  }
`

export default ChatProduct
