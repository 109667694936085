import React from 'react'
import { HelmetHeader, RefreshLink } from '@ekenta/components'
import {
  BannerImage,
  Container,
  HomeStyle,
  InsideBannerAdsStyle,
} from './styles'
import { withDefaultLayout } from '@ekenta/hoc'
import InformationCard from './common/InformationCard'
import TopSection from './common/Topsection'
import LiveCommerce from './common/Livecommerce'
import TopRated from './common/Toprated'
import NewArrivals from './common/Arrivals'
import BannerAds1 from '@ekenta/assets/images/BannerAds32x14.png'
import BannerAds2 from '@ekenta/assets/images/BannerAds32x2.png'
import BannerAds3 from '@ekenta/assets/images/BannerAds32x4.png'
import BannerAds4 from '@ekenta/assets/images/BannerAds32x5.png'
import BannerAds5 from '@ekenta/assets/images/BannerAds32x15.png'
import BannerAds6 from '@ekenta/assets/images/BannerAds32x16.png'
import BannerAds7 from '@ekenta/assets/images/BannerAds32x17.png'
import BannerAds8 from '@ekenta/assets/images/BannerAds32x18.png'
import JustForYou from './common/Justforyou'
import {
  Exchange90Days,
  ExchangeDescription,
  LiveDescription,
  PharmacyDescription,
  PriceWarranty,
  TermsDescription,
} from './common/common'
import { categoryBaseUrl } from '@ekenta/routes/constant'
import CollectionProduct from './common/CollectionProduct'
import { Element } from 'react-scroll'
import Vendors from './common/Vendors'
import Vouchers from './common/Vouchers'

const Home = () => {
  return (
    <>
      <HelmetHeader
        title="Qlason - Online Shopping Made Easy"
        description="Qlason Home Page"
      />
      <HomeStyle>
        <Container>
          <TopSection />
          {/* Live Commerce */}
          <LiveCommerce />
          {/* Just For You */}
          <JustForYou />

          <InsideBannerAdsStyle>
            <RefreshLink to={`${categoryBaseUrl}/men's-clothings-16`}>
              <BannerImage src={BannerAds1} alt="" />
            </RefreshLink>
            <RefreshLink to={`${categoryBaseUrl}/women's-accessories-19`}>
              <BannerImage src={BannerAds2} alt="" />
            </RefreshLink>
          </InsideBannerAdsStyle>
          {/* Beauty and Personal Care Products */}
          <CollectionProduct
            collectionId={27}
            title={'Beauty and Personal Care Products'}
            url={'/collection/beauty-and-personal-care-27'}
          />
          <CollectionProduct
            collectionId={6}
            title={'Qlason Fashion'}
            url={'/collection/qlason-fashion-6'}
          />
          <InsideBannerAdsStyle>
            <RefreshLink to={`${categoryBaseUrl}/mobile-phones-104`}>
              <BannerImage src={BannerAds3} alt="" />
            </RefreshLink>
            <RefreshLink to={`${categoryBaseUrl}/laptops-1`}>
              <BannerImage src={BannerAds4} alt="" />
            </RefreshLink>
          </InsideBannerAdsStyle>
          {/* Electronics For You */}
          <CollectionProduct
            collectionId={5}
            title={'Electronics For You'}
            url={'/collection/electronics-5'}
          />
          <InsideBannerAdsStyle>
            <RefreshLink to={`${categoryBaseUrl}/men's-shoes-25`}>
              <BannerImage src={BannerAds5} alt="" />
            </RefreshLink>
            <RefreshLink
              to={`${categoryBaseUrl}/home-theater-and-audio-systems-14`}
            >
              <BannerImage src={BannerAds6} alt="" />
            </RefreshLink>
          </InsideBannerAdsStyle>

          {/* Home and Kitchen */}
          <CollectionProduct
            collectionId={9}
            title="Home and Kitchen"
            url="/collection/home-and-kitchen-9"
          />
          <InsideBannerAdsStyle>
            <RefreshLink to={`${categoryBaseUrl}/men's-shoes-25`}>
              <BannerImage src={BannerAds5} alt="" />
            </RefreshLink>
            <RefreshLink
              to={`${categoryBaseUrl}/home-theater-and-audio-systems-14`}
            >
              <BannerImage src={BannerAds6} alt="" />
            </RefreshLink>
          </InsideBannerAdsStyle>
          {/* Top Rated */}
          <TopRated />
          <InsideBannerAdsStyle>
            <RefreshLink to={`${categoryBaseUrl}/mobile-phones-104`}>
              <BannerImage src={BannerAds3} alt="" />
            </RefreshLink>
            <RefreshLink to={`${categoryBaseUrl}/laptops-1`}>
              <BannerImage src={BannerAds4} alt="" />
            </RefreshLink>
          </InsideBannerAdsStyle>
          {/* New Arrival */}
          <NewArrivals />
          <InsideBannerAdsStyle>
            <RefreshLink to={`${categoryBaseUrl}/televison-10`}>
              <BannerImage src={BannerAds7} alt="" />
            </RefreshLink>
            <RefreshLink
              to={`${categoryBaseUrl}/generators-and-power-solutions-114`}
            >
              <BannerImage src={BannerAds8} alt="" />
            </RefreshLink>
          </InsideBannerAdsStyle>
          {/* New Vendors */}
          <Vendors />

          {/* New Vouchers */}
          <Vouchers />
          <div>
            <Element as="div" name="#15price_warranty">
              <InformationCard
                title="15-Days Price Warranty for Qlason.com"
                description={<PriceWarranty />}
              />
            </Element>
            <Element as="div" name="#90days_exchange">
              <InformationCard
                title="90 Days Exchange Without Repairs for Electronic Products"
                description={<Exchange90Days />}
              />
            </Element>
            <Element as="div" name="#130days_free_exchange">
              <InformationCard
                title="30 Days Free Exchange for Apparels and Shoes"
                description={<ExchangeDescription />}
              />
            </Element>
            <Element as="div" name="#24_7_Livestream_pharmacy">
              <InformationCard
                title="24/7 Livestream Pharmacy Consulting Services on E-commerce"
                description={<PharmacyDescription />}
              />
            </Element>
            <Element as="div" name="#24_7_Livestream_shopping">
              <InformationCard
                title="24/7 Livestream Shopping Experience"
                description={<LiveDescription />}
              />
            </Element>
            <Element as="div" name="#trade_ins">
              <InformationCard
                title="Trade-Ins on our E-commerce Website"
                description={<TermsDescription />}
              />
            </Element>
          </div>
        </Container>
        <br />
      </HomeStyle>
    </>
  )
}

export default withDefaultLayout(Home)
