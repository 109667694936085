import React, { Fragment } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Loader } from '@ekenta/components';
import styled from 'styled-components';
import { device } from '@ekenta/utils/devices';
import qlasonplaceholder from '@ekenta/assets/icons/qlason-placeholder.svg';


const BannerSection = (props: { isLoading: boolean; imageUrl: string; title: string }) => {
    const { isLoading, imageUrl, title } = props;
    return (
        <BannerContainerStyle placeholder={qlasonplaceholder}>
            {isLoading ? <Loader size='large' /> : (
                <div className="ImageGroup__Style">
                    <Fragment>
                        {imageUrl ? (
                            <LazyLoadImage
                                src={imageUrl}
                                alt={title}
                                effect="blur"
                                data-creative={imageUrl}
                                data-position="event_1"
                                data-srcset={imageUrl}
                            />
                        ) : null}
                    </Fragment>
                </div>
            )}
        </BannerContainerStyle>
    )
}

const BannerContainerStyle = styled.div<{ placeholder: string }>`
	flex:1;
    width: auto;
	background-color: #E3F3FF !important;
	min-height: 120px;
	border-radius: 12px;
    display:flex;
    justify-content: center;
    align-items: center;
    background-image: ${props => `url(${props.placeholder})`};
	background-position: center center;
	background-repeat: no-repeat;
	background-clip:content-box;

    @media ${device.md}{
		min-height: 260px;
	}

    @media ${device.lg}{
		min-height: 380px;
	}


	.ImageGroup__Style{
		width:100%;
		height: auto;
		border-radius: 12px;
		overflow: hidden;
        vertical-align: middle;

        span{
		    width:100%;
            display: block !important;
        }

		
	@media ${device.lg}{
		height: auto;
	}

		img{
			object-fit: cover;
			object-position: top;
			border-radius: 12px;
			width:100%;
		    height:100%;
		}
	}
`;

export default BannerSection