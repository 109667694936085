import React from 'react'

const SvgUser = ({
	width = "1rem",
	height = "1rem",
	className
}: {
	className?: string,
	width?: string;
	height?: string
}) => (
	<svg
		width={width}
		height={height}
		className={className}
		viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Iconly/Curved/Profile">
			<g id="Profile">
				<path id="Stroke 1" fillRule="evenodd" clipRule="evenodd" d="M7.89604 14.4414C5.43483 14.4414 3.33301 14.0584 3.33301 12.5245C3.33301 10.9907 5.42149 9.57471 7.89604 9.57471C10.3573 9.57471 12.4591 10.9769 12.4591 12.5108C12.4591 14.044 10.3706 14.4414 7.89604 14.4414Z" stroke="" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path id="Stroke 3" fillRule="evenodd" clipRule="evenodd" d="M7.89166 7.44919C9.50681 7.44919 10.8159 6.1401 10.8159 4.52495C10.8159 2.90979 9.50681 1.6001 7.89166 1.6001C6.27651 1.6001 4.96681 2.90979 4.96681 4.52495C4.96136 6.13464 6.26136 7.44373 7.87106 7.44919C7.87833 7.44919 7.885 7.44919 7.89166 7.44919Z" stroke="" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			</g>
		</g>
	</svg>

)

export default SvgUser;