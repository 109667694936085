import React, { forwardRef, useEffect, useRef } from 'react'
import { motion } from 'framer-motion';
import Scrollbars from 'react-custom-scrollbars';
import { useLocation } from 'react-router';
import { DashboardSellerLiveCommercePathUrl } from '../../../../routes/constant';
import { useMatchPath } from '../../../../utils';
import { DashboardCustomNavBar } from '../DashboardCustomNavBar'
import styled from 'styled-components';

export const DashboardCustomMainBar = forwardRef((props: { children: React.ReactNode, visibilty: boolean; setvisibilty: React.Dispatch<React.SetStateAction<boolean>> }, ref: any) => {

  const { pathname } = useLocation();
  const scrollbarRef = useRef<Scrollbars | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currentPage = params.get('page');

    if (currentPage !== '0' && scrollbarRef.current) {
      const scrollbars = scrollbarRef.current;
      scrollbars.scrollTop(0);
    }
  }, [location.search]);


  return (
    <Container
      ref={ref}
      animate={{
        scale: props.visibilty ? 0.9 : 1,
        opacity: props.visibilty ? 0.6 : 1
      }}
      transition={{ type: "spring", bounce: 0, duration: 0.4 }}
      className={` dashboard-custom-main-bar ${pathname !== useMatchPath(DashboardSellerLiveCommercePathUrl, pathname)
        ? 'dashboard-custom-main-bar-margin' : ''}`}>
      {pathname !== useMatchPath(DashboardSellerLiveCommercePathUrl, pathname) ? (
        <DashboardCustomNavBar setvisibilty={props.setvisibilty} />
      ) : null}

      <Scrollbars
        ref={scrollbarRef}
        className={` dashboard-custom-main-bar-content-div ${pathname === useMatchPath(DashboardSellerLiveCommercePathUrl, pathname)
          ? ' dashboard-live-stream-bg' : null}`}
        thumbMinSize={50} style={{ height: '100vh' }}>
        {props.children}
      </Scrollbars>

    </Container>
  )
});

DashboardCustomMainBar.displayName = 'DashboardCustomMainBar';

const Container = styled(motion.div)`
  background: var(--background-blue, #F5FBFF) !important;
  height: 100vh !important;
  transition: all 0.3s ease-out !important;
`;