import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createMutationRequestWithBody, createQueryWithTokenRequestByText } from '.';
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import axios from 'axios'
import type { AxiosRequestConfig, AxiosError } from 'axios'

import { BASE_URL } from '../../../constants/index';

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
      headers: AxiosRequestConfig['headers']
    },
    unknown,
    unknown
  > =>
    async ({ url, method, data, params, headers }) => {
      try {
        const result = await axios({ url: baseUrl + url, method, data, params, headers })
        return { data: result.data }
      } catch (axiosError) {
        const err = axiosError as AxiosError
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      }
    }
const cartApiHeaders = (token: string) => ({
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${token}`
});

const createQueryRequest = ({ url, method, token }: { url: string, method: string, token: string }) => ({
  url: url,
  headers: cartApiHeaders(token),
  method: method
});

const createMutationRequest = ({ url, method, body, token }: { url: string, method: string, body: any, token: string }) => ({
  url: url,
  headers: cartApiHeaders(token),
  method: method,
  data: body
});

export const cartApi: any = createApi({
  reducerPath: 'cartApi',
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL ? BASE_URL : ''
  }),
  tagTypes: ['Cart', 'Quantity'],
  endpoints: (builder) => ({
    addCartItem: builder.mutation<void, { data: { product_id: string }; token: string; }>({ //does not have body
      query: ({ data, token }) => createMutationRequest({ url: `/api/add-to-cart`, method: 'POST', body: data, token: token }),
      invalidatesTags: ['Cart']
    }),
    removeUserCartItem: builder.mutation<void, { cartItemId: string; token: string; }>({
      query: ({ token, cartItemId }) => createQueryRequest({ url: `/api/update-cart/remove/${cartItemId}`, method: 'PUT', token: token }),
      invalidatesTags: ['Cart']
    }),
    updateUserCartItemQuantity: builder.mutation<void, { cartItemId: string; quantity: string; token: string; }>({
      query: ({ token, cartItemId }) => createQueryRequest({ url: `/api/cart/updateCartItem/${cartItemId}`, method: 'PUT', token: token }),
      invalidatesTags: ['Cart']
    }),
    updateCartItemQuantity: builder.mutation<void, { cartItemId: string; token: string; type: string }>({
      query: ({ token, cartItemId, type }) => createQueryRequest({ url: `/api/update-cart/${type}/${cartItemId}`, method: 'PUT', token: token }),
      invalidatesTags: ['Cart']
    }),

    findUserCartItemByIdReturnQuantity: builder.query<void, { cartItemId: string; token: string; }>({
      query: ({ token, cartItemId }) => createQueryRequest({ url: `/api/get-cart-item-quantity/${cartItemId}`, method: 'GET', token: token }),
      providesTags: ['Cart'],
    }),

    fetchCartItems: builder.query<string[], string>({
      query: (token) => createQueryRequest({ url: `/api/get-cart-items`, method: 'GET', token: token }),
      providesTags: ['Cart', 'Quantity'],
    }),
  }),
});

export const {
  useAddCartItemMutation,
  useRemoveUserCartItemMutation,
  useUpdateUserCartItemQuantityMutation,
  useUpdateCartItemQuantityMutation,
  useFetchCartItemsQuery,
  useFindUserCartItemByIdReturnQuantityQuery
} = cartApi;

