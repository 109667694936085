import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';


const ProgressiveImg = ({ placeholderSrc, className, src, ...props }: any) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
      setIsLoading(false);
    };
    img.onerror = () => {
      setIsLoading(false);
    };
  }, [src]);

  const srcLoad = useMemo(() => (isLoading ? 'loading' : 'loaded'), [isLoading]);

  const handleImageLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <ImageSrc
      {...{ src: imgSrc, ...props }}
      alt={props.alt || ''}
      className={className}
      srcLoad={srcLoad}
      onLoad={handleImageLoad}
    />
  );
};



const ImageSrc = styled.img<{ srcLoad: string; }>`
  object-fit: contain;
  width: auto;
  height: auto;

  ${(props) =>
    props.srcLoad === 'loading' &&
    css`
      filter: blur(10px);
      clip-path: inset(0);
    `}

  ${(props) =>
    props.srcLoad === 'loaded' &&
    css`
      filter: blur(0px);
      transition: filter 0.5s linear;
    `}
`;

export default ProgressiveImg;
