import * as Yup from 'yup';
import "yup-phone-lite";

const sellerOnboardingFormValidation = () => Yup.object().shape({
    personal_detail: Yup.object().shape({
        firstname: Yup.string()
                   .required('Firstname is required'),
        lastname: Yup.string()
                   .required('Lastname is required'),
        username: Yup.string()
                   .required('Username is required'),
        phonenumber: Yup.string()
                   .phone("NG", "Provide a valid phone number")
                   .min(13, '*Phonenumber must be 13 characters')
                   .max(14, '*Phonenumber must be 13 characters')
                  .required('*Phonenumber is required'),
        email: Yup.string()
                    .email('Invalid email address')
                    .required('Email field is required'),
        password: Yup.string()
                   .required('Password field is required')
                   .min(6, 'Password is short - should be 6 chars minimum'),
                   //.matches(/[a-zA-Z0-9]/, 'Password should capital letter, numberic letter.'),
        confirmpassword: Yup.string()
                   .required('Confirm Password field is required')
                   .oneOf([Yup.ref('password'),null], 'Passwords must match'),
        referredBy: Yup.string(),
      }),
      business_detail: Yup.object().shape({
        businessname: Yup.string()
                      .required('Businessname is required'),
          address: Yup.string()
                      .required('Address is required'),
          state:  Yup.object().shape({
                    id: Yup.string(),
                    name: Yup.string(),
          })
                    .required('State is required!'),
                    
          city_or_town:  Yup.object().shape({
                      id: Yup.string(),
                      name: Yup.string(),
            })
                      .required('City is required!'),
          country:  Yup.string()
                      //.oneOf(['',''], 'Invalid Country type')
                      .required('Country is required!'),
           proof_of_work: Yup.string()
                            .required('Proof of Business is required'),
          business_phone_number: Yup.string()
                            .phone("NG", "Provide a valid phone number")
                            .min(14, '*Phonenumber must be 14 characters')
                            .max(14, '*Phonenumber must be 14 characters')
                            .required('Business or Phone number is required!'),
          business_registration_number: Yup.string(),
          vat_registration_number:  Yup.string(),
          seller_vat:  Yup.string(),
          legalForm:  Yup.string().oneOf([''], 'Invalid'),
          tax_identification_number: Yup.string(),
      }),
      bank_detail: Yup.object().shape({
          bank:  Yup.string()
                      //.oneOf([''], 'Invalid bank name')
                      .required('Bank is required'),
          account_name: Yup.string()
                          .required('Account name is required'),
          account_number: Yup.string()
                          .matches(/^\d{10}$/, 'Account number must be exactly 10 digits')
                          .required('Account number is required'),
          bank_code: Yup.string(),
          swift:  Yup.string(),
          iban:  Yup.string(),
          bank_information: Yup.string(),
          bvn:  Yup.string(),

      }),
      acceptTermsAndConditions: Yup.bool().oneOf([true], 'Accept Terms is required.'),
      acceptOnboardingPolicy: Yup.bool().oneOf([true], 'Accept Onboarding policy is required.')
    
});

export default sellerOnboardingFormValidation;