export const REACT_APP_ENV = process.env.REACT_APP_ENV;
export const GOOGLE_CLIENTID = process.env.REACT_APP_GOOGLE_CLIENTID;
export const FACEBOOK_APPID = process.env.REACT_APP_FACEBOOK_CLIENTID;

export const REDUX_PERSIST_SECRET = process.env.REACT_APP_REDUX_PERSIST_SECRET;
export const FLUTTERWAVE_KEY_PUBLIC = process.env.REACT_APP_FLUTTERWAVE_KEY;
export const THEPEER_KEY_PUBLIC = process.env.REACT_APP_THEPEER_KEY;
export const PAYSTACK_KEY_PUBLIC = process.env.REACT_APP_PAYSTACK_KEY;
export const PAYSTACK_AUTHORIZATION_KEY =
  process.env.REACT_APP_PAYSTACK_AUTHORIZATION_KEY;

export const GOOGLE_ANALYTICS_TRACKINGID = ['G-24FRKHMBLH']; //process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKINGID
export const FACEBOOK_PIXEL_ID = '1020667982771859';
//export const FACEBOOK_PIXEL_ID = '1389003814991489';


export const BASE_URL = process.env.REACT_APP_DEPLOYMENT_ENDPOINT;
export const BASE_URL_WEBSOCKET = 'ws';
export const LiveStream_BaseURL = 'https://app.simplelive.co/livestream/';
// export const LiveStreamBaseURL = 'https://live.qlason.com';
// export const LiveStreamBaseURL = 'https://ar-livestream.vercel.app';
export const LiveStreamBaseURL = 'https://livekit-livestream-g8fg.vercel.app';
