import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FiShare, FiX } from 'react-icons/fi'
import ReactTooltip from 'react-tooltip'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as queryString from 'query-string'
import AllOtherProducts from './components/SideSection/AllOtherProducts'
//import MessagingStreams from "./components/MessagingStreams";
import VideoStreamsContainer from './components/VideoStreamsContainer/VideoStreamsContainer'

import PromptDialog from './components/Dialog'
import { useAppSelector } from '@ekenta/redux/store/hook'
import {
  useFetchAllLiveSellersOnEkeQuery,
  useFetchAllLiveSellersQuery,
  useFetchAllSellerProductQuery,
  useFetchProductFilteredByCollectionQuery,
  useFetchSellerLiveCodeQuery,
} from '../../../redux/store/services/livecommerceApi'
//import LiveProductCard from "../../../components/LiveCommerce/LiveProductCard";

import logo from '../../../assets/images/216x44-logo.png'
import SocialShare from '@ekenta/components/SocialShare/SocialShare'
import { allLivePathUrl, singLiveBaseUrl } from '@ekenta/routes/constant'
import { HelmetHeader } from '@ekenta/components'
import withDefaultLayout from '../../../hoc/Layout/withDefaultLayout'

//import defaultImg from "@ekenta/assets/images/default_livestream.webp";
import SideSection from './components/SideSection'
import SellerInfo from './components/SellerInfo/SellerInfo'

const ViewLiveSeller = () => {
  const navigate = useNavigate()
  const { sellerId } = useParams()
  const query = queryString.parse(window.location.search)
  const isLiveType = query.type

  const merchantId = sellerId
  const [showDialog, setShowDialog] = useState(false)
  const { nickname } = useAppSelector((state) => state.auth.livecom)
  const { token } = useAppSelector((state) => state.auth.authentication)
  console.log(sellerId)
  // const { data, isLoading, isFetching, isError, error, isSuccess } =

  let result: {
    data: any
    isError: boolean
    isLoading: boolean
    isSuccess: boolean
    error: { status: string | number }
  }
  isLiveType === 'isLive'
    ? (result = useFetchAllSellerProductQuery(sellerId, { skip: !sellerId }))
    : (result = useFetchProductFilteredByCollectionQuery(sellerId, {
        skip: !sellerId,
      }))

  let getResult
  isLiveType === 'isLive'
    ? (getResult = useFetchAllLiveSellersQuery())
    : (getResult = useFetchAllLiveSellersOnEkeQuery())

  const getSellerResult = useFetchSellerLiveCodeQuery(
    { merchantId, token },
    { skip: !merchantId }
  )

  const [showToast, setShowToast] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (!nickname) {
      handleShow()
    }
  }, [])

  useEffect(() => {
    if (result?.isError && result?.error.status === 'FETCH_ERROR') {
      toast.error(`Something went wrong`, {
        toastId: 'view-stream-id-toast-error',
      })
    }
  }, [result?.isError])

  useEffect(() => {
    if (!isLiveType) {
      return navigate(-1)
    }
  }, [isLiveType])

  const handleClose = () => setShowDialog(false)
  const handleShow = () => setShowDialog(true)

  const handleShowModal = () => setShowModal(true)
  const handleHideModal = () => setShowModal(false)

  const getLivestreamUrl = (type: string) => {
    return `${window.location.origin}${singLiveBaseUrl}/spm-${sellerId}?type=${type}`
  }

  const closeLivestream = () => {
    return navigate(allLivePathUrl)
  }

  // const mapAllLiveSellers = (data: any, type: any) => {
  //   return (
  //     data.merchant_business.length > 0 &&
  //     data.merchant_business.map((result: any, index: number) => {
  //       return (
  //         <LiveProductCard
  //           key={`id_` + index}
  //           id={result.id}
  //           type={type === "isLive" ? "isLive" : "isEkeLive"}
  //           className="item col__cardb-wrapper"
  //           imageUrl={
  //             type === "isLive" && result.thumbnailUrl
  //               ? result.merchant_business.thumbnail_url
  //               : type === "isEkeLive" && result.merchant_business.thumbnail_url
  //                 ? result.merchant_business.thumbnail_url
  //                 : defaultImg
  //           }
  //           title={
  //             type === "isLive" ? result.merchant_business.business_name : result.merchant_business.business_name
  //           }
  //         />
  //       );
  //     })
  //   );
  // };

  return (
    <>
      <HelmetHeader
        title="View Live "
        description="QlasonView Live Seller Page"
      />
      <section className="dall_live_wrapper">
        <div className="single_live_main">
          <div className="_view_top_live_section">
            <div className="container_live mb-2 mb-lg-5">
              <div className="vid__stream_navbar pt-1">
                <div className="vid__stream_navigationBar pt-2 pb-2">
                  <Link to={''} className="vid__stream_brand">
                    <img
                      alt="qlason logo"
                      src={logo}
                      width="130"
                      height="auto"
                      className="d-inline-block mx-1 mt-0 align-top"
                    />{' '}
                  </Link>
                  <div className="d-flex align-items-center vid_navigation_right">
                    <ReactTooltip
                      id="share"
                      place="top"
                      type="dark"
                      effect="solid"
                    />
                    <button
                      className="d-flex share__button me-3 Button Button--icon"
                      data-tip="Share"
                      data-for="share"
                      onClick={handleShowModal}
                    >
                      <FiShare fontSize={22} />
                    </button>
                    <span>
                      {' '}
                      <FiX
                        className="me-2"
                        fontSize={28}
                        color="white"
                        onClick={closeLivestream}
                      />{' '}
                    </span>
                  </div>
                </div>
              </div>

              <div className="live-stream-group">
                <div className="main__v_stream_col">
                  <div className="J_lr-header"></div>
                  <div className="vid__stream-section">
                    <VideoStreamsContainer
                      messageArn={
                        getSellerResult?.isSuccess
                          ? getSellerResult?.data?.data?.chat_arn
                          : null
                      }
                      liveCode={
                        getSellerResult?.isSuccess
                          ? getSellerResult?.data?.data?.live_link
                          : null
                      }
                      isStream={
                        getSellerResult?.isSuccess
                          ? isLiveType === 'isLive'
                            ? getSellerResult?.data.data?.user?.is_seller_live
                            : getSellerResult?.data.data?.user
                                ?.is_seller_live_for_ekemarket
                          : null
                      }
                      businessName={
                        getSellerResult?.isSuccess
                          ? getSellerResult?.data?.data?.business_name
                          : ''
                      }
                      username={
                        getSellerResult?.isSuccess
                          ? getSellerResult?.data?.data?.user?.username
                          : ''
                      }
                    />

                    <SellerInfo
                      handleShowModal={handleShowModal}
                      sellerId={
                        getSellerResult?.isSuccess
                          ? getSellerResult?.data?.data?.user.id
                          : ''
                      }
                      businessName={
                        getSellerResult?.isSuccess
                          ? getSellerResult?.data?.data?.business_name
                          : ''
                      }
                    />
                    {/* <MessagingStreams
                      from={nickname}
                      clientRef={clientRef}
                      sellerId={sellerId}
                      liveCode={
                        getSellerResult?.isSuccess
                          ? getSellerResult?.data.data?.live_link
                          : null
                      }
                      setIsDisconected={setIsDisconected}
                      setIsConected={setIsConected}
                    /> */}
                  </div>
                </div>
                <SideSection
                  productData={result}
                  liveData={getResult}
                  isLiveType={isLiveType}
                />
              </div>
            </div>
          </div>
        </div>

        {/* { display on small devices} */}
        {/* <div className="d-lg-none mobile__display__section">
          <div className="d-lg-none custom all__list__items">
            {result?.isSuccess ? (
              <AllOtherProducts
                data={result?.data}
                isLoading={result?.isLoading}
              />
            ) : null}
          </div>
        </div> */}

        {/* <PromptDialog show={showDialog} onClose={handleClose} /> */}

        {/* Modal Overlay for share  */}
        <Modal
          className="modal_component shadow"
          centered
          show={showModal}
          onHide={handleHideModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Share</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SocialShare
              url={getLivestreamUrl(isLiveType)}
              title="QlasonLivestream"
            />
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default withDefaultLayout(ViewLiveSeller)
