import React, { Fragment, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import CustomModal from '../Modal/CustomModal'
import QuantityBtn from './QuantityBtn'
import { formatCurrency } from '@ekenta/utils'
import { addToCartAsync } from '@ekenta/redux/store/features/cart/cartThunks'
import { cartPathUrl } from '@ekenta/routes/constant'
import { selectProductQuantityAndId } from '@ekenta/redux/store/features/cart/cartSlice'
import {
  FeatureVariationType,
  ProductVariationType,
} from '@ekenta/redux/store/features/product/productDataType'
import { useAppSelector } from '@ekenta/redux/store/hook'
import RefreshLink from '../RefreshLink/RefreshLink'
import Loader from '../Loader/Loader'
import Button from '../Button/Button'

const VariantModal = (props: {
  isOpen: boolean
  onClose: () => void
  productName: string
  stock: number
  is_variant: number
  productVariation?: ProductVariationType[]
}) => {
  const { isOpen, onClose, productName, stock, is_variant, productVariation } =
    props

  return (
    <CustomModal
      show={isOpen}
      size="sm"
      title="Please select a Variant"
      subtitle=""
      centered={false}
      showFooter={false}
      isLoading={false}
      isDisabled={false}
      headerClass="app-modalheader event-modalhead"
      modalClass="app-modalbody"
      onHide={onClose}
      render={
        <ModalContent>
          <Fragment>
            <div className="modal--body">
              <ul>
                {productVariation?.map((item) => (
                  <VariationItem
                    key={item?.id}
                    id={item?.id}
                    features={item?.features}
                    price={item?.price}
                    stock={stock}
                    productName={productName}
                    is_variant={is_variant}
                  />
                ))}
              </ul>
            </div>
            <div className="modal--footer">
              <Button
                name="viewshopping__name"
                type="button"
                bgVariant="primary"
                defaultClass={false}
                className="shopping--btn"
                onClick={onClose}
              >
                Continue Shopping
              </Button>
              <RefreshLink to={cartPathUrl}>
                <Button
                  name="viewcart__name"
                  type="button"
                  bgVariant="primary"
                  defaultClass={false}
                  className="viewCart--btn"
                >
                  View Cart
                </Button>
              </RefreshLink>
            </div>
          </Fragment>
        </ModalContent>
      }
    />
  )
}

const VariationItem = (props: {
  id: number
  features: FeatureVariationType[]
  productName: string
  is_variant: number
  price: number
  stock: number
}) => {
  const { id, features, price, stock, productName, is_variant } = props
  const dispatch = useDispatch()
  const { quantity, cartItemId } = useAppSelector(
    selectProductQuantityAndId(id.toString())
  )

  const [loading, setLoading] = useState(false)

  const onAdd = async () => {
    try {
      setLoading(true)
      const data = {
        id: id.toString() || '',
        name: productName || '',
        price: price || 0,
        stock: stock,
        is_variant: is_variant || 1,
      }
      if (data.id && data.name && data.price && data.is_variant) {
        await dispatch(addToCartAsync(data))
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <li key={id}>
      <div className="feature-value">
        <h5>
          {features
            ?.map((feature) => feature?.feature_value?.toLowerCase())
            ?.join(', ')}
        </h5>
        <p>{formatCurrency(price || 0)}</p>
      </div>
      <div className="feature-add-quantity">
        {quantity > 0 ? (
          <QuantityBtn
            stock={stock}
            initialValue={1}
            allowHover={false}
            showDelete={true}
            quantity={quantity}
            cartItemId={cartItemId}
          />
        ) : (
          <AddPlus onClick={onAdd} disabled={loading}>
            {loading ? <Loader /> : <FiPlus color="#fff" fontSize="1rem" />}
          </AddPlus>
        )}
      </div>
    </li>
  )
}

const AddPlus = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--eke-color-primary);

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`

const ModalContent = styled.div`
  padding: 5px 20px;
  width: 100%;
  height: auto;
  border-top: 1px solid #eeeeee;

  .modal--body {
    padding-top: 12px;
    ul {
      width: 100%;
      list-style: none;
      padding-left: 0px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 24px;

      li {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .feature-value {
          flex: 1;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 2px;

          h5 {
            color: var(--Greyscale-700, #616161);
            font-family: var(--eke-body-font-family);
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.2px;
            margin: 0 !important;
            text-transform: capitalize !important;
          }

          p {
            margin: 0 !important;
            color: var(--Greyscale-800, #424242);
            font-size: 18px;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: 0.2px;
          }
        }

        .feature-add-quantity {
          flex-shrink: 1;

          .counter-qty {
            display: none;
            align-items: center;
            justify-content: center;
            width: 120px;
            border-radius: 100px;
            background: var(--Background-Orange, #fff8ed) !important;
            transition: all 0.3s ease-out;
            padding: 12px 20px !important;
            //padding: 15.677px 0px !important;

            .count-value {
              font-size: 18px !important;
              color: var(--eke-color-primary) !important;
            }

            button {
              width: 32px !important;
              height: 32px !important;
              background-color: transparent !important;
              border: 1px solid transparent;

              svg {
                width: 100%;
                height: 100%;
                stroke-width: 2px;
                stroke: #f8880a !important;
              }

              &:disabled {
                pointer-events: none !important;
                opacity: 0.2 !important;
              }
            }
          }
        }
      }
    }
  }

  .modal--footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 17px;

    button {
      width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none !important;
      border: none !important;
      height: 50px !important;
      border-radius: 100px;
      font-weight: 700 !important;
      font-size: 15px !important;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.2px;
      transition: all 0.3s ease-out;
    }

    a {
      width: 100% !important;
    }

    .shopping--btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px !important;
      background: var(--Primary-100, #ffe5c8) !important;
      box-shadow: 0px 20px 100px 0px rgba(4, 6, 15, 0.08) !important;
      color: var(--Primary-500, #f8880a) !important;
    }

    .viewCart--btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--Primary-500, #f8880a) !important;
      box-shadow: 4px 8px 24px 0px #efdcc7 !important;
      color: var(--Others-White, #fff) !important;
    }
  }
`

export default VariantModal
