import React, { useContext } from 'react'
import { Offcanvas } from 'react-bootstrap';
import { TriggerSideOffCanvasContext } from '../../../context/TriggerSideOffCanvasContext';
import { useFetchAllCollectionsQuery } from '../../../redux/store/services/collectionApi';
import CollectionGroupItem from './components/CollectionGroupItem';
import { Link } from 'react-router-dom';
import { collectionPathUrl } from '../../../routes/constant';
import Loader from '@ekenta/components/Loader/Loader';


export default function CollectionsOffCanvas() {
  const { offCanvas, handleOnHideCanvas } = useContext(TriggerSideOffCanvasContext);
  const { data, isLoading, isError, isSuccess } = useFetchAllCollectionsQuery();
  return (
    <Offcanvas closeButton={true} scroll={true} backdrop={true} show={offCanvas.show} placement={offCanvas.placement} onHide={handleOnHideCanvas}>

      <Offcanvas.Header className="d-lg-flex" closeButton>
        <Link to={collectionPathUrl} style={{ color: "black", textDecoration: "none" }} onClick={handleOnHideCanvas}>
          <Offcanvas.Title>
            <span className="d-flex align-items-center" onClick={handleOnHideCanvas}>
              <span className="ms-2">All Categories</span>
            </span>
          </Offcanvas.Title>
        </Link>
      </Offcanvas.Header>

      {/* <Scrollbars 
            className="offcanvas_bd"
            style={{width: "100%"}}> */}
      <Offcanvas.Body className="offcanvas_bd">

        {isLoading ? <Loader  size='xlarge' /> : null}
        {isSuccess && data ? <CollectionGroupItem data={data.collections} closeHandler={handleOnHideCanvas} /> : null}
        {isError ? <Loader size='xlarge' /> : null}
      </Offcanvas.Body>
      {/* </Scrollbars> */}
    </Offcanvas>
  )
}
