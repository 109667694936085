import * as Yup from 'yup';

const addProductListingFormValidation = () => Yup.object().shape({
    collectionId: Yup.string()
                  .required('Main collection is required'),
    subcategorycollectionId: Yup.string()
                  .required('Sub category collection is required'),
    subcollectionId: Yup.string()
                  .required('Sub collection is required'),
    productname: Yup.string()
               .required('Product name field is required'),
    productshortDescription: Yup.string()
                .min(100,'Minimum of 100 character')
                .required('Description field is required'),
    brandId: Yup.string()
               .required('Brand field is required'),
    defaultprice:  Yup.number()
                .min(50, 'Default price must be 50 above')
                .typeError('Default price field must not be empty')
                .required('Default price field is required'),
    discountedPrice: Yup.number()
                .typeError('Discounted Price field must be a number')
                .min(0, 'Discounted price must be greater than or equal to 0')
                .test({
                  name: 'lessThanDefaultPrice',
                  message: 'Discounted price must be lesser than Price',
                  test: function (value:number) {
                    // 'this' refers to the context of the validation
                    const defaultPrice = this.resolve(Yup.ref('defaultprice'));
                    return value <= defaultPrice;
                  },
                })
                .required('Discounted Price field is required'),
    // maximumprice:  Yup.number()
    //               .required('Maximum field is required')
    //               .moreThan(Yup.ref("minimumprice"),'Maximum price field must be greater than Minimum field '),

    discount:  Yup.number()
                .typeError('Discount field must be 0 by default'),

    quantity:  Yup.number()
                .required('Quantity field is required')
                .min(1, 'Quantity field minimum value must be 1'),
 
    vatcharges: Yup.number()
                .required('Vat field is required'),
    totalprice: Yup.number(),
    condition:      Yup.string()
                        .required('*Condition field is required'),
    productWeight:      Yup.string()
                        .required('*Weight field is required (in kg)'),
    productimages: Yup.array()
                            .of(
                                Yup.object().shape({
                                  image_url: Yup.string(),
                                })
                            )
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        .compact((v:any) => !v.image_url)
                        .required('Product image is required')
                        .min(2, '*Must upload minimum of (2) quality product images')
                        .max(5, '*Must upload maximum of (5) quality product images'),
    featureValues: Yup.array()
                            .of(
                                Yup.object().shape({
                                  feature_id: Yup.string().required('Field is required'),
                                  feature_value: Yup.string().required('Field is required'),
                                })
                            )
                        .required('Feature is required'),
    variants: Yup.array()
                        .of(
                            Yup.object().shape({
                              price: Yup.string().required('Field is required'),
                              features: Yup.array().of(
                                Yup.object().shape({
                                    feature_id: Yup.string().required('Field is required'),
                                    feature_value: Yup.string().required('Field is required'),
                                })
                              )
                            })
                        )
                    .required('variants is required'),
    productvideo: Yup.string().nullable()
                    // .min(1, '*Must upload one video - maximum of 20s')
})

export default addProductListingFormValidation;