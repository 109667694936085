import React from 'react'
import styled from 'styled-components'
import { device } from '@ekenta/utils/devices'
import RefreshLink from '../RefreshLink/RefreshLink'
import { Logo as QLogo } from '../icons'
//import LogoImg from '@ekenta/assets/images/logo_chrismas.png'

const Logo = (props: {
  width?: string
  height?: string
  showDesc?: boolean
}) => {
  const { width = 'auto', height = '', showDesc } = props
  return (
    <LogoStyle className="logo qlason-logo">
      <RefreshLink to="/">
        <QLogo width={width} height={height} />
        <div className="LogoText">
          <h3>Qlason</h3>
          {showDesc ? <p>..Shop with Confidence</p> : null}
        </div>
      </RefreshLink>
    </LogoStyle>
  )
}

const LogoStyle = styled.div`
  a {
    display: flex;
    align-items: center;
    gap: 5px;
    flex: 0.3;
    text-decoration: none !important;

    .LogoText {
      h3 {
        margin: 0;
        padding: 0;
        line-height: 20px;
        font-size: 1.5rem;
        color: var(--eke-color-secondary);
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-extrabold) !important;

        @media ${device.lg} {
          font-size: 2.204rem;
          line-height: 35px;
        }
      }
      p {
        margin: 0;
        font-family: var(--eke-body-font-family);
        font-size: 0.56175rem;
        color: var(--eke-color-secondary);
        font-weight: var(--eke-font-weight-medium) !important;
      }
    }
  }
`

export default Logo
