/* eslint-disable @typescript-eslint/no-explicit-any */
import { CardBox, Typography } from '@ekenta/components';
import React from 'react'

import "./ListRequestItem.scss";
import Tag from '@ekenta/components/Tag/Tag';

const ListRequestItem = (props: any) => {
    const { item, status, reason } = props;
    return (
        <CardBox
            className="CardBox_AddressList py-3">
            <div className="">
                <img
                    src={item.product.images ? item.product.images[0].image_url : ""}
                    width="100"
                    height="100"
                    alt={item.product.name}
                />
            </div>
            <div className="AddressItem_Typography">
                <Typography
                    as="h3"
                    size="medium"
                    color="dark"
                    className="Address_Para"
                    uiText>
                    {item.product.name}
                </Typography>

                <div className="mt-2">
                    <Typography
                        as="span"
                        size="small"
                        color="secondary"
                        uiText>
                        Reason - {reason}
                    </Typography>
                    <div>{status}</div>
                </div>
            </div>
        </CardBox>
    )
}

export default ListRequestItem;