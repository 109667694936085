import React from 'react'
import { withDefaultLayout } from '@ekenta/hoc';
import { HelmetHeader, PageTitle } from '@ekenta/components';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import BreadCrumb from '../component/BreadCrumb';
import ProfileSideBar from '../component/AccountSideBar/AccountSideBar';
import BackButton from '../component/BackButton';
import styled from 'styled-components';
import Referral from './common/Referral';
import ReferralAnalytics from './common/ReferralAnalytics';

const Referrals = () => {
    return (
        <>
            <HelmetHeader
                title="Manage Referrals"
                description="Qlason Profile Account Page"
            />
            <Container className="account-user-container">
                <><BreadCrumb activePage="Referrals" /></>

                <Row className="account-pro-row g-2">
                    <ProfileSideBar isLoading={null} />
                    <Col className="account-profile-col-right">
                        <PageTitle
                            title="Referrals"
                            margin={false}
                            subTitle={<BackButton />}
                        />
                        <Content>
                            <Tabs
                                defaultActiveKey="referral"
                                transition={false}
                                id="noanim-tab"
                                className="mb-3">
                                <Tab eventKey="referral" title="Referral">
                                    <Referral />
                                </Tab>
                                <Tab eventKey="analytic" title="Referral Analytics">
                                    <ReferralAnalytics />
                                </Tab>
                            </Tabs>
                        </Content>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

const Content = styled.div`

`;
export default withDefaultLayout(Referrals);