import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { ReactComponent as BundleBadge } from './bundle-badge.svg'
import { ReactComponent as SpinBadge } from './wheel-spin.svg'
import { ReactComponent as CloseIcon } from '@ekenta/assets/icons/close-square2.svg'
import { ReactComponent as PlayStore } from '@ekenta/assets/icons/play-store.svg'
import { ReactComponent as AppleStore } from '@ekenta/assets/icons/app-store.svg'
import useDeviceDetection from '@ekenta/hooks/useDeviceDetection'
import { device } from '@ekenta/utils/devices'

const SpinWheel = (props: { isOpen: boolean; onClose: () => void }) => {
  const { isOpen, onClose } = props
  const { isMobile, isIOS } = useDeviceDetection()

  // useEffect(() => {
  //   if (!localStorage.getItem('qlason-spinwheel')) {
  //     setShow(true)
  //     localStorage.setItem('qlason-spinwheel', 'true')
  //   }
  // }, [])

  // const closePopup = () => {
  //   setShow(false)
  //   localStorage.setItem('qlason-spinwheel', 'false')
  // }

  const appStoreLink = isIOS
    ? 'https://apps.apple.com/ng/app/qlason/id6463491869'
    : 'https://play.google.com/store/apps/details?id=com.qlason'

  return (
    <PopupWrapper show={`${isOpen}`}>
      <PopupContent>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <h2>Spin to win &#8358;20,000</h2>
        <WheelSpin>
          <BundleBadge />
          <div className="spin-badge">
            <SpinBadge />
          </div>
          {isMobile ? (
            <SpinButton href={appStoreLink}>Get In App</SpinButton>
          ) : (
            <div className="h-stack store-group">
              <a href="https://play.google.com/store/apps/details?id=com.qlason">
                <PlayStore />
              </a>
              <a href="https://apps.apple.com/ng/app/qlason/id6463491869">
                <AppleStore />
              </a>
            </div>
          )}
        </WheelSpin>
        <p>100% chance to win</p>
      </PopupContent>
    </PopupWrapper>
  )
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const PopupWrapper = styled.div<{ show: string }>`
  display: ${({ show }) => (show === 'true' ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: ${({ show }) =>
    show === 'true'
      ? css`
          ${fadeIn} 0.5s ease-out
        `
      : 'none'};
`

// const rotate = keyframes`
//   0% {
//     transform: rotate(0deg);
//     animation-timing-function: ease-in;
//   }
//   100% {
//     transform: rotate(360deg);
//     animation-timing-function: ease-out;
//   }

// `

const PopupContent = styled.div`
  width: 100%;
  background-color: transparent;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;

  @media ${device.lg} {
    width: 45%;
  }

  h2 {
    font-weight: 900;
    font-style: normal;
    font-size: 31.6px;
    line-height: 44.23px;
    letter-spacing: 0.39px;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }

  p {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }

  .spin-badge {
    position: relative;
    //animation:;
  }

  .h-stack {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.4);
    }
  }
`

const CloseButton = styled.span`
  position: absolute;
  top: -15px;
  right: 4px;
  font-size: 30px;
  cursor: pointer;

  @media ${device.lg} {
    top: -10px;
  }
`

const WheelSpin = styled.div`
  width: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin: 26px auto 20px auto;
`

const SpinButton = styled.a`
  width: 100%;
  max-width: 289px;
  height: 58px;
  padding: 18px 16px 18px 16px;
  border-radius: 100px;
  background-color: var(--eke-color-primary);
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.3);
  text-decoration: none !important;
  cursor: pointer;
`

export default SpinWheel
