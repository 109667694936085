import { api } from '@ekenta/app/api/apiSlice'
import {
  AllVoucherResponseType,
  createAdminVoucherPayloadType,
  VoucherResponseType,
} from './voucherDataType'

export const adminVoucherApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllAdminVouchers: builder.query<AllVoucherResponseType, void>({
      query: () => ({
        url: `/api/get-admin-vouchers`,
        method: 'GET',
      }),
       providesTags: ['Voucher-Admin']
    }),
    getAllVoucherForAdmin: builder.query<AllVoucherResponseType, void>({
      query: () => ({
        url: `/api/admin/get-all-vouchers`,
        method: 'GET',
      }),
    }),
    getAnalyticsAdminVoucher: builder.query<VoucherResponseType, void>({
      query: () => ({
        url: `/api/admin/get-voucher-analytics/today`,
        method: 'GET',
      }),
    }),
    adminCreateVoucher: builder.mutation<void, createAdminVoucherPayloadType>({
      query: (data) => ({
        url: `/api/admin/create-voucher`,
        method: 'POST',
        body: data,
      }),
       invalidatesTags: ['Voucher-Admin']
    }),
  }),
})
export const {
  useGetAllAdminVouchersQuery,
  useAdminCreateVoucherMutation,
  useGetAnalyticsAdminVoucherQuery,
  useGetAllVoucherForAdminQuery,
} = adminVoucherApiSlice
