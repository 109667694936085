import React, { useState } from 'react'
import Scrollbars from 'react-custom-scrollbars';
import styled from 'styled-components';
import { Popover } from '@ekenta/components';
import { SpinLoader } from '../../../../../components/Spinner/SpinLoader';

export default function PopOver(props: { show: boolean, data: any; isLoading: boolean; isSuccess: boolean; setOutside: () => void }) {



  return (
    <Popover
      show={props.show}
      setOutside={props.setOutside}
      position={'bottom'}
      round={'15'}
      width={'100'}
      height={'300'}>

      <Container className="display__products__product">
        <Scrollbars className="vid_product_contain mt-2" thumbMinSize={80}
          style={{ width: '100%', height: '250px' }} universal={false}>
          {props.isLoading && <SpinLoader size={3} variant="light" />}
          {props.isSuccess && props.data ? props.data.products?.data?.map((item: any) => (
            <SingleProduct
              key={item.id}
              title={item.name}
              image={item.thumbnailUrl}
            />
          )) : null}
        </Scrollbars>
      </Container>
    </Popover>
  )
}


const SingleProduct = (props: { title: string, image: string }) => (
  <li className="group__imG-heading">
    <button className="button__single-p">
      <div className="vid__image_prod">
        <img src={props.image} alt="" />
      </div>
      <h5>{props.title}</h5>
    </button>
  </li>
)


const Container = styled.div`
    display: block;
    width: 100%;
    overflow-x:hidden;
    overflow-y: auto;
    padding-bottom: 5px;
    margin-top: 19.5px;

    .vid_product_contain{
      margin-top: 2rem;
      padding-left: 14px;
      padding-right: 14px;
    }
    

    .group__imG-heading{
      list-style-type: none;
      width: 100%;

      button{
        width: 100%;
        border: none;
        display: flex;
        align-items: center;
        background-color: transparent;
        margin-bottom: -14.45px;
        margin-top: 1.3rem;
        padding-left: 10px;
        padding-top: 2.8px;
        padding-bottom: 2.8px;
        


        .vid__image_prod{
          width: 90px;
          

          img{width: 100%;  height: 100%; border-radius: 5.5px;}
        }
        h5{
          padding-left: 15.555px;
          display: -webkit-box;
          display: flex;
          align-items: center;
          text-decoration: none;
          font-size: 14px !important;
          color: #000;
          max-width: 100%;
          height: 43px; /* Fallback for non-webkit */
          margin-bottom: 0.1rem;
          line-height: 1.4;
          -webkit-line-clamp: 2; 
          -webkit-box-orient: vertical;
          overflow: hidden !important;
          text-overflow: ellipsis;
          transition: .3s ease-in-out;
        }
        
        &:hover{
          background-color: rgba(255, 64, 38, .1);
        }
      }
    }
  
  `;
