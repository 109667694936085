/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import AppContent from '../../components/AppContent';
import ErrorBoundaries from '../../components/ErrorBoundary/ErrorBoundaries';

export default function withAuthenticationLayout(
  WrappedComponent: () => JSX.Element,
  className?: string,
) {
  const AuthenticationLayout = (props: any) => {
    return (
      <>
        <ErrorBoundaries>
          <AppContent
            full={true}
            isPad={false}
            noStyle={true}
            className={className}>
            <WrappedComponent {...props} />
          </AppContent>
        </ErrorBoundaries>
        {/* <CollectionsOffCanvas /> */}
      </>
    );
  };
  AuthenticationLayout.displayName = 'AuthenticationLayout';
  return AuthenticationLayout;
}
