import styled from "styled-components";


export const AppBoard = styled.div`
    padding-right: 5px;
    padding-left: 5px;

    .payout-modalbody{
        width: 539px !important;
        border-radius: 16px !important;
        border: 1px solid var(--greyscale-100, #F5F5F5) !important;
        background: var(--others-white, #FFF) !important;
    }
`;