/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import moment from 'moment'
import { CellProps, Column } from 'react-table'
import styled from '@emotion/styled'
import VoucherTemplate from '@ekenta/components/VoucherCard/VoucherTemplate'
import { VoucherType } from '@ekenta/redux/store/features/voucher/voucherDataType'
import { formatCurrency } from '@ekenta/utils'

interface RowData {
  id: string
  cost: number
  status: string
  code: string
  recipient_name: string
  created_at: string
}

interface RData extends VoucherType {
  name: string
  image: string
  action: any
}

export const voucherHistoryColumn = [
  {
    Header: 'S/N',
    accessor: (_row: any, i: number) => i + 1,
    id: 'sn',
    sortType: 'basic',
    Cell: ({ row }: { row: any }) => row.index + 1,
  },
  {
    Header: 'Date Purchased',
    accessor: 'date',
    sortType: 'basic',
    Cell: ({ cell: { row } }: CellProps<RowData, string>) => (
      <span>{moment(row?.original?.created_at).format('DD/MM/YYYY')}</span>
    ),
  },
  {
    Header: 'Recipient Name',
    accessor: 'name',
    Cell: ({ cell: { row } }: CellProps<RowData, string>) => (
      <HStack>
        <span>{row?.original.recipient_name}</span>
      </HStack>
    ),
  },
  {
    Header: 'Voucher Code',
    accessor: 'voucher_code',
    sortType: 'basic',
    Cell: ({ cell: { row } }: CellProps<RowData, string>) => (
      <span>{row?.original.code}</span>
    ),
  },
  {
    Header: 'Voucher Cost',
    accessor: 'voucher_amount',
    sortType: 'basic',
    Cell: ({ cell: { row } }: CellProps<RowData, string>) => (
      <span>{formatCurrency(row?.original.cost || 0)}</span>
    ),
  },
  {
    Header: 'Type',
    accessor: 'type',
    sortType: 'basic',
  },
  {
    Header: 'Status',
    accessor: 'status',
    sortType: 'basic',
  },
]

export const voucherColumn: Column<RData>[] = [
  {
    Header: 'S/N',
    accessor: (_row, i) => i + 1,
    id: 'sn',
    sortType: 'basic',
    Cell: ({ row }: { row: any }) => row.index + 1,
  },
  {
    Header: 'Voucher Image',
    accessor: 'image',
    Cell: ({ cell: { row } }: CellProps<VoucherType, string>) => (
      <TempBox>
        <VoucherTemplate price={row?.original?.cost} voucherName="" />
      </TempBox>
    ),
  },
  {
    Header: 'Voucher Name',
    accessor: 'name',
    sortType: 'basic',
    Cell: ({ row }: { row: any }) => <span>Gift Voucher {row.index + 1}</span>,
  },
  {
    Header: 'Amount',
    accessor: 'cost',
    sortType: 'basic',
    Cell: ({ cell: { row } }: CellProps<VoucherType, string>) => (
      <span>{formatCurrency(row?.original.cost || 0)}</span>
    ),
  },
  {
    Header: 'Action',
    accessor: 'action',
    sortType: 'basic',
  },
]

const HStack = styled.div``

const TempBox = styled.div`
  position: relative;
  width: 81px;

  .voucher-gift-price {
    top: 19px;
    font-size: 8px !important;
  }
`
