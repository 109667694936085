/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react'

import { OutlineButton, SearchSortStyle, StoreMainBar } from '../style'
import { Field, Typography } from '@ekenta/components'
import { FiFilter, FiSearch } from 'react-icons/fi'
import StoreProducts from './StoreProducts'
import { CollectionContext } from '@ekenta/context/CollectionContext'

interface StoreMainType {
  loading: boolean
  isSuccess: boolean
  isError: boolean
  data: any
  queryNo: number
}

const StoreMain: React.FC<StoreMainType> = (props) => {
  const { loading, data, isSuccess, isError, queryNo } = props
  const { isLoading, filterData, pageNo, setPageNo } =
    useContext(CollectionContext)

  const [pageno, setPageno] = useState(1)
  const [search, setSearch] = useState('')
  const [filteredProducts, setFilteredProducts] = useState([])

  const newData = filterData
    ? filterData.data
    : isSuccess
    ? data.products.data
    : []
  const paginationData = filterData ? filterData : data?.products

  console.log(paginationData)

  const allData = newData
    ? newData.length > 0
      ? filteredProducts.length > 0
        ? filteredProducts
        : newData
      : []
    : []

  useEffect(() => {
    if (search === '' && filteredProducts.length > 0) {
      setFilteredProducts([])
    }
  }, [filteredProducts, search])

  const handleSearch = (term: string) => {
    if (!newData) return null
    const filtered = newData.filter((product: { name: string }) =>
      product.name.toLowerCase().includes(term.toLowerCase())
    )
    setFilteredProducts(filtered)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
    handleSearch(event.target.value)
  }

  return (
    <StoreMainBar>
      <div className="main-wrapper">
        <div className="top--section">
          <Typography as="h3" heading className="Heading">
            All Products
          </Typography>
          <SearchSortStyle>
            <div className="filter-product">
              <OutlineButton
                name="Filters"
                bgVariant=""
                textVariant="primary"
                prefixIcon={<FiFilter />}
                className="store-share-cta"
                type="button"
              />
            </div>
            <div className="SearchWrapper__Box">
              <Field
                id="search-field"
                name="search"
                value={search}
                size="small"
                type="search"
                disabled={false}
                placeholder="Search"
                fieldClassName="SearchWrapper__Field"
                autoComplete="off"
                renderPrefix={<FiSearch />}
                onChange={handleChange}
              />
            </div>
          </SearchSortStyle>
        </div>
        <div className="pt-3">
          <StoreProducts
            loading={isLoading || loading}
            isSuccess={isSuccess}
            isError={isError}
            data={allData}
            paginationData={paginationData}
            pageno={filterData ? pageNo : pageno}
            setPageno={filterData ? setPageNo : setPageno}
            queryNo={queryNo}
          />
        </div>
      </div>
    </StoreMainBar>
  )
}

export default StoreMain
