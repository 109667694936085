/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import SVG from 'react-inlinesvg'
import PinInput from 'react-pin-input'
import { useLocation, useNavigate } from 'react-router-dom'
import CodeVerifySVG from '@ekenta/assets/illustrators/code-verification 1.svg'
import { withAuthenticationLayout } from '@ekenta/hoc'
import { EmailVerifyAsideStyle, EmailVerifyMainContent } from './styles'
import {
  AuthContainerStyle,
  ErrorStyle,
  PinInputStyle,
} from '../common/auth-style'
import Logo from '@ekenta/components/Logo'
import { useVerifyUserEmailUsingCodeMutation } from '@ekenta/redux/store/services/authenticationApi'
import { loginPathUrl, registerPathUrl } from '@ekenta/routes/constant'
import { Button, HelmetHeader } from '@ekenta/components'
import styled from 'styled-components'
import { BASE_URL } from '@ekenta/constants'
import axios from 'axios'

const PIN_LENGTH = 6

const EmailVerification = () => {
  const navigate = useNavigate()
  const { state }: any = useLocation()
  const [emailCode, setEmailCode] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const [verifyUserEmailUsingCode, { isLoading }] =
    useVerifyUserEmailUsingCodeMutation()
  const emailCodeRef = useRef(null)

  useEffect(() => {
    if (!state || !state.email) {
      navigate(`${registerPathUrl}`)
    }
  }, [])

  const validatePin = (value: string) => {
    if (value.trim() === '') return false
    if (value.length < PIN_LENGTH) return false

    return true
  }

  const handleChange = (value: string) => {
    setEmailCode(value)
    if (validatePin(value) === false) return setError('Pin fields are required')
    setError('')
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (validatePin(emailCode) === false)
      return setError('Pin fields are required')
    setError('')
    const data = {
      email: state.email,
      code: emailCode,
    }
    verifyEmail(data)
  }

  const verifyEmail = async (data: any) => {
    try {
      const res = await verifyUserEmailUsingCode(data)
      if (res?.data?.status === true) {
        navigate(loginPathUrl, {
          state: {
            status: 'success',
            statusMsg: 'Email successfully verified',
          },
          replace: true,
        })
      } else if (res?.error?.status === 'FETCH_ERROR') {
        toast.error(`Something went wrong`)
      } else if (res?.error?.status === 400) {
        toast.error(`Verification code is incorrect`)
      } else {
        toast.error(`${res?.error?.data?.message}`)
      }
    } catch (error) {
      toast.error(`Something went wrong`)
    }
  }

  const handleResendCode = (email: string) => {
    setLoading(true)
    try {
      const config = {
        method: 'GET',
        url: `${BASE_URL}/api/resend-otp?email=${email}`,
        headers: {
          'Content-Type': 'application/json',
        },
      }
      axios(config)
        .then(function (response) {
          if (response.status === 200) {
            const message = response?.data.message
            toast.success(`${message}`)
            setLoading(false)
          }
        })
        .catch(function (error: any) {
          const message = error.response.data.message
          setLoading(false)
          toast.error(`${message}` || 'Could not resend otp')
        })
    } catch (e) {
      console.error(e)
      setLoading(false)
      toast.error(`Something went wrong`)
    }
  }

  return (
    <>
      <HelmetHeader
        title="Email Verification"
        description="Email Verification Page"
      />
      <AuthContainerStyle>
        <EmailVerifyAsideStyle>
          <div className="authAImage__Style">
            <SVG src={CodeVerifySVG} />
          </div>
          <h1>OTP Email Verification</h1>
          <p>Enter the OTP code sent to your email address</p>
        </EmailVerifyAsideStyle>
        <EmailVerifyMainContent>
          <div className="ContentChildGroup__Style">
            <Logo width="40px" showDesc={false} />
            <div className="OTP_Text_Style">
              <h1>OTP Email Verification</h1>
              <p>
                Enter the {PIN_LENGTH} digits OTP code sent to your email
                address
              </p>
            </div>

            <div className="ContentForm__Style">
              <form noValidate onSubmit={handleSubmit}>
                <PinInputStyle>
                  <PinInput
                    ref={emailCodeRef}
                    length={PIN_LENGTH}
                    focus
                    onChange={handleChange}
                    type="numeric"
                    inputMode="number"
                    inputStyle={{ borderColor: 'white' }}
                    onComplete={() => null}
                    autoSelect={true}
                  />
                  {error ? <ErrorStyle>{error}</ErrorStyle> : null}
                </PinInputStyle>
                <ResendCode>
                  <button
                    type="button"
                    disabled={isLoading || loading}
                    onClick={() => handleResendCode(state.email)}
                  >
                    Resend code
                  </button>
                </ResendCode>
                <Button
                  name="Submit"
                  bgVariant="primary"
                  className="Button-Call-to-action"
                  isLoading={isLoading || loading}
                  disabled={
                    isLoading || !validatePin(emailCode)
                      ? true
                      : false || loading
                  }
                  type="submit"
                  shadow={false}
                />
              </form>
            </div>
          </div>
        </EmailVerifyMainContent>
      </AuthContainerStyle>
    </>
  )
}

const ResendCode = styled.div`
  padding: 5px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    outline: none;
    border: none;
    color: var(--eke-color-primary);
    font-weight: var(--eke-font-weight-medium);
    background-color: transparent;
    text-decoration: underline;
  }
`

export default withAuthenticationLayout(EmailVerification)
