import React from 'react';
import classnames from 'classnames';
import { TableRowPropType } from '../../interfaces/Table';

import styles from "./Table.module.scss";

const TableRow = ({ children, hasLink, className, style, ...rest }: TableRowPropType) => {
  return (
    <tr
      {...rest}
      style={style}
      className={classnames(className, styles.tr, { [styles.hasLink]: hasLink })}
    >
      {children}
    </tr>
  )
}

export default TableRow;
