import React from "react";
import styled from "styled-components";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const TableSkeleton = ({ colums }: { colums: number }) => {
    return (
        <VStack>
            {" "}
            <div className="HStack">
                {[...Array(colums || 7)].map((e, i) => (
                    <Box key={i} w={`${100 / colums}%`}>
                        <Skeleton
                            baseColor="#ddd"
                            width={'100%'}
                            height={'10px'}
                            borderRadius={"10px"}
                        />
                    </Box>
                ))}
            </div>
            {[...Array(20)].map((e, i) => (
                <div key={i} className="HStack2">
                    {[...Array(colums)].map((e, i) => (
                        <Box key={i} w={`${100 / colums}%`}>
                            <Skeleton
                                baseColor="#ddd"
                                width={'100%'}
                                height={'10px'}
                                borderRadius={"10px"}
                            />
                        </Box>
                    ))}
                </div>
            ))}
        </VStack>
    );
};

const VStack = styled.div`
    width: 100%;
    background-color: white;
    border-radius: 0 0 10px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .HStack{
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 40px;
        width: 100%;
        background-color: #eee;
    }
    .HStack2{
        height: 30px;
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: row;
    }
`;

const Box = styled.div<{ w: string }>`
    height: 30px;
    padding: 8px;
    width: ${props => props.w};
`;

export default TableSkeleton;
