import React from 'react';
import { Breadcrumb } from 'react-bootstrap'
import styled from 'styled-components';

export const HomeBreadCrumb = (props: { activePage: string; }) => {

	return (
		<Container>
			<Breadcrumb className="_p_">
				<Breadcrumb.Item href="/">
					Home
				</Breadcrumb.Item>
				<Breadcrumb.Item active={true}>{props.activePage}</Breadcrumb.Item>
			</Breadcrumb>
		</Container>

	)
}

const Container = styled.div`
	width: 100%;
	padding: 5px 10px 18px 10px;
	font-size: 13px;
	font-family: var(--eke-body-font-family);
	font-weight: var(--eke-font-weight-medium);
	line-height: normal;
	letter-spacing: 0.241px;

	ol{
		margin: 0px !important;
	}
	
	a{
		text-decoration: none !important;
		color: var(--greyscale-900, #212121);
	}

`;