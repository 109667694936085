import React from 'react'
import { withDefaultLayout } from '@ekenta/hoc';
import { HelmetHeader } from '@ekenta/components';
import { Col, Container, Row } from 'react-bootstrap';
import BreadCrumb from '../component/BreadCrumb';
import ProfileSideBar from '../component/AccountSideBar/AccountSideBar';
import styled from 'styled-components';
import ChatSystem from '@ekenta/components/ChatSystem';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { checkIfAdmin, checkIfSeller } from '@ekenta/utils';


const Chats = () => {
    const { token, account } = useAppSelector(
        (state) => state.auth.authentication,
    );
    const role = account.user ? account?.user.role : null;
    const isAdmin = checkIfAdmin(token, role);
    const isSeller = checkIfSeller(token, role);
    const from = isAdmin ? 'admin' : isSeller ? 'seller' : 'buyer';


    return (
        <>
            <HelmetHeader
                title="Chats"
                description="Qlason Chats Account Page"
            />
            <Container className="account-user-container">
                <><BreadCrumb activePage="Referrals" /></>

                <Row className="account-pro-row g-2">
                    <ProfileSideBar isLoading={null} />
                    <Col className="account-profile-col-right p-0">
                        <ContainerStyle>
                            <ChatSystem
                                isPopup={false}
                                from={from}
                                userId={account.user ? account.user.id : null}
                            />
                        </ContainerStyle>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

const ContainerStyle = styled.div`
    width: 100%;
    height: 99vh;
`;


export default withDefaultLayout(Chats);