/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import * as queryString from "query-string";
import { toast } from "react-toastify";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, DataTable, Dropdown, HelmetHeader, PageTitle, Pagination, Typography } from "@ekenta/components";
import { AppBoard } from "@ekenta/pages/Dashboard/common/styles";
import { VscArrowSmallLeft } from "react-icons/vsc";
import { withDashboardLayout } from "@ekenta/hoc";
import { CardGroupStyle, DataTableWrapper, RenderCard, TopWrapper, TypographyWrapper } from "../../../common/common";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { ReactComponent as OrderIcon } from "@ekenta/assets/icons/order-value-icon.svg";
import { ReactComponent as DeliveryIcon } from "@ekenta/assets/icons/delivery-fee-icon.svg";
import { ReactComponent as DownloadIcon } from "@ekenta/assets/icons/download-icon.svg";
import { ReactComponent as DocIcon } from "@ekenta/assets/icons/doc-icon.svg";
import { useFetchPayoutHistoryQuery } from "@ekenta/redux/store/services/adminSellerApi";
import { StatusIndicator, TextTruncate } from "@ekenta/pages/Dashboard/common/Resuable";
import { formatCurrency } from "@ekenta/utils";
import { FiEye, FiMoreHorizontal } from "react-icons/fi";
import { adminPayoutRequest } from "@ekenta/routes/constant";
import { BASE_URL } from "@ekenta/constants";


const AdminPayoutHistory = () => {
    const query = queryString.parse(window.location.search);
    const _page = query.page;
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { token } = useAppSelector((state) => state.auth.authentication);
    const [pageno, setPageno] = useState(1);
    const [loading, setLoading] = useState(false);
    const result = useFetchPayoutHistoryQuery({
        pageno,
        token
    });

    const isCheckData = result?.data ? result?.data.payouts.data.length > 0 ? true : false : false;


    const columns = [
        {
            Header: 'Date',
            accessor: 'date_updated',
            disableSortBy: true,
        },
        {
            Header: 'Name',
            accessor: 'name',
            sortType: 'basic',
        },
        {
            Header: "Store Name",
            accessor: "store",
            sortType: "basic",
        },
        {
            Header: 'Email',
            accessor: 'email',
            sortType: 'basic',
        },
        {
            Header: "Amount",
            accessor: "amount",
            disableSortBy: true,
        },
        {
            Header: "Discount Amt",
            accessor: "discounted_amount",
            disableSortBy: true,
        },
        {
            Header: 'Status',
            accessor: 'status',
            disableSortBy: true,
        },
        {
            Header: 'Action',
            accessor: 'action',
            disableSortBy: true,
        },
    ];

    const mapData = (data: any) => {
        return data.length > 0 ? data.map((item: any) => ({
            ...item,
            date_updated: moment(item?.updated_at).format('MMM D, YYYY'),
            name: (
                item.user ? (
                    <TextTruncate>{item.user.first_name + " " + item.user.last_name}</TextTruncate>
                ) : ''
            ),
            email: item.user ? item?.user?.email : '',
            //phone: item.user ? item.user?.merchant_business?.business_phone_number : '',
            store: (
                <TextTruncate>{item.user?.merchant_business?.business_name}</TextTruncate>
            ),
            amount: formatCurrency(item?.amount, '₦'),
            discounted_amount: formatCurrency(item?.available_amount, '₦'),
            status: (
                item.status ? (
                    <StatusIndicator status={item?.status} />
                ) : item?.status
            ),
            action: (
                <Dropdown
                    prefixIcon={<FiMoreHorizontal fontSize="1.5rem" color="#F8880A" />}
                    placement="bottom"
                    menus={[
                        {
                            name: "View Seller",
                            icon: <FiEye fontSize="1rem" color="#4ADE80" />,
                            active: false,
                            key: "view-seller",
                            handler: () =>
                                navigate(`${adminPayoutRequest}/${item?.user?.id}?history=true`),
                        },
                    ]}
                    tooltipContent={"More Option"}
                />
            ),
        })) : [];
    };

    const handleExportFile = () => {
        if (isCheckData) {
            downloadFile('QlasonSellers_Payout_History.xlsx');
        }
    }

    const downloadFile = async (fileName: string) => {
        setLoading(true);
        try {
            const response = await fetch(`${BASE_URL}/api/admin/generate-payout-sheet?status=COMPLETED&export=${true}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            const { link } = data;
            // Create an anchor element to trigger the download
            const a = document.createElement('a');
            a.href = link;
            a.download = fileName;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            setLoading(false);
            toast.success("Exported file successfully", {
                toastId: "export-xlsx-id-toast-success",
            });
        } catch (error) {
            setLoading(false);
            toast.error("Unable to export file", {
                toastId: "export-to-xlsx-toast-error",
            });
        }
    }



    return (
        <>
            <HelmetHeader
                title="Payout History"
                description="Qlason Payout History Page"
            />
            <AppBoard className="_listing_product_">
                <div className="AppEvents_col mx-1 mx-lg-0">
                    <PageTitle
                        title="Payout History"
                        margin={false}
                        subTitle={
                            <div>
                                <span className="cursor-pointer d-flex w-lg-50  align-items-center text-muted"
                                    onClick={() => navigate(-1)}>
                                    <VscArrowSmallLeft />
                                    Back to Payout
                                </span>
                                <p className="mt-4">
                                    View analytics and seller data
                                </p>
                            </div>
                        }
                    />
                </div>
                <CardGroupStyle flexWrap={false}>
                    <RenderCard
                        icon={<OrderIcon />}
                        loading={result?.isLoading}
                        text="Total Orders Value"
                        amount={`₦${new Intl.NumberFormat().format(result?.data ? result?.data?.totalOrderValue : 0)}`}
                    />
                    <RenderCard
                        icon={<DeliveryIcon />}
                        loading={result?.isLoading}
                        text="Total Delivery Fees"
                        amount={`₦${new Intl.NumberFormat().format(result?.data ? result?.data?.totalDeliveryValue : 0)}`}
                    />
                    <RenderCard
                        icon={<DocIcon />}
                        text="Total Admin Commission"
                        loading={result?.isLoading}
                        amount={`₦${new Intl.NumberFormat().format(result?.data ? result?.data?.totalAdminCommission : 0)}`}
                    />
                </CardGroupStyle>

                <DataTableWrapper isCenter={false} className="mt-4  position-relative" >
                    <div className="tableWrapper">
                        <DataTable
                            theme="simple"
                            data={result?.data ? mapData(result?.data?.payouts?.data) : []}
                            loading={result?.isLoading || result?.isFetching || loading}
                            isSelectable={true}
                            columns={columns}
                            showPagination={true}
                            hasLink={true}
                            thClassName=""
                            trClassName=""
                            theadClassName="DataTable_THead"
                            tdClassName={`Row__col payout_td`}
                            showSearch={false}
                            renderTop={
                                <>
                                    <TypographyWrapper>
                                        <Typography
                                            as="h3"
                                            color="default"
                                            size="xlarge"
                                            heading >
                                            Sellers Payout
                                        </Typography>
                                    </TypographyWrapper>
                                    <TopWrapper>
                                        <Button
                                            name="Export"
                                            bgVariant=""
                                            prefixIcon={<DownloadIcon />}
                                            className="outline_role_button"
                                            type="button"
                                            disabled={loading || !isCheckData}
                                            shadow={false}
                                            onClick={() => handleExportFile()}
                                        />
                                    </TopWrapper>
                                </>
                            }
                            renderPagnation={
                                <div className="mt-4">
                                    {result?.data ? (
                                        <Pagination
                                            pathname={pathname}
                                            itemPerPage={result?.data?.payouts?.per_page}
                                            itemsLength={result?.data?.payouts?.total}
                                            currentPage={pageno}
                                            queryNo={_page}
                                            callback={(value: any) => setPageno(value)}
                                        />
                                    )
                                        : null}
                                </div>
                            }
                        />
                    </div>
                    <br />
                </DataTableWrapper>
            </AppBoard >
        </>
    )
}

export default withDashboardLayout(AdminPayoutHistory);