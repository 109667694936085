import React, { useState } from 'react'
import * as queryString from 'query-string'
import { useNavigate, useLocation } from 'react-router-dom'
import { VscArrowSmallLeft } from 'react-icons/vsc'
import {
  PageTitle,
  Button,
  DataTable,
  HelmetHeader,
  Pagination,
} from '@ekenta/components'
import { AdminVoucherPathUrl } from '@ekenta/routes/constant'
import { withDashboardLayout } from '@ekenta/hoc'
import { VoucherDataType } from '@ekenta/redux/store/features/voucher/voucherDataType'
import { useGetAllAdminVouchersQuery } from '@ekenta/redux/store/features/admin/vouchersApiSlice'
import CreateVoucher from './CreateVoucher'
import { voucherColumn } from './column'
import { VoucherStyle } from './style'

const CreatedVouchers = () => {
  const query = queryString.parse(window.location.search)
  const _page = query.page

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { data, isLoading } = useGetAllAdminVouchersQuery()
  const voucher = data?.data ?? ({} as VoucherDataType)
  const allVoucher = voucher?.data ?? []

  const [pageno, setPageno] = useState(1)
  const [isOpen, setIsOpen] = useState(false)

  const onOpen = async () => {
    setIsOpen(true)
  }

  const onClose = async () => {
    setIsOpen(false)
  }

  return (
    <>
      <HelmetHeader
        title="Created Vouchers"
        description="Qlason All Created Vouchers"
      />
      <VoucherStyle>
        <div className="h-stacked">
          <PageTitle
            title="Created Vouchers"
            subTitle={
              <span
                className="cursor-pointer d-flex w-lg-50 align-items-center 
						text-muted"
                onClick={() => navigate(AdminVoucherPathUrl)}
              >
                <VscArrowSmallLeft />
                Back to Voucher
              </span>
            }
          />

          <Button
            name="Create Voucher"
            type="button"
            bgVariant="primary"
            disabled={false}
            defaultClass={false}
            onClick={onOpen}
          />
        </div>

        <div className="datatable-wrapper">
          <DataTable
            theme="simple"
            data={allVoucher}
            shadow={true}
            loading={isLoading}
            isSelectable={true}
            columns={voucherColumn}
            showPagination={true}
            hasLink={true}
            thClassName=""
            trClassName="w-25"
            theadClassName="DataTable_THead"
            tdClassName={`Row__col`}
            cardClassName="DataTable_Card"
            showSearch={true}
            renderPagnation={
              <div className="mt-4">
                {voucher && voucher?.total > 10 ? (
                  <Pagination
                    pathname={pathname}
                    itemPerPage={voucher?.per_page}
                    itemsLength={voucher?.total}
                    currentPage={pageno}
                    queryNo={_page}
                    callback={(value) => setPageno(value)}
                  />
                ) : null}
              </div>
            }
          />
        </div>
      </VoucherStyle>
      {/* Create Voucher Modal */}
      <CreateVoucher
        {...{
          isOpen,
          onClose,
        }}
      />
    </>
  )
}

export default withDashboardLayout(CreatedVouchers)
