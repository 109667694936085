import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'
import styled, { css } from 'styled-components'
import Counter from '../Counter'
import Loader from '../Loader/Loader'
import {
  removeFromCartAsync,
  updateQuantityAsync,
} from '@ekenta/redux/store/features/cart/cartThunks'
import { useAppSelector } from '@ekenta/redux/store/hook'
import { useNavigate } from 'react-router'
import { loginPathUrl } from '@ekenta/routes/constant'
import { toast } from 'react-toastify'

const QuantityBtn = (props: {
  initialValue?: number
  stock: number
  quantity: number
  cartItemId: string
  allowHover?: boolean
  showDelete?: boolean
  disabled?: boolean
  className?: string
}) => {
  const {
    allowHover = true,
    initialValue = 1,
    showDelete = true,
    quantity,
    cartItemId,
    stock,
    disabled,
    className,
  } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token } = useAppSelector((state) => state.auth.authentication)
  const [loading, setLoading] = useState(false)

  const handleQuantityChange = useCallback(
    async ({
      count,
      type,
    }: {
      count: number
      type: 'increase' | 'decrease'
    }) => {
      if (!token) {
        navigate({
          pathname: loginPathUrl,
          search: `?redirect=${encodeURIComponent(
            location.pathname + location.search
          )}`,
        })
        toast.info('Sigin to add quantity')
        return
      }

      try {
        setLoading(true)
        await dispatch(
          updateQuantityAsync({ cartItemId, type, quantity: count })
        )
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    },
    [token]
  )

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true)
      await dispatch(removeFromCartAsync(cartItemId))
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }, [])

  return (
    <QuantityStyle
      allowHover={allowHover ? 'true' : 'false'}
      disabled={disabled ? 'true' : 'false'}
    >
      {allowHover && (
        <RoundItem className="action_round_btn">
          {loading ? (
            <Loader color="white" size="small" />
          ) : quantity ? (
            <>{quantity}</>
          ) : (
            0
          )}
        </RoundItem>
      )}
      <Counter
        className={classnames('counter-qty', className)}
        incrementLimit={stock}
        initialValue={initialValue}
        value={quantity}
        disabled={loading || disabled}
        showDelete={showDelete}
        onCountChange={handleQuantityChange}
        onDelete={handleDelete}
      />
    </QuantityStyle>
  )
}

const QuantityStyle = styled.div<{ allowHover: string; disabled: string }>`
  transition: all 0.5s ease-in-out;

  .counter-qty {
    display: none;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 36.367px !important;
    background-color: var(--eke-color-primary);
    border-radius: 20px;
    transition: all 0.3s ease-out;
    padding: 15.677px 0px !important;

    .count-value {
      font-size: 14px !important;
      color: var(--eke-color-white) !important;
    }

    button {
      background-color: transparent !important;
      border: 1px solid transparent;

      svg {
        stroke: #fff !important;
      }

      &:hover {
        background: var(--eke-color-primary-400) !important;
      }
    }
  }

  .action_round_btn {
  }

  ${(props) =>
    props.disabled === 'true' &&
    css`
      opacity: 0.5 !important;
      pointer-events: none !important;
    `}

  ${(props) =>
    props.allowHover === 'true'
      ? css`
          .counter-qty {
            display: none;
          }
          .action_round_btn {
          }
          &:hover {
            .action_round_btn {
              display: none;
            }
            .counter-qty {
              display: flex;
            }
          }
        `
      : props.allowHover === 'false' &&
        css`
          .counter-qty {
            display: flex !important;
          }
          .action_round_btn {
            display: none !important;
          }
          &:hover {
            .counter-qty {
              display: flex;
            }
          }
        `}
`

const RoundItem = styled.button`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-radius: 50%;
  text-align: center;
  outline: none;
  color: var(--eke-body-text-light-color);
  border: none !important;
  font-weight: 600 !important;
  background-color: var(--eke-bg-primary-color) !important;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;

  &:hover {
    background-color: var(--eke-bg-primary-light-color) !important;
  }
`

export default QuantityBtn
