import React, { useEffect, useState } from 'react'
import SVG from "react-inlinesvg";
import { toast } from 'react-toastify';
import { withAuthenticationLayout } from '@ekenta/hoc';
import { ForgetPassAsideStyle, ForgetPassMainContent } from './styles';
import { Button, CustomModal, Field, HelmetHeader, RefreshLink } from '@ekenta/components';
import ForgotPassSVG from "@ekenta/assets/illustrators/forgotpassword.svg";
import SuccessCheckSVG from "@ekenta/assets/icons/success-checkmark.svg";
import Logo from '@ekenta/components/Logo';
import { useFormik } from 'formik';
import forgotPassFormValidation from '@ekenta/validations/schemas/account-forgotpassword.schema';
import { useNavigate } from 'react-router-dom';
import { useResetUserPasswordWithEmailMutation } from '@ekenta/redux/store/services/authenticationApi';
import { loginPathUrl, resetPassPathUrl } from '@ekenta/routes/constant';
import { AuthContainerStyle, AuthFormGroupStyle, PopupStyle } from '../common/auth-style';


const ForgetPassword = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [resetUserPasswordWithEmail, { isLoading, isError, error, isSuccess }] =
		useResetUserPasswordWithEmailMutation();

	const formik = useFormik({
		initialValues: { email: '' },
		enableReinitialize: true,
		validationSchema: forgotPassFormValidation(),
		onSubmit: (values) => {
			resetUserPasswordWithEmail({
				email: values.email,
			});
		},
	});

	useEffect(() => {
		if (isError && error.status === 'FETCH_ERROR') {
			toast.error(`Something went wrong`, {
				toastId: 'error-status-fp-id-toast-error',
			});
		}
		if (isError && error.status === 400) {
			toast.error(`${error.data.title}`, {
				toastId: 'error-status-fp400--id-toast-error',
			});
		}

		if (isError && error.status === 401) {
			toast.error(`${error.data.message}`, {
				toastId: 'error-status-fp400--id-toast-error',
			});
		}

		if (isSuccess) {
			setIsOpen(true);
		}

	}, [error, isSuccess, isError]);


	const handleNavigate = () => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
			navigate(resetPassPathUrl, {
				state: {
					email: formik.values.email,
				},
			});
		}, 1000);
	}

	return (
		<>
			<HelmetHeader
				title=" Recover Password"
				description="QlasonForgot Password Page"
			/>
			<AuthContainerStyle>
				<ForgetPassAsideStyle>
					<div className="authAImage__Style">
						<SVG src={ForgotPassSVG} />
					</div>
					<h1>Forgot Password</h1>
					<p>
						Provide your account’s email for which you want to reset your password
					</p>
				</ForgetPassAsideStyle>
				<ForgetPassMainContent>
					<div className="ContentChildGroup__Style">
						<Logo
							width='auto'
							showDesc={false}
						/>
						<div className="RestPass_Text_Style">
							<h1>Forgot Password</h1>
							<p>Provide your account’s email for which you want to reset your password</p>
						</div>

						<div className="ContentForm__Style">
							<form
								noValidate
								onSubmit={formik.handleSubmit}
								autoComplete="off">
								<AuthFormGroupStyle>
									<Field
										id="email"
										name="email"
										label="Email Address"
										disabled={isLoading}
										type="email"
										required
										size="large"
										childOutsideLabel={false}
										placeholder="Johndoe@example.com"
										fieldClassName=""
										autoComplete="off"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.email}
										isError={formik.touched.email && formik.errors.email ? true : false}
										error={formik.errors.email}
									/>
								</AuthFormGroupStyle>

								<Button
									name="Request Reset Code"
									bgVariant="primary"
									className="Button-Call-to-action"
									isLoading={
										isLoading
									}
									disabled={
										isLoading
										|| !formik.values.email
									}
									type="submit"
									shadow={false}
								/>
							</form>
							<div className="LoginDiv__Style">
								<span>Remember your password?</span>
								<RefreshLink to={loginPathUrl}>Sign In</RefreshLink>
							</div>
						</div>
					</div>
					<CustomModal
						show={isOpen}
						size="sm"
						centered={false}
						showFooter={false}
						isLoading={false}
						isDisabled={false}
						render={
							<PopupStyle>
								<div className="SuccessIcon__Style">
									<SVG src={SuccessCheckSVG} />
								</div>
								<h5>OTP code requested!</h5>
								<p>Check your email for OTP code</p>
								<Button
									name="Ok"
									bgVariant="primary"
									className="Button-redirect-action"
									isLoading={loading}
									isLoadingText=" "
									type="button"
									onClick={handleNavigate}
									shadow={false}
								/>
							</PopupStyle>
						}
					/>
				</ForgetPassMainContent>
			</AuthContainerStyle>
		</>
	)
}

export default withAuthenticationLayout(ForgetPassword);