/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react'
import classnames from 'classnames';

import "./Radio.scss";
import { Form } from 'react-bootstrap';

interface RadioPropType {
    id: string;
    className: string;
    onChange: () => void;
    onBlur: () => void;
    variant: "primary" | "success";
    title: string;
    onFocus: () => void;
    label: string;
    checked: boolean;
    value: string;
    disabled: boolean;
    name: string;
}

const Radio = ({
    id,
    className,
    onChange,
    onBlur,
    variant,
    title,
    onFocus,
    label,
    checked,
    value,
    disabled,
    name,
    ...rest
}: any) => {
    const classes = classnames({
        ["RadioDisabled"]: disabled
    });



    return (
        <div className={classnames(className, "Radio__Container")}>
            <Form.Check
                {...rest}
                id={id}
                className={classnames(classes, "Radio__input")}
                label=""
                name={name}
                type="radio"
                color="success"
                disabled={disabled}
                value={value}
                checked={checked}
                onBlur={onBlur}
                onFocus={onFocus}
                onChange={onChange}
            />

            {label ?
                (
                    <label title={title} htmlFor={id}
                        className={classnames("form-check-label", {
                            ["RadioDisabled_Label"]: disabled
                        })}>
                        {label}
                    </label>
                ) : null}
        </div>

    )
}

export default Radio;