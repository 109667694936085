import React, { useState } from 'react'
import styled from 'styled-components';
import { Button, CustomModal, Field, SocialShare, Typography } from '@ekenta/components';
import ReferrelImg from '@ekenta/assets/images/referral.png'
import { device } from '@ekenta/utils/devices';
import { registerPathUrl } from '@ekenta/routes/constant';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { getAppBaseUrl } from '@ekenta/utils';

const Referral = () => {
    const { account } = useAppSelector(
        (state) => state.auth.authentication,
    );
    const [modal, setModal] = useState(false);

    const handleOnClick = (open: boolean) => {
        setModal(open);
    }

    const baseUrl = getAppBaseUrl(true);
    const referralLink = `${baseUrl}${registerPathUrl}?code=${account.user.username}`

    return (
        <Container>
            <div className="TopHeading">
                <Typography
                    as="h3"
                    size="base"
                    color="dark"
                    heading>
                    Refer a Friend
                </Typography>
                <Typography
                    as="p"
                    size="medium"
                    color="dark"
                    uiText>
                    Refer a friend and get amazing offers
                </Typography>
            </div>
            <div className="FlexDiv__Sec">
                <FormDiv>
                    <div>
                        <Field
                            id="referrallink"
                            name="referrallink"
                            label="Referral Code"
                            disabled={false}
                            type="text"
                            required
                            size="large"
                            value={referralLink}
                            childOutsideLabel={false}
                            placeholder="qlason.com/?code=refer-a-friend"
                            fieldClassName="FieldControl__Input"
                            autoComplete="off"
                        />
                        <Typography
                            as="p"
                            size="medium"
                            color="dark"
                            margin={'top'}
                            uiText>
                            Share this referral code with your friends.
                        </Typography>
                    </div>
                    <Button
                        name="Share"
                        bgVariant="primary"
                        textVariant="light"
                        className="py-2"
                        isLoadingText="Save"
                        shadow={false}
                        type="button"
                        onClick={() => handleOnClick(true)}
                    />
                </FormDiv>
                <div className="ImageDiv__Sec">
                    <img src={ReferrelImg} alt="" />
                </div>
            </div>

            <CustomModal
                show={modal}
                size="sm"
                title="Share Link to"
                subtitle=""
                centered={false}
                showFooter={false}
                isLoading={false}
                isDisabled={false}
                headerClass="app-modalheader event-modalhead"
                modalClass="app-modalbody"
                onHide={() => handleOnClick(false)}
                render={
                    <SocialShare
                        url={referralLink}
                        title="Qlason - Online Shopping Made Easy"
                    />
                }
            />
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    min-height: 269px;
    flex-shrink: 0;
    padding: 26px 23px 0px 23px;
    border-radius: 14px;
    background: #F5FBFF;

    .TopHeading{
        h3{
            color: var(--greyscale-900, #212121);
            font-size: 24px !important;
            font-weight: 700 !important;
            line-height: 120%; /* 28.8px */
            margin-bottom: 10px !important;
        }
        
        p{
            color: #000 !important;
            font-size: 15px !important;
            font-weight: var(--eke-font-weight-normal) !important;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.2px;
        }
    }

    .FlexDiv__Sec{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 2.5rem;
        padding-top: 10px;

        @media ${device.md} {
            flex-direction: row;
            gap: 0rem;

        }

        .ImageDiv__Sec{
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
            img{
                width: 90% !important;
                margin-top: -10%;
            }
         
            @media ${device.md} {
                justify-content: flex-end;
                flex: 1 1 40%;

                img{
                width: 80% !important;
                margin-top: -13%;
                }

            }

            @media ${device.xl} {
                flex: 1 1 auto;
            }
        }
    }
`;

const FormDiv = styled.div`
    flex: 1;
    width: 100% !important;
    position: relative;
    

    button {
        position: absolute;
        top: 30%;
        padding-left: 22px !important;
        padding-right: 22px !important;
        right: 0;
    }

    @media ${device.md} {
        flex: 1 1 auto;
        width: auto !important;

    }

`;



export default Referral;