import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Spinner } from "@ekenta/components/icons/icons";
import styles from "./Loader.module.scss";
import "./Loader.scss";

interface LoaderPropsType {
  withWrapper?: boolean;
  size?: "small" | "large" | "xlarge";
  isCenter?: boolean;
  padding?: boolean;
  color?: "white" | "black" | "gray",
  className?: string
}

const Loader = ({
  withWrapper = false,
  color,
  isCenter = false,
  padding = false,
  size,
  className,
}: LoaderPropsType) => {
  const loadClasses = classnames(className, styles.Span, styles.isCenter);

  const sizeClass = classnames({
    [styles.largeLoader]: size === "large",
    [styles.xlargeLoader]: size === "xlarge",
    [styles.smallLoader]: size === "small",
  });

  const centerClass = classnames(styles.Span, styles.isCenter, {
    [styles.padding]: padding
  });

  if (withWrapper) {
    return (
      <span className={loadClasses}>
        <Spinner className={classnames(styles.svgLoader, sizeClass)} color={color} />
      </span>
    );
  }

  if (isCenter) {
    return (
      <span className={centerClass}>
        <Spinner className={classnames(styles.svgLoader, sizeClass)} color={color} />
      </span>
    );
  }

  return <Spinner className={classnames(styles.svgLoader, sizeClass)} color={color} />;
};

Loader.propTypes = {
  /**
   * Whether the spinner has a span wrapper
   */
  withWrapper: PropTypes.bool,
  /**
   * What spinner color to use
   */
  color: PropTypes.string,
  /**
   * Whether the spinner is centered on the screen
   */
  isCenter: PropTypes.bool,
};

export default Loader;