/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import { toast } from 'react-toastify'
import {
  FooterBottomChildStyle,
  FooterInnerContainerStyle,
  FooterContainerStyle,
  FooterTopChildStyle,
  FooterSocialStyle,
  FooterLogoStyle,
  FooterInnerWrapperStyle,
} from './styles'
import Field from '../InputField/Field/Field'
import { Logo, MailIcon } from '../icons'
import FooterShape1SVG from '@ekenta/assets/icons/footer-shape1.svg'
import FooterShape2SVG from '@ekenta/assets/icons/footer-shape2.svg'
import FooterShape3SVG from '@ekenta/assets/icons/footer-shape3.svg'
import FooterShape4SVG from '@ekenta/assets/icons/footer-shape4.svg'
import FooterShape5SVG from '@ekenta/assets/icons/footer-shape5.svg'
import FooterShape6SVG from '@ekenta/assets/icons/footer-shape6.svg'
import FooterFaceBookSVG from '@ekenta/assets/icons/facebook-icon.svg'
import AppleStoreSVG from '@ekenta/assets/icons/apple1-store.svg'
import GooglePlaySVG from '@ekenta/assets/icons/google-play-store.svg'
import PhoneSVG from '@ekenta/assets/icons/phone-icon.svg'
import Button from '../Button/Button'
import RefreshLink from '../RefreshLink/RefreshLink'
import {
  SellerOnboardingPathUrl,
  blogPathUrl,
  careersPathUrl,
} from '@ekenta/routes/constant'

import instagramSVGI from '@ekenta/assets/icons/instagram-ic.svg'
import twitterSVGI from '@ekenta/assets/icons/twitter-ic.svg'
import whatsappSVGI from '@ekenta/assets/icons/whatsapp-ic.svg'
import axios from 'axios'
import { BASE_URL } from '@ekenta/constants'
import { showToastify } from '@ekenta/utils'

const Footer = () => {
  const [newsEmail, setNewsEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()

    if (!newsEmail.trim()) {
      return showToastify('Email is required', 'error', true)
    }
    setIsLoading(true)
    try {
      const config = {
        method: 'POST',
        url: `${BASE_URL}/api/subscribe`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: { email: newsEmail },
      }
      axios(config)
        .then(function (response: any) {
          if (response?.status === 200) {
            setNewsEmail('')
            toast.success(response?.data?.message)
            setIsLoading(false)
          }
        })
        .catch(function (error: any) {
          const data = error?.response?.data
          setIsLoading(false)
          toast.error(data?.message || 'Could not subcribe')
        })
    } catch (e) {
      console.error(e)
      setIsLoading(false)
      toast.error(`Something went wrong`)
    }
  }

  return (
    <FooterContainerStyle className="footer-container">
      <FooterInnerContainerStyle>
        <FooterInnerWrapperStyle>
          <FooterTopChildStyle>
            <h1>Qlason Newsletter</h1>
            <p>
              Be the first one to know about discounts, offers and events.
              Unsubscribe whenever you like.
            </p>
            <div className="NewsLetterField__Wrap">
              <form onSubmit={handleSubmit}>
                <Field
                  id="newsletter-field"
                  name="newsletter"
                  type="email"
                  disabled={isLoading}
                  value={newsEmail}
                  renderPrefix={<MailIcon />}
                  fieldClassName="Newsletter__FieldWrap"
                  placeholder="Enter your email"
                  autoComplete="off"
                  onChange={(e) => setNewsEmail(e.target.value)}
                />
                <div className="NewsletterField__Button">
                  <Button
                    name="Submit"
                    bgVariant="primary"
                    shadow={false}
                    type="submit"
                    isLoading={isLoading}
                    isLoadingText=" "
                  />
                </div>
              </form>
            </div>
            <SVG className="shape-right4" src={FooterShape1SVG} />
            <SVG className="shape-right5" src={FooterShape3SVG} />
            <SVG className="shape-right6" src={FooterShape5SVG} />
            <SVG className="shape-right7" src={FooterShape6SVG} />
            <SVG className="shape4" src={FooterShape4SVG} />
            <SVG className="shape3" src={FooterShape3SVG} />
            <SVG className="shape2" src={FooterShape2SVG} />
            <SVG className="shape1" src={FooterShape1SVG} />
          </FooterTopChildStyle>
          <FooterBottomChildStyle>
            <div className="FooterMenuDiv__Style">
              <div className="FooterSub__Style FooterSubMenu__Style">
                <FooterLogoStyle>
                  <RefreshLink to="/">
                    <Logo width="65" height="" />
                    <div className="LogoText">
                      <h3>Qlason</h3>
                      <p>..Shop with Confidence</p>
                    </div>
                  </RefreshLink>
                </FooterLogoStyle>
                <p className="description_Style">
                  Qlason is the world&apos;s leading social commerce for buyer
                  and sellers to achieve an social experience.
                </p>
                <FooterSocialStyle>

                  <a
                    href="https://www.facebook.com/share/5XLhXDAbaQPCLK3y/?mibextid=qi2Omg"
                    title="Facebook Qlason Nigeria"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    
                    <span>
                      <SVG src={FooterFaceBookSVG} />
                    </span>
                  </a>
                  <a
                    href="https://wa.link/2473bs"
                    title="Facebook Qlason Nigeria"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <SVG src={whatsappSVGI} />
                    </span>
                  </a>
                  <a
                    href="https://www.instagram.com/qlason_stores?igsh=MXZjZ2l6bm1wMHVzbw=="
                    title="Instagram Qlason Nigeria"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <SVG src={instagramSVGI} />
                    </span>
                  </a>
                  <a
                    href="https://x.com/Qlasonstore?t=3ql3uqVhK4RllTCVsgPnkA&s=08"
                    title="Twitter Qlason Nigeria"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <SVG src={twitterSVGI} />
                    </span>
                  </a>
                </FooterSocialStyle>
              </div>
              <div className="FooterSub__Style FooterSubMenu__Style">
                <ul>
                  <li>
                    <h5>About</h5>
                  </li>
                  <li>
                    <RefreshLink to={blogPathUrl}>Blog</RefreshLink>
                  </li>
                  <li>
                    <RefreshLink to={careersPathUrl}>Careers</RefreshLink>
                  </li>
                </ul>
              </div>
              <div className="FooterSub__Style FooterSubMenu__Style">
                <ul>
                  <li>
                    <h5>Support</h5>
                  </li>
                  <li>
                    <RefreshLink to={SellerOnboardingPathUrl}>
                      Sell on Qlason
                    </RefreshLink>
                  </li>
                  <li>
                    <RefreshLink to="/privacy-policy">
                      Return Policy
                    </RefreshLink>
                  </li>
                  <li>
                    <RefreshLink to="/terms-and-conditions">
                      Terms & Condition
                    </RefreshLink>
                  </li>
                  <li>
                    <RefreshLink to="/privacy-policy">
                      Privacy Policy
                    </RefreshLink>
                  </li>
                </ul>
              </div>
              <div className="FooterSub__Style FooterSubMenu__Style">
                <ul>
                  <li>
                    <h5 className="mb-3">Need Help?</h5>
                  </li>
                  <li className="withIcon_Style">
                    <a
                      href="https://wa.me/c/2349134317883"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="listMenu_Icon">
                        <SVG src={PhoneSVG} />
                      </span>
                      <div className="listMenu_Style">
                        <span>WhatsApp</span>
                        <span>09134317883</span>
                      </div>
                    </a>
                  </li>
                  <li className="withIcon_Style">
                    <a
                      href="https://wa.me/c/2349134317883"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="listMenu_Icon">
                        <SVG src={PhoneSVG} />
                      </span>
                      <div className="listMenu_Style">
                        <span>WhatsApp</span>
                        <span>081 4655 2089</span>
                      </div>
                    </a>
                  </li>
                  <li className="withIcon_Style">
                    <a href="mailto:support@dabsoluterobotics.com">
                      <span className="listMenu_Icon">
                        <MailIcon />
                      </span>
                      <div className="listMenu_Style">
                        <span>Contact us at</span>
                        <span>support@dabsoluterobotics.com</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="FooterSub__Style FooterSubApp_Down__Style">
                <div>
                  <h5>Download Qlason Mobile</h5>
                  <p>You can now use Qlason on your mobile</p>
                  <div data-store="true" className="FooterDownloadApp_Store">
                    <a
                      href="https://apps.apple.com/ng/app/qlason/id6463491869"
                      target="_blank"
                      rel="no noreferrer"
                    >
                      <SVG src={AppleStoreSVG} />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.qlason"
                      target="_blank"
                      rel="no noreferrer"
                    >
                      <SVG src={GooglePlaySVG} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="FooterYear_Reserved">
              <span>
                &copy; {new Date().getFullYear()}, All Rights Reserved
              </span>
            </div>
          </FooterBottomChildStyle>
        </FooterInnerWrapperStyle>
      </FooterInnerContainerStyle>
    </FooterContainerStyle>
  )
}

export default Footer
