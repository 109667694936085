import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SellerDetailState } from "../initialState";

export const SellerSlice = createSlice({
    name: 'sellerdetail',
    initialState:SellerDetailState,
    reducers: {
        setSellerDetail:(state,action: PayloadAction<{sellerInfo: any | null}>) => {
           state.details.sellerInfo = action.payload.sellerInfo;
        },
       
       removeSellerDetail:(state) => {
            state.details.sellerInfo = null;
       },

    }
})

export const { setSellerDetail, removeSellerDetail} = SellerSlice.actions;

export  default SellerSlice.reducer;
