/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tab } from '@ekenta/components';
import React from 'react'
import AllOtherProducts from './AllOtherProducts';

import "./index.scss";
import MoreLives from './MoreLives';

const SideSection = (props: { productData: any, liveData: any, isLiveType: string }) => {
    const { productData, isLiveType, liveData } = props;
    return (
        <div className="stream__col__4">
            <div className="right__side_stream_div">
                <div className="bottom__section__container">
                    <Tab
                        defaultActiveKey="allProducts"
                        id="noanim-tab"
                        transition={false}
                    >
                        <Tab.Body
                            eventKey="allProducts"
                            title="All Products"
                        >

                            <div className="list__user__product">
                                <AllOtherProducts
                                    data={productData?.data}
                                    isLoading={productData?.isLoading}
                                    isError={productData?.isError}
                                    isSuccess={productData?.isSuccess}
                                />
                            </div>

                        </Tab.Body>
                        <Tab.Body
                            eventKey="moreLive"
                            title="More Livestreams"
                        >
                            <MoreLives
                                data={liveData?.data}
                                isLoading={liveData?.isLoading}
                                isSuccess={liveData?.isSuccess}
                                isError={liveData?.isError}
                                isLiveType={isLiveType}
                            />
                        </Tab.Body>
                    </Tab>

                </div>
            </div>
        </div>
    )
}

export default SideSection;