import { AsyncThunkAction, createAsyncThunk } from '@reduxjs/toolkit'

import axios from 'axios'
import {} from './chatApiSlice'
import { BASE_URL } from '@ekenta/constants'
import { setChatCount } from './chatSlice'
import { debounce } from 'lodash'

let debouncedSetChatCount: NodeJS.Timeout | null = null

export const setChatCountAsync = createAsyncThunk<
  { count: number },
  void,
  { state: { auth: { authentication: { token: string } } } }
>('chat/setChatCount', async (_, { getState, dispatch }) => {
  const { token } = getState().auth.authentication
  try {
    if (token) {
      const response = await axios.get(
        `${BASE_URL}/api/get-all-unread-messages-count`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const count = response?.data?.data
      dispatch(setChatCount(count))
      return response.data
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject('Could not update count')
    }
    throw error
  }
  return Promise.reject('Somethin went wrong')
})

export const dispatchDebouncedSetChatCount = () => (dispatch: (arg0: AsyncThunkAction<{ count: number }, void, { state: { auth: { authentication: { token: string } } } }>) => void) => {
  if (debouncedSetChatCount) {
    clearTimeout(debouncedSetChatCount)
  }
  debouncedSetChatCount = setTimeout(() => {
    dispatch(setChatCountAsync())
  }, 1000)
}
