/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from "react"
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { newreturnRequestPathUrl } from "../../../../routes/constant";
import ProfileSideBar from "../component/AccountSideBar/AccountSideBar"
import BreadCrumb from "../component/BreadCrumb";
import { useFetchUserReturnRequestQuery } from "../../../../redux/store/services/accountApi";
import { useAppSelector } from "../../../../redux/store/hook";
import { Button, EmptyRequest, HelmetHeader, Loader, Overlay, PageTitle } from "@ekenta/components";
import { withDefaultLayout } from "@ekenta/hoc";
import ListRequestItem from "./component/ListRequestItem/ListRequestItem";
import { FiPlus } from "react-icons/fi";
import BackButton from "../component/BackButton";

const ReturnRequest = () => {

    const navigate = useNavigate();
    // useFetchProductToReturnQuery
    const { token } = useAppSelector(state => state.auth.authentication);
    //const { data, isLoading, isFetching, isError, error, isSuccess } = useFetchProductToReturnQuery(token, { skip: !token });

    const getRequest = useFetchUserReturnRequestQuery(token);

    return (
        <Fragment>
            <HelmetHeader
                title="Return Request"
                description="QlasonReturn Request Account Page"
            />
            <Container className="account-user-container">
                <><BreadCrumb activePage="Return Request" /></>

                <Row className="account-pro-row g-2">
                    <ProfileSideBar isLoading={null} />
                    <Col className="account-profile-col-right ">
                        <div className="mb-2">
                            <PageTitle
                                title="Return Request"
                                margin={false}
                                subTitle={<BackButton />}>
                                <Fragment>

                                    <Button
                                        name="New return request"
                                        shadow={false}
                                        prefixIcon={<FiPlus />}
                                        type="button"
                                        bgVariant="primary"
                                        textVariant="light"
                                        disabled={false}
                                        onClick={() => {
                                            navigate(newreturnRequestPathUrl);
                                            window.scrollTo(0, 0);
                                        }}
                                    />

                                </Fragment>
                            </PageTitle>
                        </div>
                        <Col sm={12} lg={12}>
                            <div className="main--content_div">
                                {getRequest?.data ? getRequest?.data.products.length > 0
                                    ? getRequest?.data.products.map((item: any) => (
                                        <div key={item.id}>
                                            <ListRequestItem
                                                item={item.order_item}
                                                reason={item.reason}
                                                status={item.status}
                                            />
                                        </div>
                                    )) : (
                                        <EmptyRequest
                                            title="No new return request"
                                        />
                                    ) : null}
                            </div>

                        </Col>
                        {getRequest?.isLoading ? (
                            <Overlay bgColor="dark">
                                <Loader
                                    isCenter
                                    size="large"
                                    color="white"
                                />
                            </Overlay>
                        ) : null}
                    </Col>
                </Row>
            </Container>
        </Fragment >
    )
}


export default withDefaultLayout(ReturnRequest);