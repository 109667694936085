import { api } from "@ekenta/app/api/apiSlice";


export const vendorApiSlice = api.injectEndpoints({
  endpoints: builder => ({
    getSellerProducts: builder.query({
        query: (id) => ({
            url: `/api/get-seller-product/${id}`,
            method: 'GET',
        }),
    }),
    getAllVendors: builder.query({
      query: () => ({
          url: `/api/get-vendors`,
          method: 'GET',
      }),
  }),
  }),
});
export const {
  useGetSellerProductsQuery,
  useGetAllVendorsQuery
} = vendorApiSlice;

