/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import OrderSkeletonLoader from "@ekenta/components/SkeletonLoader/Account/OrderSkeletonLoader";
import EmptyOrder from "@ekenta/components/Box/EmptyOrder";
import MainOrder from "./mainOrder";
import { CustomAlert } from "@ekenta/components";

interface CompleteOrderPropsType {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  data: any;
}

export default function CompleteOrder(props: CompleteOrderPropsType) {
  const { data, isLoading, isError, isSuccess } = props;

  return (
    <div className="w-100">
      {isLoading ? (
        <OrderSkeletonLoader />
      ) : (
        <>
          {isError ? (
            <CustomAlert
              variant="danger"
              show={true}
              message={"Something went wrong, Try again"}
            />
          ) : null}

          <div>
            {isSuccess && data ? (
              data.completedOrders.length > 0 ? (
                data.completedOrders.map((item: any, index: any) => {
                  return (
                    <MainOrder key={index} itemData={item} />
                  );
                })
              ) : (
                <EmptyOrder orderType="Completed" />
              )
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}
