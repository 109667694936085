import React, { useState } from 'react';
import styled from 'styled-components';



export const PriceWarranty = () => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggleShow = () => {
		setIsOpen(!isOpen);
	};
	return (
		<div>
			<p>At Qlason, we strive to ensure the utmost satisfaction of our valued customers. To provide you with a seamless shopping experience and demonstrate our commitment to quality, we offer a 15-Days Price Warranty for all eligible purchases made through our e-commerce platform. This warranty is designed to protect your investment and give you peace of mind.
				{!isOpen && (
					<ReadButton padding={true} onClick={handleToggleShow}>Read More</ReadButton>
				)}
			</p>
			{isOpen && (
				<>
					<p>Terms and Conditions:</p>
					<p>
						<ul>
							<li>
								1. Eligibility:
								<ul>
									<li>a. The 15-Days Price Warranty is applicable to products purchased directly from Qlason&apos;s e-commerce website.</li>
									<li>b. This warranty covers eligible products that have been paid for in full and are in new, unused condition.</li>
									<li>c. Certain products may be excluded from the warranty, including but not limited to software, digital goods, gift cards, and customised or personalised items.</li>
								</ul>
							</li>
							<li>
								2. Price Match Guarantee:
								<ul>
									<li>a. If, within 15 days of your purchase, you find the same product available at a lower price on any of the two biggest and most recognized competitor&apos;s websites, we will refund the price difference.</li>
									<li>b. The lower price must be publicly advertised and available for purchase at the time of the warranty claim herein.</li>
									<li>c. The warranty claim must be made within the 15-day period by contacting our customer support team.</li>
								</ul>
							</li>
							<li>
								3. Warranty Claim Procedure:
								<ul>
									<li>a. To make a warranty claim, please contact our customer support team via email or phone within 15 days of your purchase.</li>
									<li>b. You will need to provide formal proof of purchase, including the order number and the website where the lower price is advertised.</li>
									<li>c. Our customer support team will verify the information provided and process the refund if all eligibility criteria are met.</li>
									<li>d. The said product must be available for purchase by Us within the said period.</li>
								</ul>
							</li>
							<li>
								4. Refund Process:
								<ul>
									<li>a. If your warranty claim is approved, we will refund the price difference to your original payment method within a reasonable timeframe.</li>
									<li>b. Refunds may take several business days to reflect in your account, depending on your bank or payment provider.</li>
								</ul>
							</li>
							<li>
								5. Limitations:
								<ul>
									<li>a. The 15-Days Price Warranty is limited to one claim per eligible product purchase.</li>
									<li>b. The warranty is non-transferable and cannot be combined with any other promotional offers or discounts.</li>
									<li>c. Qlason reserves the right to modify or terminate this warranty program at any time without prior notice to you.</li>
								</ul>
							</li>
						</ul>
					</p>

					<p>By making a purchase on our e-commerce platform, you acknowledge and agree to the terms and conditions outlined in this 15-Days Price Warrants as well as our general terms and conditions.</p>

					<p>If you have any questions or need further clarification regarding this warranty, please contact our customer support team. We are here to assist you and ensure your complete satisfaction with your shopping experience.</p>

					<p>Thank you for choosing Qlason. We appreciate your trust in our products and services.</p>

					<p>Sincerely,</p>

					<p>[Absolute Robotics]</p>

					<ReadButton padding={false} onClick={handleToggleShow}>Read Less</ReadButton>
				</>
			)}
		</div>
	)
}

export const Exchange90Days = () => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggleShow = () => {
		setIsOpen(!isOpen);
	};
	return (
		<div>
			<p>At Qlason, we strive to provide high-quality electronic products and ensure your satisfaction with your purchase. We understand that sometimes issues can arise, and we want to make the process as convenient and seamless as possible for you. Our 90 Days Exchange Without Repairs policy is designed to offer you the option of exchanging eligible electronic products within a specified timeframe without the need for repairs. Please carefully review the following terms and conditions:
				{!isOpen && (
					<ReadButton padding={true} onClick={handleToggleShow}>Read More</ReadButton>
				)}
			</p>
			{isOpen && (
				<>
					<p>
						<ul>
							<li>
								1. Eligibility:
								<ul>
									<li>a. The 90 Days Exchange Without Repairs policy is applicable to electronic products purchased directly from Qlason&apos;s e-commerce website.</li>
									<li>b. This policy covers eligible electronic products that experience a routine malfunction, defect, or any performance-related issue within 90 days from the date of purchase.</li>
									<li>c. Products must be in new, unused condition and include all original packaging, accessories, and documentation.</li>
									<li>d. Certain items may be excluded from this policy, including; software, digital downloads, consumables, and products damaged due to mishandling, misuse, or unauthorised modifications.</li>
								</ul>
							</li>
							<li>
								2. Exchange Procedure:
								<ul>
									<li>a. To initiate an exchange, please contact our customer support team via email or phone within 90 days of your purchase.</li>
									<li>b. Provide the necessary details in clean clear condition, including your order number, product information, and a detailed description of the issue.</li>
									<li>c. Our customer support team will guide you through the exchange process, provide an exchange authorization, and any additional instructions.</li>
								</ul>
							</li>
							<li>
								3. Exchange Options:
								<ul>
									<li>a. Depending on product availability, you will have the option to exchange the defective electronic product for an identical replacement or a product of similar value.</li>
									<li>b. If the original product is no longer available or discontinued, we will work with you to find a suitable alternative or provide a refund within a reasonable time period.</li>
								</ul>
							</li>
							<li>
								4. Return Shipping:
								<ul>
									<li>a. For eligible exchanges, we will provide a prepaid shipping label or arrange a pickup service for the return of the defective product.</li>
									<li>b. Please ensure that the product is securely packaged to avoid damage during transit.</li>
									<li>c. You are responsible for returning the product within the specified timeframe and state and following the provided instructions.</li>
								</ul>
							</li>
							<li>
								5. Inspection and Exchange:
								<ul>
									<li>a. Once the returned product is received, it will undergo a thorough inspection to ensure it meets the eligibility criteria.</li>
									<li>b. If the product passes the inspection and the issue is verified, an exchange will be initiated within the stated period.</li>
									<li>c. The replacement product will be shipped to you within a reasonable timeframe.</li>
								</ul>
							</li>
							<li>
								6. Limitations:
								<ul>
									<li>a. The 90 Days Exchange Without Repairs policy is subject to certain limitations and may be modified or terminated at any time without prior notice to you.</li>
									<li>b. The policy does not cover accidental damage, normal wear and tear, or issues resulting from unauthorised repairs or modifications.</li>
								</ul>
							</li>
							<li>
								7. Additional Warranties:
								<ul>
									<li>a. If the electronic product is covered by an additional manufacturer&apos;s warranty, you may be directed to contact the manufacturer for further assistance and resolution.</li>
								</ul>
							</li>
							<li>
								8. Dispute Resolution:
								<ul>
									<li>a. In case of any dispute or discrepancies, Qlason reserves the right to make the final decision.</li>
								</ul>
							</li>
						</ul>
					</p>

					<p>By making a purchase on our e-commerce platform, you acknowledge and agree to the terms and conditions outlined in this 90 Days Exchange Without Repairs policy.</p>

					<p>If you have any questions or require further assistance regarding exchanges, please contact our customer support team. We are here to help and ensure your complete satisfaction.</p>

					<p>Thank you for choosing Qlason. We appreciate your business and look forward to serving you again.</p>

					<p>Sincerely,</p>

					<p>[Absolute Robotics]</p>

					<ReadButton padding={false} onClick={handleToggleShow}>Read Less</ReadButton>
				</>
			)}
		</div>
	)
}


export const TermsDescription = () => {
	const [isOpen, setIsOpen] = useState(false);


	const handleToggleShow = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div>
			<p>At Qlason, we offer a convenient Trade-In program to provide our valued customers with an opportunity to upgrade their products and receive credit towards new purchases. Please read the following terms and conditions carefully to understand the guidelines and procedures for trade-ins on our e-commerce website
				{!isOpen && (
					<ReadButton padding={true} onClick={handleToggleShow}>Read More</ReadButton>
				)}
			</p>
			{isOpen && (
				<>
					<p>
						<ul>
							<li>
								1. Eligibility:
								<ul>
									<li>a. The Trade-In program is applicable to eligible products purchased directly from Qlason&apos;s e-commerce website.</li>
									<li>b. Only certain products may be eligible for trade-in, as determined by Qlason. These products will be clearly indicated on our website.</li>
									<li>c. The condition of the product being traded in must meet the specified requirements, such as being in working condition and free from routine damage.</li>
								</ul>
							</li>
							<li>
								2. Trade-In Valuation:
								<ul>
									<li>a. The trade-in value will be exclusively determined by Qlason based on various factors, including the condition, age, and market demand of the product being traded in.</li>
									<li>b. The trade-in value provided by Qlason is final and non-negotiable.</li>
									<li>c. The trade-in value will be provided as store credit or a discount towards the purchase of a new product, as determined by Qlason.</li>
								</ul>
							</li>
							<li>
								3. Trade-In Process:
								<ul>
									<li>a. To initiate a trade-in, please follow the instructions provided on our e-commerce website or contact our customer support team for assistance.</li>
									<li>b. You will be required to provide accurate information about the product being traded in, including its condition, specifications, and any included accessories.</li>
									<li>c. Our customer support team will guide you through the trade-in process, provide a trade-in authorization, and any additional instructions.</li>
								</ul>
							</li>
							<li>
								4. Product Inspection:
								<ul>
									<li>a. Once the traded-in product is received by Qlason, it will undergo a thorough inspection to assess its condition and verify its eligibility for the trade-in program.</li>
									<li>b. The inspection process may include testing the functionality of the product, assessing its physical condition, and ensuring that it matches the provided information.</li>
								</ul>
							</li>
							<li>
								5. Trade-In Credit:
								<ul>
									<li>a. If the traded-in product meets the eligibility criteria, Qlason will provide the determined trade-in value as store credit or a discount towards the purchase of a new product.</li>
									<li>b. The trade-in credit can only be used towards eligible purchases on Qlason&apos;s e-commerce website and is non-transferable.</li>
									<li>c. The trade-in credit cannot be redeemed for cash or refunded.</li>
								</ul>
							</li>
							<li>
								6. Trade-In Return:
								<ul>
									<li>a. Once a product has been traded in, it cannot be returned or retrieved </li>
									<li>b. The traded-in product becomes the property of Qlason, and we hold the right to dispose of or resell it at our discretion.</li>
								</ul>
							</li>
							<li>
								7. Product Ownership and Authorization:
								<ul>
									<li>a. By initiating a trade-in, you confirm that you are the rightful owner of the product and have the legal authority to trade it in.</li>
									<li>b.  You are solely responsible for removing any personal data, accounts, or content from the traded-in product before sending it to Qlason.</li>
								</ul>
							</li>
							<li>
								8. Limitations:
								<ul>
									<li>a. The Trade-In program is subject to certain limitations and may be modified or terminated at any time without prior notice to you.</li>
									<li>b. Qlason reserves the right to reject a trade-in request if the product does not meet the specified eligibility criteria or if there are concerns about its condition or authenticity.</li>
								</ul>
							</li>
							<li>
								9. Dispute Resolution:
								<ul>
									<li>a. In case of any dispute or discrepancies, Qlason reserves the right to make the final decision on any issue within the terms of this Agreement.</li>
								</ul>
							</li>
						</ul>
					</p>
					<p>By participating in our Trade-In program, you acknowledge and agree to the terms and conditions outlined above.</p>
					<p>If you have any questions or require further assistance regarding trade-ins, please contact our customer support team. We are here to help and ensure your complete satisfaction</p>
					<ReadButton padding={false} onClick={handleToggleShow}>Read Less</ReadButton>
				</>
			)}
		</div>


	)
}


export const LiveDescription = () => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggleShow = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div>
			<p>Please carefully read the following terms and conditions regarding our 24/7 Livestream Shopping Experience. These terms outline the guidelines and procedures for participating in our livestream shopping events
				{!isOpen && (
					<ReadButton padding={true} onClick={handleToggleShow}>Read More</ReadButton>
				)}
			</p>
			{isOpen && (
				<>
					<p>
						<ul>
							<li>
								1. Livestream Shopping Experience:
								<ul>
									<li>a. Our 24/7 Livestream Shopping Experience provides a virtual shopping event where you can interact with our hosts and make purchases in real-time.</li>
									<li>b. During the livestream, our hosts will showcase and demonstrate various products available for purchase, provide detailed information, and answer your questions.</li>
								</ul>
							</li>
							<li>
								2. Product Information:
								<ul>
									<li>a. The information provided during the livestream, including product features, specifications, pricing, and availability, is as accurate as possible.</li>
									<li>b. However, Qlason cannot guarantee the accuracy or completeness of the information provided, and reserves the right to correct any errors or update product details as necessary.</li>
								</ul>
							</li>
							<li>
								3. Ordering Process:
								<ul>
									<li>a. To make a purchase during the livestream shopping event, follow the instructions provided by our hosts, such as sharing your desired product, size, colour, or quantity.</li>
									<li>b. Our hosts will guide you through the ordering process, including providing payment options and confirming your purchase details.</li>
									<li>c. By placing an order during the livestream shopping event, you firmly acknowledge your intention to purchase the selected product(s) and agree to pay the specified amount.</li>
								</ul>
							</li>
							<li>
								4. Pricing and Payment:
								<ul>
									<li>a. The prices displayed during the livestream shopping event are in the currency specified by Qlason and are exclusive of any applicable taxes, shipping fees, or other charges.</li>
									<li>b. Payment options will be provided during the ordering process, and you are exclusively responsible for ensuring the accuracy and security of your payment details.</li>
								</ul>
							</li>
							<li>
								5. Order Confirmation:
								<ul>
									<li>a. After placing your order during the livestream shopping event, you will receive an order confirmation via email or other communication channels specified by Qlason.</li>
									<li>b. The order confirmation will include details of your purchase, such as product(s), quantities, pricing, and shipping information.</li>
									<li>c. Please review the order confirmation carefully and promptly contact our customer support team if you notice any discrepancies or have any questions.</li>
								</ul>
							</li>
							<li>
								6. Shipping and Delivery:
								<ul>
									<li>a. The shipping and delivery of your purchased product(s) will be processed according to our standard shipping policies and timelines, as outlined on our e-commerce website.</li>
									<li>b. Any specified delivery times provided during the livestream shopping event are estimates and may be subject to change.</li>
								</ul>
							</li>
							<li>
								7. Returns and Refunds:
								<ul>
									<li>a. Our standard return and refund policies apply to products purchased during the livestream shopping event.</li>
									<li>b. Please refer to our Return Policy for detailed information on eligibility, procedures, and any associated fees or limitations.</li>
								</ul>
							</li>
							<li>
								8. Availability and Modifications:
								<ul>
									<li>a. The livestream shopping events are subject to availability and may be modified, rescheduled, or terminated at any time without prior notice to you.</li>
									<li>b. Qlason at its own exclusive election reserves the right to limit quantities, discontinue products, or make changes to pricing or promotions during the livestream shopping event.</li>
								</ul>
							</li>
							<li>
								9. Dispute Resolution:
								<ul>
									<li>a. In case of any dispute or discrepancies, Qlason reserves the right to make the final decision.</li>
								</ul>
							</li>
						</ul>
					</p>

					<p>By participating in our 24/7 Livestream Shopping Experience, you acknowledge and agree to the terms and conditions outlined above.</p>

					<p>Qlason reserves the exclusive right at its own election to update its terms and conditions from time to time; and at any time material; its most current terms and conditions will prevail.</p>

					<p>If you have any questions or require further assistance regarding the livestream shopping experience, please contact our customer support team. We are here to help and provide you with the best possible service.</p>

					<p>Thank you for choosing Qlason. We appreciate your trust and look forward to enhancing your shopping experience.</p>

					<p>Sincerely,</p>

					<p>[Absolute Robotics]</p>

					<ReadButton padding={false} onClick={handleToggleShow}>Read Less</ReadButton>
				</>
			)}
		</div>
	)
}


export const ExchangeDescription = () => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggleShow = () => {
		setIsOpen(!isOpen);
	};
	return (
		<div>
			<p>At Qlason, we want to ensure your satisfaction with your apparel and shoe purchases. We understand that sometimes the fit or style may not be exactly as expected. To provide you with a seamless shopping experience, we offer a 30 Days Free Exchange policy for eligible apparel and shoe products. Please read the following terms and conditions carefully to understand the guidelines and procedures for exchanges
				{!isOpen && (
					<ReadButton padding={true} onClick={handleToggleShow}>Read More</ReadButton>
				)}
			</p>
			{isOpen && (
				<>
					<p>
						<ul>
							<li>
								1. Eligibility:
								<ul>
									<li>a. The 30 Days Free Exchange policy is applicable to apparel and shoe products purchased directly from Qlason&apos;s e-commerce website.</li>
									<li>b. This policy covers eligible products that are in new, unworn condition, with all original tags, packaging, and accessories as bought or supplied.</li>
								</ul>
							</li>
							<li>
								2. Exchange Time Frame
								<ul>
									<li>a. You may initiate an exchange within 30 days from the date of purchase.</li>
									<li>b. The exchanged product must be shipped and postmarked within this specified timeframe to be eligible for the exchange.</li>
								</ul>
							</li>
							<li>
								3. Exchange Procedure:
								<ul>
									<li>a. To initiate an exchange, please contact our customer support team via email or phone.</li>
									<li>b. Provide the necessary details, including your order number, product information, and the reason for the exchange.</li>
									<li>c. Our customer support team will guide you through the exchange process, provide an exchange authorization, and any additional instructions.</li>
								</ul>
							</li>
							<li>
								4. Exchange Shipping:
								<ul>
									<li>a. For eligible exchanges, we will provide a prepaid shipping label or arrange a pickup service for the return of the product.</li>
									<li>b. Please ensure that the product is securely packaged to avoid damage during transit.</li>
									<li>c. You are responsible for returning the product within the specified timeframe and following the provided instructions.</li>
								</ul>
							</li>
							<li>
								5. Inspection and Exchange:
								<ul>
									<li>a. Once the returned product is received, it will undergo a thorough inspection to ensure it meets the eligibility criteria.</li>
									<li>b. If the product passes the inspection, we will initiate the exchange process.</li>
									<li>c. The replacement product will be shipped to you within a reasonable timeframe.</li>
								</ul>
							</li>
							<li>
								6. Exchange Options:
								<ul>
									<li>a. Depending on product availability, you will have the option to exchange the product for a different size, colour, or style within the same price range.</li>
									<li>b. If the desired product is not available for exchange, we will work with you to find a suitable alternative.</li>
								</ul>
							</li>
							<li>
								7. Limitations:
								<ul>
									<li>a. The 30 Days Free Exchange policy is subject to certain limitations and may be modified or terminated at any time without prior notice.</li>
									<li>b. The policy does not cover normal wear and tear, damage caused by misuse or mishandling, or issues resulting from unauthorised alterations or modifications.</li>
								</ul>
							</li>
							<li>
								8. Dispute Resolution:
								<ul>
									<li>a. In case of any dispute or discrepancies, Qlason reserves the right to make the final decision.</li>
								</ul>
							</li>
						</ul>
					</p>

					<p>By making a purchase on our e-commerce platform, you acknowledge and agree to the terms and conditions outlined in this 30 Days Free Exchange policy.</p>

					<p>If you have any questions or require further assistance regarding exchanges, please contact our customer support team. We are here to help and ensure your complete satisfaction.</p>

					<p>Thank you for choosing Qlason. We appreciate your business and look forward to serving you again.</p>

					<p>Sincerely,</p>

					<p>[Absolute Robotics]</p>

					<ReadButton padding={false} onClick={handleToggleShow}>Read Less</ReadButton>
				</>
			)}
		</div>
	)
}


export const PharmacyDescription = () => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggleShow = () => {
		setIsOpen(!isOpen);
	};
	return (
		<div>
			<p>Please carefully read the following terms and conditions regarding our 24/7 Livestream Pharmacy Consulting Services on our e-commerce platform. These terms outline the guidelines and procedures for utilising our livestream consulting services:
				{!isOpen && (
					<ReadButton padding={true} onClick={handleToggleShow}>Read More</ReadButton>
				)}
			</p>
			{isOpen && (
				<>
					<p>
						<ul>
							<li>
								1. Service Description:
								<ul>
									<li>a. Our 24/7 Livestream Pharmacy Consulting Services provide real-time video consultation with qualified pharmacists to address medication-related questions and concerns.</li>
									<li>b. The livestream consulting services are intended to provide information, advice, and recommendations based on the information provided by you.</li>
									<li>c. Please note that our consulting services are not a substitute for professional medical advice, diagnosis, or treatment. Always consult with your primary healthcare provider or a licensed pharmacist for specific medical concerns or issues.</li>
								</ul>
							</li>
							<li>
								2. Accessing Livestream:
								<ul>
									<li>a. Livestream consultations will be conducted through a designated platform or application specified by Qlason.</li>
									<li>b. You may be required to create an account, provide relevant information, and agree to the terms and conditions of the livestream platform.</li>
								</ul>
							</li>
							<li>
								3. Appointment Booking:
								<ul>
									<li>a. To access the livestream consulting services, you may be required to book an appointment in advance.</li>
									<li>b. Appointment availability is subject to the scheduling system and availability of our qualified pharmacists.</li>
								</ul>
							</li>
							<li>
								4. Professional Advice:
								<ul>
									<li>a. Our pharmacists will provide consultation and advice based on the information provided by you during the livestream session.</li>
									<li>b. It is important to provide accurate and complete information to ensure appropriate recommendations.</li>
									<li>c. The advice provided by our pharmacists is based on their professional knowledge and experience. However, it shall not replace professional medical advice, diagnosis, or treatment.</li>
								</ul>
							</li>
							<li>
								5. Limitations:
								<ul>
									<li> a. The livestream consulting services may not cover all medical conditions, medications, or individual circumstances. Some queries may require further evaluation or consultation with a healthcare professional.</li>
								</ul>
							</li>
							<li>
								6. Exchange Options:
								<ul>
									<li>a. Depending on product availability, you will have the option to exchange the product for a different size, colour, or style within the same price range.</li>
									<li>b. If the desired product is not available for exchange, we will work with you to find a suitable alternative.</li>
								</ul>
							</li>
							<li>
								7. Limitations:
								<ul>
									<li>a. The 30 Days Free Exchange policy is subject to certain limitations and may be modified or terminated at any time without prior notice.</li>
									<li>b. The policy does not cover normal wear and tear, damage caused by misuse or mishandling, or issues resulting from unauthorised alterations or modifications.</li>
								</ul>
							</li>
							<li>
								8. Dispute Resolution:
								<ul>
									<li>a. In case of any dispute or discrepancies, Qlason reserves the right to make the final decision.</li>
								</ul>
							</li>
						</ul>
					</p>

					<p>By making a purchase on our e-commerce platform, you acknowledge and agree to the terms and conditions outlined in this 30 Days Free Exchange policy.</p>

					<p>If you have any questions or require further assistance regarding exchanges, please contact our customer support team. We are here to help and ensure your complete satisfaction.</p>

					<p>Thank you for choosing Qlason. We appreciate your business and look forward to serving you again.</p>

					<p>Sincerely,</p>

					<p>[Absolute Robotics]</p>

					<ReadButton padding={false} onClick={handleToggleShow}>Read Less</ReadButton>
				</>
			)}
		</div>
	)
}




const ReadButton = styled.span<{ padding: boolean }>`
					padding-left: ${props => props.padding ? "10px" : "0px"};
					color: var(--eke-color-primary) !important;
					cursor: pointer;
					margin-top: 0;
					`;