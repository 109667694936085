import styled from "styled-components";


export const SearchContainerStyle = styled.div<{isOpen:boolean}>`
	 display: flex;
	flex-wrap: wrap;
	width: 95% !important;
	align-self: center;
	z-index: ${props => props.isOpen ? 9999 : 1};
	justify-content: center;
	//background-color: #F0F1F2;
	border: 1px solid #eee;
    border-radius: 30px;
    //height: 48px;
    /* padding: 2px; */

	.SearchAutocomplete___Wrapper {
		width:auto;
		position: relative;
		padding: 0rem;
		border-radius: 0px;
}

`;

export const SearchInputWrapperStyle = styled.div`
      position: relative;
      flex: 1;
      padding: 0 !important;
      div {
        transform: none !important;
      }
      .SearchBar__FieldDiv {
        position: relative;

		span{
			padding: 9px 10px 10px 10px !important;
		}

		.SearchBar__FieldWrap   {
			display: flex;
			height: 44px;
			padding: 12px 10px !important;
			padding-left: 35px !important;
			padding-right: 35px !important;
			align-items: center;
			flex-shrink: 0;
			border-radius: 0px 30px 30px 0px !important;
            background: var(--eke-color-white);
			font-size: 14px;
			font-family: var(--eke-body-font-family);
			font-weight: var(--eke-font-weight-semibold);
			line-height: 140%;
			letter-spacing: 0.2px;
			//border: none !important;
			box-shadow: none !important;
	border-left: 1px solid #eee;


			&::placeholder{
				color: #9A9EA6;
			}
        }
	}

	.SearchBar___Right{
		position: absolute;
		right: -5px;
		top: 2%;
	}
`;

export const AutoCompleteStyle = styled.div`
	    position: absolute;
		padding-bottom: 0px;
		min-height: 0px;
		margin: 0 auto;
		z-index: 99999;
		top: 115%;
		// top: -5% !important;
		// left: 24%;
		width: calc(100% + 1px);
		background: var(--eke-bg-light-color);
		border: 1px solid rgba(51, 51, 51, 0.1);
		box-shadow: 0 8px 32px rgb(0 0 0 / 8%);
		border-radius: 0px 30px 30px 30px;
		overflow: hidden;


		.SearchAutocomplete_Style{


			.SearchAutocomplete__fieldContainer__Style{
				position: relative;
				width: 100%;
				height: auto;

				.SearchAutocomplete__List_Style {
					margin-bottom: 0.8rem;
					//padding: 5px 9px
					padding-left: 1.5px !important;
					padding-bottom: 20px;
					list-style: none;
					height: auto;
					overflow: hidden;

					.SearchAutocomplete__List__val_Style{
						width: 100%;
            			height: auto;

						.SearchAutocomplete__List__href__Style {
							position: relative;
							display: flex;
							align-items: center;
							padding: 4px 8px;
							padding-top: 0px;
							white-space: nowrap;
							font-weight: 400;
							font-size: 13.5px;

							line-height: 20px;
							//color: var(--c-black);
							cursor: pointer;
							text-decoration: none;

						.SearchAutocomplete__List__icon_Style {
							margin-right: 8px;
							background-repeat: no-repeat;
							flex-shrink: 0;

							svg {
							width: 1rem;
							height: 1rem;
							fill: rgba(0, 0, 0, 0.26);
							}
						}

						.SearchAutocomplete__List__value {
								color: #333;
								overflow: hidden;
								font-family: var(--eke-body-font-family);
								text-overflow: ellipsis;
								flex: 1;
						}

							&:hover {
								background-color: #eee;
								border-radius: 4px;
								transition: all 0.3s ease-in-out;
							}
						

					}

			   } 

			   
			   .SearchAutoComplete_Margin_Style {
					margin-top: 9px;
				}


				.SearchAutocomplete_Group__Style{
					width: 100%;
					background-color: #eee;
					padding-left: 10px;
					padding-top: 5px;
					padding-bottom: 5px;
					margin-bottom: 9px;

					h5 {
					color: var(--eke-color-dark);
					text-transform: uppercase;
					font-weight: var(--eke-font-weight-semibold);
					font-family: var(--eke-body-font-family);
					font-size: 14px;
					margin: 0 !important;
					} 
				}

				.SearchAutocomplete_Content_li_Style {
            .SearchAutocomplete_Content_Sublist__Style {
              list-style: none;
              width: 100%;
              min-height: 0px;
              overflow-x: hidden;
              overflow-y: auto;
              padding-left: 0 !important;

              .SearchAutocomplete__List__item_Style {
                width: 100%;
                height: auto;
                padding-left: 0px !important;

                .SearchAutocomplete_Padding {
                  padding: 10px 8px !important;
                }
                .SearchAutocomplete__item_href {
                  position: relative;
                  display: flex;
                  width: 100%;
                  align-items: center;
                  padding: 6px 8px;
                  padding-left: 1rem !important;
                  cursor: pointer;
                  text-decoration: none;
                  border-bottom: 1px solid var(--eke-border-white-dark-color);

                  img {
                    width: 40px;
                    height: 35px;
                    margin-right: 0.5rem;
                    background-color: var(--eke-bg-white-dark-color);
                  }

                  &__Span {
                    width: 85%;
                    font-size: 12.8px;
                    display: inline-block;
                    text-transform: capitalize !important;
                    color: var(--eke-color-dark) !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    margin-right: 11px;
                  }

                  &__Svg {
                    flex-shrink: 0;
                    position: absolute;
                    right: 17px;
                    color: var(--eke-body-text-gray-dark-color) !important;
                  }

                  &:hover {
                    background-color: #eee;
                    transition: all 0.3s ease-in-out;
                  }
                }
              }
            }
          }

          .SearchAutocomplete__Loading__Style {
            display: flex;
            margin-top: 0.8rem;
            min-height: 100px;
            align-items: center;
            justify-content: center;
          }
					
				}
			}
		}

		.SearchAutocomplete_noResult__Style {
    text-align: center;
  }
`;