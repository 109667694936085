import React from 'react'
import Lottie from 'react-lottie';

interface CustomSpinnerProps{
    animationData: any;
    size: number;
}

export default function CustomLottieLoader({animationData,size}:CustomSpinnerProps) {
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    
  return (
    <Lottie options={defaultOptions}
            style={{margin: 0}}
            height={size}
            width={size}
    />
  )
}
