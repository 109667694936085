/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  LazyLoadComponent,
} from "react-lazy-load-image-component";

import ProfileSideBar from "../component/AccountSideBar/AccountSideBar";
import { useFetchUserSavedItemsQuery } from "@ekenta/redux/store/services/accountApi";
import { useAppSelector } from "@ekenta/redux/store/hook";
import ProductCardComponent from "../../../../components/Product/ProductCardComponent";
import BreadCrumb from "../component/BreadCrumb";
// import EmptyOrder from "../../../../components/Box/EmptyOrder";
import { useFetchCartItemsQuery } from "@ekenta/redux/store/services/cartApi";
import { mapCartDataReturnQuantity } from "../../../../utils";
import { withDefaultLayout } from "@ekenta/hoc";
import { CustomAlert, EmptyRequest, HelmetHeader, Loader, Overlay, PageTitle } from "@ekenta/components";
import BackButton from "../component/BackButton";

import "./SavedItem.scss";

const SavedItem = () => {
  const { token } = useAppSelector(state => state.auth.authentication);

  const getSavedItem = useFetchUserSavedItemsQuery(token)
  const { data: allCartData } = useFetchCartItemsQuery(token);


  return (
    <Fragment>
      <HelmetHeader
        title="Saved Items"
        description="QlasonSaved Items Page"
      />
      <Container className="account-user-container">
        <><BreadCrumb activePage="Saved Item" /></>

        <Row className="account-pro-row g-2">
          <ProfileSideBar isLoading={null} />
          <Col className="account-profile-col-right">
            <div className="mb-0">
              <PageTitle
                title="Saved Items"
                margin={false}
                subTitle={<BackButton />}
              />
            </div>
            {getSavedItem?.isError ? (
              <CustomAlert
                variant="danger"
                show={true}
                message={"Something went wrong, Try reloading"}
              />
            ) : null}

            <div className="__main_collection_product_card_">

              <Row className="product_vid_commponent p-0 m-0">
                {getSavedItem?.isSuccess ?
                  <>
                    {getSavedItem?.data.favoriteItems.length > 0
                      ? getSavedItem?.data.favoriteItems.map((item: any) => {
                        return (
                          <LazyLoadComponent key={item.id}>
                            {/* {index > 0 ?
                              <div aria-hidden="true" className="Hr_horizontal" />
                              : null} */}

                            <ProductCardComponent
                              productId={item.product.id}
                              product={item.product}
                              layoutView="list"
                              cartItem={mapCartDataReturnQuantity(allCartData, item.product.id)}
                              productname={item.product.name}
                              defaultprice={item.product.price}
                              discount={item.product.discount}
                              adminDiscount={item.product.admin_discount}
                              discountPercentage={item.product.discount_percentage}
                              maxPrice={item.product.max_price}
                              minPrice={item.product.min_price}
                              isFavorite={true}
                              isShadow={true}

                              thumbnailUrl={
                                item.product.images ? item.product.images[0].image_url : ""
                              }
                              imageUrl={item.product.images ? item.product.images[1].image_url : ""}
                              videoUrl={item.product.video_url}
                              condition={item.product.condition}
                            //savedItems={isSuccess ? data.products : []}
                            />
                          </LazyLoadComponent>
                        )
                      }) : <EmptyRequest
                        title="No new saved products"
                      />}
                  </>
                  : null}

              </Row>
            </div>
            {getSavedItem?.isLoading ? (
              <Overlay bgColor="dark">
                <Loader
                  isCenter
                  size="large"
                  color="white"
                />
              </Overlay>
            ) : null}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default withDefaultLayout(SavedItem);