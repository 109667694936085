import React from 'react'
import styled, { keyframes } from 'styled-components'

const pingAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.75;
  }
  50% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0.75;
  }
`

const NotificationContainer = styled.span`
  position: relative;
  display: flex;
  height: 12px;
  width: 12px;
`

const PingCircleStyled = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: var(--eke-color-primary); /* You can change this color */
  animation: ${pingAnimation} 1s infinite; /* Adjust the duration as needed */
`

const PingCircle = () => {
  return (
    <NotificationContainer>
      <PingCircleStyled />
    </NotificationContainer>
  )
}

export default PingCircle
