import * as Yup from 'yup';

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const specialRegex = /(?=.*[!@#$%^&*])/;
const numericRegex = /(?=.*[0-9])/;
const spaceRegex = /^(.*)?\S+(.*)?$/;

const changePassFormValidation = () => Yup.object().shape({

    currentPassword: Yup.string()
               .required('Password field is required'),
    newPassword: Yup.string()
                .matches(lowercaseRegex, 'One lowercase required')
                .matches(uppercaseRegex, 'One upper required')
                .matches(specialRegex, 'One special character')
                .matches(numericRegex, 'One number required')
                .matches(spaceRegex, 'Space not required')
                .min(6, 'New password is short - should be 6 chars minimum')
               .required('New password field is required'),
    confirmPassword: Yup.string()
               .required('Confirm new password field is required')
               .oneOf([Yup.ref('newPassword'),null], 'Passwords must match')
})


export default changePassFormValidation;