import { api } from '@ekenta/app/api/apiSlice';

export const chatApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllAdminRooms: builder.query({
      query: () => ({
        url: `/api/admin/get-all-rooms`,
        method: 'GET',
      }),
      providesTags: ['Chat']
    }),
    getAllRooms: builder.query({
      query: () => ({
        url: `/api/get-all-rooms`,
        method: 'GET',
      }),
      providesTags: ['Chat']
    }),
    getMessages: builder.query({
      query: (conversationId) => ({
        url: `/api/get-messages/${conversationId}`,
        method: 'GET',
      }),
    }),
    sendMessage: builder.mutation<void, { conversationId: string | null | undefined, data: { message: string, type: string } }>({
      query: ({ conversationId, data }) => ({
        url: `/api/send-message/${conversationId}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Chat']
    }),
    getUnreadCount: builder.query({
      query: () => ({
        url: `api/get-all-unread-messages-count`,
        method: 'GET',
      }),
      providesTags: ['Chat']
    }),
  }),
});



export const {
  useGetAllAdminRoomsQuery,
  useGetAllRoomsQuery,
  useGetUnreadCountQuery,
  useGetMessagesQuery,
  useSendMessageMutation,
} = chatApiSlice;
