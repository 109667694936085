import React, { FC } from 'react'
import styled from 'styled-components'
import { ProductCard } from '@ekenta/components'
import { ProductType } from '@ekenta/redux/store/features/product/productDataType'
interface PropsType {
  relatedProduct: ProductType[]
}

const RelatedProduct: FC<PropsType> = (props) => {
  const { relatedProduct } = props
  return (
    <Container>
      <div className="top-header">
        <h5>Related Products</h5>
      </div>
      <Stack>
        <ContentStyle className="product_vid_commponent">
          <Row>
            {relatedProduct?.length > 0
              ? relatedProduct?.map((item) => {
                  return (
                    <ProductCard
                      key={item.id}
                      productId={item.id}
                      isVariant={item.is_variant}
                      productVariation={item?.productVariation}
                      stockCount={item.stock}
                      aggregateRating={item.rating}
                      isShadow={true}
                      productName={item.name}
                      price={item.price}
                      discount={Number(item.discount)}
                      adminDiscount={Number(item.admin_discount)}
                      maxPrice={item.max_price}
                      imageUrl={item?.images[0]?.image_url ?? ''}
                      videoUrl={item.video_url || ''}
                      condition={item.condition}
                    />
                  )
                })
              : null}
          </Row>
        </ContentStyle>
      </Stack>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 22px 19.5px 21px;
  border-radius: 12px;
  background: #fff;
  font-family: var(--eke-body-font-family);
  gap: 20px;
  //box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.12);

  .top-header {
    width: 100%;
    height: 57px;
    border-radius: 12px;
    padding: 15px 16px 12px 16px;
    align-items: center;
    background: #f3faff;

    h5 {
      color: var(--secondary-500, #1d4057);
      font-size: 22px;
      font-weight: 700;
      margin: 0;
      padding: 0;
    }
  }
`

const Stack = styled.div`
  width: 100%;
  display: block;
  font-family: var(--eke-body-font-family);
  padding-left: 13px;
  gap: 14px;
`
const ContentStyle = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  white-space: wrap;
  overflow: hidden;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  margin-top: 0 !important;
  animation: slide 20s linear infinite;

  .product__card__col {
    display: block;
    padding: 0 !important;
    border-radius: 10px !important;
    width: 214px !important;
  }

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

const Row = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-wrap: nowrap;
  white-space: wrap;
  gap: 1em !important;
  padding-left: 5px;
`
export default RelatedProduct
