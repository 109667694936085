import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row, Form } from 'react-bootstrap';
import withDashboardLayout from '../../../../hoc/Layout/withDashboardLayout';
import { useAppSelector } from '../../../../redux/store/hook';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { AdminDashboardSubCollectionPathUrl } from '../../../../routes/constant';
import sent from '@ekenta/assets/images/sent.gif';
import {
  useAddNewSubCollectionMutation,
  useFetchAdminAllSubCollectionCategoryQuery,
  useFetchAllSubCollectionCategoriesQuery,
} from '../../../../redux/store/services/collectionApi';
import toast from 'react-hot-toast';
import axios from 'axios';
import { BASE_URL } from '../../../../constants';
import { HelmetHeader } from '@ekenta/components';

const AddSubCollection = () => {
  const [lgShow, setLgShow] = useState(false);
  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const result = useFetchAdminAllSubCollectionCategoryQuery(token);
  const [
    addCollectionData,
    {
      data: addCollection_data,
      isLoading: addCollection_isLoading,
      isError: addCollection_isError,
      error: addCollection_error,
      isSuccess: addCollection_isSuccess,
    },
  ] = useAddNewSubCollectionMutation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [details, setDetails] = useState({
    name: '',
    description: '',
    visibility: 'false',
    thumbnailUrl: '',
    file: '',
    sub_collection_category_id: '',
  });
  // const formData = new FormData()
  const handleImage = (e: any) => {
    // console.log(e.target.files[0])
    setDetails({ ...details, file: e.target.files[0] });

    // console.log(formData)
  };

  const handleSubmit = async () => {
    setLoading(true);
    console.log(details);
    // const data = {
    //     name: details?.name,
    //     description: details?.description,
    //     visibility: details?.visibility == 'true' ? true : false,
    //     subCollectionCategoryId: parseInt(details?.collectionId)
    // }
    const data = new FormData();
    data.append('name', details?.name);
    data.append('description', details?.description);
    data.append('visibility', details?.visibility);
    data.append(
      'sub_collection_category_id',
      details?.sub_collection_category_id,
    );
    data.append('image', details?.file);

    console.log(data);

    const config = {
      method: 'POST',
      url: `${BASE_URL}/api/admin/create-sub-collection`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        handleShow();
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });

    // addCollectionData({ data, token })
  };

  const uploadThumbnail = async () => {
    await axios
      .post(
        `${BASE_URL}/api/sub-collection-category/thumbnail/${addCollection_data?.id}`,
        {
          fileType: details.file,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((resp: any) => {
        setLoading(false);
      })
      .catch(function (error: any) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (addCollection_isSuccess) {
      uploadThumbnail();
      handleShow();
    }

    if (addCollection_isError) {
      setLoading(false);
      toast.error(<div className="cus_t">An error occured !</div>);
      // toastDisplay(true);
    }

    // navigate(adminManageBanner)
  }, [addCollection_isSuccess, addCollection_isError]);

  return (
    <>
      <HelmetHeader
        title="Add Subcollection Dashboard - Qlason.com"
        description="QlasonAdd Subcollection Dashboard Page"
      />

      <Col className=" col-lg-11 mx-auto _listing_product_">
        <div className="mt-0">
          <h3 className="display_lg_header">
            {/* <FiChevronLeft /> */}
            Add Sub Collection
          </h3>
          <Col className="mt-3">
            <Button
              className="mb-3 text-white"
              onClick={() => navigate(AdminDashboardSubCollectionPathUrl)}
            >
              <FaArrowLeft /> Back
            </Button>
            <Card>
              <Card.Body>
                <Form.Label htmlFor="inputPassword5">
                  Sub Collection Name *{' '}
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setDetails({ ...details, name: e.target.value })
                  }
                />
                <br />
                <Form.Label htmlFor="inputPassword5">Description * </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setDetails({ ...details, description: e.target.value })
                  }
                />
                <br />
                <Form.Label htmlFor="inputPassword5">
                  Thumbnail Image *
                </Form.Label>
                <Form.Control type="file" onChange={(e) => handleImage(e)} />
                <br />
                <Form.Label htmlFor="inputPassword5">Visibility</Form.Label>
                <Form.Select
                  onChange={(e) =>
                    setDetails({ ...details, visibility: e.target.value })
                  }
                >
                  <option>Select Visibility</option>
                  <option value={'true'}>True</option>
                  <option value={'false'}>False</option>
                </Form.Select>
                <br />
                <br />
                <Form.Label htmlFor="inputPassword5">
                  Sub Collection Category *
                </Form.Label>
                <Form.Select
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      sub_collection_category_id: e.target.value,
                    })
                  }
                >
                  <option>Select Sub Collection category</option>
                  {!result.isSuccess ? (
                    <option>Loading...</option>
                  ) : (
                    result?.data?.collections?.map((e: any, i: any) => (
                      <option key={i} value={e?.id}>
                        {e?.name}
                      </option>
                    ))
                  )}
                </Form.Select>
                <br />
                <Button
                  variant="danger"
                  disabled={
                    loading ||
                    details?.sub_collection_category_id == '' ||
                    details?.name == '' ||
                    details?.description == ''
                  }
                  onClick={handleSubmit}
                >
                  {loading ? 'Loading...' : 'Submit'}
                </Button>{' '}
                <Button variant="outline-danger">Cancel</Button>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </Col>

      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row>
            <img src={sent} alt="" height={300} width={'100%'} />
            <Button
              variant="success"
              className="text-white text-center mx-auto"
              onClick={() =>
                (window.location.href = AdminDashboardSubCollectionPathUrl)
              }
            >
              Done
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withDashboardLayout(AddSubCollection);
