import React from 'react'
import { Card, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import DashboardComponent from '../component/DashboardComponent';
import DashboardLayout from '../../../hoc/Layout/Dashboard-Layout';

export default function WareHouse({ collapsed, toggled, handleToggleSidebar, handleCollapsedChange }: any) {
  return (
    <DashboardLayout>
      <Helmet>
        <title>Seller Dashboard - Qlason</title>
        <meta charSet="utf-8" />
        <meta name="title" content="QlasonWareHouse Page" />
        <meta name="description"
          content="QlasonWareHouse  Page"
        />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <Col className=" col-lg-11 mx-auto _listing_product_">
          <div className="mt-0">
            <h3 className="display_lg_header">WareHouse</h3>
            <Col className="mt-3">
              <Card>
                <Card.Body>
                  <div>WareHouse</div>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Col>
      </DashboardComponent>
    </DashboardLayout>

  )
}
