/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import SVG from "react-inlinesvg";
import styled from 'styled-components';
import { OnboardingAsideStyle } from '../../style'
import ProfileSVG from "@ekenta/assets/icons/profile-icon.svg";
import BusinessSVG from "@ekenta/assets/icons/business-icon.svg";
import BankSVG from "@ekenta/assets/icons/bank-icon.svg";
import SummarySVG from "@ekenta/assets/icons/summary-iccon.svg";
import { Checkbox } from '@ekenta/components';

interface AsideStepperType {
    page: number;
    setPage: any;
}


const AsideStepper = ({ page, setPage }: AsideStepperType) => {

    const handleStepClick = (step: number) => {
        if (step > page) {
            return; // Do not update the active step if the clicked step is greater
        }
        setPage(step);
    };

    return (
        <OnboardingAsideStyle>
            <List>
                <ListItem active={page === 0} disabled={0 > page}>
                    <label title="Personal account details" htmlFor="personal">
                        <span className="Image_IconStyle">
                            <SVG src={ProfileSVG} />
                        </span>
                        <div className="contentStyle_group">
                            <h5>Personal account details</h5>
                            <p>Enter your personal account details</p>
                        </div>
                        <Checkbox
                            id="personal"
                            name="personal"
                            value={page >= 0}
                            className=""
                            readOnly
                        />
                    </label>
                </ListItem>
                <ListItem active={page === 1} disabled={1 > page}>
                    <label title="Business Details" htmlFor="business"
                        onClick={() => handleStepClick(1)}>
                        <span className="Image_IconStyle">
                            <SVG src={BusinessSVG} />
                        </span>
                        <div className="contentStyle_group">
                            <h5>Business Details</h5>
                            <p>Enter your business details</p>
                        </div>
                        <Checkbox
                            id="business"
                            name="business"
                            value={page >= 1}
                            className=""
                            readOnly
                        />
                    </label>
                </ListItem>
                <ListItem active={page === 2} disabled={2 > page}
                    onClick={() => handleStepClick(2)}>
                    <label title="Bank Details" htmlFor="bank">
                        <span className="Image_IconStyle">
                            <SVG src={BankSVG} />
                        </span>
                        <div className="contentStyle_group">
                            <h5>Bank Details</h5>
                            <p>Enter your bank details</p>
                        </div>
                        <Checkbox
                            id="bank"
                            name="bank"
                            value={page >= 2}
                            className=""
                            readOnly
                        />
                    </label>
                </ListItem>
                <ListItem active={page === 3} disabled={3 > page}
                    onClick={() => handleStepClick(3)}>
                    <label title="Summary" htmlFor="summary">
                        <span className="Image_IconStyle">
                            <SVG src={SummarySVG} />
                        </span>
                        <div className="contentStyle_group">
                            <h5>Summary</h5>
                            <p>View summary of your details</p>
                        </div>
                        <Checkbox
                            id="summary"
                            name="summary"
                            value={page >= 3}
                            className=""
                            readOnly
                        />
                    </label>
                </ListItem>
            </List>
        </OnboardingAsideStyle>
    )
}

const List = styled.ul`
    display: flex;
    width: 90%;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.1em;
    list-style: none;
    padding: 0 !important;
    margin: 0  auto !important;
`;

const ListItem = styled.li<{ active: boolean; disabled: boolean }>`
    display: block;
    width: 100%;
    font-family: var(--eke-body-font-family);
    //padding: 0px 0px 0px 0px;

    label{
        position: relative;
        width: 100%;
        display: flex;
        padding: 15px 21px;
        align-items: center;
        justify-content: space-between;
        border-radius: 16px;
        gap: 0.4em;
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
        opacity: ${({ disabled }) => (disabled ? '0.65' : 1)};;
        ${({ active }) => active && 'background: #F5FBFF;'}
        pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
      

        .Checkbox__Container{
            right: 0;
            margin-left: 0.3em;
            width: auto !important;
        }
    }


    .Image_IconStyle{
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 50%;
        background: var(--transparent-blue, rgba(51, 94, 247, 0.08));
    }

    .contentStyle_group{
        flex: 1;
        display: flex;
        flex-direction: column;

        h5{
            color: var(--greyscale-900, #212121);
            font-weight: var(--eke-font-weight-semibold);
            font-size: 16px;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.2px;
            margin-bottom: 0.1em;
        }
        p{
            color: var(--greyscale-500, #9E9E9E);
            font-weight: var(--eke-font-weight-normal);
            font-size: 12px;
            line-height: normal;
            letter-spacing: 0.2px;
            margin: 0;
        }
    }
`;

export default AsideStepper