import React from 'react'
import styled from 'styled-components'
import { HelmetHeader } from '@ekenta/components'

const DeleteAccountProcess = () => {
  return (
    <>
      <HelmetHeader
        title="Delete Account Process - Eyeshopper"
        description="Eyeshopper delete process App"
      />
      <Container>
        <div className="div-div">
          <h1>How to Delete Your Eyeshopper Account</h1>
        </div>
        <br />
        <div className="section-para-2">
          <p>
            We understand that circumstances may lead you to consider removing
            the eyeshopper app from your device. Below are simple steps to guide
            you through the process of deleting the app:
          </p>
        </div>
        <div className="ul-process">
          <ul>
            <li>
              <h5>Step 1: Access Your Profile</h5>
              <p>
                Open the eyeshopper app on your device and navigate to your user
                profile. This is typically represented by your username or
                avatar in the app.
              </p>
            </li>
            <li>
              <h5>Step 2: Locate the &quot;Delete Account&quot; Button</h5>
              <p>
                Within your profile, look for the &quot;Delete Account&quot;
                button. Depending on the app&quot;s design, this button might be
                located in the account settings or profile management section.
                It is essential to explore areas related to account management.
              </p>
            </li>
            <li>
              <h5>Step 3: Confirm Action</h5>
              <p>
                Upon clicking the &quot;Delete Account&quot; button, the app
                will likely prompt you to confirm your action. This confirmation
                is a crucial step to ensure that the deletion is intentional and
                irreversible. Be attentive to any additional information or
                warnings provided by the app during this process.
              </p>
              <div style={{ margin: '15px 0' }}>
                <h5>Important Note:</h5>
              </div>
              <ul>
                <li>
                  After your account has been deleted, you can sign up again
                  with the same username and email as long as it hasn&quot;t
                  been taken by a new person.
                </li>
                <li>
                  If you have any concerns about data security or the deletion
                  process, consider reaching out to
                  support@dabsoluterobotics.com for assistance before
                  proceeding.
                </li>
              </ul>
            </li>
            <li style={{ marginBottom: '30px' }}>
              <p style={{ fontSize: '17px', margin: '0' }}>
                By following these steps, you can successfully delete the
                eyeshopper app from your device. If you have any further
                questions or require additional assistance, please feel free to
                contact our support team at support@dabsoluterobotics.com.
              </p>
            </li>
            <li>
              <p style={{ fontSize: '17px', margin: '0' }}>
                Thank you for using the eyeshopper app. We appreciate your
                feedback and hope to serve you again in the future.
              </p>
            </li>
          </ul>
        </div>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  width: 65%;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 45px;
  font-family: var(--eke-body-font-family) !important;

  h1 {
    color: #000;
    font-size: 24px;
    font-weight: 700;
  }

  .section-para-2 {
    margin: 25px 0;

    p {
      font-size: 23px;
    }
  }

  .ul-process {
    ul {
      display: flex;
      align-items: flex-start !important;
      flex-direction: column;
      list-style: none;
      gap: 20px;
      padding: 0 !important;

      li {
        h5 {
          font-size: 18px;
          font-weight: 800 !important;
          margin-bottom: 15px;
        }
        p {
        }
      }
    }
    .list-important {
      gap: 0px !important;
      align-items: flex-start !important;
      padding: 0 !important;

      li {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        font-size: 17px !important;
      }
    }
  }
`
export default DeleteAccountProcess
