import React from 'react'
import { VscArrowSmallRight } from 'react-icons/vsc';
import { getIn, useFormikContext } from 'formik';
import ProductImages from './common/ProductImages';
import AddProductDetails from './common/AddProductDetails/AddProductDetails';
import ProductFeatures from './common/ProductFeatures';
import { StepContainer, StepFooter } from '../style';
import { Button } from '@ekenta/components';
import { NewProductListingFormType } from '@ekenta/interfaces/ProductListing';

const ProductDetails = (props: { nextPage: () => void; isLoading: boolean }) => {
    const { nextPage, isLoading } = props;
    const { values, errors, } = useFormikContext<NewProductListingFormType>();


    return (
        <StepContainer>
            <ProductImages />
            <AddProductDetails />
            <ProductFeatures />
            <StepFooter>
                <Button
                    name="Proceed"
                    textVariant="light"
                    width="w-100"
                    bgVariant="primary"
                    className="PageStep__Btn"
                    disabled={
                        isLoading ||
                        !values.productname ||
                        !values.productshortDescription ||
                        getIn(errors, 'productimages') ||
                        getIn(errors, 'productname') ||
                        getIn(errors, 'productshortDescription') ||
                        getIn(errors, 'brandId') ||
                        getIn(errors, 'featureValues')
                    }
                    shadow={false}
                    suffixIcon={<VscArrowSmallRight />}
                    type="button"
                    onClick={nextPage}
                />

            </StepFooter>
        </StepContainer>
    )
}


export default ProductDetails