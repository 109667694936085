import React from 'react'
import { Col, Card } from 'react-bootstrap'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import { ReactComponent as Qlasonholder } from '@ekenta/assets/icons/qlason-placeholder.svg';



export const ProductLoader = ({ layoutView }: { layoutView: string }) => {
    return (
        <Col xs={6} sm={4} md={4} lg={`${layoutView === 'grid' ? '3' : '12'}`}
            className='product__card__col' style={{ height: "100%", marginLeft: "0.1em" }}>
            <CardStyle as={Card}>
                <Col className={`_card_image-wrapper p-0  bg-transparent position-relative ${layoutView === 'grid' ? 'col-lg-12' : 'col-md-4'}`}>
                    <TopHeader>
                        <Skeleton width={'100%'} height={200} style={{ background: "#F5FBFF !important" }} />
                        <Qlasonholder />
                    </TopHeader>
                </Col>
                <Col className={`${layoutView === 'grid' ? 'col-lg-12' : 'col-md-8'}`} >
                    <Card.Body >
                        <Card.Title className="w-100" >
                            <Skeleton width={'100%'} height={10} />
                            <Skeleton width={80} height={10} />
                        </Card.Title>
                        <div className="card-text">
                            {/* <div className="no_item_sold_ d-flex justify-content-between">
                <div className="_no_p_">
                  <span className="_text_">
                    <Skeleton width={75} height={9} />
                  </span>
                </div>
                <div className="_n_rating_row">
                  <Skeleton width={60} height={9} />
                </div>
              </div> */}
                            {/* <div className="wrap__t__group d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center mt-2 justify-content-between w-100">
                  <Skeleton width={60} height={12} />
                </div>
              </div> */}
                        </div>
                    </Card.Body>
                </Col>
            </CardStyle>
        </Col>
    )
}

const CardStyle = styled.div`
    .react-loading-skeleton{
    --base-color: #E3F3FF !important;
    --highlight-color: #F5FBFF !important;
  }
`;

const TopHeader = styled.div`
  position: relative;
  width: auto;
  height: auto;

  .react-loading-skeleton{
    --base-color: #E3F3FF !important;
    --highlight-color: #F5FBFF !important;
  }

  svg{
    position: absolute;
    width: 55%;
    top: 20%;
    left: 20%;
    display: flex;
    justify-self: center;
    align-self: center;
    margin: 0 auto;
    z-index: 99;
  }
`;