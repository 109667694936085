/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import PinInput from "react-pin-input";
import SVG from "react-inlinesvg";
import SuccessCheckSVG from "@ekenta/assets/icons/success-checkmark.svg";
import LoaderSVG from "@ekenta/assets/icons/loader-2.svg";
import CreatePassSVG from "@ekenta/assets/illustrators/createpassword.svg";
import { LoaderStyle, PassResetAsideStyle, PassResetMainContent } from './styles';
import { AuthContainerStyle, AuthFormGroupStyle, PinInputStyle, PopupStyle } from '../common/auth-style';
import Logo from '@ekenta/components/Logo';
import { useFormik } from 'formik';
import newPasswordFormValidation from '@ekenta/validations/schemas/account-newpassword.schema';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRestoreUserPasswordMutation } from '@ekenta/redux/store/services/authenticationApi';
import { Button, CustomModal, Field, HelmetHeader } from '@ekenta/components';
import { loginPathUrl } from '@ekenta/routes/constant';

const PIN_LENGTH = 6;


const PasswordReset = () => {
	const { state }: any = useLocation();
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);

	const emailCodeRef = useRef(null);

	const [
		restoreUserPassword,
		{ isSuccess, isError, error: errorPass, isLoading },
	] = useRestoreUserPasswordMutation();


	useEffect(() => {
		if (isError && errorPass.status === "FETCH_ERROR") {
			toast.error(`Something went wrong,Try again`, {
				toastId: "error-status-fp-id-toast-error",
			});
		}
		if (isError && errorPass.status === 400) {
			toast.error(`Something went wrong,Try again`, {
				toastId: "error-status-passR-400-id-toast-error",
			});
		}

		if (isError && errorPass.status === 401) {
			toast.error(`${errorPass.data.message}`, {
				toastId: "error-status-passR-401--id-toast-error",
			});
		}

		if (isSuccess) {
			setIsOpen(true);

			setTimeout(() => {
				handleNavigate();
			}, 1500);
		}


	}, [isError, errorPass, isSuccess]);



	const formik = useFormik({
		initialValues: {
			emailCode: "",
			password: "",
			confirmPassword: "",
		},
		validationSchema: newPasswordFormValidation(),
		onSubmit: (values) => {
			if (state.email) {
				const data = {
					email: state.email,
					code: values.emailCode,
					password: values.password,
					password_confirmation: values.confirmPassword,
				};
				restoreUserPassword(data);
			}
		},
	});


	const handleNavigate = () => {
		navigate(loginPathUrl, {
			state: {
				status: "success",
				statusMsg: "Password reset successfully",
			},
			replace: true,
		});
		setIsOpen(false);
	}


	const validatePin = (value: string) => {
		if (value.trim() === '') return false;
		if (value.length < PIN_LENGTH) return false;

		return true;
	};


	const handleChange = (value: string) => {
		formik.setFieldValue("emailCode", value);
	};


	return (
		<>
			<HelmetHeader
				title="Password Reset"
				description="Qlason Password Reset Page"
			/>

			<AuthContainerStyle>
				<PassResetAsideStyle>
					<div className="authAImage__Style">
						<SVG src={CreatePassSVG} />
					</div>
					<h1>Create New Password</h1>
					<p>
						Create your new password on  Qlason to continue
					</p>
				</PassResetAsideStyle>
				<PassResetMainContent>
					<div className="ContentChildGroup__Style">
						<Logo
							width='auto'
							showDesc={false}
						/>
						<div className="Pass_Text_Style">
							<h1>Create New Password</h1>
							<p>Put the OTP sent to your email & Create your new password on  Qlason to continue</p>
						</div>
						<div className="ContentForm__Style">
							<form
								noValidate
								onSubmit={formik.handleSubmit}
								autoComplete="off">
								<PinInputStyle>
									<PinInput
										ref={emailCodeRef}
										length={PIN_LENGTH}
										focus
										onChange={handleChange}
										type="numeric"
										inputMode="number"
										inputStyle={{
											borderColor: "white",
											padding: "0 !important",
										}}

										onComplete={() => null}
										autoSelect={true}
									/>
									{/* {formik.errors.emailCode ? (
										<ErrorStyle>
											{formik.errors.emailCode}
										</ErrorStyle>
									) : null} */}
								</PinInputStyle>
								<AuthFormGroupStyle>
									<Field
										id="password"
										name="password"
										label="New Password"
										disabled={false}
										type="password"
										required
										size="large"
										childOutsideLabel={false}
										placeholder="New password"
										fieldClassName=""
										autoComplete="off"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.password}
										isError={formik.touched.password && formik.errors.password ? true : false}
										error={formik.errors.password}
									/>
								</AuthFormGroupStyle>
								<AuthFormGroupStyle>
									<Field
										id="confirmPassword"
										name="confirmPassword"
										label="Confirm Password"
										disabled={false}
										type="password"
										required
										size="large"
										childOutsideLabel={false}
										placeholder="Confirm password*"
										fieldClassName=""
										autoComplete="off"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.confirmPassword}
										isError={formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false}
										error={formik.errors.confirmPassword}
									/>
								</AuthFormGroupStyle>
								<Button
									name="Continue"
									bgVariant="primary"
									className="Button-Call-to-action"
									isLoading={
										isLoading
									}
									disabled={
										isLoading
										|| !validatePin(formik.values.emailCode)
										|| !formik.values.password
										|| !formik.values.confirmPassword
									}
									type="submit"
									shadow={false}
								/>
							</form>
						</div>
					</div>

					<CustomModal
						show={isOpen}
						size="sm"
						centered={false}
						showFooter={false}
						isLoading={false}
						isDisabled={false}
						render={
							<PopupStyle>
								<div className="SuccessIcon__Style">
									<SVG src={SuccessCheckSVG} />
								</div>
								<h5>Congratulation!</h5>
								<p>
									Your account is ready to use.
									You will be redirected to the Login page in a few seconds..
								</p>
								<LoaderStyle>
									<SVG className="spin" src={LoaderSVG} />
								</LoaderStyle>
							</PopupStyle>
						}
					/>
				</PassResetMainContent>
			</AuthContainerStyle>
		</>
	)
}

export default PasswordReset;