/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import * as queryString from 'query-string';
import {
    FiCheck,
    FiChevronDown,
    FiExternalLink,
    FiTrash2,
} from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router';
import { useAppSelector } from '@ekenta/redux/store/hook';
import {
    useFetchAllUsersQuery,
    useFetchUsersByDateMutation,
    useSuspendOrActivateUserMutation,
} from '@ekenta/redux/store/services/adminSellerApi';
import { DashboardSellers } from '@ekenta/routes/constant';
import {
    Button,
    DataTable,
    Dropdown,
    Field,
    HelmetHeader,
    PageTitle,
    Pagination,
} from '@ekenta/components';
import { withDashboardLayout } from '@ekenta/hoc';
import { VscArrowSmallLeft } from 'react-icons/vsc';
// import toast from 'react-hot-toast';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Export from '../../common/ExportButton/Export';
import { BASE_URL } from '@ekenta/constants';
import styled from 'styled-components';

const AllUsers = () => {
    const query = queryString.parse(window.location.search);
    const _page = query.page;
    const formValidation = () =>
        Yup.object().shape({
            start_date: Yup.string().required('Start date field is required'),
            end_date: Yup.string().required('End date field is required'),
        });
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { token } = useAppSelector((state) => state.auth.authentication);
    const [pageNo, setPageNo] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const result = useFetchAllUsersQuery({ token, pageNo });
    const [updateSellerStatus, getResult] = useSuspendOrActivateUserMutation();
    const [getUserByDate, resultUserByDate] = useFetchUsersByDateMutation();

    const columns = [
        // {
        //     Header: "Image",
        //     accessor: "avatar",
        //     sortType: "basic",
        // },
        {
            Header: 'Name',
            accessor: 'name',
            sortType: 'basic',
        },
        {
            Header: 'Email',
            accessor: 'email',
            sortType: 'basic',
        },
        {
            Header: 'Phone Number',
            accessor: 'phone',
            disableSortBy: true,
        },
        {
            Header: 'Gender',
            accessor: 'gender',
            disableSortBy: true,
        },
        {
            Header: 'Origin',
            accessor: 'origin',
            disableSortBy: true,
        },
        {
            Header: 'Role',
            accessor: 'role',
            disableSortBy: true,
        },
        {
            Header: 'Date Joined',
            accessor: 'created_at',
            disableSortBy: true,
        },
        {
            Header: 'Action',
            accessor: 'option',
            disableSortBy: true,
        },
    ];

    const handleSuspend = (userId: any) => {
        updateSellerStatus({ token, userId });
    };

    useEffect(() => {
        if (getResult?.isSuccess) {
            // toast.success(<div className="cus_t">Successfully Updated</div>);
            toast.success(`Successfully Updated`, {
                toastId: '-product-toggle-success-id-toast',
            });
            result?.refetch();
        }
    }, [getResult]);

    useEffect(() => {
        if (resultUserByDate?.isSuccess) {
            toast.success(`Successfully Fetched`, {
                toastId: '-product-toggle-success-id-toast',
            });
        }
    }, [resultUserByDate]);

    const mapData = (data: any) => {
        return data.map((item: any) => ({
            ...item,
            name: item.first_name + ' ' + item.last_name,
            email: item.email,
            phone: item.phone_number,
            gender: item.gender,
            origin: (
                <Text>{item.origin}</Text>
            ),
            role: item.role,
            created_at: moment(item.created_at).format('MMM Do YY'),
            option: (
                <Dropdown
                    name={'more'}
                    suffixIcon={<FiChevronDown />}
                    placement="bottom"
                    menus={[
                        {
                            name: 'View',
                            icon: <FiExternalLink />,
                            active: false,
                            key: 'view',
                            handler: () => navigate(`/admin/user/${item.id}`),
                        },
                        // {
                        //     name: "Edit",
                        //     icon: <FiEdit3 />,
                        //     active: false,
                        //     key: "edit",
                        //     handler: () => console.log("Edit"),
                        // },
                        {
                            name: item.is_active ? 'Suspend' : 'Activate',
                            icon: item.is_active ? <FiTrash2 /> : <FiCheck />,
                            active: false,
                            key: 'delete',
                            handler: () => handleSuspend(item?.id),
                        },
                    ]}
                    tooltipContent={''}
                />
            ),
        }));
    };

    //   <img className="img-thumbnail rounded-circle" style={{ height: "50px" }} src={e.imageUrl !== null ? e?.imageUrl : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt="" />
    const formik = useFormik({
        initialValues: {
            start_date: '',
            end_date: '',
        },
        validationSchema: formValidation(),
        onSubmit: (values) => {
            const data = {
                start_date: values.start_date,
                end_date: values.end_date,
            };
            getUserByDate({ data, token });
            console.log(data);
        },
    });

    const handleDownloadData = async (type: string) => {
        console.log(type, 'exporting data');
        const fileName = 'all_total_sellers.xlsx';
        await downloadFile(fileName);
    };

    const downloadFile = async (fileName: string) => {
        setIsLoading(true);
        try {
            const response = await fetch(
                `${BASE_URL}/api/admin/users?export=${true}&page=1`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            const { link } = data;
            // Create an anchor element to trigger the download
            const a = document.createElement('a');
            a.href = link;
            a.download = fileName;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            setIsLoading(false);
            toast.success('Exported file successfully', {
                toastId: 'export-xlsx-id-toast-success',
            });
        } catch (error) {
            setIsLoading(false);
            toast.error('Unable to export file', {
                toastId: 'export-to-xlsx-toast-error',
            });
        }
    };

    return (
        <Fragment>
            <HelmetHeader title="All Users" description="QlasonAll Users Page" />
            <Col className=" col-lg-12 _listing_product_">
                <div className="mt-0 pt-3">
                    <PageTitle
                        title="All Users"
                        subTitle={
                            <span
                                className="cursor-pointer d-flex w-lg-50 align-items-center 
                                text-muted"
                                onClick={() => navigate(DashboardSellers)}>
                                <VscArrowSmallLeft />
                                Back to Dashboard
                            </span>
                        }>
                        <Export
                            downloadData={handleDownloadData}
                            type="excel"
                            name={'Export'}
                        />
                    </PageTitle>
                    <Col className="mt-5 tab__">
                        <form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
                            <Row>
                                <Col lg={2}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Field
                                            id="start_date"
                                            name="start_date"
                                            label="Start Date"
                                            disabled={false}
                                            required
                                            type="date"
                                            size="large"
                                            childOutsideLabel={false}
                                            placeholder=""
                                            fieldClassName=""
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.start_date}
                                            isError={
                                                formik.touched.start_date && formik.errors.start_date
                                                    ? true
                                                    : false
                                            }
                                            error={formik.errors.start_date}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={2}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Field
                                            id="end_date"
                                            name="end_date"
                                            label="End Date"
                                            disabled={false}
                                            required
                                            type="date"
                                            size="large"
                                            childOutsideLabel={false}
                                            placeholder=""
                                            fieldClassName=""
                                            autoComplete="off"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.end_date}
                                            isError={
                                                formik.touched.end_date && formik.errors.end_date
                                                    ? true
                                                    : false
                                            }
                                            error={formik.errors.end_date}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={2} className="mt-4">
                                    <Button
                                        name="Filter"
                                        bgVariant="primary"
                                        className="Button-Call-to-action"
                                        textColor={'#fff'}
                                        isLoading={resultUserByDate?.isLoading}
                                        disabled={false}
                                        type="submit"
                                        shadow={false}
                                    />
                                </Col>
                            </Row>
                        </form>

                        <DataTable
                            theme="simple"
                            data={
                                resultUserByDate?.data
                                    ? mapData(resultUserByDate?.data?.users.data)
                                    : result?.data
                                        ? mapData(result?.data?.users.data)
                                        : []
                            }
                            loading={result?.isLoading || isLoading}
                            isSelectable={true}
                            columns={columns}
                            showPagination={false}
                            hasLink={true}
                            thClassName="w"
                            trClassName="w_Tr"
                            tdClassName={`Row__col`}
                            renderPagnation={
                                !resultUserByDate?.data && (
                                    <>
                                        {result?.data
                                            ? result?.data.users.total > 20 && (
                                                <Pagination
                                                    pathname={pathname}
                                                    itemPerPage={result?.data.users.per_page}
                                                    itemsLength={result?.data.users.total}
                                                    currentPage={pageNo}
                                                    queryNo={_page}
                                                    callback={(value) => setPageNo(value)}
                                                />
                                            )
                                            : null}
                                    </>
                                )
                            }
                        />
                        <br />
                    </Col>
                </div>
            </Col>
        </Fragment>
    );
};

const Text = styled.div`
    text-transform: uppercase;
`;

export default withDashboardLayout(AllUsers);
