import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { VscArrowLeft, VscChromeClose } from 'react-icons/vsc'
import { useAppDispatch, useAppSelector } from '@ekenta/redux/store/hook'
import { useGetSingleSellerDetailQuery } from '@ekenta/redux/store/services/adminSellerApi'
import { Link } from 'react-router-dom'
import {
  DashboardPathUrl,
  homePathUrl,
  singLiveBaseUrl,
} from '@ekenta/routes/constant'
import logo from '@ekenta/assets/images/216x44-white-logo.png'
import livestream from '@ekenta/assets/images/live_icon_dd.png'
import { useNavigate } from 'react-router'
import CloseDialog from './components/Dialogs/CloseDialog'
import { LiveVideoProductFrame } from './components/Frame/LiveVideoFrame'
import { MessageFrame } from './components/Frame/MessageFrame'

import { NoticeCard } from './components/Cards/NoticeCard'
import noliveaccess from '../../../assets/images/nolive_access.png'
import eke_market from '../../../assets/images/eke_market.png'
import WelcomeLiveCard from './components/Cards/WelcomeLiveCard'
import {
  removeLive,
  setWelcomeDialog,
  setIsLiveLink,
  setIsLive,
} from '@ekenta/redux/store/states/reducerSlice/livecomSlice'
import { ErrorCard } from '@ekenta/components/ErrorsPage/ErrorCard'
import { SpinLoader } from '../../../components/Spinner/SpinLoader'
import { FiLink, FiShare, FiUploadCloud } from 'react-icons/fi'
import PopOver from './components/Dialogs/PopOver'
import {
  useFetchAllSellerProductQuery,
  // useFetchProductFilteredByCollectionQuery,
  useSetSellerGoLiveStatusMutation,
  useSetSellerToEkeLiveMutation,
} from '../../../redux/store/services/livecommerceApi'
import SocialShare from '../../../components/SocialShare/SocialShare'
import CustomModal from '../../../components/Modal/CustomModal'
import LiveStreamImageDialog from './components/Dialogs/LiveStreamImageDialog'
import DashboardComponent from '../component/DashboardComponent'
import { HelmetHeader } from '@ekenta/components'
import { LiveStreamBaseURL } from '@ekenta/constants'
import { KeepAwake } from 'react-keep-awake'
const SellerLiveScreen = () => {
  const navigate = useNavigate()
  const clientRef = useRef(null)
  const dispatch = useAppDispatch()
  const { account, token } = useAppSelector(
    (state) => state.auth.authentication
  )
  const { nickname, isLive, isLiveLink, welcomeDialog, liveType } =
    useAppSelector((state) => state.auth.livecom)

  const [isDisconected, setIsDisconected] = useState(false)
  const [isConected, setIsConected] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [leaving, setLeaving] = useState(false)
  const [showPopover, setShowPopover] = useState(false)
  const [show, setShow] = useState(false)
  const [copied, setCopied] = useState(false)
  const [showThumbnailDialog, setShowThumbnailDialog] = useState(false)

  const merchantId = account ? account.user.id : ''
  const { data, isLoading, isSuccess, isFetching, isError, error } =
    useGetSingleSellerDetailQuery(token)
  const sellerId = merchantId
  const getProductResult = useFetchAllSellerProductQuery(sellerId, {
    skip: !sellerId,
  })

  const [setSellerGoLiveStatus, getResult] = useSetSellerGoLiveStatusMutation()
  const [setSellerToEkeLive, result] = useSetSellerToEkeLiveMutation()

  // const checkResult = useFetchProductFilteredByCollectionQuery(sellerId, {
  //   skip: !sellerId,
  // });

  // useEffect(() => {
  //   if (liveType === 'isEkeLive') {
  //     if (checkResult?.data) {
  //       if (checkResult?.data.content.length <= 0) {
  //         navigate(DashboardPathUrl);
  //       }
  //     } else {
  //       navigate(DashboardPathUrl);
  //     }
  //   }
  // }, [checkResult?.data, liveType]);

  useEffect(() => {
    if (isError && error.status === 'FETCH_ERROR') {
      toast.error(`Something went wrong`, {
        toastId: 'sellerLive-error--id-toast-error',
      })
    }

    if (isSuccess) {
      const liveLink = data.user?.merchant_business?.live_link
      if (liveLink) {
        dispatch(
          setIsLiveLink({
            isLiveLink: liveLink,
          })
        )
        dispatch(
          setWelcomeDialog({
            welcomeDialog: true,
          })
        )
      }

      if (!data?.user?.merchant_business?.thumbnail_url) {
        setShowThumbnailDialog(true)
      }
    }
  }, [isSuccess, isError])

  useEffect(() => {
    if (
      getProductResult?.isError &&
      getProductResult?.error.status === 'FETCH_ERROR'
    ) {
      toast.error(`Something went wrong`, {
        toastId: 'sellerLive-allproduct-fetch-id-toast-error',
      })
    }
  }, [getProductResult?.isError, getProductResult?.error])

  useEffect(() => {
    if (getResult?.isError && getResult?.error.status === 'FETCH_ERROR') {
      toast.error(`Something went wrong`, {
        toastId: 'sellerLive-result-fetch-id-toast-error',
      })
    }

    if (getResult?.isSuccess && liveType === 'isSellerLive') {
      const _isSellerLive = getResult?.data?.data?.is_seller_live
      const merchantDetails = getResult?.data?.merchantDetails
      const liveLink = `${LiveStreamBaseURL}/channel/${account?.user?.username}/host`
      // const liveLink = `${LiveStreamBaseURL}?uid=${merchantDetails?.uid}&sk=${merchantDetails?.stream_key}&channelType=STANDARD&chatArn=${merchantDetails?.chat_arn}`;
      if (liveLink) {
        dispatch(
          setIsLiveLink({
            isLiveLink: liveLink,
          })
        )
        dispatch(
          setWelcomeDialog({
            welcomeDialog: true,
          })
        )
      }
      dispatch(
        setIsLive({
          isLive: _isSellerLive,
        })
      )

      if (!_isSellerLive) {
        dispatch(
          setIsLive({
            isLive: _isSellerLive,
          })
        )
        dispatch(
          setWelcomeDialog({
            welcomeDialog: true,
          })
        )
        setLeaving(false)
        onCloseDialog()
      }
    }
  }, [getResult?.isSuccess, getResult?.isError])

  useEffect(() => {
    if (result?.isError && result?.error.status === 'FETCH_ERROR') {
      toast.error(`Something went wrong`, {
        toastId: 'eke-result-fetch-id-toast-error',
      })
    }

    if (result?.isSuccess && liveType === 'isEkeLive') {
      const _isEkeLive = result?.data.isSellerLiveForEkeMarket
      dispatch(
        setIsLive({
          isLive: _isEkeLive,
        })
      )

      if (!_isEkeLive) {
        dispatch(
          setIsLive({
            isLive: _isEkeLive,
          })
        )
        dispatch(
          setWelcomeDialog({
            welcomeDialog: true,
          })
        )
        setLeaving(false)
        onCloseDialog()
      }
    }
  }, [result?.isSuccess, result?.isError])

  useEffect(() => {
    if (copied) {
      toast.success(`Link copied!`, {
        toastId: 'eke-link-toast-success',
      })
      setCopied(false)
    }
  }, [copied])

  if (isLive) {
    dispatch(
      setWelcomeDialog({
        welcomeDialog: false,
      })
    )
  }

  const handleGoBack = () => navigate(DashboardPathUrl)
  const onCloseDialog = () => setShowDialog(false)

  const helperFunc = (_isLive: boolean) => {
    if (isLiveLink && liveType === 'isSellerLive') {
      setSellerGoLiveStatus({
        isLive: _isLive,
        token: token,
      })
    } else if (isLiveLink && liveType === 'isEkeLive') {
      setSellerToEkeLive({
        isEkeLive: _isLive,
        token: token,
      })
    }
  }
  const handleSetGoLive = () => {
    helperFunc(true)
  }

  const handleLeaveLive = () => {
    helperFunc(false)
  }

  const onConfirmDialog = () => {
    setLeaving(true)
    handleLeaveLive()
    removeLive()
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const hideThumbnailDialog = () => setShowThumbnailDialog(false)
  const displayThumbnailDialog = () => setShowThumbnailDialog(true)

  const getSellerLiveUrl = (liveType: string) => {
    const type = liveType === 'isSellerLive' ? 'isLive' : liveType
    return `${window.location.origin}${singLiveBaseUrl}/spm-${sellerId}?type=${type}`
  }

  return (
    <DashboardComponent isPad={false} noStyle={true}>
      <HelmetHeader
        title={`${
          liveType === 'isSellerLive' ? 'Live Commerce' : 'Eke Market Live'
        } `}
        description={`Qlason ${
          liveType === 'isSellerLive' ? 'LiveCommerce' : 'Eke Market Live'
        } Dashboard  Page`}
      />
      <KeepAwake />
      <div className="w-100 p-0 m-0 col-12 mx-auto live__streams__frame mt-0">
        <div className="mt-0">
          <div className="live__s__f position-relative">
            <div className="mx-auto mt-0 main__v_stream_col">
              <div className="vid__stream-section">
                <div className="frame_section">
                  <div className="inner_row">
                    <div className="mx_QQ stream_page mt_ px-0 pb-0">
                      <div className="vid__stream_wrapper_ pt-1">
                        <div className="vid__stream_navigationBar pt-2 pb-2">
                          <Link
                            to={DashboardPathUrl}
                            className="vid__stream_brand"
                          >
                            <img
                              alt="Qlasonlogo"
                              src={logo}
                              width="140px"
                              height="auto"
                              className="d-inline-block mx-1 mt-0 align-top"
                            />{' '}
                            {liveType === 'isSellerLive' ? (
                              <img
                                src={livestream}
                                height="auto"
                                width="45px"
                                className="d-liveicon"
                              />
                            ) : (
                              <img
                                src={eke_market}
                                height="auto"
                                width=""
                                className=""
                              />
                            )}
                          </Link>

                          <div className="vid__stream_right_container">
                            {isLive && isLiveLink ? (
                              <div className="d-flex align-items-center gx-2">
                                <button
                                  className="d-flex share__button me-2 Button Button--icon"
                                  data-tip="Add Stream Image"
                                  data-for="uploadImage"
                                  onClick={displayThumbnailDialog}
                                >
                                  <ReactTooltip
                                    id="uploadImage"
                                    place="top"
                                    type="dark"
                                    effect="solid"
                                  />
                                  <FiUploadCloud />
                                </button>

                                {isSuccess && data && (
                                  <CopyToClipboard
                                    text={data?.user.merchant_business.live_key}
                                    onCopy={() => setCopied(true)}
                                  >
                                    <button
                                      className="d-flex share__button me-2 Button Button--icon"
                                      data-tip="Copy Stream Key"
                                      data-for="_livekey"
                                    >
                                      <ReactTooltip
                                        id="_livekey"
                                        place="top"
                                        type="dark"
                                        effect="solid"
                                      />
                                      <FiLink />
                                    </button>
                                  </CopyToClipboard>
                                )}

                                <button
                                  className="d-flex share__button me-2 Button Button--icon"
                                  data-tip="Share"
                                  data-for="share"
                                  onClick={handleShow}
                                >
                                  <ReactTooltip
                                    id="share"
                                    place="top"
                                    type="dark"
                                    effect="solid"
                                  />
                                  <FiShare />
                                </button>
                                <button
                                  className="close-live-stream d-flex align-items-center gap-0"
                                  onClick={() => setShowDialog(true)}
                                >
                                  <VscChromeClose fontSize={15} />
                                  <span className="d-none d-lg-flex">
                                    Leave Stream
                                  </span>
                                </button>
                              </div>
                            ) : (
                              <button
                                className="close-live-stream"
                                onClick={handleGoBack}
                              >
                                <VscArrowLeft fontSize={15} />
                                Back Home
                              </button>
                            )}
                          </div>
                        </div>

                        <div className="vid__stream_container position-relative">
                          {isLoading || isFetching ? (
                            <SpinLoader size={4} variant="light" />
                          ) : (
                            <>
                              {isError ? (
                                <ErrorCard
                                  title="Something went wrong!"
                                  description="Sorry! An error occurred while fetching, retry later"
                                  image={''}
                                />
                              ) : (
                                <>
                                  {!isLiveLink ? (
                                    <NoticeCard
                                      image={noliveaccess}
                                      title="We are setting you up to go live"
                                      description="Feature will be available in the next 15 minutes, Once it’s available, you can stream or go live instantly"
                                    />
                                  ) : null}

                                  {isLiveLink && welcomeDialog ? (
                                    <WelcomeLiveCard
                                      isLoading={
                                        liveType == 'isSellerLive'
                                          ? getResult?.isLoading
                                          : result?.isLoading
                                      }
                                      liveType={liveType}
                                      handleSetGoLive={handleSetGoLive}
                                    />
                                  ) : null}

                                  {isLiveLink && isLive && !welcomeDialog ? (
                                    <LiveVideoProductFrame
                                      nickname={nickname}
                                      clientRef={clientRef}
                                      sellerId={merchantId}
                                      setIsDisconected={setIsDisconected}
                                      setIsConected={setIsConected}
                                      liveCode={isLiveLink}
                                      data={getProductResult?.data}
                                      isSuccess={getProductResult?.isSuccess}
                                      isLoading={getProductResult?.isLoading}
                                      setShowPopover={setShowPopover}
                                    />
                                  ) : null}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* {!isError && (
                      <>
                        {isLive && isLiveLink && !welcomeDialog ? (
                          <>
                            <div className="mr_QQ stream_chat mt">
                              <>
                                {isLoading || isFetching ? (
                                  <SpinLoader size={1.5} variant="light" />
                                ) : (
                                  <MessageFrame
                                    nickname={nickname}
                                    clientRef={clientRef}
                                    sellerId={merchantId}
                                    setIsDisconected={setIsDisconected}
                                    setIsConnected={setIsConected}
                                  />
                                )}
                              </>
                            </div>
                          </>
                        ) : null}
                      </>
                    )} */}
                  </div>
                </div>
              </div>

              {!welcomeDialog && (
                <LiveStreamImageDialog
                  merchantId={merchantId}
                  show={showThumbnailDialog}
                  thumbnailUrl={data?.thumbnailUrl ? data?.thumbnailUrl : null}
                  onCancel={hideThumbnailDialog}
                />
              )}

              <div className="popover_panel_container">
                <PopOver
                  show={showPopover}
                  setOutside={() => setShowPopover(false)}
                  data={getProductResult?.data}
                  isSuccess={getProductResult?.isSuccess}
                  isLoading={getProductResult?.isLoading}
                />
              </div>

              {/* {promopt dialog} */}

              <CloseDialog
                show={showDialog}
                isLoading={leaving}
                contentClass={'w-auto'}
                onClose={onCloseDialog}
                onConfirm={onConfirmDialog}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Modal Overlay for share  */}
      <CustomModal
        title="Share"
        show={show}
        centered={true}
        onHide={handleClose}
        render={
          <SocialShare
            url={getSellerLiveUrl(liveType)}
            title="QlasonLivestream"
          />
        }
      />
    </DashboardComponent>
  )
}

export default SellerLiveScreen

{
  /* <div className="vid_controller_div">
                                <ul className="list__controller_parent">
                                  {/* <li className="list__controller_child">
                                     <button className={`icon__span ${toggleAudio  && stream && !disabled ? "default_b": "disabled_bg"}`} 
                                     title="Microphone" onClick={toggleAudioHandle} >
                                        {toggleAudio  && stream && !disabled ? (<FiMic title="Turn off microphone" />) : (
                                          <FiMicOff title="Turn on microphone" />
                                        )} 
                                     </button>
                                  </li> */
}
{
  /* <li className="list__controller_child">
                                      <button className={`icon__span ${toggleVideo && stream && !disabled? "default_b": "disabled_bg"}`} 
                                             onClick={toggleVideoHandle}>
                                        {toggleVideo  && stream && !disabled ? (<FiVideo  title="Turn off camera" />) : (
                                          <FiVideoOff title="Turn on camera" />
                                        )}
                                      </button>
                                  </li> */
}
{
  /*                                 
                                  <li className="list__controller_child ">
                                      <button className="icon__span default_b" title="Present now" 
                                          disabled={stream && !disabled ? false : true} onClick={shareScreenHandle}>
                                          <FiAirplay title="Present now"/>
                                      </button>
                                  </li>
                                  <li className="list__controller_child ">
                                     <button className="icon__span default_b" title="Share stream link"
                                      disabled={stream && !disabled ? false : true}>
                                       <FiShare title="Share stream link"/>
                                     </button>
                                  </li> */
}
{
  /* <li className="list__controller_button" >
                                     <Button className="endButton btn_live" title="End Live">
                                         <VscChromeClose fontSize={24} />
                                     </Button>
                                  </li>
                                </ul>
                              </div> */
}
