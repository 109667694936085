import React from "react"
import styled, { css } from "styled-components";
import classnames from "classnames";
import { AppContentPropsType } from "@ekenta/interfaces"
import { ErrorCard } from "../ErrorsPage/ErrorCard";
import { device } from "@ekenta/utils/devices";

const AppContent = ({ children, className, error, full, isPad, noStyle = false, ...rest }: AppContentPropsType) => {

  return (
    <Container noStyle={noStyle} full={full} className={classnames("app-content", className, isPad)} {...rest}>
      {error && (
        <ErrorCard
          title="We are Sorry..."
        />
      )}
      {!error && children}
    </Container>
  )

}

const Container = styled.div<{ noStyle: boolean, full?: boolean }>`
 
  ${props => !props.noStyle && css`
    margin: 0 auto !important;
    padding: 0.8rem 0.5rem 0.8rem 0.5rem !important;

    @media ${device.lg}{
      padding-right: 1rem !important;
      padding-left: 1rem !important;
   }

    @media ${device.xl}{
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
   }

   @media ${device.xxl}{
      padding-right: 3rem !important;
      padding-left: 3rem !important;
   }
   
  `}

  ${props => props.full && css`
      width: 100%;
   `}


`;

export default AppContent;