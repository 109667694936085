import classnames from 'classnames';
import React from 'react'
import logo from '../../assets/images/216x44-logo.png';
import lightLogo from '../../assets/images/216x44-white-logo.png';

interface LogoPropTypes {
    width?: string;
    height?: string;
    className?: string;
    type?: string;
}

const Logo: React.FC<LogoPropTypes> = ({ width = "130", height = "30", className, type = "light" }) => {
    return (
        <img alt="qlason logo"
            src={
                type === "light" ? lightLogo :
                    type === "default" ? logo : logo
            }
            width={width}
            height={height}
            className={classnames("d-inline-block align-top", className)}
        />
    )
}

export default Logo;