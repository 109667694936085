import React, { useContext, useState } from 'react'
import { Offcanvas, Col, Form, Row, Button, Spinner } from 'react-bootstrap';
import { TriggerHelpCanvasContext } from '../../../context/TriggerHelpCanvasContext';
import { useStepActions } from 'react-step-machine';
import ReactStars from "react-rating-stars-component"
import SmileyRating from '../SmileyRating';
import toast from 'react-hot-toast';
import { toast as tst } from "react-toastify";
import { Box } from '@mui/material';
import axios from 'axios';

export default function HelpSideCanvas() {
  const { offCanvas, handleOnHideCanvas } = useContext(TriggerHelpCanvasContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    experience: '',
    challenges: '',
    recommendation: '',
    more: ''
  });

  const ratingChanged = (newRating: any) => {
    setDetails({ ...details, recommendation: newRating })
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChangeRating = (data: any) => {
    setDetails({ ...details, experience: data })
  }


  const handleSubmit = async () => {
    setLoading(true)
    const data = new FormData();
    data.append("experience", details.experience);
    data.append("challenges", details.challenges);
    data.append("recommendation", details.recommendation);
    data.append("more", details.more);

    try {
      await axios.post(
        "https://hooks.zapier.com/hooks/catch/13115430/bqlbwm5",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false)
      // handleOnHideCanvas
      document.getElementById('closeIt')?.click()
      toast.success(<div className="cus_t">Feedback sent!</div>);
      tst.success(`Feedback sent!`, {
        toastId: "state-un-id-toast-success",
      });
      setDetails({
        experience: '',
        challenges: '',
        recommendation: '',
        more: ''
      })
      //toastDisplay(true);

    } catch (err) {
      setLoading(false)
      document.getElementById('closeIt')?.click()
      //handleOnHideCanvas
    }
  }

  return (
    <Offcanvas closebutton={true} scroll={true} backdrop={true} show={offCanvas.show} placement={'end'} onHide={handleOnHideCanvas}>

      <Offcanvas.Header className="d-lg-flex" style={{ backgroundColor: "#232f3e" }} closeVariant='white' closeButton>
        <Box style={{ color: "#fff", textDecoration: "none" }} onClick={handleOnHideCanvas}>
          <Offcanvas.Title>
            <span className="d-flex align-items-center" id='closeIt' onClick={handleOnHideCanvas}>
              <span className="ms-2">Feedback</span>
            </span>
          </Offcanvas.Title>
        </Box>
      </Offcanvas.Header>


      <Offcanvas.Body className="px-2">
        <div className='bg-gray' style={{ borderRadius: '10px' }}>
          <div className="mt-1 px-3">
            <Form.Label className={'help-header'} style={{ fontSize: "18px", }}>How was your experience using the platform?</Form.Label>
            <SmileyRating onChange={handleChangeRating} />

            <p style={{ color: '#232F3E' }}>(1 = Very poor, 5 = Excellent!)</p>
          </div>
        </div>

        <div className='bg-gray mt-3' style={{ borderRadius: '10px' }}>
          <div className="mt-1 px-3">

            <Form.Label className={'help-header'} style={{ fontSize: "18px" }}>What were your challenges using the platform?</Form.Label>
            <Form.Group className="mb-3 formgroup__wrapper" controlId="formproductName">
              <Form.Control
                type="text"
                as={'textarea'}
                rows={5}
                value={details?.challenges}
                onChange={(e) => setDetails({ ...details, challenges: e.target.value })}
                name="productname"
                placeholder=""

              />

            </Form.Group>
          </div>
        </div>

        <div className='bg-gray mt-3' style={{ borderRadius: '10px' }}>
          <div className="mt-1 px-3">
            <Form.Label className={'help-header'} style={{ fontSize: "18px" }}>How likely are you to recommend to friends and family?</Form.Label>
            <Form.Group className="mb-3 formgroup__wrapper" controlId="formproductName">
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={48}
                activeColor="#232f3e"
              />
              <Row>
                <Col><p>Very poor</p></Col>
                <Col><p>Excellent</p></Col>
              </Row>
            </Form.Group>
          </div>
        </div>

        <div className='bg-gray mt-3' style={{ borderRadius: '10px' }}>
          <div className="mt-1 px-3">

            <Form.Label className={'help-header'} style={{ fontSize: "18px" }}>Please tell us more.</Form.Label>
            <Form.Group className="mb-3 formgroup__wrapper" controlId="formproductName">
              <Form.Control
                type="text"
                as={'textarea'}
                rows={5}
                name="productname"
                placeholder=""
                value={details?.more}
                onChange={(e) => setDetails({ ...details, more: e.target.value })}

              />

            </Form.Group>
          </div>
        </div>

        <div className={'mt-3 col-lg-12 text-white'}>
          <Button
            className={'text-white w-100 py-2'}

            disabled={
              details.challenges.length < 1 || details.recommendation.length < 1 || details.experience.length < 1
            }
            onClick={handleSubmit}
            style={{ fontSize: '20px' }}>
            {loading ? (<Spinner size="sm" animation="border" variant="white" />) : 'Submit'}

          </Button>
        </div>

      </Offcanvas.Body>
      {/* </Scrollbars> */}
    </Offcanvas>
  )
}

const ActionBtn = () => {
  const {
    goToNamedStep,
    goToStep,
    firstStep,
    lastStep,
    nextStep,
    previousStep,
  } = useStepActions({
    /**
     * On Step change you can do something here
    */

    // onStepChange: (prevStep: any, activeStep: any) => {
    //   console.log(prevStep, activeStep);
    // },
  });

  // const { activeNamedStep, totalSteps, activeStep } = useStepStore();

  return <div>
    <Row>
      <Col><button className='btn btn-primary text-white' onClick={previousStep}>Previous Step</button></Col>
      <Col><button className='btn btn-secondary' onClick={nextStep}>Next Step</button></Col>
    </Row>
    {/* <h2>Step {activeStep}</h2>
    <h2>Step {activeNamedStep}</h2>
    <p>Total Steps: {totalSteps}</p> */}
    <p></p>
    <p></p>
    {/* <p><button onClick={() => goToStep(2)}>Step 2</button></p> */}
    {/* <p><button onClick={() => goToNamedStep("foo")}>Fooo</button></p> */}
    {/* <p><button onClick={firstStep}>First Step</button></p> */}
    {/* <p><button onClick={lastStep}>Last Step</button></p> */}
  </div>

};
