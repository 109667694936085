/* eslint-disable @typescript-eslint/no-explicit-any */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createQueryWithTokenRequestByText, createMutationRequestToTextWithoutBody } from '.';
import { BASE_URL } from '../../../constants/index';
//import { getUserToken } from '../../../utils';

//const _token = getUserToken();
//console.log(_token,'_token');

const generalApiHeaders = (token: string) => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
});

const createQueryRequest = ({ url, method }: { url: string, method: string }) => ({
    url: url,
    headers: {
        'Content-Type': 'application/json'
    },
    method: method
});

const createQueryWithTokenRequest = ({ url, method, token }: { url: string, method: string, token: string }) => ({
    url: url,
    headers: generalApiHeaders(token),
    method: method,
});

const createMutationRequestWithoutBody = ({ url, method, token }: { url: string, method: string, token: string }) => {
    return {
        url: url,
        headers: generalApiHeaders(token),
        method: method,
    }
};

const createFormDataMutationRequest = ({ url, method, body, token }: { url: string, method: string, body: any, token: string }) => ({
    url: url,
    headers: {
        "Authorization": `Bearer ${token}`
    },
    method: method,
    body: body
});

export const adminSellerApi: any = createApi({
    reducerPath: 'adminSellerApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['banner', 'Seller', 'Event', 'Order', 'Offer', 'Notification'],
    endpoints: (builder) => ({
        fetchTotalOrders: builder.query<void, string>({
            query: () => createQueryRequest({ url: `/api/brand/all`, method: 'GET' })
        }),
        fetchPendingSellers: builder.query<void, { token: string; keyword: string; pageno: number }>({
            query: ({ pageno, keyword, token }) => createQueryWithTokenRequest({ url: `/api/admin/sellers-awaiting-approval?keyword=${keyword}&page=${pageno}`, method: 'GET', token: token })
        }),
        fetchSellerDetails: builder.query<void, { merchantId: string; token: string; }>({
            query: ({ merchantId, token }) => createQueryWithTokenRequest({ url: `/api/admin/seller/${merchantId}`, method: 'GET', token: token })
        }),
        getSingleSellerDetail: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/user`, method: 'GET', token: token })
        }),

        updateSellerRequestStatus: builder.mutation<void, { data: { status: string }, merchantid: string; token: string; }>({
            query: ({ data, merchantid, token }) => createFormDataMutationRequest({ url: `/api/admin/seller/${merchantid}`, method: 'POST', body: data, token: token })
        }),

        fetchAdminAllOrders: builder.query<void, { token: string; status: string, keyword: string, pageNo: string }>({
            query: ({ token, status, keyword, pageNo }) => createQueryWithTokenRequest({ url: `/api/admin/${status}/orders?keyword=${keyword}&page=${pageNo}`, method: 'GET', token: token }),
            providesTags: ['Order']
        }),

        fetchOrderStatusDetails: builder.query<void, { token: string, id: string }>({
            query: ({ token, id }) => createQueryWithTokenRequest({ url: `/api/order/${id}`, method: 'GET', token: token }),
            providesTags: ['Order'],
        }),

        updateOrderStatusAdmin: builder.mutation<void, any>({
            query: ({ data, id, token }) => createFormDataMutationRequest({ url: `/api/admin/update-order-status/${id}`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['Order']
        }),

        fetchDailyTotalOrder: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/fetch-daily-total-order-accounting`, method: 'GET', token: token })
        }),
        fetchAdminDashboard: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/dashboard`, method: 'GET', token: token })
        }),
        fetchAccountingSalesAdmin: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/fetch-seller-daily-total-order-accounting`, method: 'GET', token: token })
        }),

        fetchActiveSellers: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/fetch-all-seller-approved-registration-request`, method: 'GET', token: token }),
            providesTags: ['Seller']
        }),
        fetchAdminSellerDetails: builder.query<void, { merchantId: string; token: string; }>({
            query: ({ merchantId, token }) => createQueryWithTokenRequest({ url: `/api/admin/view-seller-profile-product-listing-page/${merchantId}`, method: 'GET', token: token })
        }),

        fetchAllActiveSellers: builder.query<void, { token: string; keyword: string; pageno: number }>({
            query: ({ pageno, keyword, token }) => createQueryWithTokenRequest({ url: `/api/admin/active-sellers?keyword=${keyword}&page=${pageno}`, method: 'GET', token: token }),
            providesTags: ['Seller']
        }),

        fetchAllTotalSellers: builder.query<void, { token: string; status: string; isExport: boolean; keyword: string; pageno: number }>({
            query: ({ pageno, status, keyword, isExport, token }) => createQueryWithTokenRequest({ url: `/api/admin/all-sellers?keyword=${keyword}&status=${status}&export=${isExport}&page=${pageno}`, method: 'GET', token: token }),
        }),

        fetchAdminAllReturnRequest: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/all-product-return-request`, method: 'GET', token: token })
        }),
        fetchSellerDailyTotal: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/fetch-all-seller-daily-total-order-accounting`, method: 'GET', token: token })
        }),

        // Banners Request
        fetchAllBanner: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/get-ad-banners`, method: 'GET', token: token }),
            providesTags: ['banner'],
        }),
        createBanner: builder.mutation<any, any>({
            query: ({ data, token }) => createFormDataMutationRequest({ url: `/api/admin/create-banners`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['banner']
        }),
        updateBanner: builder.mutation<any, any>({
            query: ({ data, id, token }) => createFormDataMutationRequest({ url: `/api/admin/update-banner/${id}`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['banner']
        }),
        deleteBanner: builder.mutation<any, any>({
            query: ({ id, token }) => createFormDataMutationRequest({ url: `/api/admin/delete-banner/${id}`, method: 'DELETE', body: {}, token: token }),
            invalidatesTags: ['banner']
        }),
        // fetchAdminVideos: builder.query<void, string>({
        //     query: (token) => createQueryWithTokenRequest({ url: `/api/admin/view-uploaded-video`, method: 'GET', token: token }),
        // }),
        // addNewVideo: builder.mutation<any, any>({
        //     query: ({ data, other, token }) => createFormDataMutationRequest({ url: `/api/admin/video/upload?videoTitle=${other.videoTitle}&videoNumber=${other.videoNumber}`, method: 'POST', body: data, token: token })
        // }),
        updateSellerProfile: builder.mutation<any, any>({
            query: ({ data, merchantOwnerId, token }) => createFormDataMutationRequest({ url: `/api/admin/update-seller-live-link/${merchantOwnerId}`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['Seller']
        }),
        fetchAllUsers: builder.query<void, { token: string, pageNo: string }>({
            query: ({ token, pageNo }) => createQueryWithTokenRequest({ url: `/api/admin/users?page=${pageNo}`, method: 'GET', token: token })
        }),
        fetchAllVerifiedUsers: builder.query<void, { token: string, pageno: string }>({
            query: ({ token, pageno }) => createQueryWithTokenRequest({ url: `/api/users/managed-verified-users?page=${pageno}`, method: 'GET', token: token })
        }),

        //Payout for admin endpoint
        fetchPayoutRequest: builder.query<void, { pageno: number; token: string }>({
            query: ({ pageno, token }) => createQueryWithTokenRequest({ url: `/api/admin/payouts?page=${pageno}`, method: 'GET', token: token })
        }),

        fetchPayoutHistory: builder.query<void, { pageno: number; token: string }>({
            query: ({ pageno, token }) => createQueryWithTokenRequest({ url: `/api/admin/payouts-history?page=${pageno}`, method: 'GET', token: token })
        }),

        acknowledgePayoutRequest: builder.mutation<void, { data: { password: string }; token: string }>({
            query: ({ data, token }) => createFormDataMutationRequest({ url: `/api/admin/acknowledge-payout`, method: 'POST', body: data, token: token })
        }),

        fetchSinglePayout: builder.query<void, { pageno: number; id: string; token: string }>({
            query: ({ pageno, id, token }) => createQueryWithTokenRequest({ url: `/api/admin/seller-order-history/${id}?page=${pageno}`, method: 'GET', token: token })
        }),

        fetchSinglePayoutHistory: builder.query<void, { pageno: number; status: string; id: string; token: string }>({
            query: ({ pageno, id, status, token }) => createQueryWithTokenRequest({ url: `/api/admin/seller-payout-history/${id}?status=${status}&page=${pageno}`, method: 'GET', token: token })
        }),

        initializePayout: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/payout-init`, method: 'GET', token: token })
        }),

        setProductFlagOrUnFlag: builder.query<void, { prodId: string, flagStatus: string, token: string }>({
            query: ({ prodId, flagStatus, token }) => createQueryWithTokenRequest({ url: `/api/admin/flag-product/${flagStatus}/${prodId}`, method: 'GET', token: token }),
        }),

        fetchAdminFilter: builder.query<void, { token: string, date: string }>({
            query: ({ date, token }) => createQueryWithTokenRequest({ url: `/api/admin/dashboard/${date}`, method: 'GET', token: token })
        }),

        createEvent: builder.mutation<void, { data: { name: string }; token: string; }>({
            query: ({ data, token }) => createFormDataMutationRequest({ url: `/api/admin/create-event`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['Event']
        }),

        updateEvent: builder.mutation<void, { data: { name: string }; id: string; token: string; }>({
            query: ({ data, id, token }) => createFormDataMutationRequest({ url: `/api/admin/update-event/${id}`, method: 'PUT', body: data, token: token }),
            invalidatesTags: ['Event']
        }),

        fetchAllEvent: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/all-events`, method: 'GET', token: token }),
            providesTags: ['Event'],
        }),

        fetchSingleEvent: builder.query<void, { id: string; token: string }>({
            query: ({ id, token }) => createQueryWithTokenRequest({ url: `/api/event-details/${id}`, method: 'GET', token: token }),
        }),

        removeSingleEventProduct: builder.mutation<void, { data: any; eventId: string; token: string }>({
            query: ({ data, eventId, token }) => createFormDataMutationRequest({ url: `/api/admin/remove-product-from-event/${eventId}`, method: 'POST', body: data, token: token })
        }),

        removeMultipleEventProduct: builder.mutation<void, { data: any; eventId: string; token: string }>({
            query: ({ data, eventId, token }) => createFormDataMutationRequest({ url: `/api/admin/remove-multiple-product-from-event/${eventId}`, method: 'POST', body: data, token: token })
        }),

        fetchAllCollection: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/all/activated-collection`, method: 'GET', token: token }),
        }),

        toggleEvent: builder.mutation<void, { id: string; token: string; }>({
            query: ({ id, token }) => createMutationRequestWithoutBody({ url: `/api/admin/event/toggle/${id}`, method: 'PUT', token: token }),
            invalidatesTags: ['Event']
        }),
        setDiscountToAllProduct: builder.mutation<void, { categoryId: string; value: string, token: string; }>({
            query: ({ categoryId, value, token }) => createMutationRequestToTextWithoutBody({ url: `/api/admin/activate-discount-for-all-product-by-category/${categoryId}?percentageValue=${value}`, method: 'PUT', token: token }),
        }),

        updateEkeMarketActivation: builder.mutation<void, { collectionId: string; token: string; }>({
            query: ({ collectionId, token }) => createMutationRequestWithoutBody({ url: `/api/admin/toggle-ekemarket-activation/collection/${collectionId}`, method: 'PUT', token: token })
        }),

        setOrderToPaid: builder.mutation<void, { orderId: string; token: string; }>({
            query: ({ orderId, token }) => createMutationRequestWithoutBody({ url: `/api/admin/pay-on-delivery-update/${orderId}`, method: 'PUT', token: token }),
        }),

        fetchAllBlockedSellers: builder.query<void, { token: string; keyword: string; pageno: number }>({
            query: ({ pageno, keyword, token }) => createQueryWithTokenRequest({ url: `/api/admin/blocked-sellers?keyword=${keyword}&page=${pageno}`, method: 'GET', token: token }),
            providesTags: ['Seller']
        }),

        fetchSellerMerchantDetails: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/seller/merchant-business-details`, method: 'GET', token: token })
        }),

        updateSellerDetails: builder.mutation<void, any>({
            query: ({ data, merchantid, token }) => createFormDataMutationRequest({ url: `/api/admin/update-seller/${merchantid}`, method: 'POST', body: data, token: token })
        }),

        fetchSingleUser: builder.query<void, { token: string, userId: string }>({
            query: ({ token, userId }) => createQueryWithTokenRequest({ url: `/api/admin/user/${userId}`, method: 'GET', token: token })
        }),

        suspendOrActivateUser: builder.mutation<void, { token: string, userId: string }>({
            query: ({ token, userId }) => createMutationRequestWithoutBody({ url: `/api/admin/change-user-status/${userId}`, method: 'PUT', token: token })
        }),
        fetchAllOffers: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/offers`, method: 'GET', token: token }),
            providesTags: ['Offer'],
        }),
        createOffer: builder.mutation<void, { data: any; token: string; }>({
            query: ({ data, token }) => createFormDataMutationRequest({ url: `/api/admin/create-offer`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['Offer']
        }),
        fetchAllNotifications: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/notifications`, method: 'GET', token: token }),
            providesTags: ['Notification'],
        }),
        createNotification: builder.mutation<void, { data: any; token: string; }>({
            query: ({ data, token }) => createFormDataMutationRequest({ url: `/api/admin/create-notification`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['Notification']
        }),

        updateOffer: builder.mutation<void, { data: any; token: string; }>({
            query: ({ data, token }) => createFormDataMutationRequest({ url: `/api/admin/update-offer`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['Offer']
        }),
        deleteOffer: builder.mutation<any, any>({
            query: ({ offerId, token }) => createMutationRequestWithoutBody({ url: `/api/admin/delete-offer/${offerId}`, method: 'PUT', token: token }),
            invalidatesTags: ['Offer'],
        }),

        deleteNotification: builder.mutation<any, any>({
            query: ({ notificationId, token }) => createMutationRequestWithoutBody({ url: `/api/admin/delete-notification/${notificationId}`, method: 'PUT', token: token }),
            invalidatesTags: ['Notification'],
        }),
        getSellerProfile: builder.query<void, { sellerId: string; token: string }>({
            query: ({ token, sellerId }) => createQueryWithTokenRequest({ url: `/api/admin/get-seller-details/${sellerId}`, method: 'GET', token: token }),
        }),

        fetchSingleReturnRequest: builder.query<void, { id: string; token: string; }>({
            query: ({ id, token }) => createQueryWithTokenRequest({ url: `/api/admin/product-return-request/${id}`, method: 'GET', token: token }),
        }),
        updateReturnRequest: builder.mutation<void, { data: { status: string, admin_notes: string }, id: string; token: string; }>({
            query: ({ data, id, token }) => createFormDataMutationRequest({ url: `/api/admin/product-return-request/${id}`, method: 'PUT', body: data, token: token })
        }),

        getReviewReport: builder.query({
            query: ({ token, currentPage }) => ({
                url: `/api/admin/app-rating-review?page=${currentPage}`,
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                method: 'GET',
            })
        }),
        fetchUsersByDate: builder.mutation<void, { data: { start_date: string, end_date: string }; token: string; }>({
            query: ({ data, token }) => createFormDataMutationRequest({ url: `/api/admin/users`, method: 'POST', body: data, token: token }),
            // invalidatesTags: ['Event']
        }),
    })
})


export const {
    useFetchPendingSellersQuery,
    useFetchAllOrdersQuery,
    useFetchSellerDetailsQuery,
    useUpdateSellerRequestStatusMutation,
    useFetchDailyTotalOrderQuery,
    useFetchAdminDashboardQuery,
    useFetchAccountingSalesAdminQuery,
    useFetchActiveSellersQuery,
    useFetchAllActiveSellersQuery,
    useFetchAllTotalSellersQuery,
    useFetchAdminSellerDetailsQuery,
    useFetchAdminAllReturnRequestQuery,

    useFetchAdminSliderImagesQuery,
    useCreateBannerMutation,
    useUpdateBannerMutation,
    useDeleteBannerMutation,
    useFetchAllBannerQuery,

    useFetchSellerDailyTotalQuery,
    useGetSingleSellerDetailQuery,
    useFetchAdminAllOrdersQuery,
    //useAddNewVideoMutation,
    useUpdateSellerProfileMutation,
    useFetchAllUsersQuery,
    useSetProductFlagOrUnFlagQuery,
    useFetchAllVerifiedUsersQuery,
    useFetchPayoutRequestQuery,
    useFetchPayoutHistoryQuery,
    useAcknowledgePayoutRequestMutation,
    useFetchSinglePayoutQuery,
    useFetchSinglePayoutHistoryQuery,
    useInitializePayoutQuery,
    useFetchAllEventQuery,
    useFetchSingleEventQuery,
    useRemoveSingleEventProductMutation,
    useRemoveMultipleEventProductMutation,
    useFetchAllCollectionQuery,
    useFetchAdminFilterQuery,
    useUpdateEkeMarketActivationMutation,
    useCreateEventMutation,
    useUpdateEventMutation,
    useToggleEventMutation,
    useSetDiscountToAllProductMutation,
    useSetOrderToPaidMutation,
    useUpdateOrderStatusAdminMutation,
    useFetchOrderStatusDetailsQuery,
    useFetchAllBlockedSellersQuery,
    useFetchSellerMerchantDetailsQuery,
    useUpdateSellerDetailsMutation,
    useFetchSingleUserQuery,
    useSuspendOrActivateUserMutation,
    useFetchAllOffersQuery,
    useCreateOfferMutation,
    useFetchAllNotificationsQuery,
    useCreateNotificationMutation,
    useUpdateOfferMutation,
    useDeleteOfferMutation,
    useDeleteNotificationMutation,
    useGetSellerProfileQuery,
    useFetchSingleReturnRequestQuery,
    useUpdateReturnRequestMutation,
    useGetReviewReportQuery,
    useFetchUsersByDateMutation
} = adminSellerApi;