import React from 'react'
import styled from 'styled-components'
import { FeaturesType } from '@ekenta/redux/store/features/product/productDataType'

interface FeatureProps {
  features: FeaturesType[],
  skus: string[]
}

const Features = (props: FeatureProps) => {
  const { features,skus } = props
  return (
    <Container>
      <div className="top-header">
        <h5>Features</h5>
      </div>
      <div className="descriptions">
        <ul>
          <li>
            <h3>Key Features</h3>
            <p>
              {features?.length > 0
                ? features
                    ?.map(
                      (item) =>
                        item?.feature?.name + ' : ' + item?.feature_value
                    )
                    .join(', ')
                : 'no features'}
            </p>
          </li>
          <li>
            <h3>Warranty</h3>
            <p></p>
          </li>
          <li>
            <h3>SKU</h3>
            <p>{skus?.length > 0 && skus[0]}</p>
          </li>
        </ul>
      </div>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 22px 18px 21px;
  border-radius: 12px;
  background: #fff;
  font-family: var(--eke-body-font-family);
  gap: 20px;
  //box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.12);

  .top-header {
    width: 100%;
    height: 57px;
    border-radius: 12px;
    padding: 15px 16px 12px 16px;
    align-items: center;
    background: #f3faff;

    h5 {
      color: var(--secondary-500, #1d4057);
      font-size: 22px;
      font-weight: 700;
      margin: 0;
      padding: 0;
    }
  }

  .descriptions {
    width: 100%;
    ul {
      width: 100%;
      padding: 0;
      padding-left: 13px;
      display: flex;
      flex-direction: column;
      align-items: center;
      list-style: none;
      gap: 16px;

      li {
        width: 100%;
        h3 {
          color: var(--greyscale-700, #616161) !important;
          font-size: 16px;
          font-weight: 700 !important;
          letter-spacing: 0.2px;
        }
        p {
          color: var(--greyscale-700, #616161);
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.2px;
          margin: 0;
        }
      }
    }
  }
`
export default Features
