/* eslint-disable @typescript-eslint/no-explicit-any */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../../constants/index';


const generalApiHeaders = (token: string) => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
});

const createQueryRequest = ({ url, method }: { url: string, method: string }) => ({
    url: url,
    headers: {
        'Content-Type': 'application/json'
    },
    method: method
});

const createQueryWithTokenRequest = ({ url, method, token }: { url: string, method: string, token: string }) => ({
    url: url,
    headers: generalApiHeaders(token),
    method: method,
});


const createFormDataMutationRequest = ({ url, method, body, token }: { url: string, method: string, body: any, token: string }) => ({
    url: url,
    headers: {
        "Authorization": `Bearer ${token}`
    },
    method: method,
    body: body
});


export const vendorProductApi: any = createApi({
    reducerPath: 'vendorProductApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['Product', 'Brand', 'Order'],
    endpoints: (builder) => ({
        fetchAllProducts: builder.query<void, string>({
            query: (pageno) => createQueryRequest({ url: `/api/product/all?page=${pageno}`, method: 'GET' }),
            providesTags: ['Product'],
        }),
        fetchBrandsByCollectionId: builder.query<void, string>({
            query: (collectionId) => createQueryRequest({ url: `/api/collection-with-brand/${collectionId}`, method: 'GET' }),
            providesTags: ['Brand'],
        }),
        fetchTotalOrders: builder.query<void, string>({
            query: () => createQueryRequest({ url: `/api/brand/all`, method: 'GET' })
        }),

        fetchAllSellerOrders: builder.query<void, { token: string; status: string, keyword: string; pageNo: string }>({
            query: ({ token, status, keyword, pageNo }) => createQueryWithTokenRequest({ url: `/api/seller/${status}/orders?keyword=${keyword}&page=${pageNo}`, method: 'GET', token: token })
        }),

        fetchCompletedOrders: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `api/fetch-seller-orders-by-orderstatus?orderStatus=COMPLETED`, method: 'GET', token: token })
        }),
        fetchProcessingOrders: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `api/fetch-seller-orders-by-orderstatus?orderStatus=PROCESSING`, method: 'GET', token: token })
        }),
        fetchCancelledOrders: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `api/fetch-seller-orders-by-orderstatus?orderStatus=CANCELLED`, method: 'GET', token: token })
        }),
        fetchPendingOrders: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `api/fetch-seller-orders-by-orderstatus?orderStatus=PENDING`, method: 'GET', token: token })
        }),

        fetchSellerProducts: builder.query<void, any>({
            query: ({ id, token, pageno }) => createQueryWithTokenRequest({ url: `/api/product/seller/${id}?page=${pageno}`, method: 'GET', token: token }),
            providesTags: ['Product'],
        }),
        fetchSellerReturnRequest: builder.query<void, any>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/seller/product-return-request`, method: 'GET', token: token })
        }),

        addBrandRequest: builder.mutation<void, { data: any; token: string }>({
            query: ({ data, token }) => createFormDataMutationRequest({ url: `/api/create-brand`, method: 'POST', body: data, token: token }),
        }),

        mapBrandToCollection: builder.mutation<void, { data: any; token: string }>({
            query: ({ data, token }) => createFormDataMutationRequest({ url: `/api/collection/add-brand`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['Brand']
        }),

        getAllProducts: builder.query<void, { pageno: string; keyword: string; token: string }>({
            query: ({ pageno, keyword, token }) => createQueryWithTokenRequest({ url: `/api/products?keyword=${keyword}&page=${pageno}`, method: 'GET', token: token }),
            providesTags: ['Product'],
        }),

        setProductPublishOrUnpublish: builder.query<void, { prodId: string, prodStatus: string, token: string }>({
            query: ({ prodId, prodStatus, token }) => createQueryWithTokenRequest({ url: `/api/seller/product-status/${prodStatus}/${prodId}`, method: 'GET', token: token }),
            providesTags: ['Product'],
        }),


        fetchSellerOrderStatusDetails: builder.query<void, { token: string, id: string }>({
            query: ({ token, id }) => createQueryWithTokenRequest({ url: `/api/seller/get-order-item-details/${id}`, method: 'GET', token: token }),
        }),

        updateOrderStatusSeller: builder.mutation<void, any>({
            query: ({ data, id, token }) => createFormDataMutationRequest({ url: `/api/seller/update-order-item-status/${id}`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['Order']
        }),

        getSellerProducts: builder.query<void, { token: string, id: string }>({
            query: (id) => createQueryRequest({ url: `/api/get-seller-product/${id}`, method: 'GET' }),
        }),

        //remove later

        fetchAdmniAllOrders: builder.query<void, { token: string; status: string, pageNo: string }>({
            query: ({ token, status, pageNo }) => createQueryWithTokenRequest({ url: `/api/admin/get-all-order?orderStatus=${status}&page=${pageNo}`, method: 'GET', token: token })
        }),

        fetchAllOrders: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/fetch-seller-order`, method: 'GET', token: token })
        }),
        getLowStockProducts: builder.query<void, { pageno: string; token: string }>({
            query: ({ pageno, token }) => createQueryWithTokenRequest({ url: `/api/seller/product-low-stock?page=${pageno}`, method: 'GET', token: token }),
            providesTags: ['Product'],
        }),
    })
})


export const {
    useFetchAllProductsQuery,
    useFetchBrandsByCollectionIdQuery,
    useFetchAllSellerOrdersQuery,
    useFetchCompletedOrdersQuery,
    useFetchProcessingOrdersQuery,
    useFetchCancelledOrdersQuery,
    useFetchPendingOrdersQuery,
    useFetchSellerProductsQuery,
    useFetchSellerReturnRequestQuery,
    useSetProductPublishOrUnpublishQuery,
    useAddBrandRequestMutation,
    useMapBrandToCollectionMutation,
    useGetAllProductsQuery,
    useUpdateOrderStatusSellerMutation,
    useFetchSellerOrderStatusDetailsQuery,

    useGetSellerProductsQuery,
    //remove later
    useFetchAdmniAllOrdersQuery,
    useFetchAllOrdersQuery,
    useFetchOrderDetailsSellerQuery,
    useFetchOrderDetailsQuery,
    useGetLowStockProductsQuery
} = vendorProductApi;