import { api } from '@ekenta/app/api/apiSlice'
import { ProductByIdResponseType } from './productDataType'

export const productApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getProductByCollectionId: builder.query({
      query: ({ collectionId, pageno }) => ({
        url: `/api/product-by-collection/${collectionId}?page=${pageno}`,
        method: 'GET',
      }),
    }),
    
    getNewArrivalsProduct: builder.query({
      query: () => ({
        url: `/api/new-arrivals`,
        method: 'GET',
      }),
    }),

    getJustForYouProduct: builder.query({
      query: () => ({
        url: `/api/just-for-you`,
        method: 'GET',
      }),
    }),

    getTopRatedProduct: builder.query({
      query: () => ({
        url: `/api/top-rated`,
        method: 'GET',
      }),
    }),

    fetchSingleProductById: builder.query<ProductByIdResponseType, string>({
      query: (productId) => ({
        url: `/api/product/${productId}`,
        method: 'GET',
      }),
    }),
  }),
})
export const {
  useGetProductByCollectionIdQuery,
  useGetNewArrivalsProductQuery,
  useGetJustForYouProductQuery,
  useGetTopRatedProductQuery,
  useFetchSingleProductByIdQuery
} = productApiSlice
