import React, { Helmet } from 'react-helmet';
import { Card, Col, Row, Form, Button, Spinner } from 'react-bootstrap';
import DashboardComponent from '../component/DashboardComponent';
import DashboardLayout from '../../../hoc/Layout/Dashboard-Layout';
import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router';
import { DashboardReturnRequestPathUrl } from '../../../routes/constant';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { useFetchSingleReturnRequestQuery, useGetSellerProfileQuery, useUpdateReturnRequestMutation } from '@ekenta/redux/store/services/adminSellerApi';
import { Loader, Overlay } from '@ekenta/components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { checkIfAdmin } from '@ekenta/utils';


export default function ViewReturnRequest({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}: any) {
  const navigate = useNavigate();
  const params = useParams();

  const { token, account } = useAppSelector((state) => state.auth.authentication);
  const isAdmin = account.user ? checkIfAdmin(token, account.user.role) : null;
  const details = useFetchSingleReturnRequestQuery({ token, id: params.id });
  const [status, setStatus] = useState("PENDING");
  const [note, setNote] = useState("");
  const [updateReturnRequestStatus, getResult] = useUpdateReturnRequestMutation();

  const formik = useFormik({
    initialValues: {
      admin_notes: "",
      status: "",
    },

    onSubmit: (values) => {
      console.log(values)
      updateReturnRequestStatus({
        data: {
          status: status,
          admin_notes: note,
        },
        id: params.id,
        token: token,
      });
    },
  });

  useEffect(() => {
    if (getResult?.isSuccess) {
      toast.success(`Updated Seller status Successfully`, {
        toastId: 'success-id-toast',
      });
    }
    if (details?.isSuccess) {
      setNote(details?.data?.data?.admin_notes)
      setStatus(details?.data?.data?.status)
    }
  }, [getResult?.isSuccess, details?.isSuccess])

  // const allOrders = useFetchDailyTotalOrderQuery(token)
  // const ord = useFetchAccountingSalesAdminQuery(token)

  return (
    <DashboardLayout>
      <Helmet>
        <title>Seller Dashboard - Ekenta</title>
        <meta charSet="utf-8" />
        <meta name="title" content="QlasonOrders Dashboard Page" />
        <meta name="description" content="QlasonOrders Dashboard  Page" />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <Col className=" col-lg-11 mx-auto _listing_product_">
          <div className="mt-0">
            <hr />
            <h3 className="display_lg_header text-bold">
              <FiChevronLeft
                size={'16px'}
                onClick={() => navigate(DashboardReturnRequestPathUrl)}
              />
              View return request details
            </h3>
            <hr />
            {
              details.isLoading ?
                <Overlay bgColor="light">
                  <div
                    className="d-flex align-items-center 
                  justify-content-end mx-auto w-25 p-4"
                  >
                    <Loader withWrapper />
                  </div>
                </Overlay>
                :
                <Form noValidate onSubmit={formik.handleSubmit}>
                  <Col className="mt-3">
                    <Card className={'p-3'}>
                      <Card.Body>
                        <Row>
                          <Col lg={3}>
                            <Form.Label htmlFor="inputPassword5">ID ? </Form.Label>
                          </Col>
                          <Col lg={9}>{details?.data?.data?.id}</Col>
                        </Row>{' '}
                        <br />
                        <Row>
                          <Col lg={3}>
                            <Form.Label htmlFor="inputPassword5">Order# ?</Form.Label>
                          </Col>
                          <Col lg={9}>{details?.data?.data?.order_item_id}</Col>
                        </Row>{' '}
                        <br />
                        <Row>
                          <Col lg={3}>
                            <Form.Label htmlFor="inputPassword5">
                              Customer ?{' '}
                            </Form.Label>
                          </Col>
                          <Col lg={9}>{details?.data?.data?.user?.email}</Col>
                        </Row>{' '}
                        <br />
                        <Row>
                          <Col lg={3}>
                            <Form.Label htmlFor="inputPassword5">
                              Product ?:
                            </Form.Label>
                          </Col>
                          <Col lg={9}>{details?.data?.data?.order_item?.product?.name}</Col>
                        </Row>{' '}
                        <br />
                        <Row>
                          <Col lg={3}>
                            <Form.Label htmlFor="inputPassword5">
                              Quantity?
                            </Form.Label>
                          </Col>
                          <Col lg={9}>
                            <Form.Control
                              type="text"
                              readOnly
                              value={details?.data?.data?.quantity}
                              id="inputPassword5"
                              aria-describedby="passwordHelpBlock"
                            />
                          </Col>
                        </Row>{' '}
                        {/* <br />
                        <Row>
                          <Col lg={3}>
                            <Form.Label htmlFor="inputPassword5">
                              Quantity returned to stock?
                            </Form.Label>
                          </Col>
                          <Col lg={9}>
                            <Form.Control
                              type="text"
                              value={1}
                              id="inputPassword5"
                              aria-describedby="passwordHelpBlock"
                            />
                          </Col>
                        </Row>{' '} */}
                        <br />
                        <Row>
                          <Col lg={3}>
                            <Form.Label htmlFor="inputPassword5">
                              Return Request Reason?
                            </Form.Label>
                          </Col>
                          <Col lg={9}>
                            <Form.Control
                              value={details?.data?.data?.reason}
                              type="text"
                              readOnly
                              id="inputPassword5"
                              aria-describedby="passwordHelpBlock"
                            />
                          </Col>
                        </Row>{' '}
                        <br />

                        <Row>
                          <Col lg={3}>
                            <Form.Label htmlFor="inputPassword5">
                              Request Action?{' '}
                            </Form.Label>
                          </Col>
                          <Col lg={9}>
                            <Form.Control
                              type="text"
                              readOnly
                              value={details?.data?.data?.action}
                              id="inputPassword5"
                              aria-describedby="passwordHelpBlock"
                            />
                          </Col>
                        </Row>{' '}
                        <br />
                        <Row>
                          <Col lg={3}>
                            <Form.Label htmlFor="inputPassword5">
                              Customers Comment?
                            </Form.Label>
                          </Col>
                          <Col lg={9}>
                            <Form.Control
                              type="text"
                              readOnly
                              value={details?.data?.data?.comment}
                              id="inputPassword5"
                              aria-describedby="passwordHelpBlock"
                            />
                          </Col>
                        </Row>{' '}
                        <br />
                        <Row>
                          <Col lg={3}>
                            <Form.Label htmlFor="inputPassword5">Date</Form.Label>
                          </Col>
                          <Col lg={9}>{moment(details?.data?.data?.created_at).format('d/m/YYYY h:mm a')}</Col>
                        </Row>{' '}
                        <br />
                        <Row>
                          <Col lg={3}>
                            <Form.Label htmlFor="inputPassword5">
                              Admin Note
                            </Form.Label>
                          </Col>
                          <Col lg={9}>
                            <Form.Control
                              type="text"
                              value={note}
                              readOnly={isAdmin ? false : true}
                              onChange={(e) => setNote(e.target.value)}
                              id="inputPassword5"
                              aria-describedby="passwordHelpBlock"
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col lg={3}>
                            <Form.Label htmlFor="inputPassword5">
                              Return Request Status?
                            </Form.Label>
                          </Col>
                          <Col lg={9}>
                            <Form.Select
                              defaultValue={details?.data?.data?.status}
                              onChange={(e) =>
                                setStatus(e.target.value)
                              }>
                              <option value={"PENDING"}>PENDING</option>
                              <option value={"PROCESSING"}>PROCESSING</option>
                              <option value={"APPROVED"}>APPROVED</option>
                              <option value={"BLOCKED"}>BLOCKED</option>
                            </Form.Select>
                          </Col>
                        </Row>{' '}
                        <br />
                        {
                          isAdmin ?
                            <Button variant="primary text-white" type="submit">
                              {getResult?.isLoading ? (
                                <div className="d-flex align-items-center justify-content-center">
                                  <Spinner animation="grow" />
                                  <span className="ms-1 text-sm">
                                    <small>Please Wait...</small>
                                  </span>
                                </div>
                              ) : (
                                <span>Update</span>
                              )}
                            </Button> : null
                        }

                      </Card.Body>
                    </Card>
                  </Col>
                </Form>

            }

          </div>
        </Col>
      </DashboardComponent>
    </DashboardLayout>
  );
}
