import React from 'react'
import styled from 'styled-components'
//import ReviewCard from './common/reviewcard'
import { device } from '@ekenta/utils/devices'

const ReviewList = () => {
  return (
    <Container>
      <ul>
        {/* <li>
          <ReviewCard />
        </li> */}
      </ul>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px 10px 19.5px 0px;
  border-radius: 12px;
  background: #fff;
  font-family: var(--eke-body-font-family);
  gap: 20px;

  ul {
    width: 100%;
    list-style: none;
    padding: 0;

    li {
      width: 100%;
    }
  }
  //box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.12);

  @media ${device.lg} {
    padding: 16px 22px 19.5px 21px;
  }
`

export default ReviewList
