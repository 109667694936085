import { api } from '@ekenta/app/api/apiSlice'
import { ProductEventType } from './eventDataType';

export const eventApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
      fetchProductByEventId: builder.query<
      ProductEventType,
      { eventId: number; pageno: number; keyword: string }
    >({
      query({ eventId, pageno, keyword }) {
        return {
          url: `/api/admin/get-product-event-collection/${eventId}?page=${pageno}&keyword=${keyword}`,
          method: 'GET',
        }
      },
    }),
  }),
})
export const {
    useFetchProductByEventIdQuery
} = eventApiSlice
