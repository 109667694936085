import React from "react";
import classnames from "classnames";
import { FiCheck } from "react-icons/fi";
import styled from "styled-components";

interface HeaderPropType {
  title: string;
  active: boolean;
  isBorder: boolean;
  renderAction?: () => void;
}

function Header({
  title, active, isBorder, renderAction,
}: HeaderPropType) {
  return (
    <Container className={classnames("_heading_d", isBorder ? "borderBottom" : null)}>
      <div className="d-flex align-items-start">
        <span className={`check__icon ${active ? "active_check" : "inactive_check"} `}>
          <FiCheck fontSize={15} />
        </span>
        <h5>{title}</h5>
      </div>
      {renderAction ? renderAction() : null}
    </Container>
  );
}

const Container = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.7rem;
      padding-bottom: 0.2rem;
      margin-top: 0.3rem;
      padding-left: 1rem;
      padding-right: 1rem;

      color: var(--eke-body-text-dark-color);
      font-size: 24px !important;
      margin-bottom: 0.5rem !important;
      font-family: var(--eke-body-font-family);
      font-weight: var(--eke-font-weight-extrabold);

      .check__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          border-radius: 50% !important;
          color: var(--eke-body-text-light-color);
          margin-right: 0.8rem;

      }

      .inactive_check {
          background-color: #eee !important;
      }

      .active_check {
          background-color: var(--eke-bg-success-color); // 
      }


      h5 {
          color: var(--eke-body-text-dark-color);
          font-size: 14px !important;
          text-transform: uppercase;
          font-family: var(--eke-body-font-family);
          font-weight: var(--eke-font-weight-bold);
      }
`;

export default Header;
