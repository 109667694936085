/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, memo, Suspense, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { VscArrowSmallRight } from 'react-icons/vsc';
import { getIn, useFormikContext } from 'formik';
import { toast } from 'react-toastify';
import {
    useFetchAllStateQuery,
    useFetchCitiesByStateIdQuery,
} from '@ekenta/redux/store/services/accountApi';
import CustomSelect from '@ekenta/components/Select/CustomSelect';
import {
    Button,
    Field,
    FileUploader,
    Label,
    Loader,
    ProgressiveImg,
    Overlay,

} from '@ekenta/components';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { MyFormValues } from '../OnboardingForm/types';
import { AlertNote } from '../Reusable';
import UploadSVG from '@ekenta/assets/icons/upload-icon.svg';
import FileSVG from '@ekenta/assets/icons/file-icon.svg';
import {
    LogoPreview,
    OnboardingContentWidth,
    UploadLogoStyle,
} from '../../style';
import { BASE_URL } from '@ekenta/constants';
import { useAppSelector } from '@ekenta/redux/store/hook';
// import { FiEdit } from 'react-icons/fi';

const mapStateData = (data: any) => {
    return (
        data &&
        data.map((item: any) => ({
            value: item.id,
            label: item.name,
        }))
    );
};

const BusinessDetail = ({ nextPage }: { nextPage: () => void }) => {
    const {
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
    } = useFormikContext<MyFormValues>();

    const { token } = useAppSelector(
        (state) => state.auth.authentication,
    );

    console.log(errors, "errors")

    const [loading, setLoading] = useState(false);
    const [stateId, setStateId] = useState<null | string>(null);
    const [stateOptions, setStateOption] = useState<any>([]);
    const [cityOptions, setCityOption] = useState(null);

    const { data, isLoading, isFetching, isError } = useFetchAllStateQuery();
    const {
        data: fetchCity_data,
        isLoading: fetchCity_isLoading,
        isFetching: fetchCity_isFetching,
        isError: fetchCity_isError,
    } = useFetchCitiesByStateIdQuery({ stateId }, { skip: !stateId });

    const defaultValue = (options: any, val: string) => {
        return options ? options.find((option: any) => option.value === val) : '';
    };

    useEffect(() => {
        if (data) {
            const objs = mapStateData(data.states);
            setStateOption(objs);
        }

        if (fetchCity_data) {
            const resObj = mapStateData(fetchCity_data.states.cities);
            setCityOption(resObj);
        }
    }, [data, fetchCity_data]);

    const handleStateChange = (value: string) => {
        setStateOption([]);
        setCityOption(null);
        setStateId(value);
    };

    const uploadFile = (acceptedFiles: File[], name: string) => {
        if (acceptedFiles.length <= 0) {
            return toast.error(`File must be selected`, {
                toastId: '-upload-error-400-id-toast-error',
            });
        }
        setLoading(true);
        const formData = new FormData();
        acceptedFiles.forEach((file: File) => {
            if (file) {
                formData.append('media', file);
            }
        });

        try {
            const config = {
                method: 'POST',
                url: `${BASE_URL}/api/upload-media`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token ? token : values.token}`,
                },
                data: formData,
            };
            axios(config)
                .then(function (response) {
                    if (response.status === 200) {
                        const imageUrl = response?.data?.url;
                        setFieldValue(name, imageUrl);
                        setLoading(false);
                    }
                })
                .catch(function () {
                    setLoading(false);
                    toast.error(`Could not upload file`, {
                        toastId: '-toggle-error-400-id-toast-error',
                    });
                });
        } catch (e) {
            console.error(e);
            setLoading(false);
            toast.error(`Something went wrong`, {
                toastId: '-wrong-error-400-error',
            });
        }
    };

    const hasLogo = values.business_detail.logo !== null;
    const hasProofOfWork = values.business_detail.proof_of_work !== null;

    console.log(errors, "errors");

    return (
        <OnboardingContentWidth>
            <div className="_top_header">
                <h5>Business Details</h5>
                <p>Complete the form below to become a seller</p>
            </div>
            <div>
                <UploadLogoStyle>
                    <div>
                        {loading ? (
                            <LogoPreview>
                                <Loader />
                            </LogoPreview>
                        ) : (
                            <>
                                {hasLogo ? (
                                    <LogoPreview>
                                        <ProgressiveImg
                                            src={values.business_detail.logo}
                                            alt="image"
                                            placeholderSrc={values.business_detail.logo}
                                        />
                                        {/* <span><FiEdit /></span> */}
                                    </LogoPreview>
                                ) : (
                                    <FileUploaderComponent errors={errors} uploadFile={uploadFile} />
                                )}
                            </>
                        )}
                    </div>
                    <p className="add-logo-identifier">Add Store Logo</p>
                    <p className="description">Format allowed (jpg & png)</p>
                </UploadLogoStyle>
                <div style={{ marginTop: '12px' }}>
                    <Form.Group className="mb-3 position-relative">
                        <Field
                            id="businessname"
                            name="business_detail.businessname"
                            label="Business Name"
                            disabled={isLoading}
                            required
                            type="text"
                            size="large"
                            childOutsideLabel={false}
                            placeholder="e.g. John&Sons"
                            fieldClassName=""
                            autoComplete="off"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.business_detail.businessname}
                            isError={
                                getIn(touched, 'business_detail.businessname') &&
                                    getIn(errors, 'business_detail.businessname')
                                    ? true
                                    : false
                            }
                            error={getIn(errors, 'business_detail.businessname')}
                        />
                        <AlertNote
                            text="Choose a distinctive name for your online space: this will be the name that appears on the  Seller space
                            It is prohibited to use a registered trademark in your business name without the permission of the brand."
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative">
                        <Field
                            id="address"
                            name="business_detail.address"
                            label="Address"
                            disabled={isLoading}
                            required
                            type="text"
                            size="large"
                            childOutsideLabel={false}
                            placeholder="e.g. Address"
                            fieldClassName=""
                            autoComplete="off"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.business_detail.address}
                            isError={
                                getIn(touched, 'business_detail.address') &&
                                    getIn(errors, 'business_detail.address')
                                    ? true
                                    : false
                            }
                            error={getIn(errors, 'business_detail.address')}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative">
                        <Label required type="text" text="Country" />
                        <div>
                            <Form.Select
                                name="business_detail.country"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.business_detail.country}
                                isInvalid={!!getIn(errors, 'business_detail.country')}
                            >
                                <option value="">Select Country</option>
                                <option value="nigeria">Nigeria</option>
                            </Form.Select>

                            {getIn(touched, 'business_detail.country') &&
                                getIn(errors, 'business_detail.country') ? (
                                <div className="invalid-fbk">
                                    {getIn(errors, 'business_detail.country')}
                                </div>
                            ) : null}
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative">
                        <Label required type="text" text="State" />
                        <div>
                            <Suspense fallback={<Loader />}>
                                <CustomSelect
                                    className="custom-select"
                                    placeholder="Select State"
                                    isLoading={isLoading && isFetching}
                                    noOptionsMessage={() =>
                                        isError ? 'Could not load, refresh' : 'No State Found'
                                    }
                                    value={defaultValue(
                                        stateOptions,
                                        values.business_detail.state.id,
                                    )}
                                    options={stateOptions}
                                    onBlur={handleBlur}
                                    onChanged={(value: any) => {
                                        setFieldValue('business_detail.state', {
                                            id: value.value,
                                            name: value.label,
                                        });
                                        handleStateChange(value.value);
                                    }}
                                />

                                {getIn(errors, 'business_detail.state') ? (
                                    <div className="invalid-fbk">
                                        {getIn(errors, 'business_detail.state')}
                                    </div>
                                ) : null}
                            </Suspense>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3 position-relative">
                        <Label required type="text" text="City/Town" />
                        <div>
                            <Suspense fallback={<Loader />}>
                                <CustomSelect
                                    isDisabled={cityOptions ? false : true}
                                    className="custom-select"
                                    placeholder="Select City"
                                    isLoading={fetchCity_isLoading && fetchCity_isFetching}
                                    noOptionsMessage={() =>
                                        fetchCity_isError
                                            ? 'Could not load, refresh'
                                            : 'No City Found'
                                    }
                                    value={
                                        cityOptions
                                            ? defaultValue(
                                                cityOptions,
                                                values.business_detail.city_or_town.id,
                                            )
                                            : ''
                                    }
                                    options={cityOptions ? cityOptions : []}
                                    onBlur={handleBlur}
                                    onChanged={(value: any) => {
                                        setFieldValue('business_detail.city_or_town', {
                                            id: value.value,
                                            name: value.label,
                                        });
                                    }}
                                />
                                {getIn(errors, 'business_detail.city_or_town') ? (
                                    <div className="invalid-fbk">
                                        {getIn(errors, 'business_detail.city_or_town')}
                                    </div>
                                ) : null}
                            </Suspense>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3 position-relative">
                        <Field
                            id="business_phone_number"
                            name="business_detail.business_phone_number"
                            label="Business Phone Number "
                            disabled={isLoading}
                            type="number"
                            required
                            childOutsideLabel={false}
                            placeholder="e.g. +234805282837924"
                            numberFieldPrefix={'+234'}
                            autoComplete="off"
                            onChange={(e) => {
                                setFieldValue(
                                    'business_detail.business_phone_number',
                                    e.formattedValue,
                                );
                            }}
                            value={values.business_detail.business_phone_number}
                            isError={
                                values.business_detail.business_phone_number &&
                                    getIn(errors, 'business_detail.business_phone_number')
                                    ? true
                                    : false
                            }
                            error={getIn(errors, 'business_detail.business_phone_number')}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 position-relative">
                        <Field
                            id="business_registration_number"
                            name="business_detail.business_registration_number"
                            label="Identification Number"
                            disabled={isLoading}
                            type="text"
                            size="large"
                            childOutsideLabel={false}
                            placeholder="e.g. 30026329362"
                            fieldClassName=""
                            autoComplete="off"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.business_detail.business_registration_number}
                            isError={
                                getIn(errors, 'business_detail.business_registration_number')
                                    ? true
                                    : false
                            }
                            error={getIn(
                                errors,
                                'business_detail.business_registration_number',
                            )}
                        />
                        <AlertNote text="For example: NIN-1234567890 or BVN-1234567890 or CAC-1234567890" />
                    </Form.Group>

                    <Form.Group className="mb-3 position-relative">
                        <Label text="Profile of Identification File" type="text" required />
                        <FileUpload status={hasProofOfWork ? "true" : "false"}>
                            <FileUploader
                                maxSize={10000000}
                                placeholder={UploadSVG}
                                requiredText=""
                                acceptFileType="image/*"
                                acceptFileTypeList={['image']}
                                fileType="image"
                                maxFiles={1}
                                multiple={false}
                                errors={errors}
                                showSucessAlert={false}
                                name="business_detail.proof_of_work"
                                callback={acceptedFiles => uploadFile(acceptedFiles, "business_detail.proof_of_work")}
                            />
                            {loading && (<Overlay className="overlay" position="absolute" bgColor="light">
                                <Loader
                                    isCenter
                                    size="large"
                                    color="gray"
                                />
                            </Overlay>)}
                            {hasProofOfWork ? (
                                <AlertNote status="success" text="File added successfully" />) : (
                                <AlertNote text="You can upload a copy of your NIN, BVN or CAC. This is to help us verify" />
                            )}
                        </FileUpload>
                    </Form.Group>

                    {/* <Button
                            name="Back"
                            shadow={false}
                            bgVariant="secondary"
                            width="w-100"
                            disabled={ek_isAuth}
                            textVariant="light"
                            prefixIcon={<VscArrowSmallLeft />}
                            type="button"
                            onClick={prevPage}
                        /> */}
                    <Button
                        name="Proceed"
                        textVariant="light"
                        width="w-100"
                        className="PageStep__Btn"
                        disabled={
                            !values.business_detail.city_or_town ||
                            !values.business_detail.address ||
                            !values.business_detail.businessname ||
                            !values.business_detail.business_phone_number ||
                            !values.business_detail.proof_of_work ||
                            getIn(errors, 'business_detail.sellername') ||
                            getIn(errors, 'business_detail.address') ||
                            getIn(errors, 'business_detail.city_or_town') ||
                            getIn(errors, 'business_detail.business_phone_number') ||
                            getIn(errors, 'business_detail.proof_of_work')
                        }
                        shadow={false}
                        suffixIcon={<VscArrowSmallRight />}
                        type="button"
                        onClick={nextPage}
                    />
                </div>
            </div>
        </OnboardingContentWidth>
    );
};

interface FileUploaderProps {
    errors: any;
    uploadFile: (acceptedFiles: File[], name: string) => void;
}

const FileUploaderComponent: React.FC<FileUploaderProps> = ({ errors, uploadFile }) => {
    return (
        <div className="uploader">
            <FileUploader
                maxSize={10000000}
                placeholder={FileSVG}
                requiredText=""
                acceptFileType="image/*"
                acceptFileTypeList={['image']}
                fileType="image"
                maxFiles={1}
                multiple={false}
                errors={errors}
                name={'logo'}
                callback={acceptedFiles => uploadFile(acceptedFiles, "business_detail.logo")}
            />
        </div>
    );
};

const FileUpload = styled.div<{ status: string }>`
    position: relative !important;
    height: 250px;

    .overlay{
        height: 100%;
    }

    ${props => props.status === "true" && css`
        .dropdiv{
            border-color: #62d58c !important;
        }

        .alertnote-div{
            align-items: center;
            background-color: #eaf3e6;
            
            span{
                color: var(--eke-body-text-success-color);
            }
            .IconStyle{
                padding-top: 0.3rem;
                svg{
                    stroke: var(--eke-body-text-success-color) !important;
                }
            }
        }
    `}
`;

export default memo(BusinessDetail);
