import { Switch } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import { Button, Card, Col, Spinner, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FaPlus } from 'react-icons/fa';
import { FiCheckCircle, FiEdit, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import TableComponent from '../../../../components/Dashboard/components/TableComponent';
import DashboardComponent from '../../component/DashboardComponent';
import DashboardLayout from '../../../../hoc/Layout/Dashboard-Layout';
import { useAppSelector } from '../../../../redux/store/hook';
import { toast } from "react-toastify";
import {
  useDeleteSubCollectionCategoryMutation,
  useFetchAdminAllSubCollectionCategoryQuery,
  useFetchAllCollectionsQuery,
  useFetchAllSubCollectionCategoriesQuery,
} from '../../../../redux/store/services/collectionApi';
import {
  AddCollectionUrl,
  AddSubCollectionCategoryUrl,
} from '../../../../routes/constant';
import { BASE_URL } from '@ekenta/constants';
import axios from 'axios';

export default function AdminSubCollectionCategories({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}: any) {
  const navigate = useNavigate();
  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const result = useFetchAdminAllSubCollectionCategoryQuery(token);
  const [_pageSize, setPageSize] = useState(5);
  const [
    deleteCollectionCall,
    { data: deleteCollection_data, isSuccess: deleteCollection_isSuccess },
  ] = useDeleteSubCollectionCategoryMutation();
  const [loading, setLoading] = useState(false);

  const handleDeleteCollection = async (id: any) => {
    setLoading(true)
    deleteCollectionCall({ subCollectionCategoryId: id, token })
  }

  const toggleFunction = useCallback((id: string, data: any) => {
    const config = {
      method: 'post',
      url: `${BASE_URL}/api/admin/update-sub-collection-category/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    setLoading(true);
    axios(config)
      .then(function (res) {
        setLoading(false);
        toast.success(`${res.data.message}`, {
          toastId: "-coll-toggle-success-id-toast",
        });
        result.refetch()
      })
      .catch(function () {
        setLoading(false);
        toast.error(`Something went wrong`, {
          toastId: "-coll-error-400-id-toast-error",
        });
        result.refetch()

      });
  }, [result]);

  useEffect(() => {
    if (deleteCollection_isSuccess) {
      // window.location.reload();
      setLoading(false);
      toast.success(`${deleteCollection_data?.message}`, {
        toastId: "-coll-toggle-success-id-toast",
      });
      result.refetch()
    }
  }, [deleteCollection_isSuccess]);

  return (
    <DashboardLayout>
      <Helmet>
        <title>Seller Dashboard - Qlason</title>
        <meta charSet="utf-8" />
        <meta name="title" content="QlasonAll Product Page" />
        <meta name="description" content="QlasonAll Product  Page" />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <Col className=" col-lg-11 mx-auto _listing_product_ mt-1 px-2">
          <div className="mt-0">
            <h3 className="display_lg_header">Sub-Collection Category</h3>
            <Col className="mt-4">
              <Button
                className="mb-3 text-white"
                onClick={() => navigate(AddSubCollectionCategoryUrl)}
              >
                <FaPlus /> Add New Sub-Collection Category
              </Button>
              <Card>
                <Card.Body>
                  <Table striped hover className="custom_table_listing">
                    <thead>
                      <tr>
                        <th>Picture</th>
                        <th>Name</th>
                        <th>Collection</th>
                        <th>Visibility</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!result.isSuccess ? (
                        <div>Loading</div>
                      ) : (
                        result?.data?.collections?.map((e: any, i: any) => {
                          if (e?.deleted == null || e?.deleted == false) {
                            return (
                              <tr key={i}>
                                <td>
                                  <div className="picture__thumb">
                                    <img
                                      className="img-thumbnail rounded"
                                      src={e?.image_url}
                                    />
                                  </div>
                                </td>
                                <td>{e?.name}</td>
                                <td>{e?.collectionName}</td>
                                <td>
                                  <Switch
                                    defaultChecked={e?.visibility}
                                    onChange={() => {
                                      const data = {
                                        name: e?.name,
                                        description: e?.description,
                                        collection_id: e?.collection_id,
                                        visibility: !e?.visibility ? true : false,
                                      }
                                      toggleFunction(e?.id, data);
                                    }}
                                  />
                                </td>
                                <td>
                                  <FiEdit
                                    className="cursor-pointer text-primary"
                                    fontSize={15}
                                    onClick={() =>
                                      navigate(
                                        `/admin/sub-collection-category/${e?.id}`,
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  {
                                    loading ? <Spinner animation="grow" /> :
                                      <FiTrash2 onClick={() => handleDeleteCollection(e?.id)} className="cursor-pointer" fontSize={15} />
                                  }
                                </td>
                              </tr>
                            );
                          }
                        })
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Col>
      </DashboardComponent>
    </DashboardLayout>
  );
}
