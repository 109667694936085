import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { CartInitialState } from "../initialState";


export const cartSlice = createSlice({
    name: 'cart',
    initialState:CartInitialState,
    reducers: {
        addToCart: (state, action:PayloadAction<any>) => { 
            if(action.payload.stock > 0){
                const tempProduct = {product: { ...action.payload}, quantity: 1}
                state.cartItems.push(tempProduct);
                toast.success(`${action.payload.name} was added to your cart list`,{
                    toastId: "local-cart-added-list-id-toast-success",
                    });
            }else{
                toast.error('Product is out of stock',{
                    toastId: "local-addcart-out-of-stock-added-list-id-toast-success",
                });       
            }
        },
        // increaseCartQty: (state, action:PayloadAction<any>) => { 
        //    const itemIndex = state.cartItems.findIndex((item:any) => item.product.id === action.payload.id);
        //    if (itemIndex >= 0) {
        //         if(state.cartItems[itemIndex].quantity < action.payload.stock &&
        //             state.cartItems[itemIndex].quantity !== action.payload.stock){
        //             state.cartItems[itemIndex].quantity += 1;
        //             toast.success('Quantity added to your cart list',{
        //                 toastId: "local-cart-added-list-id-toast-success",
        //               });
        //             }else{
        //                 toast.error('Product is out of stock',{
        //                     toastId: "local-cart-out-of-stock-added-list-id-toast-error",
        //                 });       
        //         }
        //    }

        // },
        // decreaseCartQty: (state, action:PayloadAction<any>) => {
        //     const decrease_itemIndex = state.cartItems.findIndex((item:any) => item.product.id === action.payload.id);
        //     if (state.cartItems[decrease_itemIndex].quantity > 1) {
        //          state.cartItems[decrease_itemIndex].quantity -= 1; 
        //          toast.success('Quantity reduced in your cart list',{
        //             toastId: "local-cart-added-list-id-toast-success",
        //           });
        //     }
        // },
        removeFromCart: (state, action:PayloadAction<any>) => {
           const nextCartItems = state.cartItems.filter((cartItem:any) => cartItem.product.id !== action.payload.id)
           state.cartItems = nextCartItems;
           toast.success('Product remove from cart list',{
            toastId: "prod-cart-remove-list-id-toast-success",
          });
        },
        getTotals: (state) => {
            const {totalCost, quantity} = state.cartItems.reduce((cartTotal, cartItem) => {
                const itemTotal = cartItem.product.price * cartItem.quantity;
                cartTotal.totalCost += itemTotal;
                cartTotal.quantity += cartItem.quantity;
                return cartTotal;
            }, {
                totalCost: 0,
                quantity: 0
            });

            state.cartTotalQuantity = quantity;
            state.cartTotalAmount = totalCost;
        },

        removeLocalCart:(state)=>{
            state.cartItems = [];
        },
    },
})

export const { addToCart, removeFromCart, removeLocalCart, getTotals } = cartSlice.actions;

export  default cartSlice.reducer;

