import React, { FC } from 'react'
import { Col } from 'react-bootstrap'
import classnames from 'classnames'
import {
  Content,
  ProductCardItem,
  Title,
  VStack,
  WrapContainer,
  Image,
  NOStock,
  WrapRating,
  FlexRate,
  Divider,
  ConditionBadge,
  WrapPrices,
  PricePerKg,
  NoDiscount,
  CenterPlay,
} from './style'
import { productBaseUrl } from '@ekenta/routes/constant'
import { formatCurrency, setDiscountPercent, slugifyText } from '@ekenta/utils'
import { ReactComponent as Ratings } from '@ekenta/assets/icons/ratings_icon.svg'
import { ProductVariationType } from '@ekenta/redux/store/features/product/productDataType'
import AddToCart from './AddToCart'
import StockEmpty from '../StockEmpty/StockEmpty'
import Typography from '../Typography/Typography'
import { CircleIcon, PlayIcon } from '../icons/icons'
import RefreshLink from '../RefreshLink/RefreshLink'
import Tag from '../Tag/Tag'

interface ProductProps {
  productId: string
  productName: string
  imageUrl: string
  videoUrl: string
  price: number
  isVariant: number
  stockCount: number
  isShadow: boolean
  condition: string
  aggregateRating: number
  maxPrice: number
  discount: number
  adminDiscount: number
  productVariation: ProductVariationType[]
  render?: JSX.Element
}

const ProductCard: FC<ProductProps> = (props) => {
  const {
    productId,
    productName,
    price,
    isVariant,
    productVariation,
    stockCount,
    condition,
    aggregateRating,
    maxPrice,
    discount,
    adminDiscount,
    imageUrl,
    videoUrl,
    isShadow,
    render,
  } = props

  const slug = slugifyText(productName?.toLowerCase(), productId)
  return (
    <WrapContainer
      as={Col}
      xs={6}
      sm={6}
      md={4}
      lg={3}
      className="product__card__col"
      style={{ height: '100%' }}
    >
      <ProductCardItem
        className={classnames(isShadow ? 'boxShadow' : 'card-noBorder')}
      >
        {stockCount >= 1 ? (
          <div className="_add_cart_button">
            <AddToCart
              price={price}
              productId={productId}
              productName={productName}
              is_variant={isVariant}
              productVariation={productVariation}
              stock={stockCount}
            />
          </div>
        ) : (
          <StockEmpty className="StockEmptyWrapper_float" />
        )}
        <RefreshLink
          to={`${productBaseUrl}/${slug}`}
          data-name={productName}
          data-price={price}
          data-brand=""
          data-category=""
          data-track-onclick="false"
        >
          <VStack>
            <Image>
              <img src={imageUrl} alt={productName} />
              {videoUrl && (
                <CenterPlay>
                  <span className="center-button play-btn">
                    <PlayIcon />
                    <CircleIcon />
                  </span>
                </CenterPlay>
              )}
            </Image>
            <Content>
              <Title as={Typography}>{productName.toLowerCase()}</Title>
              <NOStock>
                {stockCount} item{stockCount > 1 ? 's' : ''} in stock
              </NOStock>
              <WrapRating>
                <FlexRate>
                  <Ratings />
                  <span>{aggregateRating}</span>
                </FlexRate>
                <Divider />
                <ConditionBadge>
                  <div className="pr_condition_sec">
                    {condition == 'new' ? (
                      <span className="span__new">New</span>
                    ) : (
                      <span className="span__used">Used</span>
                    )}
                  </div>
                </ConditionBadge>
              </WrapRating>
              <WrapPrices>
                <div className="card-image-wrapper"></div>
                <PricePerKg>
                  <span className="main-price">
                    {formatCurrency(price || 0)}
                  </span>
                  {((discount || adminDiscount) && discount > 0) ||
                  adminDiscount > 0 ? (
                    <span>{formatCurrency(maxPrice || 0)}</span>
                  ) : null}
                </PricePerKg>
                <NoDiscount>
                  {adminDiscount == null ? (
                    discount > 0 ? (
                      <Tag
                        numberOf={setDiscountPercent(discount)}
                        className="Discount_percent"
                      />
                    ) : null
                  ) : (
                    <Tag
                      numberOf={setDiscountPercent(adminDiscount)}
                      className="Discount_percent"
                    />
                  )}
                </NoDiscount>
              </WrapPrices>
            </Content>
          </VStack>
        </RefreshLink>
        {render ? render : null}
      </ProductCardItem>
    </WrapContainer>
  )
}

export default ProductCard
