import React from 'react'

const SvgLogo = ({
	width = "1rem",
	height = "1rem",
	className
}: {
	className?: string,
	width?: string;
	height?: string
}) => (
	<svg className={className} width={width} height={height} viewBox="0 0 65 48" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Group">
			<path id="Vector" d="M63.4712 6.80095C63.6473 7.27253 62.7491 10.7828 59.9153 15.2194C54.3645 23.9095 45.2862 29.7804 35.9736 32.1911C32.6995 33.0386 29.3022 33.5846 25.9362 33.2783C23.3386 33.0419 20.127 32.4173 18.0508 30.529C18.0508 30.529 27.0256 43.0537 47.8663 37.1349C72.2163 30.2198 63.4716 6.80178 63.4712 6.80078V6.80095Z" fill="url(#paint0_linear_2033_42764)" />
			<g id="Group_2">
				<path id="Vector_2" d="M34.6928 43.5288C34.6928 45.8212 32.8343 47.6799 30.5417 47.6799C28.2491 47.6799 26.3906 45.8214 26.3906 43.5288C26.3906 41.2362 28.2491 39.3777 30.5417 39.3777C32.8341 39.3777 34.6928 41.2362 34.6928 43.5288Z" fill="url(#paint1_linear_2033_42764)" />
				<path id="Vector_3" d="M53.6682 43.5288C53.6682 45.8212 51.8097 47.6799 49.5171 47.6799C47.2245 47.6799 45.3662 45.8214 45.3662 43.5288C45.3662 41.2362 47.2245 39.3777 49.5171 39.3777C51.8097 39.3777 53.6682 41.2362 53.6682 43.5288Z" fill="url(#paint2_linear_2033_42764)" />
			</g>
			<path id="Vector_4" d="M0 0.320068C0 0.320068 7.06534 2.1126 9.19219 10.2575C11.1983 17.94 11.6219 26.6185 22.9209 31.1261C34.22 35.6338 41.9117 28.1415 41.9117 28.1415C41.9117 28.1415 33.5432 32.1315 26.4077 23.1432C18.8543 13.628 21.7722 1.17461 0 0.320068Z" fill="url(#paint3_linear_2033_42764)" />
			<path id="Vector_5" d="M50.7475 21.4096C51.391 21.0521 51.8892 19.47 52.1234 18.8179C52.4606 17.8785 52.6623 16.9111 52.7772 15.9214C53.2718 11.6652 52.2397 7.46649 50.6865 3.52612C51.5312 3.66688 52.3759 3.80763 53.2207 3.94823C54.503 4.16194 55.8217 4.38899 56.9278 5.07195C59.9937 6.96476 58.4082 11.4042 57.2805 14.0486C55.9583 17.1491 53.7037 19.7675 50.7475 21.4096Z" fill="url(#paint4_linear_2033_42764)" />
		</g>
		<defs>
			<linearGradient id="paint0_linear_2033_42764" x1="41.8539" y1="6.8027" x2="41.5503" y2="38.7251" gradientUnits="userSpaceOnUse">
				<stop stopColor="#DB232A" />
				<stop offset={1} stopColor="#E48923" />
			</linearGradient>
			<linearGradient id="paint1_linear_2033_42764" x1="30.5998" y1="39.3782" x2="30.4837" y2="47.6794" gradientUnits="userSpaceOnUse">
				<stop stopColor="#DB232A" />
				<stop offset={1} stopColor="#E48923" />
			</linearGradient>
			<linearGradient id="paint2_linear_2033_42764" x1="49.5753" y1="39.3782" x2="49.4592" y2="47.6794" gradientUnits="userSpaceOnUse">
				<stop stopColor="#DB232A" />
				<stop offset={1} stopColor="#E48923" />
			</linearGradient>
			<linearGradient id="paint3_linear_2033_42764" x1="21.2493" y1="0.32201" x2="20.9023" y2="32.5698" gradientUnits="userSpaceOnUse">
				<stop stopColor="#DB232A" />
				<stop offset={1} stopColor="#E48923" />
			</linearGradient>
			<linearGradient id="paint4_linear_2033_42764" x1="54.7539" y1="3.5272" x2="54.1968" y2="21.3947" gradientUnits="userSpaceOnUse">
				<stop stopColor="#DB232A" />
				<stop offset={1} stopColor="#E48923" />
			</linearGradient>
		</defs>
	</svg>

)

export default SvgLogo;