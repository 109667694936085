/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useCallback, useEffect } from 'react'
import { useFormik } from 'formik'
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { CardBox, HelmetHeader } from '@ekenta/components'
import { useAppSelector } from '@ekenta/redux/store/hook'
import { useFetchAllAddressesQuery } from '@ekenta/redux/store/services/accountApi'
import {
  useFetchLogisticPriceMutation,
  usePlaceOrderCheckoutMutation,
} from '@ekenta/redux/store/services/checkoutApi'
import { cartPathUrl } from '@ekenta/routes/constant'
import checkoutFormValidation from '@ekenta/validations/schemas/checkout-p.schema'
import { withCheckoutLayout } from '@ekenta/hoc'
import ReviewOrder from './components/ReviewOrder'
import DeliveryMethod from './components/DeliveryMethod/DeliveryMethod'
import DeliveryAddress from './components/DeliveryAddress/DeliveryAddress'
import PaymentOption from './components/PaymentOption/PaymentOption'

import './index.scss'

interface FormValueType {
  address: string
  deliveryChannel: string
  deliveryCharge: string | null
  deliveryDate: string
  paymentChannel: string
  logistics: string
}

function CheckoutOnePage() {
  const { token, shippingAddress } = useAppSelector(
    (state) => state.auth.authentication
  )

  // const [isChangeAddress, setIsChangeAddress] = useState(false);
  // const [selectedAddress, setSelectedAddress] = useState({
  //   id: "",
  //   homeAddress: "",
  //   stateId: "",
  //   cityId: "",
  //   defaultAddress: true,
  //   cityName: { id: "" },
  //   state: {
  //     id: "",
  //   },
  // });

  const [placeOrderCheckout, { data, isLoading, isError, error, isSuccess }] =
    usePlaceOrderCheckoutMutation()

  const [fetchLogisticPrice, getLogisticResult] =
    useFetchLogisticPriceMutation()
  const getAllAddress = useFetchAllAddressesQuery(token)

  const paymentOptionEnums = {
    PAYSTACK: 'PAYSTACK',
    FLUTTERWAVE: 'FLUTTERWAVE',
    POD: 'PAY_ON_DELIVERY',
    THEPEER: 'THEPEER',
  }

  useEffect(() => {
    if (
      getLogisticResult?.isError &&
      getLogisticResult?.error.status === 'FETCH_ERROR'
    ) {
      toast.error('Something went wrong', {
        toastId: 'error-getGIG-id-toast-error',
      })
    }
    if (getLogisticResult?.isError && getLogisticResult?.error.status === 500) {
      toast.error(`Something went wrong`, {
        toastId: 'error-getG-500-id-toast-error',
      })
    }

    if (isError && error.status === 'FETCH_ERROR') {
      toast.error('Something went wrong', {
        toastId: 'error-isErr-id-toast-error',
      })
    }

    if (isError && error.status === 400) {
      mapErrors(error.data.errors)
    }
  }, [getLogisticResult?.isError, isError])

  function mapErrors(errors: string[]) {
    for (const [key, value] of Object.entries(errors)) {
      toast.error(`${value[0]}`, {
        toastId: `checkout-${key}-toast-error`,
      })
    }
  }

  const formik = useFormik<FormValueType>({
    initialValues: {
      address: '',
      deliveryChannel: '',
      deliveryCharge: null,
      deliveryDate: '',
      paymentChannel: '',
      logistics: '',
    },
    validationSchema: checkoutFormValidation(),
    onSubmit: (values) => {
      if (!values.deliveryChannel) {
        toast.error(`${formik.errors.deliveryChannel}`, {
          toastId: 'delivery-error-defaultAddress-id-toast-error',
        })
      }

      const data = {
        address_id: values.address,
        delivery_type: values.deliveryChannel,
        delivery_charge: values.deliveryCharge,
        payment_channel: values.paymentChannel,
        logistics: values.logistics,
      }

      placeOrderCheckout({ data, token })
    },
  })

  useEffect(() => {
    if (shippingAddress) {
      handleSendAddress(shippingAddress.id)
      if (formik.values.deliveryChannel === 'DOOR_DELIVERY') {
        sendDataToLogistics(shippingAddress.id)
      }
    }
  }, [formik.values.deliveryChannel, shippingAddress])

  // useEffect(() => {
  //   if (isSaveChanged && getLogisticResult?.isSuccess && changeAddress_isSuccess) {
  //     handleSendAddress({ ...changeAddress_data });
  //     setisSaveChanged(false);

  //     if (formik.values.deliveryChannel === "DOOR_DELIVERY" && cartItems.data) {
  //       sendDataToLogistics(
  //         cartItems.data,
  //         changeAddress_data.cityName,
  //         changeAddress_data.homeAddress,
  //       );
  //     }
  //   }
  // }, [
  //   isSaveChanged,
  //   changeAddress_isSuccess,
  //   getLogisticResult?.isSuccess,
  //   formik.values.deliveryChannel,
  // ]);

  const handleSendAddress = useCallback((addressId) => {
    formik.setFieldValue('address', addressId)
  }, [])

  const sendDataToLogistics = (id: string) => {
    const data = {
      address_id: id,
    }
    fetchLogisticPrice({ data, token })
  }

  return (
    <>
      <HelmetHeader
        title="Checkout Process"
        description="Qlason Order and Checkout Page"
      />
      <div className="d_checkout_wrapper">
        <div className="checkout_col__v">
          <Container>
            <Col lg={12} className="checkout__mainBar mt-4">
              <Row>
                <Col className="checkout__stepper__pro" xs={12} lg={8}>
                  <Col lg={12} className="checkout__tit_">
                    <CardBox className="card" padding={false}>
                      <div className="mt-0 _ct_wrapper">
                        <div className="__sub_wrapper">
                          <Breadcrumb className="_p_">
                            <Breadcrumb.Item>
                              <Link to={cartPathUrl}>Cart</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item className="active_breadcrumb" />
                          </Breadcrumb>
                        </div>
                      </div>
                      <h5 className="_heading p-3 pt-2 pb-2">Checkout</h5>
                    </CardBox>
                  </Col>

                  <DeliveryAddress
                    values={formik.values}
                    getGIGResult={getLogisticResult}
                    isLoading={getAllAddress?.isLoading}
                  />

                  {/* {!profile_data?.phoneNumber || !defaultaddress_data ? (
                    <CustomAlert show={true} variant="warning">
                      <span className="Warning__Note">
                        <b>Note:</b> <br />
                        Delivery address and phone number are required for the checkout process, please provide a
                        {!defaultaddress_data ? " valid address " : null}
                        {!profile_data?.phoneNumber ? " phone number " : null}
                      </span>
                    </CustomAlert>
                  ) : null} */}

                  <Fragment>
                    {/* DELIVERY METHOD */}
                    <div>
                      <DeliveryMethod
                        handleBlur={formik.handleBlur}
                        setFieldValue={formik.setFieldValue}
                        errors={formik.errors}
                        values={formik.values}
                        touched={formik.touched}
                        isSuccess={isSuccess}
                        // isLoading={isLoading}
                        loading={getAllAddress?.isLoading}
                        getGIGResult={getLogisticResult}
                        shippingAddress={shippingAddress}
                        data={data}
                      />
                    </div>

                    {/* PAYMENT OPTION SECTION */}
                    <div>
                      <PaymentOption
                        handleSubmit={formik.handleSubmit}
                        isLoading={getAllAddress?.isLoading}
                        values={formik.values}
                        handleBlur={formik.handleBlur}
                        setFieldValue={formik.setFieldValue}
                        errors={formik.errors}
                        isSuccess={isSuccess}
                        orderLoading={isLoading}
                        paymentOptionEnums={paymentOptionEnums}
                      />
                    </div>

                    {/* ORDER,SHIPMENT AND PAYMENT  SECTION */}
                    <div>
                      <ReviewOrder
                        orderSuccess={isSuccess}
                        orderData={data}
                        values={formik.values}
                        isLoading={getAllAddress?.isLoading}
                        paymentOptionEnums={paymentOptionEnums}
                        // generateReference={geneRef_data}
                      />
                    </div>
                  </Fragment>
                  {/* ) : null} */}
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </div>
    </>
  )
}

export default withCheckoutLayout(CheckoutOnePage)
