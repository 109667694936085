/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, useEffect, useState } from 'react'
import { Card, Col, Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import classnames from 'classnames'
import NumberFormat from 'react-number-format'
import { setDiscountPercent, slugifyText } from '../../utils'

import { productBaseUrl } from '../../routes/constant'
import VideoPlayerWithoutControls from '../VideoPlayer/VideoPlayerWithoutControls'
import { useAppSelector } from '../../redux/store/hook'
import LiveIconComp from '../LiveCommerce/LiveIconComp'
import StockEmpty from '../StockEmpty/StockEmpty'
import Tag from '../Tag/Tag'
import SavedButton from './SavedItemButtons/SavedButton'
import RemoveSaved from './SavedItemButtons/RemoveSaved'
import Typography from '../Typography/Typography'
import RefreshLink from '../RefreshLink/RefreshLink'
import { ReactComponent as Ratings } from '@ekenta/assets/icons/ratings_icon.svg'
import './ProductCardComponent.scss'
import AddToCart from '../ProductCard/AddToCart'
//import CartBtn from "../Carts/CartBtn";

interface ProductCardComponent {
  productname: string
}

export const mapCartDataReturnLocalQuantity = (
  cartData: any,
  productid: number
) => {
  return cartData
    ? cartData.find((item: any) => {
        if (item.product.id === productid) {
          return item
        }
      })
    : null
}

export default function ProductCardComponent({
  cartItem,
  condition,
  videoUrl,
  thumbnailUrl,
  product,
  productId,
  productname,
  defaultprice,
  layoutView,
  maxPrice,
  isShadow,
  discount,
  isFavorite,
  render,
  adminDiscount = null,
}: any) {
  const { ek_isAuth } = useAppSelector((state) => state.auth.authentication)
  const { cartItems: _cartItems } = useAppSelector((state) => state.cart.cart)

  // const [mysaved, setMySaved] = useState(savedItems);
  // const allSaved = (y: any) => {
  //   const x = [];
  //   for (let i = 0; i < y.length; i++) {
  //     x.push(y[i].id);
  //   }
  //   setMySaved(x);
  // };
  const [_cartItem, setCartItem] = useState<any>(null)
  const [localCartItem, setLocalCartItem] = useState<any>(null)

  const _slugProduct = productId
    ? slugifyText(productname.toLowerCase(), productId)
    : '/'

  // useEffect(() => {
  //   if (toFavorite_isSuccess) {
  //     toast.success("Favourite list updated!", {
  //       toastId: "favourite-id-toast-error",
  //     });

  //   }
  // }, [toFavorite_isSuccess]);

  useEffect(() => {
    if (ek_isAuth) {
      if (cartItem) {
        setCartItem(cartItem)
      } else {
        setCartItem(null)
      }
    } else {
      const items = mapCartDataReturnLocalQuantity(_cartItems, productId)
      setLocalCartItem(items)
    }
  }, [ek_isAuth, cartItem, _cartItems])

  return (
    <>
      <Col
        xs={6}
        sm={6}
        md={4}
        lg={`${layoutView === 'grid' ? '3' : '12'}`}
        className="product__card__col position-relative"
        style={{ height: '100%' }}
      >
        <Card className={classnames(isShadow ? 'boxShadow' : 'card-noBorder')}>
          {/* {discount && discountPercentage > 0 ?
              <Tag
                numberOf={setDiscountPercent(discountPercentage)}
                className="priceOff"
              /> : (
                <>
                  {parseInt(maxPrice) > parseInt(defaultprice) ? (
                    <Tag
                      numberOf={calculatePercentage(maxPrice, minPrice)}
                      className="priceOff"
                    />
                  ) : null}
                </>
              )} */}
          {product.user ? product.user.isSellerLive && <LiveIconComp /> : null}

          {/* {SavedItem Buttom} */}
          <SavedItem>
            {!isFavorite ? (
              <SavedButton productId={productId} />
            ) : (
              <RemoveSaved productId={productId} />
            )}
          </SavedItem>

          {product && product.stock >= 1 ? (
            <div className="_add_cart_button">
              <AddToCart
                price={defaultprice}
                productId={productId}
                productName={productname}
                is_variant={product?.is_variant}
                stock={product?.stock}
                productVariation={product?.productVariation}
              />
            </div>
          ) : (
            <StockEmpty className="StockEmptyWrapper_float" />
          )}

          {/* {product && product.stock >= 1 ? (
            <div className="_add_cart_button">
              {ek_isAuth && token ? (
                // <CartBtn
                //   productId={productId}
                //   name={productname}
                //   stock={product.stock}
                // />
                <>
                  {cartItem && cartItem.quantity >= 1 ? (
                    <div className="group--components">
                      <QuantityCartButton
                        cartItems={cartItem}
                        cartItemId={cartItem.id}
                        className="quantity--picker"
                      />
                    </div>
                  ) : (
                    <>
                      <AddCartButton
                        productId={productId}
                        productname={productname}
                        product={product}
                        name="Add"
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  {localCartItem && localCartItem.quantity >= 1 ? (
                    <div className="group--components">
                      <QuantityCartButton
                        cartItems={localCartItem}
                        cartItemId={
                          localCartItem ? localCartItem.product.id : ''
                        }
                        className="quantity--picker"
                      />
                    </div>
                  ) : (
                    <>
                      <AddCartButton
                        productId={productId}
                        productname={productname}
                        product={product}
                        name="Add"
                      />
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            <StockEmpty className="StockEmptyWrapper_float" />
          )} */}

          <RefreshLink
            className={`card--inner ${layoutView === 'grid' ? '' : 'row'}`}
            to={`${productBaseUrl}/${_slugProduct}`}
            data-name={productname}
            data-price={defaultprice}
            data-brand=""
            data-category=""
            data-track-onclick="false"
          >
            <Col
              className={`_card_image-wrapper position-relative 
                  ${layoutView === 'grid' ? 'col-lg-12' : 'col-4 col-md-2'}`}
            >
              {/* <Link to={`${productBaseUrl}/${_slugProduct}`}> */}
              <div className="video__wrapper">
                <Suspense fallback={<Spinner animation="border" size="sm" />}>
                  <VideoPlayerWithoutControls
                    videoUrl={videoUrl}
                    thumbnailUrl={thumbnailUrl}
                    title={productname}
                  />
                </Suspense>
              </div>
              {/* </Link> */}
            </Col>
            <Col
              className={`${layoutView === 'grid' ? 'col-lg-12' : 'col-md-10'}`}
            >
              <Card.Body>
                <Title as={Typography}>{productname.toLowerCase()}</Title>
                <NOStock>
                  {product.stock} item{product.stock > 1 ? 's' : ''} in stock
                </NOStock>
                <WrapRating>
                  <FlexRate>
                    <Ratings />
                    <NumberFormat
                      value={product.rating}
                      displayType="text"
                      decimalSeparator="."
                      decimalScale={1}
                      fixedDecimalScale
                      allowNegative={false}
                    />
                  </FlexRate>
                  <Divider />
                  <ConditionBadge>
                    <div className="pr_condition_sec">
                      {condition == 'new' ? (
                        <span className="span__new">New</span>
                      ) : (
                        <span className="span__used">Used</span>
                      )}
                    </div>
                  </ConditionBadge>
                </WrapRating>

                <div className="card-text">
                  <div className="wrap__t__group d-flex align-items-center justify-content-between">
                    <div className="card-image-wrapper"></div>
                    <div className="d-flex align-items-start justify-content-between w-100">
                      <Relative>
                        <div className="_main_price_">
                          <h3>
                            <NumberFormat
                              value={defaultprice}
                              displayType="text"
                              thousandSeparator={true}
                              prefix="₦"
                              className="some"
                            />
                          </h3>

                          {((discount || adminDiscount) && discount > 0) ||
                          adminDiscount > 0 ? (
                            <span className="span__body">
                              <NumberFormat
                                value={`${maxPrice}`}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="₦"
                                className="some"
                              />
                            </span>
                          ) : // : parseInt(maxPrice) > parseInt(defaultprice) ? (
                          //   <span className="span__body">
                          //     <NumberFormat
                          //       value={`${maxPrice}`}
                          //       displayType="text"
                          //       thousandSeparator={true}
                          //       prefix="₦"
                          //       className="some"
                          //     />
                          //   </span>
                          // )
                          null}
                        </div>
                        <NoDiscount>
                          {adminDiscount == null ? (
                            discount > 0 ? (
                              <Tag
                                numberOf={setDiscountPercent(discount)}
                                className="Discount_percent"
                              />
                            ) : null
                          ) : (
                            <Tag
                              numberOf={setDiscountPercent(adminDiscount)}
                              className="Discount_percent"
                            />
                          )}
                        </NoDiscount>
                      </Relative>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Col>
          </RefreshLink>
          {render ? render : null}
        </Card>
      </Col>
    </>
  )
}

const Title = styled.h1`
  width: 100% !important;
  display: block;
  display: -webkit-box;
  text-decoration: none;
  text-transform: capitalize;
  padding-bottom: 0.2rem;
  max-width: 100%;
  height: 30px; /* Fallback for non-webkit */
  margin-bottom: 0rem;
  line-height: 1.5;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px !important;
  font-family: var(--eke-body-font-family);
  color: var(--eke-color-greyscale);
  font-weight: var(--eke-font-weight-bold);
  line-height: 140%; /* 27.404px */
  letter-spacing: 0.241px;

  &:hover {
    color: var(--eke-body-text-primary-color) !important;
    text-decoration: none !important;
  }
`

const NOStock = styled.p`
  color: var(--eke-color-greyscale);
  font-family: var(--eke-body-font-family);
  font-size: 13.536px !important;
  font-weight: var(--eke-font-weight-medium);
  letter-spacing: 0.241px;
  margin: 0 !important;
`

const WrapRating = styled.div`
  display: flex;
  align-items: center;
  gap: 9.634px;
  align-self: stretch;
  padding: 7px 0;
`

const FlexRate = styled.div`
  display: flex;
  align-items: center;
  gap: 9.787px;

  span {
    color: var(--greyscale-700, #616161) !important;
    font-family: var(--eke-body-font-family);
    font-size: 15.06px;
    font-weight: var(--eke-font-weight-medium);
    line-height: 140%; /* 23.604px */
    letter-spacing: 0.241px;
  }
`

const Divider = styled.div`
  width: 1px;
  height: 13px;
  border-right: 1.5px solid #616161;
`
const ConditionBadge = styled.div``

const NoDiscount = styled.div`
  position: absolute;
  top: -10%;
  right: -45%;

  .Discount_percent {
    position: static !important;
    left: 50% !important;
  }
`

const Relative = styled.div`
  position: relative !important;

  ._main_price_ {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h3 {
      margin-bottom: 0rem !important;
      margin-right: 0.5rem;
      font-family: var(--eke-body-font-family);
      color: var(--greyscale-900, #212121);
      font-weight: var(--eke-font-weight-bold);
      font-size: 18.486px !important;
      line-height: 120%; /* 24.583px */
      span {
        font-weight: var(--eke-font-weight-bold);
      }
    }
    .span__body {
      font-size: 13.5px !important;
      color: var(--eke-body-text-secondary-color);
      opacity: 0.6;
      font-weight: normal !important;
      text-decoration: line-through;
      font-family: var(--eke-body-font-family);
      font-weight: (--eke-font-weight-light);
    }
  }
`

const SavedItem = styled.div`
  position: relative !important;
`
