import React from 'react'

const SvgInfo = ({
	width = "1rem",
	height = "1rem",
	className
}: {
	className?: string,
	width?: string;
	height?: string
}) => (
	<svg
		width={width}
		height={height}
		className={className}
		viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Iconly/Curved/Info Square">
			<g id="Info Square">
				<path id="Stroke 1" fillRule="evenodd" clipRule="evenodd" d="M14.166 7.99984C14.166 12.6245 12.624 14.1665 7.99935 14.1665C3.37468 14.1665 1.83268 12.6245 1.83268 7.99984C1.83268 3.37517 3.37468 1.83317 7.99935 1.83317C12.624 1.83317 14.166 3.37517 14.166 7.99984Z" stroke="#F8880A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path id="Stroke 3" d="M7.99967 10.5967V8.00001" stroke="#F8880A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path id="Stroke 15" d="M8.00267 5.66667H7.99667" stroke="#F8880A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			</g>
		</g>
	</svg>


)

export default SvgInfo;