import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
// import NavigationBar from '../../components/NavigationBar/NavigationBar';
// import DashboardNavigationBar from '../../components/NavigationBar/Dashboard_NavigationBar';

interface DashboardLayoutPropsType {
    children:any;
}

export default function DashboardLayout({children}:DashboardLayoutPropsType) {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [toggled, setToggled] = useState<boolean>(false);

    const handleCollapsedChange = () => {
        setCollapsed((prevState) => !prevState);
    };

    const handleToggleSidebar = () => {
        setToggled((prevState) => !prevState);
    };

  return (<>
            {React.Children.map(children, (child) =>
                React.cloneElement(child, { collapsed,toggled,handleToggleSidebar,handleCollapsedChange })
            )}
            
        </>
        
        );
}