import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import SectionCard from '../Sectioncard'
import styled from 'styled-components'
import { RefreshLink } from '@ekenta/components'
import { useGetAllVendorsQuery } from '@ekenta/redux/store/features/vendor/vendorApiSlice'
import { sliderSettings } from './common'
import { allStorePathUrl } from '@ekenta/routes/constant'

const Vendors = () => {
  const { data } = useGetAllVendorsQuery(null)
  const vendors = data?.vendors?.data?.slice(0, 8) ?? []

  return (
    <SectionCard
      title="Discover Vendors shop"
      size="small"
      url={allStorePathUrl}
    >
      <ContainerWrapper>
        <ContentStyle>
          <Swiper {...sliderSettings}>
            {/* slider */}
            {vendors?.map(
              (item: {
                id: string
                thumbnail_url: string | null
                business_name: string
                user: {
                  id: string
                }
              }) => (
                <SwiperSlide key={item.id}>
                  <FlexItem
                    as={RefreshLink}
                    to={`/vendor/${item?.user?.id}/store`}
                  >
                    <Image>
                      {item?.thumbnail_url ? (
                        <img
                          src={item?.thumbnail_url}
                          alt={item.business_name}
                        />
                      ) : (
                        <h1>{item.business_name.charAt(0)}</h1>
                      )}
                    </Image>
                    <h3>{item.business_name}</h3>
                  </FlexItem>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </ContentStyle>
      </ContainerWrapper>
    </SectionCard>
  )
}

const ContainerWrapper = styled.div`
  width: 100%;
  margin-bottom: 21px;
  max-height: auto;
  position: relative;
`

const FlexItem = styled.div`
  width: auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-decoration: none !important;

  h3 {
    color: var(--greyscale-900, #212121);
    text-align: center;
    font-family: var(--eke-body-font-family);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
    letter-spacing: 0.241px;
  }
`
const Image = styled.div`
  width: 179px;
  height: 179px;
  border-radius: 179px;
  background-color: var(--eke-color-primary);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 5rem;
    color: #fff;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 179px;
    object-fit: cover;
    object-position: center;
  }
`

const ContentStyle = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  gap: 15px;
  -ms-overflow-style: none;
  scrollbar-width: none !important;
  margin-top: 0 !important;
  animation: slide 20s linear infinite;

  .swiper-slide {
    width: 230px !important;
  }
`

export default Vendors
