import React from 'react'
import { HelmetHeader } from '@ekenta/components'
import { withDashboardLayout } from '@ekenta/hoc'
import styled from 'styled-components'
import ChatSystem from '@ekenta/components/ChatSystem'
import { useAppSelector } from '@ekenta/redux/store/hook'
import { AdminChatPathUrl } from '@ekenta/routes/constant'

const AdminChat = () => {
  const { account } = useAppSelector((state) => state.auth.authentication)

  return (
    <>
      <HelmetHeader title="All Chats" description="Qlason All Chats Page" />
      <ContainerWrapper>
        <Container>
          <ChatSystem
            isPopup={false}
            userId={account.user ? account.user.id : null}
            redirectUrl={AdminChatPathUrl}
            from="admin"
          />
        </Container>
      </ContainerWrapper>
    </>
  )
}

const ContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  // box-shadow: 0px 4px 60px 0px rgba(203, 216, 224, 0.50);
`

export default withDashboardLayout(AdminChat)
