import React from 'react'
import { VscArrowSmallLeft } from 'react-icons/vsc'
import { useNavigate } from 'react-router';
import { mobileAccountIndexPath } from '../../../../routes/constant'

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <span 
    className=" d-lg-none cursor-pointer d-flex w-auto align-items-center 
    text-muted"
     onClick={() => navigate(mobileAccountIndexPath)}>
       <VscArrowSmallLeft />
       Back to Account
  </span>
  )
}

export default BackButton