/* eslint-disable @typescript-eslint/no-explicit-any */
import { getIn } from 'formik';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProductListingFormType } from '../../interfaces/ProductListing';
import { formatBytes } from '../../utils';
import CustomAlert from '../Alert/CustomAlert';
import Label from '../Label/Label';

import './FileUploader.scss';

interface FileUploaderPropsType {
    value?: File[] | null;
    maxSize: number;
    placeholder: string;
    label?: string;
    requiredText: string;
    acceptFileType: string;
    acceptFileTypeList: string[];
    maxFiles: number;
    multiple: boolean;
    fileType: string;
    setFieldValue?: any;
    setFieldTouched?: any;
    callback?: (fileAccepted: File[]) => void;
    touched?: any;
    name: string;
    errors?: any;
    showSucessAlert?: boolean;
}

export default function FileUploader({
    value,
    maxSize,
    placeholder,
    requiredText,
    acceptFileType,
    maxFiles,
    label,
    multiple,
    fileType,
    acceptFileTypeList,
    showSucessAlert = true,
    name,
    setFieldValue,
    callback,
    setFieldTouched,
    touched,
    errors,
}: FileUploaderPropsType) {
    const [fileerrors, setFileErrors] = useState<{ msg: string }[] | []>([]);
    const [fileAccepted, setFileAccepted] = useState<File[]>(value ? value : []);

    useEffect(() => {
        if (fileAccepted.length > 0) {
            callback ? callback(fileAccepted) : setFieldValue(name, fileAccepted);
        }
    }, [fileAccepted]);

    const getFileFromEvent = (e: any) => {
        const files = e.target.files;
        return files;
    };

    const validateImagesType = (e: any) => {
        setFileErrors([]);
        setFileAccepted([]);

        const files = getFileFromEvent(e);

        if (files.length > 0) {
            const _resultArr = Array.from(files);

            _resultArr.map((file: any) => {
                const _fileTypeSplit = file.type.split('/')[0];

                const fileTypeArr = [fileType];
                //const res_T = acceptFileTypeList.some(item => file.type.includes(item));
                const res_T = fileTypeArr.some((item) => _fileTypeSplit.includes(item));

                if (
                    res_T &&
                    file.type !== 'image/svg+xml' &&
                    file.type !== 'image/gif' &&
                    file.type !== 'image/webp'
                ) {
                    setFileAccepted([file]);
                    setFileErrors([]);
                } else {
                    setFileErrors([
                        ...fileerrors,
                        { msg: `File Type must be ${acceptFileType}` },
                    ]);
                    setFileAccepted([]);
                }

                if (file.size > maxSize) {
                    setFileErrors([
                        ...fileerrors,
                        { msg: `File Size Exceeds ${formatBytes(maxSize)}` },
                    ]);
                    setFileAccepted([]);
                }
            });
        }
    };

    const _borderColor =
        fileerrors.length > 0
            ? '#f67f8a'
            : fileAccepted.length > 0 && !getIn(errors, name)
                ? '#62d58c'
                : getIn(touched, name) && getIn(errors, name)
                    ? '#f67f8a'
                    : '#ddd';

    const useLabel = label
        ? label
        : `Click the button to select your ${fileType}`;
    return (
        <Container className="file-uploader-container">
            <div className="dropdiv" style={{ border: `2px dashed ${_borderColor}` }}>
                {placeholder && (
                    <div className="d-flex align-items-center justify-content-center mb-1">
                        <img width={'50px'} height={'50px'} src={placeholder} />
                    </div>
                )}
                <FileUploadText className="file-uploader-label">
                    <Label className="Label__FileUpload" text={useLabel} center />
                    <span>{requiredText}</span>
                </FileUploadText>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div className="upload-btn-wrapper mb-3">
                        <button className="btn">Choose file</button>
                        <input
                            className="form-control"
                            type="file"
                            name="files"
                            accept={acceptFileType}
                            onChange={(e) => {
                                validateImagesType(e);
                                setFieldTouched ? setFieldTouched(name, true) : null;
                            }}
                            multiple={multiple}
                        />
                    </div>
                </div>
            </div>

            <div className="message_alert">
                {fileerrors.length > 0 && (
                    <CustomAlert
                        variant="danger"
                        show={true}
                        message={
                            <>
                                {fileerrors.map((items: any, index: number) => {
                                    return (
                                        <span
                                            key={'ind' + '_' + index}
                                            className="d-block mt-1 w-100  error_message"
                                            style={{ fontSize: '11px' }}
                                        >
                                            Error: {items?.msg}
                                        </span>
                                    );
                                })}
                            </>
                        }
                    />
                )}

                {showSucessAlert && fileAccepted.length > 0 && !getIn(errors, name) && (
                    <CustomAlert
                        variant="success"
                        show={true}
                        message={
                            <>
                                {fileAccepted.map((items: any, index: number) => {
                                    return (
                                        <span
                                            key={'ind' + '_' + index}
                                            className="d-block mt-1 w-100"
                                            style={{ fontSize: '11px' }}
                                        >
                                            Filename: {items.name}
                                        </span>
                                    );
                                })}
                            </>
                        }
                    />
                )}

                {getIn(touched, name) && getIn(errors, name) && (
                    <CustomAlert
                        variant="danger"
                        show={true}
                        message={
                            <>
                                <div></div>
                                <span>{getIn(errors, name)}</span>
                            </>
                        }
                    />
                )}
            </div>
        </Container>
    );
}

const Container = styled.div`
  .dropdiv {
    width: 100%;
    padding: 2rem;
    margin-top: 0.7em;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    border-radius: 10px;
    background-color: #fdfeff;

    label {
      span {
        color: var(--gray-500, #667085) !important;
        font-family: var(--eke-body-font-family);
        font-size: 13px !important;
        font-weight: var(--eke-font-weight-medium) !important;
        line-height: normal;
        letter-spacing: 0.2px;
      }
    }

    .upload-btn-wrapper {
      position: relative;
      overflow: hidden;
      width: auto;
      display: inline;
      cursor: pointer;

      .btn {
        /* border: 2px solid #ddd;
            color: gray;
            background-color: white;
            padding: 4px 16px;
            border-radius: 8px;
            font-size: 15px;
            font-weight: bold;
            margin: 0 auto;
            cursor: pointer !important; */
        display: flex;
        padding: 8.184px 7.275px;
        justify-content: center;
        align-items: center;
        gap: 4.547px;
        min-width: 100px;
        border-radius: 45.467px;
        border: 0.455px solid var(--eke-color-primary);
        background: var(--eke-color-white);
        color: var(--eke-color-primary);
        text-align: center;
        font-size: 13.275px;
        text-shadow: 1.8186849355697632px 3.6373698711395264px
          10.912110328674316px 0px #efdcc7;
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-bold);
        line-height: 140%; /* 10.185px */
        letter-spacing: 0.091px;
      }

      input[type='file'] {
        font-size: 100px;
        width: auto;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer !important;
      }
    }

    img {
      filter: grayscale(0.8) !important;
      opacity: 0.3;
    }
  }
  .message_alert {
    overflow: hidden;
    .alert {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      border-radius: 0 !important;
      margin-top: -10px !important;
      text-overflow: ellipsis;
      display: inline-block;
      width: 95%;

      p {
        padding: 0 !important;
        span {
          font-size: 13px !important   ;

          li {
            list-style: none;
          }
        }
      }
    }
  }
`;

const FileUploadText = styled.div`
  text-align: center;
  font-family: var(--eke-body-font-family);
  padding-bottom: 10px;

  .Label__FileUpload span {
    color: var(--greyscale-600, #757575);
    font-size: 14px;
    font-weight: var(--eke-font-weight-semibold);
    letter-spacing: 0.149px;
    margin-bottom: 0.2em !important;
    line-height: 0;
  }

  span {
    color: var(--greyscale-600, #757575);
    font-size: 12px;
    font-weight: var(--eke-font-weight-normal);
    letter-spacing: 0.149px;
  }
  /* 
    span{
        font-weight: var(--eke-font-weight-semibold);
        color: var(--eke-body-text-gray-color);
    } */
`;
