/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap';
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Accordion, Button, CustomModal, Field, HelmetHeader, PageTitle } from '@ekenta/components';
import { DashboardSellers } from '@ekenta/routes/constant';
import { VscArrowSmallLeft } from 'react-icons/vsc';
import { withDashboardLayout } from '@ekenta/hoc';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { useFetchAdminAllCollectionsQuery } from '@ekenta/redux/store/services/collectionApi';
import { useSetDiscountToAllProductMutation } from '@ekenta/redux/store/services/adminSellerApi';
import { Plus } from '@ekenta/components/icons';

import styles from "./Discount.module.scss";

const Discount = () => {
  const navigate = useNavigate();
  const { token } = useAppSelector((state) => state.auth.authentication);

  const [obj, setObj] = useState({
    id: "",
    value: "",
    show: false
  });

  const getResult = useFetchAdminAllCollectionsQuery(token);
  const [setDiscountToAllProduct, getResponse] = useSetDiscountToAllProductMutation();

  useEffect(() => {

    if (getResponse?.isSuccess) {
      toast.success("Discount Added Successfully", {
        toastId: "dis=count--id-toast-success",
      });
      setObj({ id: "", value: "", show: false });
    }

    if (getResponse?.isError && getResponse?.error.status === "FETCH_ERROR") {
      toast.error("Discount Failed", {
        toastId: "discount-added-id-toast-error",
      });
    }

    if (getResponse?.isError && getResponse?.error.status === 500) {
      toast.error("Discount Failed", {
        toastId: "discount2-id-toast-error",
      });
    }

    if (getResponse?.isError && getResponse?.error.status === 400) {
      toast.error(`${getResponse?.error.data.title}`, {
        toastId: "discount-id-toast-error",
      });
    }
  }, [
    getResponse?.isSuccess,
    getResponse?.isError,
    getResponse?.error
  ]);

  const handleSubmit = () => {
    if (obj.value !== "") {
      setDiscountToAllProduct({
        categoryId: obj.id,
        value: parseFloat(obj.value),
        token: token
      });
    }
  }

  console.log(getResult?.data)

  return (
    <>
      <HelmetHeader
        title="Discount"
        description="QlasonDiscount Page"
      />
      <Col className=" col-lg-12 mx-auto _listing_product_ dash_top px-2">
        <div className="mt-0">
          <Col className="AppEvents_col mx-1 mx-lg-0">
            <PageTitle
              title="Discount"
              subTitle={
                <span
                  className="cursor-pointer d-flex w-lg-50 align-items-center 
                    text-muted"
                  onClick={() => navigate(DashboardSellers)}>
                  <VscArrowSmallLeft />
                  Back to Dashboard
                </span>
              }
            />
          </Col>
          <Col className="mt-4 tab__ position-relative">
            {getResult?.isError ? "error" : null}
            {getResult?.isLoading ? "Loading" : null}
            {getResult?.isSuccess && getResult?.data ? getResult?.data.collections.map((item: any, index: number) => (
              <Accordion
                key={index}
                title={item.name}
              >
                {item.sub_collection_category.map(({ id, name }: { id: string, name: string }) => (
                  <div key={id} className={styles.AccordionList__Item}>
                    <span className={styles.AccordionList__Name}>{name}</span>
                    <Button
                      prefixIcon={<Plus />}
                      name="Add"
                      className={styles.AccordionList__Button}
                      onClick={() => setObj({ ...obj, id: id, value: "", show: true })}
                    />
                  </div>
                ))}
              </Accordion>
            )) : null}
            <br />
            <br />
          </Col>
        </div>
      </Col>
      <CustomModal
        title="Add Discount (%)"
        centered={false}
        showFooter={true}
        show={obj.show}
        isLoading={getResponse?.isLoading}
        isDisabled={getResponse?.isLoading}
        onHide={() => setObj({ ...obj, show: false })}
        onSubmit={handleSubmit}
        render={
          <Field
            id="discount-field"
            name={"discount"}
            type="number"
            value={obj.value}
            fieldClassName={styles.Discount_FieldWrap}
            placeholder={`Discount %`}
            autoComplete="off"
            numberFieldPrefix="%"
            onChange={(e: any) => setObj({ ...obj, value: e.value })}
          />
        }
      />
    </>
  )
}


export default withDashboardLayout(Discount);