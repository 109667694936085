const generalApiHeaders = (token:string) => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
});

//Query
const createQueryRequest = ({url,method}:{url:string,method:string}) => ({
    url:url,
    method:method,
});

const createQueryRequestWithBody = ({url,method,body}:{url:string,method:string,body:any}) => ({
    url:url,
    headers:{
        'Content-Type':'application/json'
    },
    method:method,
    body:body
});

const createQueryWithTokenRequest = ({url,method,token}:{url:string,method:string,token:string}) => ({
    url:url,
    headers:generalApiHeaders(token),
    method:method,
});



//Mutation
const createMutationRequestWithoutBody = ({url,method,token}:{url:string,method:string,token:string}) => {
    return{
        url:url,
        headers:generalApiHeaders(token),
        method:method,
}};

const createMutationRequestWithBody = ({url,method,body,token}:{url:string,method:string,body:any,token:string}) => ({
    url:url,
    headers:generalApiHeaders(token),
    method:method,
    body:body
});

//createFormDataMutationWithoutTokenRequest
const createFormDataMutationWithoutTokenRequest = ({url,method,body}:{url:string,method:string,body:any}) => ({
    url:url,
    method:method,
    body:body
});

const createFormDataMutationRequest = ({url,method,body,token}:{url:string,method:string,body:any,token:string}) => ({
    url:url,
    headers:{
       "Authorization": `Bearer ${token}`
    },
    method:method,
    body:body
});


const createQueryWithTokenRequestByText = ({url,method,token}:{url:string,method:string,token:string}) => ({
    url:url,
    headers:generalApiHeaders(token),
    responseHandler: (response:any) => response.text(),
    method:method,
});

const createMutationRequestToText = ({url,method,body}:{url:string,method:string,body:any}) => ({
    url:url,
    responseHandler: (response:any) => response.text(),
    headers:{
        'Content-Type':'application/json'
    },
    method:method,
    body:body,
});

const createMutationRequestToTextWithoutBody = ({url,method,token}:{url:string,method:string,token:string}) => ({
    url:url,
    responseHandler: (response:any) => response.text(),
    headers:generalApiHeaders(token),
    method:method
});



export {
    createQueryRequest,
    createQueryWithTokenRequest,
    createQueryWithTokenRequestByText,
    createQueryRequestWithBody,
    createMutationRequestWithoutBody,
    createMutationRequestWithBody,
    createFormDataMutationWithoutTokenRequest,
    createFormDataMutationRequest,
    createMutationRequestToText,
    createMutationRequestToTextWithoutBody
}