import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { withDefaultLayout } from '@ekenta/hoc'
import { HelmetHeader } from '@ekenta/components'
import { DetailsContainer, DetailsStyle } from './styles'
import Topsection from './Topsection'
import ProductDesc from './Productdec'
import SellerDetails from './Sellerdetails'
import Reviews from './Reviews'
import RelatedProduct from './RelatedProduct'
import { ProductDetailLoader } from '../Product/component/SkeletonLoader/ProductDetailLoader'
import { useFetchSingleProductByIdQuery } from '@ekenta/redux/store/features/product/productApiSlice'
import { ProductType } from '@ekenta/redux/store/features/product/productDataType'
import EmptyBox from '@ekenta/components/Box/EmptyBox'

const Details = () => {
  const params = useParams()
  const productId = params['productslug']
    ? params?.productslug.slice(params?.productslug.lastIndexOf('-') + 1)
    : ''

  const { data: productData, isLoading } = useFetchSingleProductByIdQuery(
    productId,
    { skip: !productId }
  )

  const productName = productData?.product?.name ?? 'Loading...'
  const product = productData?.product ?? ({} as ProductType)
  const relatedProduct = productData?.related_product ?? ([] as ProductType[])

  return (
    <Fragment>
      <HelmetHeader title={productName} description="Qlason Product Details" />
      <DetailsStyle>
        <DetailsContainer>
          {isLoading ? (
            <ProductDetailLoader />
          ) : product?.is_deleted ? (
            <EmptyBox />
          ) : (
            <Fragment>
              <Topsection product={product} />
              <ProductDesc product={product} />
              <SellerDetails product={product} />
              <Reviews />
              <RelatedProduct relatedProduct={relatedProduct} />
              {/* <SellersProduct {...{ product }} /> */}
            </Fragment>
          )}
        </DetailsContainer>
      </DetailsStyle>
    </Fragment>
  )
}

export default withDefaultLayout(Details)
