import { device } from '@ekenta/utils/devices'
import styled from 'styled-components'

export const TopSectionContainerStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: auto;
  gap: 4px;
  padding: 0 10px;
  margin-top: 0em;
  margin-bottom: 0em;

  @media ${device.lg} {
    margin-top: 0.8em;
    padding: 0;

    min-height: 400px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`

export const CarouselContainerStyle = styled.div<{ placeholder: string }>`
  position: relative;
  max-width: 900px;
  width: 100%;
  background-color: #e3f3ff !important;
  min-height: 183px;
  height: auto;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${(props) => `url(${props.placeholder})`};
  background-size: 15rem;
  background-position: center 130px;
  background-repeat: no-repeat;
  background-clip: content-box;

  @media ${device.md} {
    min-height: 260px;
    max-height: auto;
  }

  @media ${device.lg} {
    min-height: 380px;
    max-height: auto;
  }

  .ImageGroup__Style {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    //background-color: #eee;
    overflow: hidden;

    span {
      width: 100%;
      display: block !important;
    }

    @media ${device.lg} {
      height: auto;
    }

    img {
      object-fit: cover;
      object-position: top center;
      border-radius: 12px;
      width: 100%;
      height: 100%;
    }
  }
`

//----------RightSideContainer --------------------
export const RightSideContainerStyle = styled.div`
  flex-shrink: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
  gap: 0.9em;
  row-gap: 0.4em !important;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;

  .card-full {
    flex: 1;

    a {
      padding-left: 21px !important;
      padding-right: 20px !important;
    }

    .HCard {
      width:  376px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .CardInner__Style {
        width: auto !important;
        text-align: left !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;

        h2 {
          margin: 0 !important;
        }

		p{
			height: auto !important;
			margin-bottom: 3px !important;
		}
      }
    }
  }

  @media ${device.lg} {
    width: 450px;
    flex-wrap: wrap;
    overflow-x: none;
  justify-content: flex-end;

  }
`


export const RightCardStyle = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  height: 190px;
  align-items: center;
  border-radius: 12px;
  background: ${(props) => props.color && `var(--bg-color, ${props.color})`};

  a {
    width: 100%;
    display: block;
    padding: 0px 10.5px 0px 11.5px !important;
    text-decoration: none;

    svg {
      width: 50px;
      height: auto;
    }

  .live-img{
      width: 90px !important;
    }
  .eye-img{
    width: 70px !important;
  }
   

    button {
      margin: 0 !important;
      display: flex;
      padding: 11.172px 28px;
      align-items: center;
      gap: 6.207px;
      border-radius: 62.069px;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.124px;
      box-shadow: 2.4827585220336914px 4.965517044067383px 14.896551132202148px
        0px #efdcc7;
    }
  }

  .CardInner__Style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 188px;
    gap: 4px;
    font-family: var(--eke-body-font-family);
    color: var(--greyscale-800, #424242) !important;
    text-align: center;
    letter-spacing: 0.2px;
    overflow: hidden;

    h2 {
      font-size: 18px;
      font-weight: var(--eke-font-weight-bold);
      line-height: 140%;
      margin: 0 !important;
    }
    p {
      font-size: 11px;
      font-weight: var(--eke-font-weight-normal);
      line-height: 16px;
      margin: 0 !important;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2.5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 45px; /* Fallback for non-webkit */
      text-overflow: ellipsis;
    }
  }
`
