import React from 'react'
import { Tab as BTab, Tabs } from 'react-bootstrap'

import "./index.scss";

const Tab = (props:
    {
        children: React.ReactNode;
        defaultActiveKey: string;
        id: string;
        transition: boolean;
    }) => {
    const { children, id, defaultActiveKey, transition = false } = props;
    return (
        <Tabs
            defaultActiveKey={defaultActiveKey}
            transition={transition}
            id={id}>
            {children}
        </Tabs>
    )
}

export const CustomTabBody = (props: { children: React.ReactNode; eventKey: string; title: string; }) => {
    const { children, eventKey, title } = props;
    return (
        <BTab eventKey={eventKey} title={title}>
            {children}
        </BTab>
    )
}

Tab.Body = CustomTabBody;

export default Tab;