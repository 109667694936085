/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ProductType } from '@ekenta/redux/store/features/product/productDataType'
import Gallery from './Gallery'
import ProductInfo from './ProductInfo'
import { Stack } from '../styles'
import { device } from '@ekenta/utils/devices'

interface PropsType {
  product: ProductType
}

const mapMediaDataToSlider = (images: { image_url: string }[]) => {
  if (!images) return []
  if (images.length == 0) return []
  const listImages: any[] = []
  images.length > 0 &&
    images.map((item) => {
      if (item) {
        const data = {
          original: `${item.image_url}`,
          thumbnail: `${item.image_url}`,
        }
        listImages.push(data)
      }
    })

  return listImages
}

const Topsection: React.FC<PropsType> = (props) => {
  const { product } = props
  const [_mediaList, setMediaList] = useState<
    { original: string; thumbnail: string }[] | null
  >(null)

  useEffect(() => {
    if (product) {
      const result = mapMediaDataToSlider(product?.images)
      if (product?.video_url) {
        const allSlideImGVid = [
          {
            id: 'video',
            original: product?.images[0]?.image_url,
            thumbnail: product?.images[0].image_url,
            embedUrl: product?.video_url,
          },
          ...result,
        ]
        setMediaList(allSlideImGVid)
      } else {
        setMediaList(result)
      }
    }
  }, [product])

  return (
    <Container>
      <Stack>
        <Gallery imageList={product?.images} images={_mediaList} />
        <ProductInfo product={product} />
      </Stack>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 22px 20px 35.431px 22px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.12);

  @media ${device.lg} {
    padding: 22px 76px 35.431px 22px;
  }
`

export default Topsection
