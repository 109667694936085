/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Navigate, useLocation } from 'react-router'
//import { DecodeTokenType } from '../../interfaces/decode';
import { useAppSelector } from '../../redux/store/hook'
import { checkAuthorizationRoles } from '../../utils'
import { homePathUrl, loginPathUrl } from '../constant'

export default function ProtectedRouteWithRoles({
  component: RouteComponent,
  roles,
  ...rest
}: {
  component: () => JSX.Element
  roles: string[]
}) {
  const location = useLocation()
  const { token, account } = useAppSelector(
    (state: any) => state.auth.authentication
  )

  if (!account.user || !token) {
    return (
      <Navigate
        to={{
          pathname: loginPathUrl,
          search: `?redirect=${encodeURIComponent(
            location.pathname + location.search
          )}`,
        }}
        replace
      />
    )
  }

  // if (!account.user || !token) {
  //   return <Navigate
  //     to={{
  //       pathname: loginPathUrl,
  //       search: `?redirect=${encodeURIComponent(
  //         location.pathname + location.search
  //       )}`,
  //     }}
  //     state={{ from: location }}
  //     replace
  //   />;
  // }

  // const user_token = token;
  // const decoded_Token:DecodeTokenType | any  =  decodeToken(user_token);

  // if (!decoded_Token || decoded_Token.exp * 1000 < Date.now()) {
  //   dispatch(removeUser());
  //   return <Navigate to={loginPathUrl} />;
  // }

  const userRole = account.user.role
  const checkUserRole = [userRole]
  const authorize = checkAuthorizationRoles(checkUserRole, roles)

  if (!authorize) return <Navigate to={homePathUrl} /> //or redirect to unauthorize page

  return <RouteComponent {...rest} />
}
