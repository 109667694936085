/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { Button, ButtonGroup, Loader, Typography } from '@ekenta/components';
import ListItem from './ListItem/ListItem';
import { toast } from "react-toastify";
import { useAppDispatch } from '@ekenta/redux/store/hook';
import AddressForm from '@ekenta/pages/Account/ManageAccount/Address/Form/AddressForm';
import { VscArrowSmallRight } from 'react-icons/vsc';
import Scrollbars from 'react-custom-scrollbars';
import { setShippingAddress } from '@ekenta/redux/store/states/reducerSlice/authenticationSlice';

import "./ListAddress.scss";
const ListAddress = (props: any) => {
    const { getAllAddress } = props;

    const dispatch = useAppDispatch();
    const [addressData, setAddressData] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [isEdit, setEdit] = useState({
        show: false,
        data: {}
    });

    useEffect(() => {
        if (getAllAddress?.isError) {
            handleSubmit(null);
        }

        if (getAllAddress?.isError
            && getAllAddress?.error.status === "FETCH_ERROR") {
            handleSubmit(null);
            toast.error(`Something went wrong,Try again`, {
                toastId: "error-status-listA-toast-error",
            });
        }
    }, [
        getAllAddress?.isError
    ]);


    function handleShow() {
        setShowForm(true);
    }

    function handleCancel() {
        setShowForm(false);
        setEdit({ show: false, data: {} });
    }

    function handleSelectedAddress(data: any) {
        if (data) {
            setIsDisabled(false);
            setAddressData(data);
        }
    }

    function handleSubmitFunc(addressData: any) {
        if (addressData) {
            setIsLoading(true);
            setTimeout(() => {
                handleSubmit(addressData);
                setIsLoading(false);
                window.scrollTo(0, 700);
            }, 1000);
        }
    }

    function handleSubmit(data: any) {
        dispatch(
            setShippingAddress({
                address: data
            })
        )
    }


    const minH = getAllAddress?.isError
        || getAllAddress?.isLoading
        ? "50px" : "280px"

    return (
        <div className="ListAddress_WrapperFrame">
            <Typography
                as="span"
                size="smallest"
                className=""
                color="dark"
                uiText>* Required fields</Typography>

            {!showForm && !isEdit.show ? (
                <div className="ListAddress_HeadingFlex">
                    <div>
                        <Typography
                            as="h5"
                            size="small"
                            color="secondary"
                            margin="top"
                            className=""
                            heading>
                            Select an address
                        </Typography>
                        <Typography
                            as="span"
                            size="small"
                            color="light"
                            uiText>Where we should deliver your order?</Typography>
                    </div>
                    <Button
                        aria-label="Add address"
                        name="+ Add new address"
                        as="text"
                        textVariant="dark"
                        className="List_Button"
                        onClick={handleShow}
                    />
                </div>
            ) : null}

            <div className="ListAddress_Item">
                {showForm || isEdit.show ? (
                    <AddressForm
                        isEdit={isEdit}
                        setEdit={setEdit}
                        onCancel={handleCancel}
                        variant="success"
                    />

                ) : (
                    <Fragment>
                        {getAllAddress?.isLoading || getAllAddress?.isError ? <Loader isCenter /> : null}
                        <Scrollbars className="ListAddressItem_Wrapper"
                            style={{ width: "100%", height: 'auto', maxHeight: "300px", minHeight: `${minH}` }}>
                            {getAllAddress?.data ? getAllAddress?.data.addresses.length > 0 ? getAllAddress?.data.addresses.map((item: any, index: number) => (
                                <div className="ListSingleItem_DIV" key={item.id}>
                                    <ListItem
                                        item={item}
                                        setEdit={setEdit}
                                        onSelect={handleSelectedAddress}
                                    />
                                    <div aria-hidden="true" className="Hr_horizontal" />
                                </div>
                            )) : (<span>No address</span>) : null}
                        </Scrollbars>
                        <ButtonGroup pos="right">
                            <Button
                                name="Proceed"
                                bgVariant="success"
                                textVariant="light"
                                disabled={isLoading || isDisabled}
                                suffixIcon={<VscArrowSmallRight fontSize={15} />}
                                width="w-100"
                                isLoadingText="Proceed"
                                className="save__Button"
                                isLoading={isLoading}
                                shadow={false}
                                type="button"
                                onClick={() => handleSubmitFunc(addressData)}
                            />
                        </ButtonGroup>

                    </Fragment>
                )}

            </div>
        </div>
    )
}

export default ListAddress;