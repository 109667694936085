/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  createFormDataMutationWithoutTokenRequest,
  createMutationRequestWithBody,
  createQueryRequest,
  createQueryRequestWithBody,
  createQueryWithTokenRequest,
  createQueryWithTokenRequestByText,
} from '.'
import { BASE_URL } from '../../../constants/index'

export const productsApi: any = createApi({
  reducerPath: 'productsApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Product', 'Viewed'],
  endpoints: (builder) => ({
    fetchAllProduct: builder.query<void, { productId: string; token: string }>({
      query: () =>
        createQueryRequest({ url: `/api/product/all`, method: 'GET' }),
      providesTags: ['Product'],
    }),
    fetchGeneralBanner: builder.query<void, string>({
      query: () =>
        createQueryRequest({
          url: `/api/get-ad-banners?tag=GENERAL`,
          method: 'GET',
        }),
    }),
    fetchProductBySubCollectionId: builder.query<
      void,
      {
        subCollectionId: number
        pageno: string
        ikitchen: boolean
        token: string
      }
    >({
      query: ({ subCollectionId, pageno, ikitchen, token }) =>
        createQueryWithTokenRequest({
          url: `/api/product-by-sub-collection/${subCollectionId}?page=${pageno}&ikitchen=${ikitchen}`,
          method: 'GET',
          token: token,
        }),
    }),
    fetchProductBySubCategoryCollectionId: builder.query<
      void,
      { categoryId: number; pageno: string; ikitchen: boolean; token: string }
    >({
      query: ({ categoryId, pageno, ikitchen, token }) =>
        createQueryWithTokenRequest({
          url: `/api/product-by-sub-collection-category/${categoryId}?page=${pageno}&ikitchen=${ikitchen}`,
          method: 'GET',
          token: token,
        }),
    }),

    // fetchProductByCollectionId: builder.query<
    //   void,
    //   { eventId: number; pageno: string; keyword: string }
    // >({
    //   query({ eventId, pageno, keyword }) {
    //     return {
    //       url: `/api/admin/get-product-event-collection/${eventId}?page=${pageno}&keyword=${keyword}`,
    //       method: 'GET',
    //     }
    //   },
    // }),

    fetchSingleProductById: builder.query<void, number>({
      query: (productId) =>
        createQueryRequest({ url: `/api/product/${productId}`, method: 'GET' }),
    }),

    generateSku: builder.query<void, string>({
      query: (token) =>
        createQueryWithTokenRequestByText({
          url: `/api/sku-generator`,
          method: 'GET',
          token: token,
        }),
    }),

    fetchSearchProducts: builder.query<void, any>({
      query: ({ body }) =>
        createQueryRequestWithBody({
          url: `/api/filter-and-sort-products/`,
          method: 'GET',
          body: body,
        }),
    }),
    // searchProduct: builder.mutation<any, { body: any, token: string }>({
    //     query: (body) => createFormDataMutationWithoutTokenRequest({ url: `/api/search-product-by-name`, method: "POST", body: body }),
    // }),

    authSearchProduct: builder.mutation<any, { body: any; token: string }>({
      query: ({ body, token }) =>
        createMutationRequestWithBody({
          url: `/api/auth-search-product-by-name`,
          method: 'POST',
          body: body,
          token: token,
        }),
    }),

    // fetchSearchProductsWithFilter: builder.mutation<any, { body: any, token: string, pageNo: any }>({
    //     query: ({ body, token, pageNo }) => createFormDataMutationWithoutTokenRequest({ url: `/api/filter-and-sort-products?page=${pageNo}`, method: "POST", body: body }),
    // }),

    // fetchSearchProWithFilter: builder.mutation<any, { body: any, token: string, pageNo: any }>({
    //     query: ({ body, token, pageNo }) => createFormDataMutationWithoutTokenRequest({ url: `/api/filter-and-sort-products?page=${pageNo}`, method: "POST", body: body }),
    // }),

    // searchSellerProduct: builder.query<void, any>({
    //     query: ({ sellerId, pageno }) => createQueryRequest({ url: `/api/seller-product/${sellerId}?page=${pageno}`, method: "GET" }),
    // }),

    // searchBrandProduct: builder.query<void, any>({
    //     query: ({ brandId, pageno }) => createQueryRequest({ url: `/api/brand-product/${brandId}?page=${pageno}`, method: "GET" }),
    // }),

    cancelOrder: builder.query<any, any>({
      query: ({ orderId, token }) =>
        createQueryWithTokenRequest({
          url: `/api/cancel-order/${orderId}`,
          method: 'GET',
          token: token,
        }),
    }),
    fetchAllRatingsForSingleProduct: builder.query<void, number>({
      query: (productId) =>
        createQueryRequest({
          url: `/api/product/product-ratings/${productId}`,
          method: 'GET',
        }),
    }),

    fetchJustForYouProduct: builder.query<
      void,
      { productId: string; token: string }
    >({
      query: () =>
        createQueryRequest({ url: `/api/just-for-you`, method: 'GET' }),
      providesTags: ['Product'],
    }),

    fetchSortByHighest: builder.query<
      void,
      { collectionId: string; token: string }
    >({
      query: ({ collectionId }) =>
        createQueryRequest({
          url: `/api/product/sort-by-highest/${collectionId}`,
          method: 'GET',
        }),
      providesTags: ['Product'],
    }),

    fetchProductByBrand: builder.query<void, string>({
      query: (brandId) =>
        createQueryRequest({
          url: `/api/product/brand/${brandId}`,
          method: 'GET',
        }),
      providesTags: ['Product'],
    }),

    updateProduct: builder.mutation<any, any>({
      query: ({ productId, obj, token }) =>
        createMutationRequestWithBody({
          url: `/api/seller/update-product/${productId}`,
          method: 'PUT',
          body: obj,
          token: token,
        }),
      invalidatesTags: ['Product'],
    }),

    addRecentlyViewedProduct: builder.mutation<any, { productId: string }>({
      query: ({ productId }) =>
        createFormDataMutationWithoutTokenRequest({
          url: `/api/recently-viewed/${productId}`,
          method: 'POST',
          body: {},
        }),
      invalidatesTags: ['Viewed'],
    }),

    getAllRecentlyViewed: builder.query<void, string>({
      query: (token) =>
        createQueryWithTokenRequest({
          url: `/api/view-user-all-recently-viewed-item`,
          method: 'GET',
          token: token,
        }),
      providesTags: ['Viewed'],
    }),

    fetchBestSeller: builder.query<void, { productId: string; token: string }>({
      query: () =>
        createQueryRequest({ url: `/api/best-selling`, method: 'GET' }),
      providesTags: ['Product'],
    }),

    fetchSellerOtherProduct: builder.query<
      void,
      { sellerId: string; token: string }
    >({
      query: (sellerId) =>
        createQueryRequest({
          url: `/api/sellers-other-product/${sellerId}`,
          method: 'GET',
        }),
      providesTags: ['Product'],
    }),
    fetchIkitchenProduct: builder.query<
      void,
      {
        location: string
        sort: string
        minPrice: number
        maxPrice: number
        pageNo: number
      }
    >({
      query: ({ location, sort, minPrice, maxPrice, pageNo }) =>
        createQueryRequest({
          url: `/api/product-ikitchen?location=${location}&sort=${sort}&minPrice=${minPrice}&maxPrice=${maxPrice}&page=${pageNo}`,
          method: 'GET',
        }),
    }),
  }),
})

export const {
  useFetchGeneralBannerQuery,
  useFetchAllProductQuery,
  useFetchProductBySubCollectionIdQuery,
  //useFetchProductByCollectionIdQuery,
  useFetchProductBySubCategoryCollectionIdQuery,

  useFetchSingleProductByIdQuery,

  useSearchProductMutation,
  useFetchSearchProductsQuery,
  useFetchSearchProductsWithFilterMutation,
  useCancelOrderQuery,
  useGenerateSkuQuery,
  useFetchAllRatingsForSingleProductQuery,
  useFetchJustForYouProductQuery,
  useFetchSortByHighestQuery,
  useFetchProductByBrandQuery,
  useUpdateProductMutation,

  useGetAllRecentlyViewedQuery,
  useAddRecentlyViewedProductMutation,
  useSearchSellerByMerchantQuery,
  useFetchBestSellerQuery,
  useFetchSellerOtherProductQuery,
  // useSearchSellerProductQuery,
  // useSearchBrandProductQuery,
  useAuthSearchProductMutation,
  useFetchIkitchenProductQuery,
} = productsApi
