/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Col } from "react-bootstrap";
import * as queryString from "query-string";
import {
    FiChevronDown,
    FiExternalLink,
} from "react-icons/fi";
import { useLocation, useNavigate } from "react-router";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { toast } from "react-toastify";
import {
    AdminSellerProfileId,
    DashboardSellers,
} from "@ekenta/routes/constant";
import { Pagination, DataTable, HelmetHeader } from "@ekenta/components";
import Dropdown from "@ekenta/components/Dropdown/Dropdown3";
import PageTitle from "@ekenta/components/PageTitle/PageTitle";
import { VscArrowSmallLeft } from "react-icons/vsc";
import withDashboardLayout from "@ekenta/hoc/Layout/withDashboardLayout";
import { useFetchAllTotalSellersQuery } from "@ekenta/redux/store/services/adminSellerApi";
import { StatusIndicator, TextTruncate } from "../../common/Resuable";
import { ReactComponent as PendingIcon } from "@ekenta/assets/icons/info-square-icon.svg";
import { ReactComponent as ApprovedIcon } from "@ekenta/assets/icons/success-check-icon.svg";
import { ReactComponent as BlockIcon } from "@ekenta/assets/icons/danger-x-icon.svg";
import Export from "../../common/ExportButton/Export";
import { BASE_URL } from "@ekenta/constants";


const AllSellers = () => {
    const query = queryString.parse(window.location.search);
    const _page = query.page;

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { token } = useAppSelector((state) => state.auth.authentication);
    const [pageno, setPageno] = useState(1);
    const [activeFilter, setActiveFilter] = useState('');
    const [status, setStatus] = useState('');
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

    const result = useFetchAllTotalSellersQuery(
        {
            pageno,
            keyword: debouncedSearchTerm,
            isExport: false,
            status,
            token
        },
        {
            refetchOnMountOrArgChange: true,
            refetchOnReconnect: true,
            keepPreviousData: false,
        }
    );

    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
            sortType: 'basic',
        },
        {
            Header: "Store Name",
            accessor: "store",
            sortType: "basic",
        },
        {
            Header: 'Phone No',
            accessor: 'phone',
            sortType: 'basic',
        },
        {
            Header: 'Email',
            accessor: 'email',
            sortType: 'basic',
        },
        {
            Header: "Date Joined",
            accessor: "created_at",
            disableSortBy: true,
        },
        {
            Header: 'Status',
            accessor: 'status',
            disableSortBy: true,
        },
        {
            Header: "Action",
            accessor: "option",
            disableSortBy: true,

        },
    ];

    useEffect(() => {
        const delay = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 1000);

        return () => {
            clearTimeout(delay);
        };
    }, [search]);


    const onSearch = (e: any) => {
        setSearch(e.target.value);
    };




    const mapData = (data: any) => {
        return data.length > 0 ? data.map((item: any) => ({
            ...item,
            name: (
                item.user ? (
                    <TextTruncate>{item.user.first_name + " " + item.user.last_name}</TextTruncate>
                ) : ''
            ),
            store: (
                <TextTruncate>{item.business_name}</TextTruncate>
            ),
            phone: item.business_phone_number,
            email: item.user ? item.user.email : '',
            status: (
                item.seller_request_status ? (
                    <StatusIndicator status={item.seller_request_status} />
                ) : ""
            ),
            created_at: item.user ? moment(item.user?.created_at).format('DD-MM-YYYY') : '',
            option: (
                <Dropdown
                    name={"more"}
                    suffixIcon={<FiChevronDown />}
                    placement="bottom"
                    menus={[
                        {
                            name: "Seller Profile",
                            icon: <FiExternalLink />,
                            active: false,
                            key: "seller-profile",
                            handler: () =>
                                item.user
                                    ? navigate(`${AdminSellerProfileId}/${item?.user?.id}`)
                                    : console.log("user not found"),
                        },
                        {
                            name: "View details",
                            icon: <FiExternalLink />,
                            active: false,
                            key: "view-detail",
                            handler: () =>
                                navigate(`/admin/seller-review/${item.id}?type=all`),
                        },
                    ]}
                    tooltipContent={"More Option"}
                />
            ),
        })) : [];
    };


    const handleFilter = (status: string) => {
        setActiveFilter(status.toLowerCase());
        setStatus(status);
    }

    const clearFilter = () => {
        setStatus('');
        setActiveFilter('');
    }

    const handleDownloadData = async (type: string) => {
        console.log(type, "exporting data");
        const fileName = 'all_total_sellers.xlsx';
        await downloadFile(fileName);
    }

    const downloadFile = async (fileName: string) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL}/api/admin/all-sellers?keyword=&status=&export=${true}&page=1`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            const { link } = data;
            // Create an anchor element to trigger the download
            const a = document.createElement('a');
            a.href = link;
            a.download = fileName;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            setIsLoading(false);
            toast.success("Exported file successfully", {
                toastId: "export-xlsx-id-toast-success",
            });
        } catch (error) {
            setIsLoading(false);
            toast.error("Unable to export file", {
                toastId: "export-to-xlsx-toast-error",
            });
        }
    }


    return (
        <>
            <HelmetHeader
                title="All Sellers"
                description="Qlason All Sellers Page"
            />
            <Col className="_listing_product_ dash_top px-2">
                <div className="mt-0">
                    <Col className="AppEvents_col mx-1 mx-lg-0">
                        <PageTitle
                            title=" All Sellers"
                            subTitle={
                                <span
                                    className="cursor-pointer d-flex w-lg-50 align-items-center text-muted"
                                    onClick={() => navigate(DashboardSellers)}>
                                    <VscArrowSmallLeft />
                                    Back to Dashboard
                                </span>
                            }
                        >
                            <Export downloadData={handleDownloadData} type="excel" name={"Export"} />
                        </PageTitle>
                    </Col >

                    <Col className="mt-4 tab__ position-relative">
                        <DataTable
                            theme="simple"
                            data={result?.data ? mapData(result?.data?.sellers.data) : []}
                            loading={result?.isLoading || result?.isFetching || isLoading}
                            isSelectable={true}
                            columns={columns}
                            showPagination={true}
                            hasLink={true}
                            thClassName=""
                            trClassName=""
                            tdClassName={`Row__col`}
                            showSearch={true}
                            searchValue={search}
                            onSearch={onSearch}
                            showFilter={true}
                            activeFilter={activeFilter}
                            clearFilter={clearFilter}
                            filterMenu={[
                                {
                                    name: "Pending",
                                    icon: <PendingIcon />,
                                    active: false,
                                    key: "pending",
                                    handler: () => handleFilter("PENDING")
                                },
                                {
                                    name: "Approved",
                                    icon: <ApprovedIcon />,
                                    active: false,
                                    key: "approved",
                                    handler: () => handleFilter("APPROVED")
                                },
                                {
                                    name: "Blocked",
                                    icon: <BlockIcon />,
                                    active: false,
                                    key: "blocked",
                                    handler: () => handleFilter("BLOCKED")
                                },
                            ]}
                            renderPagnation={
                                <div className="mt-4">
                                    <Pagination
                                        pathname={pathname}
                                        itemPerPage={result?.data?.sellers?.per_page}
                                        itemsLength={result?.data?.sellers?.total}
                                        currentPage={pageno}
                                        queryNo={_page}
                                        callback={(value) => setPageno(value)}
                                    />
                                </div>
                            }
                        />

                        <br />
                    </Col>
                </div >
            </Col>
        </>
    );
};


export default withDashboardLayout(AllSellers);
