import React from 'react'
import styled from 'styled-components'
import { useGetRecentBlogPostQuery } from '@ekenta/redux/store/features/blog/blogApiSlice'
import { BlogContentType } from '@ekenta/redux/store/features/blog/blogDataType'
import Post from './common/Post'
import { PostStyle } from './style'
import { Loader } from '@ekenta/components'
import { device } from '@ekenta/utils/devices'

const RecentPost = () => {
  const { data, isLoading } = useGetRecentBlogPostQuery()
  const recentPosts = data?.blogs ?? []
  // const rightSideData = recentPosts?.slice(1, 2) ?? []
  // const isExist = rightSideData?.length !== 0

  const leftSideData = recentPosts?.slice(0, 1) ?? []
  const rightSideData = recentPosts?.slice(1, 3) ?? []
  const isExist = rightSideData?.length !== 0
  const isRightLength = rightSideData?.length === 1

  return (
    <Box>
      <PostStyle>
        <h3>Recent blog posts</h3>
        {isLoading ? <Loader /> : null}
        <Flex>
          <div className="leftSide-Box">
            {leftSideData?.map((item: BlogContentType) => (
              <Post
                key={item?.id}
                title={item?.title}
                summary={item?.summary}
                image_url={item?.image_url}
                date={item.updated_at}
                written_by={item.written_by}
                slug={item.slug}
                direction={isExist ? 'column' : 'row'}
                imgH={isExist ? '342.7px' : '288px'}
                imgW={isExist ? '100%' : 'auto'}
                imgFlexGrow={1}
                paragraphWidth="auto"
              />
            ))}
          </div>
          {rightSideData?.length !== 0 && (
            <div className="rightSide-Box">
              <div className="flex-col">
                {rightSideData?.map((item: BlogContentType) => (
                  <Post
                    key={item?.id}
                    title={item.title}
                    summary={item.summary}
                    image_url={item.image_url}
                    date={item.updated_at}
                    written_by={item.written_by}
                    slug={item.slug}
                    direction={isRightLength ? 'column' : 'row'}
                    imgW={isRightLength ? '100%' : 'auto'}
                    imgH={isRightLength ? '342.7px' : '240px'}
                    imgFlexGrow={1}
                    paragraphWidth="auto"
                  />
                ))}
              </div>
            </div>
          )}
        </Flex>
      </PostStyle>
    </Box>
  )
}
const Box = styled.div`
  width: 100%;
  display: block;
  max-width: 90%;
  min-height: 450px;
  margin: 0 auto;
  height: auto;
  background-color: var(--eke-color-white) !important;

  h3 {
    font-family: var(--eke-body-font-family);
    font-size: 32px;
    font-weight: 700 !important;
    line-height: 38.4px;
    color: #101828;
    margin-bottom: 27px;
  }

  @media ${device.lg} {
    width: 100%;
    max-width: 100%;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  gap: 32px;

  .leftSide-Box {
    flex: 1;
    height: 100%;
  }
  .rightSide-Box {
    flex: 1.1;
    height: 100%;

    .flex-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
    }
  }

  @media ${device.lg} {
    flex-direction: row;
  }
`
export default RecentPost
