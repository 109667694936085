import { api } from '@ekenta/app/api/apiSlice'

export const cartsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCartItems: build.query({
      query: () => ({
        url: `/api/get-cart-items`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetCartItemsQuery } = cartsApi
