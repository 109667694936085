/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
import SVG from 'react-inlinesvg'
import axios from 'axios'
import { toast } from 'react-toastify'
import { googleLogout } from '@react-oauth/google'
import { useAppDispatch, useAppSelector } from '@ekenta/redux/store/hook'
import sellerOnboardingFormValidation from '@ekenta/validations/schemas/seller-onboarding'
import AccountDetails from '../Steps/AccountDetails'
import BusinessDetail from '../Steps/BusinessDetail'
import BankDetails from '../Steps/BankDetails'
import { MyFormValues } from './types'
import Summary from '../Steps/Summary'
import { BASE_URL } from '@ekenta/constants'
import { OnboardingFormStyle, SpinnerStyle, SuccessPopStyle } from '../../style'
import { DashboardPathUrl, emailVerifyPathUrl } from '@ekenta/routes/constant'
import {
  removeUser,
  setUser,
} from '@ekenta/redux/store/states/reducerSlice/authenticationSlice'
import { removeLive } from '@ekenta/redux/store/states/reducerSlice/livecomSlice'
import { CustomModal } from '@ekenta/components'
import SuccessCheckSVG from '@ekenta/assets/icons/success-checkmark.svg'
import LoaderSVG from '@ekenta/assets/icons/loader-2.svg'

interface OnboardingType {
  page: number
  nextPage: () => void
  prevPage: () => void
}

const OnboardingForm = ({ page, nextPage, prevPage }: OnboardingType) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { ek_isAuth, account, token } = useAppSelector(
    (state) => state.auth.authentication
  )

  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const [initialValue, setIntialValue] = useState({
    personal_detail: {
      firstname: '',
      lastname: '',
      username: '',
      phonenumber: '',
      email: '',
      password: '',
      confirmpassword: '',
      referedby: '',
    },
    business_detail: {
      logo: null,
      businessname: '',
      address: '',
      state: { id: '', name: '' },
      city_or_town: { id: '', name: '' },
      country: '',
      proof_of_work: null,
      business_phone_number: '',
      business_registration_number: '',
      vat_registration_number: '',
      seller_vat: '',
      tax_identification_number: '',
    },
    bank_detail: {
      bank: '',
      account_name: '',
      account_number: '',
      bank_code: '',
      bankSwift: '',
      bankIban: '',
      bank_information: '',
      bankBvn: '',
    },
    acceptTermsAndConditions: false,
    acceptOnboardingPolicy: false,
    token: '',
    user_id: '',
  })

  const formik = useFormik<MyFormValues>({
    initialValues: initialValue,
    enableReinitialize: true,
    validationSchema: sellerOnboardingFormValidation(),
    onSubmit: (values) => {
      if (ek_isAuth && token) {
        const user_id = account.user.id
        handleOnboardingRegisteredUserSignUp(values, user_id, token)
      } else {
        handleOnboardingRegisteredUserSignUp(
          values,
          values.user_id,
          values.token
        )
      }
    },
  })

  useEffect(() => {
    if (ek_isAuth && token && account.user) {
      nextPage()
      setIntialValue({
        ...initialValue,
        personal_detail: {
          firstname: account.user.firstName,
          lastname: account.user.lastName,
          username: account.user.username,
          phonenumber: '+2349032418972',
          email: account.user.email,
          password: '********',
          confirmpassword: '********',
          referedby: '',
        },
      })
    }
  }, [])

  const handleOnboardingRegisteredUserSignUp = (
    values: any,
    user_id: string,
    token: string
  ) => {
    if (values.business_detail.proof_of_work) {
      const email = values.personal_detail.email
      const data = new FormData()
      data.append('user_id', user_id)
      data.append(
        'accept_terms_and_conditions',
        values.acceptTermsAndConditions
      )
      data.append('business_name', values.business_detail.businessname)
      data.append('bank_account_name', values.bank_detail.account_name)
      data.append('bank_account_number', values.bank_detail.account_number)
      data.append('bank_name', values.bank_detail.bank)
      data.append('proof_of_work', values.business_detail.proof_of_work)
      data.append(
        'business_registration_number',
        values.business_detail.business_registration_number
      )
      data.append(
        'business_phone_number',
        values.business_detail.business_phone_number
      )
      data.append('address', values.business_detail.address)
      data.append('state_id', values.business_detail.state.id)
      data.append('city_id', values.business_detail.city_or_town.id)
      data.append('country', values.business_detail.country)

      sellerOnboardingSignUp(data, email, token)
    }
  }

  const sellerOnboardingSignUp = useCallback(
    (data: any, email: string, token: string) => {
      const config = {
        method: 'POST',
        url: `${BASE_URL}/api/create-merchant-business`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      }
      setIsLoading(true)
      axios(config)
        .then(function (response) {
          setIsLoading(false)

          if (ek_isAuth && token) {
            setIsOpen(true)
            // copy the user credentials and token
            const new_account = account.user
            const newToken = token

            //Clear Local Storage
            googleLogout()
            dispatch(removeUser())
            dispatch(removeLive())
            //Update local storage with new role
            dispatch(
              setUser({
                user: {
                  ...new_account,
                  role: response.data.user.role,
                },
                token: newToken,
                anonymousUser: false,
                ek_isAuth: true,
              })
            )

            setTimeout(() => {
              navigate(DashboardPathUrl, {
                replace: true,
              })
            }, 1600)
          } else {
            setIsOpen(true)

            setTimeout(() => {
              navigate(emailVerifyPathUrl, {
                state: {
                  email: email,
                },
                replace: true,
              })
            }, 1600)
          }
        })
        .catch(function (errors) {
          if (errors.response.status === 500) {
            toast.error(`Something went wrong`, {
              toastId: '-toggle-error-500-id-toast-error',
            })
          }
          if (errors.response.status === 401) {
            mapErrors(errors.response.data.errors)
          }
          setIsLoading(false)
        })
    },
    []
  )

  function mapErrors(errors: string[]) {
    for (const [key, value] of Object.entries(errors)) {
      toast.error(`${value[0]}`, {
        toastId: `onbording-${key}-toast-error`,
      })
    }
  }

  return (
    <OnboardingFormStyle>
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={formik.handleSubmit}>
          {page === 0 && <AccountDetails nextPage={nextPage} />}

          {page === 1 && <BusinessDetail nextPage={nextPage} />}

          {page === 2 && <BankDetails nextPage={nextPage} />}

          {page === 3 && <Summary prevPage={prevPage} isLoading={isLoading} />}
        </Form>
      </FormikProvider>

      <CustomModal
        show={isOpen}
        size="sm"
        centered={false}
        showFooter={false}
        isLoading={false}
        isDisabled={false}
        render={
          <SuccessPopStyle>
            <div className="SuccessIcon__Style">
              <SVG src={SuccessCheckSVG} />
            </div>
            <h5>{ek_isAuth ? 'Congratulation!' : 'OTP code sent to email!'}</h5>
            <p>
              {ek_isAuth
                ? 'seller account is ready to use'
                : 'check your email for you OTP code'}
              . You will be redirected to the{' '}
              {ek_isAuth ? 'Dashboard' : 'Email verification'} page in a few
              seconds..
            </p>
            <SpinnerStyle>
              <SVG className="spin" src={LoaderSVG} />
            </SpinnerStyle>
          </SuccessPopStyle>
        }
      />
    </OnboardingFormStyle>
  )
}

export default OnboardingForm
