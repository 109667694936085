/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { FiChevronDown, FiDelete, FiEdit3, FiExternalLink, FiPlus } from "react-icons/fi";
import styled from "styled-components";
import {
    useCreateEventMutation,
    useCreateOfferMutation,
    useDeleteOfferMutation,
    useFetchAllEventQuery,
    useFetchAllOffersQuery,
} from "@ekenta/redux/store/services/adminSellerApi";
import CustomModal from "@ekenta/components/Modal/CustomModal";
import { withDashboardLayout } from "@ekenta/hoc";
import { Pagination, Button, Dropdown, DataTable, PageTitle, HelmetHeader } from "@ekenta/components";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { AdminDashoardEkeMarketPathUrl, DashboardSellers } from "@ekenta/routes/constant";
import { useLocation, useNavigate } from "react-router";
import { VscArrowSmallLeft } from "react-icons/vsc";

import "./Events.scss";

const mapDataAddUrl = (data: any, urls: { title: string; url: string }[]) => {
    const newData = [];
    if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < urls.length; j++) {
                if (data[i].title.toLowerCase() == urls[j].title.toLowerCase()) {
                    newData.push({
                        ...data[i],
                        url: `${urls[j].url}?_id=${data[i].id}`,
                        img: <img style={{ height: '50px' }} className="img-thumbnail rounded" src={data[i].body} alt="" />
                    });
                } else {
                    newData.push({
                        ...data[i],
                        url: "",
                        img: <img style={{ height: '50px' }} className="img-thumbnail rounded" src={data[i].body} alt="" />
                    });
                }
            }
        }
    }
    return newData;
};

const Offers = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { token } = useAppSelector((state) => state.auth.authentication);

    const [modal, setModal] = useState(false);
    const [img, setImg] = useState({
        img: ""
    });
    const [event, setEvent] = useState({
        title: "",
        image: "",
        link: "",
        type: "",
    });
    const [iserror, setisError] = useState("");
    const [
        deleteOfferCall,
        { data: deleteOffer_data, isSuccess: deleteOffer_isSuccess },
    ] = useDeleteOfferMutation();

    const [createOffer, result] = useCreateOfferMutation();
    const allEvent = useFetchAllOffersQuery(token);

    useEffect(() => {
        if (result?.isSuccess) {
            setModal(false);
        }
    }, [result?.isSuccess]);

    const handleModal = () => setModal(true);

    const handleSubmit = () => {
        setisError("");
        console.log(event.title, img.img, event.link, event.type)
        if (event.title && img.img && event.link && event.type) {

            const data = new FormData();
            data.append("title", event?.title);
            data.append("image", img?.img);
            data.append("link", event?.link);
            data.append("type", event?.type);
            data.append("body", "body");

            createOffer({
                data,
                token,
            });
        } else {
            setisError("*Field is required");
        }
    };

    const navigateTo = (url: string) => {
        if (url) {
            navigate(url);
        }
    };

    const urls = [
        {
            title: "ekemarket",
            url: AdminDashoardEkeMarketPathUrl,
        },
    ];
    const columns = React.useMemo(
        () => [
            {
                Header: "Title",
                accessor: "title",
                sortType: "basic",
            },
            {
                Header: "Image",
                accessor: "img",
                sortType: "basic",
            },
            {
                Header: "type",
                accessor: "type",
                disableSortBy: true,
            },
            {
                Header: "Url",
                accessor: "link",
                disableSortBy: true,
                Cell: ({ value }: any) => {
                    return (
                        <span
                            className="ViewExternal__link"
                        >
                            <a href={value} target="_blank" rel="noopener noreferrer">
                                <FiExternalLink />
                                view link
                            </a>

                        </span>
                    );
                },
            },
            {
                Header: "Action",
                accessor: "id",
                disableSortBy: true,
                Cell: ({ value }: any) => {
                    return (
                        <Dropdown
                            name="more"
                            suffixIcon={<FiChevronDown />}
                            placement="bottom"
                            tooltipContent=""
                            menus={[
                                {
                                    name: "Edit Offer",
                                    icon: <FiEdit3 />,
                                    active: false,
                                    key: "live-code-key",
                                    handler: () => console.log(value),
                                },
                                {
                                    name: "Delete Offer",
                                    icon: <FiDelete />,
                                    active: false,
                                    key: "live-code-key",
                                    handler: () => deleteOfferCall({ offerId: value, token }),
                                },
                            ]}
                        />
                    )
                }
            }
        ],
        [],
    );



    return (
        <div className="EventDiv_Wrapper">
            <HelmetHeader title="Offers " description="Qlason Offers Page" />
            <Col className="AppEvents_col">
                <PageTitle
                    title="Offers"
                    subTitle={
                        <span
                            className="cursor-pointer d-flex align-items-center
              text-muted"
                            onClick={() => navigate(DashboardSellers)}>
                            <VscArrowSmallLeft />
                            Back to Dashboard
                        </span>
                    }
                >
                    <Button
                        bgColor="white"
                        prefixIcon={<FiPlus />}
                        name="Add Offer"
                        onClick={handleModal}
                    />
                </PageTitle>
            </Col>
            <Col>
                <DataTable
                    theme="simple"
                    data={allEvent?.data ? mapDataAddUrl(allEvent?.data.offers, urls) : []}
                    loading={allEvent?.isLoading}
                    isSelectable={true}
                    columns={columns}
                    showPagination={false}
                    thClassName="w"
                    trClassName="w"
                    tdClassName={`Row__col`}
                    renderPagnation={() => (
                        <>
                            {allEvent?.data.offers && allEvent?.data.offers.length > 0 ? (
                                <div className="mt-3">
                                    <Pagination
                                        pathname={pathname}
                                        itemPerPage={1}
                                        itemsLength={10}
                                        currentPage={1}
                                    />
                                </div>
                            ) : null}
                        </>
                    )}
                />
            </Col>
            <CustomModal
                title="Add Offer"
                show={modal}
                centered={false}
                showFooter={true}
                onHide={() => {
                    setModal(false);
                    setEvent({ title: "", image: "", type: "", link: "" });
                }}
                isLoading={result?.isLoading}
                onSubmit={handleSubmit}
                render={
                    <RenderComponent event={event} error={iserror} setEvent={setEvent} setImg={setImg} img={img} />
                }
            />
        </div>
    );
};

const RenderComponent = (props: {
    setImg: React.Dispatch<
        React.SetStateAction<{ img: string }>
    >,
    img: {
        img: string
    },
    event: {
        title: string,
        image: string,
        type: string,
        link: string
    };
    error: string;
    setEvent: React.Dispatch<
        React.SetStateAction<{
            title: string;
            image: string;
            type: string;
            link: string;
        }>
    >;
}) => {
    const handleImage = (e: any) => {
        // console.log(e.target.files[0])
        props.setImg({ img: e.target.files[0] })

        // console.log(formData)
    }
    return (
        <DIV>
            <div className="mb-2">
                <label>Title</label>
                <Input
                    type="text"
                    height={"400px"}
                    name="addevent"
                    placeholder="Title"
                    value={props.event.title}
                    onChange={(e) =>
                        props.setEvent({ ...props.event, title: e.target.value })
                    }
                />
                {props.error && !props.event.title ? <small>{props.error}</small> : null}
            </div>
            <div className="mb-2">
                <label>Image (jpg,png,gif)</label>
                <Input
                    type="file"
                    height={"400px"}
                    name="addevent"
                    placeholder="image"
                    // value={props.img.img}
                    onChange={(e) => handleImage(e)}
                />
                {props.error && !props.event.image ? <small>{props.error}</small> : null}
            </div>
            <div className="mb-2">
                <label>Link</label>
                <Input
                    type="text"
                    height={"400px"}
                    name="addevent"
                    placeholder="Link"
                    value={props.event.link}
                    onChange={(e) =>
                        props.setEvent({ ...props.event, link: e.target.value })
                    }
                />
                {props.error && !props.event.link ? <small>{props.error}</small> : null}
            </div>
            <div className="mb-2">
                <label>Type</label>
                <Form.Select
                    onChange={(e) =>
                        props.setEvent({ ...props.event, type: e.target.value })
                    }
                >
                    <option>Select one</option>
                    <option value="ALL">ALL</option>
                    <option value="BUYER">BUYER</option>
                    <option value="SELLER">SELLER</option>
                </Form.Select>
                {props.error && !props.event.type ? <small>{props.error}</small> : null}
            </div>
        </DIV>
    );
};

const DIV = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    font-size: 13px;
    margin-bottom: 5px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 400;
  }

  small {
    font-size: 12px;
    color: red;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
`;

export default withDashboardLayout(Offers);
