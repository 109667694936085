import { Switch } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import { Button, Card, Col, Spinner, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FaPlus } from 'react-icons/fa';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import TableComponent from '../../../../components/Dashboard/components/TableComponent';
import DashboardComponent from '../../component/DashboardComponent';
import DashboardLayout from '../../../../hoc/Layout/Dashboard-Layout';
import { useAppSelector } from '../../../../redux/store/hook';
import {
  useDeleteSubCollectionMutation,
  useFetchAdminAllSubCollectionsQuery,
  useFetchAllCollectionsQuery,
  useFetchAllSubCollectionsQuery,
} from '../../../../redux/store/services/collectionApi';
import {
  AddCollectionUrl,
  AddSubCollectionUrl,
} from '../../../../routes/constant';
import axios from 'axios';
import { BASE_URL } from '@ekenta/constants';
import { toast } from "react-toastify";
import { DataTable } from '@ekenta/components';
import { SpinLoader } from '@ekenta/components/Spinner/SpinLoader';

export default function AdminSubCollection({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}: any) {
  const navigate = useNavigate();
  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const result = useFetchAdminAllSubCollectionsQuery(token);
  const [_pageSize, setPageSize] = useState(5);
  const [
    deleteCollectionCall,
    { data: deleteCollection_data, isSuccess: deleteCollection_isSuccess },
  ] = useDeleteSubCollectionMutation();


  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState([])

  const handleDeleteCollection = async (id: any) => {
    // alert(id)
    deleteCollectionCall({ subCollectionId: id, token });
  };

  const toggleFunction = useCallback((id: string, data: any) => {
    const config = {
      method: 'post',
      url: `${BASE_URL}/api/admin/update-sub-collection/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    setLoading(true);
    axios(config)
      .then(function (res) {
        setLoading(false);
        toast.success(`${res.data.message}`, {
          toastId: "-coll-toggle-success-id-toast",
        });
        result.refetch()
      })
      .catch(function () {
        setLoading(false);
        toast.error(`Something went wrong`, {
          toastId: "-coll-error-400-id-toast-error",
        });
        result.refetch()

      });
  }, [result]);

  useEffect(() => {
    if (deleteCollection_isSuccess) {
      window.location.reload();
    }
  }, [deleteCollection_isSuccess]);

  const columns = [
    {
      Header: 'Picture',
      accessor: 'image',
      sortType: 'basic',
      disableSortBy: true,
    },

    {
      Header: 'Name',
      accessor: 'name',
      sortType: 'basic',
    },
    {
      Header: 'Collection',
      accessor: 'collectionName',
      sortType: 'basic',
      disableSortBy: true,
    },
    {
      Header: 'Sub Collection',
      accessor: 'subCollectionCatName',
      sortType: 'basic',
    },
    {
      Header: `Visibility`,
      accessor: 'visibility',
      disableSortBy: true,
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      disableSortBy: true,
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      disableSortBy: true,
    },
  ];

  const mapData = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      image: (
        <div
          className="picture__thumb"
          style={{ width: '70px', height: 'auto' }}
        >
          <img
            className="img-thumbnail rounded"
            width="100%"
            height="70px"
            src={item?.image_url}
          />
        </div>
      ),
      name: item?.name,
      collectionName: item?.collectionName,
      subCollectionCatName: item?.subCollectionCatName,
      visibility: <Switch
        defaultChecked={item?.visibility}
        onChange={() => {
          const data = {
            name: item?.name,
            description: item?.description,
            sub_collection_category_id: item?.sub_collection_category_id,
            visibility: !item?.visibility ? true : false,
          }
          toggleFunction(item?.id, data);
        }}
      />,
      edit: <FiEdit
        className="cursor-pointer text-primary"
        onClick={() =>
          navigate(`/admin/sub-collection/${item?.id}`)
        }
        fontSize={15}
      />,
      delete: loading ? <Spinner animation="grow" /> :
        <FiTrash2 onClick={() => handleDeleteCollection(item?.id)} className="cursor-pointer" fontSize={15} />

    }));
  };

  const handleSearch = (keyword: string) => {
    if (keyword) {
      const searchResultData = result?.data?.collections.filter((e: any) => e?.name?.includes(keyword))
      setSearchResult(searchResultData)
    }
  };

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);



  //

  return (
    <DashboardLayout>
      <Helmet>
        <title>Seller Dashboard - Qlason</title>
        <meta charSet="utf-8" />
        <meta name="title" content="QlasonAll Product Page" />
        <meta name="description" content="QlasonAll Product  Page" />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <Col className=" col-lg-11 mx-auto _listing_product_ mt-1 px-2">
          <div className="mt-0">
            <h3 className="display_lg_header">Sub-Collections</h3>
            <Col className="mt-4">
              <Button
                className="mb-3 text-white"
                onClick={() => navigate(AddSubCollectionUrl)}
              >
                <FaPlus /> Add New Sub-Collection
              </Button>
              <Card>
                <Card.Body>
                  {
                    result.isSuccess ?
                      <DataTable
                        theme="simple"
                        data={

                          searchValue
                            ? mapData(searchResult)
                            : mapData(result?.data?.collections)
                        }
                        loading={result?.isLoading || result?.isFetching}
                        isSelectable={true}
                        columns={columns}
                        showPagination={false}
                        hasLink={true}
                        thClassName="w"
                        trClassName="w"
                        tdClassName={`Row__col`}
                        align="left"
                        searchValue={searchValue}
                        showSearch={true}
                        onChange={(e: any) => setSearchValue(e.target.value)}
                      // renderPagnation={
                      // <div className="mt-4">
                      //   {!searchValue
                      //     ? result?.data
                      //       ? result?.data.product.total > 19 && (
                      //           <Pagination
                      //             pathname={pathname}
                      //             itemPerPage={result?.data.product.per_page}
                      //             itemsLength={result?.data.product.total}
                      //             currentPage={pageno}
                      //             queryNo={_page}
                      //             callback={(value) => setPageno(value)}
                      //           />
                      //         )
                      //       : null
                      //     : null}
                      // </div>
                      // }
                      /> : <SpinLoader size={3} variant="dark" />
                  }

                  {/* <Table striped hover className="custom_table_listing">
                    <thead>
                      <tr>
                        <th>Picture</th>
                        <th>Name</th>
                        <th>Collection</th>
                        <th>Sub Collection Category</th>
                        <th>Visibility</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!result.isSuccess ? (
                        <div>Loading</div>
                      ) : (
                        result?.data?.collections?.map((e: any, i: any) => {
                          if (e?.deleted == null || e?.deleted == false) {
                            return (
                              <tr key={i}>
                                <td>
                                  <div className="picture__thumb">
                                    <img
                                      className="img-thumbnail rounded"
                                      src={e?.image_url}
                                    />
                                  </div>
                                </td>
                                <td>{e?.name}</td>
                                <td>
                                  {e?.collectionName}
                                </td>
                                <td>
                                  {e?.subCollectionCatName}
                                </td>
                                <td>
                                  <Switch
                                    defaultChecked={e?.visibility}
                                    onChange={() => {
                                      const data = {
                                        name: e?.name,
                                        description: e?.description,
                                        sub_collection_category_id: e?.sub_collection_category_id,
                                        visibility: !e?.visibility ? true : false,
                                      }
                                      toggleFunction(e?.id, data);
                                    }}
                                  />
                                </td>
                                <td>
                                  <FiEdit
                                    className="cursor-pointer text-primary"
                                    onClick={() =>
                                      navigate(`/admin/sub-collection/${e?.id}`)
                                    }
                                    fontSize={15}
                                  />
                                </td>
                                <td>
                                  {
                                    loading ? <Spinner animation="grow" /> :
                                      <FiTrash2 onClick={() => handleDeleteCollection(e?.id)} className="cursor-pointer" fontSize={15} />
                                  }
                                </td>
                              </tr>
                            );
                          }
                        })
                      )}
                    </tbody>
                  </Table> */}
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Col>
      </DashboardComponent>
    </DashboardLayout>
  );
}
