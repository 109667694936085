import React from 'react'
import styled from '@emotion/styled'
import { VoucherCard } from '@ekenta/components'
import { allVoucherPathUrl } from '@ekenta/routes/constant'
import { useGetAllAdminVouchersQuery } from '@ekenta/redux/store/features/voucher/voucherApiSlice'
import SectionCard from '../Sectioncard'

const Vouchers = () => {
  const { data } = useGetAllAdminVouchersQuery()
  const allVoucher = data?.data?.data ?? []
  return (
    <>
      {allVoucher?.length > 0 ? (
        <SectionCard title="Vouchers" size="small" url={allVoucherPathUrl}>
          <ContainerWrapper>
            <ContentStyle>
              {allVoucher?.length == 0 ? <div>No Voucher</div> : null}
              {allVoucher?.map((item, index) => (
                <VoucherCard
                  key={item.id}
                  index={index + 1}
                  voucher_id={item.id}
                  cost={item.cost}
                />
              ))}
            </ContentStyle>
          </ContainerWrapper>
        </SectionCard>
      ) : null}
    </>
  )
}

const ContainerWrapper = styled.div`
  width: 100%;
  margin-bottom: 21px;
  max-height: auto;
  position: relative;
`
const ContentStyle = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  gap: 15px;
  -ms-overflow-style: none;
  scrollbar-width: none !important;
  margin-top: 0 !important;
  animation: slide 20s linear infinite;

  .swiper-slide {
    width: 230px !important;
  }
`

export default Vouchers
