import * as React from 'react';
import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon className='mr-3' fontSize='large' htmlColor='#dc3545' />, //deep red
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon className='mr-3' fontSize='large' htmlColor='#ff4500' />,// light red
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon className='mr-3' fontSize='large' htmlColor='gray' />, //grey
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon className='mr-3' fontSize='large' htmlColor='#232f3e' />, //primary
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon className='mr-3' fontSize='large' htmlColor='green' />, //green
    label: 'Very Satisfied',
  },
};

interface CallProps {
  onChange: (params: any) => any;
}


function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

export default function SmileyRating({onChange}:CallProps) {

  const onchangeValue = (e:any)=>{
    const button = e.target as HTMLButtonElement;
    onChange(button.value)
  }
  return (
    <StyledRating
      onChange={(e)=>onchangeValue(e)}
      name="highlight-selected-only"
      // defaultValue={2} 
      IconContainerComponent={IconContainer}
      getLabelText={(value: number) => customIcons[value].label}
      highlightSelectedOnly
    />
  );
}
