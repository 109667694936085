import React from 'react'
import { Spinner } from 'react-bootstrap'

export const SpinLoader = (props:{size:number;variant:string}) => {
  return (
    <div className="w-100 d-flex align-items-center justify-content-center" style={{width:"100%",height:"80vh"}}>
      <Spinner style={{width: `${props.size}rem`, height:  `${props.size}rem`}}  animation="border" variant={props.variant} />
      </div>
  )
}
