import classNames from 'classnames'
import React from 'react'
import styles from "./reels.module.scss";

export default function Video({ src }: any) {
    return (
        <video
            className={classNames(styles.ReelsVideo)}
            // onEnded={handleAutoScroll}
            // onClick={handleMute}
            controls
            // muted='muted'

            autoPlay
        >
            <source
                src={src}
                type='video/webm'
            />
        </video>
    )
}
