import React from 'react'
import { Card, Container, Col } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';


export default function OrderSkeletonLoader(props:any) {
  return (
    <Container className="skeleton-loader-bases">
       <div className="collection-  border-none">
        <Card className="pt-4 pl-0 ">
                <Col xs={12} className='pl-0'>
                    <ContentLoader 
                    speed={2}
                    width={400}
                    height={200}
                    viewBox="0 0 500 260"
                    backgroundColor="#e0e0e0"
                    foregroundColor="#ecebeb"
                    {...props}
                    >
                        <rect x="0" y="0" rx="5" ry="5" width="250" height="200" />
                        <rect x="288" y="5" rx="5" ry="35" width="700" height="10" /> 
                        <rect x="288" y="25" rx="5" ry="35" width="150" height="10" /> 
                        <rect x="288" y="150" rx="0" ry="0" width="100" height="15" /> 

                    </ContentLoader>
                </Col>
                <Col className="pt-0 pl-0" xs={12} >
                    <ContentLoader 
                    speed={2}
                    width={400}
                    height={200}
                    viewBox="0 0 500 260"
                    backgroundColor="#e0e0e0"
                    foregroundColor="#ecebeb"
                    {...props}
                    >
                        <rect x="0" y="0" rx="5" ry="5" width="250" height="200" />
                        <rect x="288" y="5" rx="2" ry="35" width="800" height="10" /> 
                        <rect x="288" y="25" rx="5" ry="35" width="150" height="10" /> 
                        <rect x="288" y="150" rx="0" ry="0" width="100" height="15" /> 
                    </ContentLoader>
                </Col>
        </Card>
       </div>
    </Container>
  )
}
