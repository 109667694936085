import React, { useState } from 'react'
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc';
import styled, { css } from 'styled-components';

export const SubMenu = (props: { className?: string; active: boolean; icon: any; text: string; children: React.ReactNode }) => {
    const [visible, setVisible] = useState(props.active || false);

    const handleDropdown = () => setVisible((prevState) => !prevState);
    return (
        <li className="sub-menu ">
            <ButtonStyle active={props.active} className="cursor-pointer sub-menu-href" onClick={handleDropdown}>
                <div className="submenu_Round"></div>
                <div className="submenu_inner_wrap">
                    <div className="left-icon-text">
                        {props.icon}
                        <span>{props.text}</span>
                    </div>
                    <span className="icon-arrow">
                        {visible ? <VscChevronUp /> : <VscChevronDown />}
                    </span>
                </div>
            </ButtonStyle>
            <ul className={`sub-menu-ul  w-100 ${visible ? 'visible-dropdown' : 'not-visible-dropdown'}`} style={{ paddingLeft: '18px' }}>
                {props.children}
            </ul>
        </li>
    )
}

const ButtonStyle = styled.button<{ active: boolean }>`
    width: 100%;
    outline: none !important;
    border: none !important;
    display: flex;
    align-items: center;
    //gap:6px;
    padding-left: 0 !important;
    padding-right: 5px !important;
    background-color: transparent;
    border-radius: 45px;

    &:focus{
        outline: none;
        border: none;
    }


  

    .submenu_Round{
        /* width: 12px;
        height: 48px; */
        border-radius: 0px 40px 40px 0px;
        //background: var(--primary-500, #F8880A);
    }

    .submenu_inner_wrap{
        padding: 10px 20px 10px 0px !important;
        padding-left: 25.55px !important;
        width: 100%;
        height: auto;
        text-decoration: none;
        //color:  #adadad; // rgb(93, 98, 145);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0rem !important;
        color: var(--greyscale-700, #000);
        font-family: var(--eke-body-font-family);
        font-size: 13.5px;
        font-weight: var(--eke-font-weight-normal) !important;
        /* line-height: 140%; 
        letter-spacing: 0.2px; */
        border-radius: 45px;

        ${props => props.active && css`
            background:var(--eke-color-primary);
            color: var(--eke-color-white) !important;
        `}

        .left-icon-text{
            flex:1;
            display: flex;
            align-items: center;
            gap: 0.5rem !important;
            svg{
                display: inline-block;
                height: 15px !important;
                width: 15px;
                font-size: 14px;
                text-align: center;
            }

            span{
                    display: inline-block;
                    line-height: 20px;
                    text-align: center;
                }
        }

        transition: 0.3s ease-in-out;

        &:hover{
      background: var(--eke-color-primary);
    color: var(--eke-color-white) !important;

    svg{
      fill: var(--eke-color-white) !important;
    }
            
        }
    }
`;