import React from 'react';
import { Helmet } from 'react-helmet';
import DashboardComponent from '../component/DashboardComponent';
import DashboardLayout from '../../../hoc/Layout/Dashboard-Layout';


export default function ManageVideo({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}: any) {
  return (
    <DashboardLayout>
      <Helmet>
        <title>Admin Dashboard - Qlason</title>
        <meta charSet="utf-8" />
        <meta name="title" content="QlasonOrders Dashboard Page" />
        <meta name="description" content="QlasonOrders Dashboard  Page" />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <div className=" col-lg-11 mx-auto _listing_product_">
        </div>
      </DashboardComponent>
    </DashboardLayout>
  );
}
