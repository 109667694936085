import React, { Fragment } from "react"
import styled from "styled-components";
import { loginPathUrl } from "@ekenta/routes/constant"
import { useAppSelector } from "@ekenta/redux/store/hook";
import { RefreshLink } from "@ekenta/components";

const SignInLink = () => {
    const { token } = useAppSelector(state => state.auth.authentication);
    return (
        <Fragment>
            {!token ? (
                <Container>
                    <span>Already have an account?</span>
                    <RefreshLink to={loginPathUrl}> Sign in here! </RefreshLink>
                </Container>
            ) : null}
        </Fragment>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    span{
        color: var(--greyscale-700, #616161);
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-normal);
        font-size: 14px;
        line-height: 140%; /* 19.6px */
        letter-spacing: 0.2px;
    }

    a{
        color: var(--eke-color-primary);
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-bold);
        font-size: 14px;
        line-height: 140%; /* 19.6px */
        letter-spacing: 0.2px;
        text-decoration: none;

        &:hover{
            text-decoration: underline;
        }
    }
`;

export default SignInLink;