/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Col } from 'react-bootstrap'
import { slugifyText } from '../../../utils';
import { categoryBaseUrl, collectionBaseUrl } from '../../../routes/constant';
import { RefreshLink } from '@ekenta/components';


interface SingleCollectionCardPropsType {
    collectionName: string;
    collectionSlug: string;
    subCatgories: any[];
}
export default function SingleCollectionCard(props: SingleCollectionCardPropsType) {

    return (
        <>
            {/* {props.subCatgories.length > 0 && ( */}
            <Col className="single__col" xs={12} lg={4}>
                <Card>
                    <Card.Body>
                        <div className="left__v_content">
                            <h5 className="m0a">
                                <RefreshLink to={`${collectionBaseUrl}/${props.collectionSlug}`}>{props.collectionName}</RefreshLink>
                            </h5>
                            <ul className="pt5 pt list">
                                {props.subCatgories.length > 0 && props.subCatgories.map((item: any, index: number) => {
                                    const _slugCategory = slugifyText(item.name.toLowerCase(), item.id);
                                    return (
                                        <li key={item.id + '__' + index} className="pvm s_list">
                                            <RefreshLink
                                                to={`${categoryBaseUrl}/${_slugCategory}`}
                                                state={{ "newPath": true, url: `${categoryBaseUrl}/${_slugCategory}` }}
                                                replace>
                                                {item.name}
                                            </RefreshLink>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="right_ic"></div>
                    </Card.Body>
                </Card>
            </Col>
            {/* )} */}
        </>
    )
}
