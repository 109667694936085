import React, { FC, useEffect, useState } from 'react'
import moment from 'moment'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ReactComponent as Arrow } from '@ekenta/assets/icons/Icon-right.svg'
import { ReactComponent as CopyIcon } from '@ekenta/assets/icons/copy-icon.svg'
import { ReactComponent as TwitterIcon } from '@ekenta/assets/icons/twitter-icon.svg'
import { ReactComponent as FacebookIcon } from '@ekenta/assets/icons/facebook-ico.svg'
import { ReactComponent as LinkedinIcon } from '@ekenta/assets/icons/linkedin-icon.svg'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'

interface PropType {
  title: string
  date: string
  summary: string
  image_url: string
  written_by: string
}

const TopHeader: FC<PropType> = (props) => {
  const { title, date, image_url,summary, written_by } = props
  const [copied, setCopied] = useState(false)

  const pageUrl = window.location.href

  useEffect(() => {
    if (copied) {
      toast.success(`Link copied`)
      setTimeout(() => setCopied(false), 1500)
    }
  }, [copied])

  console.log(pageUrl)
  return (
    <HeaderStyle>
      <div className="top-section">
        <div className="post--read">
          <span>8 min read</span>
          <Arrow />
        </div>
        <h1>{title}</h1>
      </div>
      <div className="bottom-section">
        <div className="group-item">
          <div>
            <span>Written by</span>
            <h5>{written_by}</h5>
          </div>
          <div>
            <span>Published on</span>
            <h5>{date && moment(date).format('DD MMM, YYYY')}</h5>
          </div>
        </div>
        <div className="buttons-group">
          <CopyToClipboard text={pageUrl} onCopy={() => setCopied(true)}>
            <button className="copy-button">
              <CopyIcon />
              <span>{copied ? 'Copied!!' : 'Copy link'}</span>
            </button>
          </CopyToClipboard>
          <TwitterShareButton url={pageUrl} title={title}>
            <button className="icon-button">
              <TwitterIcon />
            </button>
          </TwitterShareButton>
          <FacebookShareButton url={pageUrl} quote={title} hashtag="">
            <button className="icon-button">
              <FacebookIcon />
            </button>
          </FacebookShareButton>
          <LinkedinShareButton
            url={pageUrl}
            title={title}
            summary={summary}
            source=""
          >
            <button className="icon-button">
              <LinkedinIcon />
            </button>
          </LinkedinShareButton>
        </div>
      </div>

      <div className="banner-section">
        <img src={image_url} alt={title} />
      </div>
    </HeaderStyle>
  )
}

const HeaderStyle = styled.div`
  padding-top: 25px;
  font-family: var(--eke-body-font-family) !important;

  .top-section {
    margin-bottom: 20px;
    .post--read {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 101px;
      padding: 4px 10px 4px 10px;
      height: 26px;
      gap: 8px;
      border-radius: 16px;
      background-color: #ffc960;

      span {
        font-family: var(--eke-body-font-family);
        font-size: 12.5px;
        color: #113b16;
        font-weight: 500 !important;
      }
    }
    h1 {
      font-size: 48px;
      line-height: 60px;
      font-weight: 600 !important;
      color: #101828;
      font-family: var(--eke-body-font-family);
      margin-top: 15px;
    }
  }

  .bottom-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .group-item {
      display: flex;
      align-items: center;
      gap: 64px;

      span {
        color: var(--eke-color-primary);
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }

      h5 {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #101828;
        margin-top: 7px;
      }
    }

    .buttons-group {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      gap: 12px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: auto;
        height: 40px;
        border: 1px solid #d0d5dd;
        padding: 10px;
        border-radius: 8px;
        background-color: #ffffff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        span {
          font-size: 14px;
          font-weight: 600 !important;
          line-height: 20px;
          color: #344054;
        }
      }
      .copy-button {
        padding: 10px 16px 10px 16px;
      }
      .icon-button {
        width: 40px;
      }
    }
  }

  .banner-section {
    width: 100%;
    height: 644px;
    background-color: #eee;
    margin-top: 19px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

export default TopHeader
