/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import OrderSkeletonLoader from "@ekenta/components/SkeletonLoader/Account/OrderSkeletonLoader";
import EmptyOrder from "@ekenta/components/Box/EmptyOrder";
import MainOrder from "./mainOrder";
import { CustomAlert } from "@ekenta/components";

interface ActiveOrderPropsType {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  data: any;
}

export default function ActiveOrder(props: ActiveOrderPropsType) {
  const { data, isLoading, isError, isSuccess } = props;

  return (
    <div className="w-100" style={{ marginBottom: "100px" }}>
      {isLoading ? (
        <OrderSkeletonLoader />
      ) : (
        <>

          {isError ? (
            <CustomAlert
              variant="danger"
              show={true}
              message={"Something went wrong, Try again"}
            />
          ) : null}

          <div>
            {isSuccess && data ? (
              data.activeOrders.length > 0 ? (
                data.activeOrders.map((item: any, i: any) => {
                  return (
                    <MainOrder key={i} itemData={item} />
                  );
                })
              ) : (
                <EmptyOrder orderType="active" />
              )
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}
