import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import splashImg from '../../../../../assets/images/splash_welcome_background.png';

export default function WelcomeLiveCard({ handleSetGoLive, liveType, isLoading }: { isLoading: boolean; liveType: string | null; handleSetGoLive: () => void }) {
  return (
    <Container>
      <div className="container-wrapper">
        <div className="image_wrapper">
          <img src={splashImg} alt="" />
        </div>
        <div className="container-content">
          <h5>Welcome to the
            {liveType == 'isSellerLive' ? ' Qlason Live Stream' : ' Ekemarket Live Stream'}
          </h5>
          <p>Start selling in real-time today</p>

          <div className="ek_container-dialog _ekl">
            <p>

              {liveType == 'isSellerLive' ? 'Get set up to live stream now.' : 'Join the Ekemarket week'}
            </p>
            <button className={`${isLoading ? 'disabled' : ''}`} onClick={handleSetGoLive}>
              {isLoading && <Spinner size="sm" animation="border" variant="light" />}
              Continue
            </button>
          </div>
        </div>
      </div>
    </Container>
  )
}


const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
  .container-wrapper {
    width: 100%;
    max-width: 1520px;
    text-align: center;
    background-color: #1E293B;
    border-radius: 7px;
    color: whitesmoke;
    box-shadow: rgba(0, 0, 0, 0.10) 0px 2px 4px;
    margin: 15px;

    @media (min-width: 1200px) { 
        width: 45%;
        max-width: 600px;
        margin: 0;
    }

     .image_wrapper{
        width: 100%;
        overflow: hidden;
        border-radius: 6px 6px 0 0;
        height: 136px;
        margin: 0;
        background-color: #fc624d;
        
        img{
            width: 100% !important;
            object-fit: cover;
            object-position: 0 -34px;
        }

        }

     .container-content{
       padding: 2rem 1rem;
       @media (min-width: 1200px) { 
        padding: 2rem 2rem;
    }

       h5{
        text-align: center;
        font-size: 20px; 
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 8px;
        font-family: 'Noto Sans', sans-serif;
       }
       p{
        font-size: 15px;
        font-weight: 300;
        max-width: 350px;
        margin: 0 auto;
        margin-bottom: 30px;
        text-align: center;
        font-family: 'Noto Sans', sans-serif;
       }

       .ek_container-dialog{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            padding: 7px 18px 7px 0px;
            margin-top: 5px;
            background-color: #374151;
            
           

            p{
                display: none;
                font-size: 14px;
                margin-bottom: 0;
                font-weight: 500;
                font-family: 'Noto Sans', sans-serif;
            }

            button{
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 4px;
              border: none;
              width: 110px;
              height: 40px;
              margin-left: 10px;
              background-color: #000;
              border-radius: 7px;
              color: #fff;
              font-size: 14px;
              font-weight:600;
              font-family: 'Noto Sans', sans-serif;
              transition: 0.3s all ease-in-out;

              &:hover{
                background-color: rgba(0, 0, 0, 0.7)
              }
            }

            @media (min-width: 1200px) { 
              justify-content: space-between;
              p{
                display: flex;
                
              }
            }
       }
     }

  }
  
`