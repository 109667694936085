import React, { useState, Fragment } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Tab,
  Tabs,
  Stack,
  Form,
} from 'react-bootstrap';
import TableComponent from '../../../components/Dashboard/components/TableComponent';
import withDashboardLayout from '../../../hoc/Layout/withDashboardLayout';
import { useAppSelector } from '../../../redux/store/hook';
import {
  useFetchAllOrdersQuery,
  useFetchAdmniAllOrdersQuery,
} from '../../../redux/store/services/vendorProductApi';
import EmptyOrder from '../../../components/Box/EmptyOrder';
import {
  useFetchAccountingSalesAdminQuery,
  useFetchDailyTotalOrderQuery,
} from '../../../redux/store/services/adminSellerApi';
import {
  FiBarChart,
  FiBarChart2,
  FiChevronLeft,
  FiGift,
  FiToggleRight,
  FiUser,
  FiUserCheck,
} from 'react-icons/fi';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { MDBDataTable } from 'mdbreact';
import { HelmetHeader } from '@ekenta/components';

const AddLogo = () => {
  const [lgShow, setLgShow] = useState(false);
  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  // const allOrders = useFetchDailyTotalOrderQuery(token)
  // const ord = useFetchAccountingSalesAdminQuery(token)

  return (
    <Fragment>
      <HelmetHeader
        title="Add Logo - Dashboard - Qlason.com"
        description="QlasonAdd Logo Page"
      />

      <Col className=" col-lg-11 mx-auto _listing_product_">
        <div className="mt-0">
          <hr />
          <h3 className="display_lg_header">
            {/* <FiChevronLeft /> */}
            Add Logo Image
          </h3>
          <hr />

          <Col className="mt-3">
            <Card>
              <Card.Body>
                <Form.Label htmlFor="inputPassword5">Logo Title * </Form.Label>
                <Form.Control
                  type="text"
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                />
                <br />
                <Form.Label htmlFor="inputPassword5">Logo Image *</Form.Label>
                <Form.Control
                  type="file"
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                />
                <br />
                <Form.Label htmlFor="inputPassword5">Status</Form.Label>
                <Form.Select>
                  <option>Active</option>
                  <option>Inactive</option>
                </Form.Select>
                <br />
                <Button variant="danger">Submit</Button>{' '}
                <Button variant="outline-danger">Cancel</Button>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </Col>
    </Fragment>
  );
};

export default withDashboardLayout(AddLogo);
