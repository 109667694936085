import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { setChatCountAsync } from './chatThunk';

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    count: 0,
  },
  reducers: {
    setChatCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload
    },
    emptyCount: (state) => {
      state.count = 0
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setChatCountAsync.fulfilled, () => {
      console.log('update count');
    });
  },
})

export const { setChatCount, emptyCount } = chatSlice.actions

export default chatSlice.reducer;
