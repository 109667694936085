import * as Yup from 'yup';

const newPasswordFormValidation = () => Yup.object({
    emailCode: Yup.string()
                .min(6,'Pin must be 6 characters')
                .required('Pin field is required'),
    password: Yup.string()
                  .required('Password field is required')
                  .min(6, 'Password is short - should be 6 chars minimum'),
    confirmPassword: Yup.string()
                  .required('Confirm Password field is required')
                  .oneOf([Yup.ref('password'),null], 'Passwords must match')
})

export default newPasswordFormValidation;