import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LiveComState } from "../initialState";

export const LiveComSlice = createSlice({
    name: 'livecom',
    initialState:LiveComState,
    reducers: {
        setNickname:(state,action: PayloadAction<{nickname: string}>) =>{
           state.nickname = action.payload.nickname;
       },
       removeNickname:(state) => {
            state.nickname = null;
       },
       setIsLive:(state,action: PayloadAction<{isLive: boolean}>) => {
          state.isLive = action.payload.isLive;
       },
       setIsLiveLink:(state,action: PayloadAction<{isLiveLink: string | null}>) => {
        state.isLiveLink = action.payload.isLiveLink;
       },
       setWelcomeDialog:(state,action: PayloadAction<{welcomeDialog: boolean}>) => {
        state.welcomeDialog = action.payload.welcomeDialog;
     },
      setLiveType:(state,action: PayloadAction<{liveType: string | null}>) => {
       state.liveType = action.payload.liveType;
      },

     removeLive:(state) => {
        state.isLive = false;
        state.isLiveLink = null;
        state.welcomeDialog = false;
        state.liveType = null;
       },

    }
})

export const { setNickname,removeNickname, setIsLive, setLiveType, setIsLiveLink,setWelcomeDialog,removeLive } = LiveComSlice.actions;

export  default LiveComSlice.reducer;
