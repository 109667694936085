import React, { Fragment, useState } from 'react'
import axios from 'axios'
import { FiX } from 'react-icons/fi'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import ChatSystem from '@ekenta/components/ChatSystem'
import { BASE_URL } from '@ekenta/constants'
import { useAppSelector } from '@ekenta/redux/store/hook'
import { Button } from '@ekenta/components'
import { ReactComponent as ChatIcon } from '@ekenta/assets/icons/chat-icon.svg'

interface ProductType {
  id: string
  name: string
  price: number
  imageUrl: string
}

const ChatSeller = (props: {
  sellerUserId: string
  sellerName: string
  productId: string
}) => {
  const { sellerName, sellerUserId, productId } = props
  const { token, account } = useAppSelector(
    (state) => state.auth.authentication
  )
  const senderUserId = account.user ? account.user.id : null

  const [isOpen, setIsOpen] = useState(false)
  const [conversationId, setConversationId] = useState(null)
  const [product, setProduct] = useState<ProductType | null>(null)
  const [loader, setLoader] = useState(false)

  const handleOnClick = (val: boolean) => {
    setIsOpen(val)
  }

  const createRoom = async (
    senderUserId: string,
    sellerUserId: string,
    productId: string
  ) => {
    if (!token) return toast.error('Sign in to start chat')
    try {
      if (senderUserId && sellerUserId && productId) {
        const data = {
          user_id: senderUserId,
          seller_id: sellerUserId,
          product_id: productId,
        }
        setLoader(true)
        const response = await axios.post(
          `${BASE_URL}/api/get-chat-room`,
          data,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        const newConversationId = response.data.chat_room.room_name
        const currentProduct = response.data.chat_room.product
        const image =
          currentProduct.images.length > 0
            ? currentProduct.images[0].image_url
            : ''
        setConversationId(newConversationId)
        setProduct({
          id: currentProduct.id,
          name: currentProduct.name,
          price: currentProduct.price,
          imageUrl: image,
        })
        setLoader(false)
        setIsOpen(true)
      }
    } catch (error) {
      console.error('Error creating conversation:', error)
      setLoader(false)
    }
  }

  return (
    <Fragment>
      <Container>
        <div className="top-header">
          <h5>Start Chat With Seller</h5>
        </div>
        <div className="descriptions">
          <VStack>
            <h4>{sellerName}</h4>
            <p>0% positive feedback</p>
            <Button
              name="Start Chat"
              bgVariant="primary"
              textVariant="light"
              type="button"
              prefixIcon={<ChatIcon />}
              isLoading={loader}
              isLoadingText="Start Chat"
              disabled={loader}
              shadow
              onClick={() => createRoom(senderUserId, sellerUserId, productId)}
            />
          </VStack>
        </div>
      </Container>
      <ChatComposePopover isOpen={isOpen}>
        <ChatSystem
          isPopup
          from="buyer"
          sellerStoreName={sellerName}
          product={product}
          convertsatId={conversationId}
          isChatStarted={!!conversationId}
          closeIcon={
            <FiX
              fontSize="1.2em"
              cursor="pointer"
              onClick={() => handleOnClick(false)}
            />
          }
        />
      </ChatComposePopover>
    </Fragment>
  )
}

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 22px 18px 21px;
  border-radius: 12px;
  background: #fff;
  font-family: var(--eke-body-font-family);
  gap: 20px;
  //box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.12);

  .top-header {
    width: 100%;
    height: 57px;
    border-radius: 12px;
    padding: 15px 16px 12px 16px;
    align-items: center;
    background: #f3faff;

    h5 {
      color: var(--secondary-500, #1d4057);
      font-size: 22px;
      font-weight: 700;
      margin: 0;
      padding: 0;
    }
  }

  .descriptions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 19px;
  }
`

const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: var(--eke-body-font-family);
  padding-left: 13px;
  gap: 4px;

  h4 {
    color: var(--greyscale-900, #212121);
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.2px;
    margin: 0;
  }

  p {
    color: var(--greyscale-700, #616161);
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.2px;
    margin: 0;
  }
`

const ChatComposePopover = styled.div<{ isOpen: boolean }>`
  position: fixed;
  bottom: 3px;
  right: 20px;
  width: 400px;
  height: 480px;
  z-index: 9999;
  overflow: hidden;
  background-color: #f5fbff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease-in-out;
  transform: translateY(${(props) => (props.isOpen ? '0' : '100%')});

  svg {
    z-index: 9999 !important;
  }
`
export default ChatSeller
