/* eslint-disable @typescript-eslint/no-explicit-any */

import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import SVG from 'react-inlinesvg'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { useNavigate } from 'react-router-dom'
import { googleLogout } from '@react-oauth/google'
import {
  ChatPathUrl,
  DashboardPathUrl,
  DashboardSellers,
  SellerOnboardingPathUrl,
  accountProfilePathUrl,
  cartPathUrl,
  collectionBaseUrl,
  loginPathUrl,
  ordersPathUrl,
  registerPathUrl,
  savedItemPathUrl,
} from '@ekenta/routes/constant'
import { useAppDispatch, useAppSelector } from '@ekenta/redux/store/hook'
import { TriggerSideOffCanvasContext } from '@ekenta/context/TriggerSideOffCanvasContext'
import {
  checkIfAdmin,
  checkIfAdminOrSellerRoleExist,
  logoutHandler,
} from '@ekenta/utils'
import DashboardSvg from '@ekenta/assets/icons/dashboard.svg'
import { emptyCart } from '@ekenta/redux/store/features/cart/cartSlice'
import { removeLive } from '@ekenta/redux/store/states/reducerSlice/livecomSlice'
import { removeUser } from '@ekenta/redux/store/states/reducerSlice/authenticationSlice'
import { useSearchProductSuggestionMutation } from '@ekenta/redux/store/features/search/searchApiSlice'
import NavigationSearchBar from './NavigationSearchBar'
import {
  NavigationStyle,
  ContainerTopStyle,
  ContainerBottomStyle,
  RightOptionStyle,
  DropdownStyle,
  ListItemStyle,
  ItemStyle,
  SearchStyle,
  ContentPopStyle,
  ListPopItem,
  PopOverlayStyle,
  BadgeStyle,
  FlexStyle,
} from './style'
import {
  BagIcon,
  CartIcon,
  ChatIcon,
  FavoriteIcon,
  InfoIcon,
  UserIcon,
} from '../icons'
import {
  BeautyIcon,
  ElectricIcon,
  FashionIcon,
  KitchenIcon,
  MenusIcon,
} from '../icons/icons'
import RefreshLink from '../RefreshLink/RefreshLink'
import HelpList from './common/HelpList'
import Button from '../Button/Button'
import Logo from '../Logo'
//import CategoryList from './common/CategoryList';

const Navigation = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const urlParams = new URLSearchParams(window.location.search)
  const category = urlParams.get('category')
  const { noOfItems } = useAppSelector((state) => state.cart.newCart)
  const { account, token } = useAppSelector(
    (state) => state.auth.authentication
  )
  const { count } = useAppSelector((state) => state.auth.chat)
  const isAdmin = account.user ? checkIfAdmin(token, account.user.role) : null

  const { handleShowCanvas } = useContext(TriggerSideOffCanvasContext)
  const [isOpen, setIsOpen] = useState(false)
  const [showAutocomplete, setShowAutocomplete] = useState(false)
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [searchProductSuggestion, getSearchResult] =
    useSearchProductSuggestionMutation()

  const searchLists = [
    { name: 'all' },
    { name: 'products' },
    { name: 'seller' },
    { name: 'brands' },
  ]

  const filterSearchLists = searchLists.filter((item) => item.name === category)

  const formik = useFormik({
    initialValues: {
      search: '',
      by: category ? filterSearchLists[0] : searchLists[0],
    },
    validationSchema: '',
    onSubmit: (values) => {
      setShowAutocomplete(false)
      navigate(
        '/search-result?q=' + values.search + '&category=' + values.by.name
      )
    },
  })

  const { values } = formik

  useEffect(() => {
    setShowAutocomplete(!!formik.values.search.trim())
  }, [formik.values.search])

  useEffect(() => {
    const delay = setTimeout(() => setDebouncedSearchTerm(values.search), 1000)
    return () => clearTimeout(delay)
  }, [values.search])

  useEffect(() => {
    handleSearch()
  }, [debouncedSearchTerm])

  const handleSearch = useCallback(async () => {
    if (!debouncedSearchTerm) return
    const body = { keyword: debouncedSearchTerm }
    try {
      await searchProductSuggestion(body).unwrap()
    } catch (error) {
      console.error('Error occurred while fetching search results:', error)
    }
  }, [debouncedSearchTerm])

  const autoCompleteData = () => {
    if (formik.values.by.name === 'products') {
      return getSearchResult?.data.result.data
    } else if (formik.values.by.name === 'brands') {
      return getSearchResult?.data.brands.data
    } else if (formik.values.by.name === 'seller') {
      return getSearchResult?.data.sellers.data
    } else if (formik.values.by.name === 'all') {
      return {
        products: getSearchResult?.data.result.data,
        brands: getSearchResult?.data.brands.data,
        sellers: getSearchResult?.data.sellers.data,
      }
    }
  }

  const logoutHandlerFunc = () => {
    logoutHandler(
      navigate,
      dispatch,
      googleLogout,
      loginPathUrl,
      removeUser,
      emptyCart,
      removeLive
    )
    toast.success(`You've successfully logout`, {
      toastId: 'logout-success-id-toast-success',
    })
  }

  return (
    <NavigationStyle>
      <ContainerTopStyle>
        <div className="LogoFrame__Style">
          <span onClick={() => handleShowCanvas('all')}>
            <MenusIcon color="" />
          </span>
          <Logo />
        </div>
        <SearchStyle>
          <NavigationSearchBar
            formik={formik}
            getSearchResult={getSearchResult}
            showAutocomplete={showAutocomplete}
            searchLists={searchLists}
            setShowAutocomplete={setShowAutocomplete}
            autoCompleteData={autoCompleteData}
          />
        </SearchStyle>
        <RightOptionStyle>
          <Popover
            isOpen={isOpen}
            padding={10}
            positions={['bottom']}
            onClickOutside={() => setIsOpen(false)}
            content={({ position, childRect, popoverRect }) => (
              <ArrowContainer
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
                arrowColor={'white'}
                arrowSize={10}
                arrowStyle={{ opacity: 0.9 }}
                className="popover-arrow-container"
                arrowClassName="popover-arrow"
              >
                <ContentPopStyle isOpen={isOpen}>
                  <ListGroupPop
                    token={token}
                    account={account}
                    isAdmin={isAdmin}
                    isLoggedIn={token && account.user}
                    logoutHandlerFunc={logoutHandlerFunc}
                  />
                </ContentPopStyle>
              </ArrowContainer>
            )}
          >
            <DropdownStyle isOpen={isOpen}>
              <div className="DropdownBtnDiv">
                <Button
                  name={
                    token && account.user
                      ? 'Hi,  ' + account.user.firstName
                      : 'Sign In'
                  }
                  bgVariant=""
                  type="button"
                  shadow={false}
                  prefixIcon={<UserIcon />}
                  onClick={() => setIsOpen((prev) => !prev)}
                />
              </div>
              {isOpen ? (
                <PopOverlayStyle onClick={() => setIsOpen(false)} />
              ) : null}
            </DropdownStyle>
          </Popover>
          <DropdownStyle isOpen={false}>
            <RefreshLink to={cartPathUrl}>
              <CartIcon />
              <span className="CartText_Style">Cart</span>
              {noOfItems > 0 && <BadgeStyle>{noOfItems}</BadgeStyle>}
            </RefreshLink>
          </DropdownStyle>
          <DropdownStyle isOpen={false}>
            <RefreshLink to={ChatPathUrl}>
              <ChatIcon />
              <span className="CartText_Style">Chats</span>
              {count > 0 && <BadgeStyle>{count}</BadgeStyle>}
            </RefreshLink>
          </DropdownStyle>
        </RightOptionStyle>
      </ContainerTopStyle>

      <ContainerBottomStyle>
        <ListItemStyle>
          {/* <Popover
						isOpen={isvisible}
						padding={10}
						positions={['bottom']}
						align='start'
						onClickOutside={() => setIsVisisble(false)}
						content={({ position, childRect, popoverRect }) => (
							<ArrowContainer
								position={position}
								childRect={childRect}
								popoverRect={popoverRect}
								arrowColor={'white'}
								arrowSize={10}
								arrowStyle={{ opacity: 0.9 }}
								className='popover-arrow-container'
								arrowClassName='popover-arrow'
							>
								<ContentPopStyle isOpen={isvisible}>
									<CategoryList />
								</ContentPopStyle>
							</ArrowContainer>
						)}>
						<ItemStyle>
							<Button
								name="All Categories"
								bgVariant=""
								type="button"
								shadow={false}
								prefixIcon={<MenusIcon />}
								onClick={() => setIsVisisble((prev) => !prev)}
							/>
							{isvisible ? <PopOverlayStyle onClick={() => setIsVisisble(false)} /> : null}
						</ItemStyle>

					</Popover> */}
          <ItemStyle>
            <button onClick={() => handleShowCanvas('all')}>
              <MenusIcon /> All Categories
            </button>
          </ItemStyle>
          <ItemStyle>
            <RefreshLink to={`${collectionBaseUrl}/qlason-fashion-6`}>
              <FashionIcon />
              Qlason Fashion
            </RefreshLink>
          </ItemStyle>
          <ItemStyle>
            <RefreshLink to={`${collectionBaseUrl}/electronics-5`}>
              <ElectricIcon />
              Electronics
            </RefreshLink>
          </ItemStyle>
          <ItemStyle>
            <RefreshLink
              to={`${collectionBaseUrl}/beauty-and-personal-care-27`}
            >
              <BeautyIcon />
              Beauty & Personal Care
            </RefreshLink>
          </ItemStyle>
          <ItemStyle>
            <RefreshLink to={`${collectionBaseUrl}/home-and-kitchen-9`}>
              <KitchenIcon />
              Home & Kitchen Appliances
            </RefreshLink>
          </ItemStyle>
        </ListItemStyle>
      </ContainerBottomStyle>
    </NavigationStyle>
  )
}

const ListGroupPop = (props: {
  token: string
  account: any
  isLoggedIn: boolean
  isAdmin: boolean | null
  logoutHandlerFunc: () => void
}) => {
  const [isShow, setIsShow] = useState(false)

  const handleClick = () => {
    setIsShow((prev) => !prev)
  }

  return (
    <ListPopItem stroke={true}>
      {!props.isLoggedIn ? (
        <Fragment>
          <FlexStyle>
            <RefreshLink to={registerPathUrl} className="Button_Signup_Style">
              <Button
                name="Sign Up"
                bgVariant=""
                type="button"
                shadow={false}
              />
            </RefreshLink>
            <RefreshLink to={loginPathUrl} className="Button_iL_Style">
              <Button
                name="Sign In"
                bgVariant=""
                type="button"
                shadow={false}
              />
            </RefreshLink>
          </FlexStyle>
          <li className="Divider_iL_Style " />
        </Fragment>
      ) : null}

      {props.isLoggedIn ? (
        <li>
          <RefreshLink to={accountProfilePathUrl}>
            <UserIcon />
            My Account
          </RefreshLink>
        </li>
      ) : null}

      <li>
        {props.token && props.account.user ? (
          checkIfAdminOrSellerRoleExist(
            props.token,
            props.account.user.role
          ) === true ? (
            <RefreshLink
              to={props.isAdmin ? DashboardSellers : DashboardPathUrl}
            >
              <SVG src={DashboardSvg} />
              {props.isAdmin ? 'Admin Account' : 'Seller’s Dashboard'}
            </RefreshLink>
          ) : (
            <RefreshLink target="_blank" to={SellerOnboardingPathUrl}>
              <SVG src={DashboardSvg} />
              Sell on Qlason
            </RefreshLink>
          )
        ) : (
          <RefreshLink target="_blank" to={SellerOnboardingPathUrl}>
            <SVG src={DashboardSvg} />
            Sell on Qlason
          </RefreshLink>
        )}
      </li>
      <li>
        <RefreshLink to={ordersPathUrl}>
          <BagIcon />
          Orders
        </RefreshLink>
      </li>
      <li>
        <RefreshLink to={savedItemPathUrl}>
          <FavoriteIcon />
          Favourite
        </RefreshLink>
      </li>
      {/* <li>
				<RefreshLink to={ordersPathUrl}>
					<BagIcon />
					Notifications
				</RefreshLink>
			</li> */}
      <li>
        <Popover
          isOpen={isShow}
          padding={10}
          positions={['right']}
          onClickOutside={() => setIsShow(false)}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={'white'}
              arrowSize={10}
              arrowStyle={{ opacity: 0.9 }}
              className="popover-arrow-container"
              arrowClassName="popover-arrow"
            >
              <ContentPopStyle isOpen={isShow}>
                <HelpList />
              </ContentPopStyle>
            </ArrowContainer>
          )}
        >
          <div className="HelpStyle">
            <a href="#" role="button" onClick={handleClick}>
              <InfoIcon />
              Help
            </a>
          </div>
        </Popover>
      </li>
      {props.isLoggedIn ? (
        <Fragment>
          <li className="Divider_iL_Style " />
          <li className="Button_iL_Style Button_Logout">
            <Button
              width="w-100"
              name="Logout"
              bgVariant=""
              type="button"
              shadow={false}
              onClick={props.logoutHandlerFunc}
            />
          </li>
        </Fragment>
      ) : null}
    </ListPopItem>
  )
}

export default Navigation
