import React, { ChangeEvent, Fragment } from 'react'

import styled from 'styled-components'
import { Button, Field } from '@ekenta/components'
import { ReactComponent as AttachIcon } from '@ekenta/assets/icons/attach-icon.svg'
import { ReactComponent as SendIcon } from '@ekenta/assets/icons/send-icon.svg'
import { FormikProvider, useFormik } from 'formik'

const ChatForm = (props: {
  isLoading?: boolean
  isPopup: boolean
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleSubmit: (content: { message: string; type: string }) => void
}) => {
  const { isLoading, isPopup, handleChange, handleSubmit } = props

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: '',
    onSubmit: (values, { resetForm }) => {
      if (values.message) {
        const data = {
          message: values.message,
          type: 'TEXT',
        }

        handleSubmit(data)
      }
      resetForm({
        values: {
          message: '',
        },
      })
    },
  })

  const renderAttachment = () => (
    <UploadStyle>
      <Button
        bgVariant=""
        textVariant=""
        disabled={isLoading}
        className="OptionChat_Button"
        shadow={false}
      >
        <AttachIcon />
      </Button>
      <input type="file" accept="image/*" onChange={handleChange} />
    </UploadStyle>
  )

  return (
    <Fragment>
      <ChatFooter isPopup={isPopup}>
        <FormikProvider value={formik}>
          <form noValidate onSubmit={formik.handleSubmit} id="messageForm">
            <HStack>
              {isPopup ? renderAttachment() : null}
              <Field
                id="chat--form"
                name="message"
                type="text"
                disabled={isLoading}
                fieldClassName=""
                placeholder="Type your message..."
                autoComplete="off"
                value={formik.values.message}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />

              {!isPopup ? renderAttachment() : null}
              <Button
                bgVariant="primary"
                textVariant="light"
                disabled={
                  !formik.isValid || !formik.values.message.trim() || isLoading
                }
                type="submit"
                shadow={false}
              >
                <SendIcon />
              </Button>
            </HStack>
          </form>
        </FormikProvider>
      </ChatFooter>
    </Fragment>
  )
}

const ChatFooter = styled.div<{ isPopup?: boolean }>`
  position: sticky !important;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 3px 14px 3px 14px;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 9;
  gap: 8px;

  //box-shadow: 0px 4px 60px 0px rgba(92, 107, 173, 0.1) !important;
  gap: 8px;
  border: 1px solid #eee;

  form {
    display: block;
    width: 100%;
  }
  .InputField {
    flex: 1;

    input {
      display: flex;
      width: 100%;
      height: ${(props) => (props.isPopup ? '55px' : '70px')};
      padding: 8px 16px !important;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: #fff !important;
      border: none !important;
      outline: none !important;
      color: #818386;
      font-family: var(--eke-body-font-family);
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.32px;
      cursor: text !important;
      box-shadow: none !important;

      &:focus {
        outline: none !important;
        box-shadow: none;
        border: none !important;
      }
    }
  }

  button {
    display: flex;
    width: ${(props) => (props.isPopup ? '50px' : '50px')} !important;
    height: ${(props) => (props.isPopup ? '50px' : '50px')} !important;
    //padding: 6px 4px !important;
    justify-content: center;
    align-items: center;
    border-radius: 50% !important;
    margin: 0 !important;
  }

  .OptionChat_Button {
    background: #f0f0f0 !important;
  }
`

const HStack = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`

export const UploadStyle = styled.div`
  width: auto;
  position: relative;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    cursor: pointer !important;
    opacity: 0;
  }
  box-shadow: 0px 20px 100px 0px rgba(4, 6, 15, 0.08);
`
export default ChatForm
