/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from "react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { Button, Loader } from "@ekenta/components";
import NumberFormat from "react-number-format";
import classnames from "classnames";

import styles from "./PaymentHookButton.module.scss";
import { FLUTTERWAVE_KEY_PUBLIC } from "@ekenta/constants";

interface FlutterwaveButtonPropsType {
  email: string;
  name: string;
  phoneNumber: string;
  amount: number;
  text: string;
  className: string;
  textPrice: number;
  currency: string;
  reference: string;
  isLoading: boolean;
  callback: (response: any) => void;

}

const FlutterwaveHookButton: React.FC<FlutterwaveButtonPropsType> = (props) => {

  const publicKey = FLUTTERWAVE_KEY_PUBLIC ? FLUTTERWAVE_KEY_PUBLIC : ""

  const config = {
    public_key: publicKey,
    tx_ref: props.reference,
    amount: props.amount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: props.email,
      phone_number: props.phoneNumber,
      name: props.name,
    },
    customizations: {
      title: "Qlason Payment",
      description: "Payment for items in cart",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  return (
    <div className={styles.ButtonGroup}>
      <Button
        className={
          classnames(
            styles.PaymentHook__Button,
            "d-flex algin-items-center justify-content-center"
            , props.className)
        }
        name="paymentBtn__hook"
        width="w-100"
        disabled={props.isLoading}
        onClick={() => {
          handleFlutterPayment({
            callback: (response) => {
              props.callback(response);
              closePaymentModal()
            },
            onClose: () => console.log('close'),
          });
        }}>
        {props.isLoading ? <Fragment><Loader color="white" /> Loading..</Fragment> : (
          <>
            <span>{props.text}</span>
            <NumberFormat
              className="tot ms-2"
              thousandSeparator={true}
              displayType={"text"}
              decimalSeparator="."
              decimalScale={2}
              prefix={props.currency}
              fixedDecimalScale
              value={props.textPrice}
            />
          </>
        )}
      </Button>
    </div>
  )
}
//₦

export default FlutterwaveHookButton;