/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState } from 'react'
import { FiShare2 } from 'react-icons/fi'
import { useParams } from 'react-router-dom'
import * as queryString from 'query-string'
import {
  StoreAside,
  StoreBottom,
  StoreContainer,
  StoreTop,
  Wrapper,
} from './style'
import { withDefaultLayout } from '@ekenta/hoc'
import {
  Button,
  CustomModal,
  FollowButton,
  HelmetHeader,
  Loader,
  SocialShare,
  Typography,
} from '@ekenta/components'
import StoreBanner from './common/banner.png'
import SideFilter from '@ekenta/components/Collection/SideFilter'
import { useGetSellerProductsQuery } from '@ekenta/redux/store/features/vendor/vendorApiSlice'
import { CollectionProvider } from '@ekenta/context/CollectionContext'
import StoreMain from './common/StoreMain'

const Store = () => {
  const params = useParams()
  const queryParam = queryString.parse(window.location.search)
  const _page = queryParam.page
  const merchantId = params.id ? params.id : ''
  const [modal, setModal] = useState(false)

  const result = useGetSellerProductsQuery(merchantId, {
    skip: !merchantId,
  })
  const data = result?.isSuccess ? result.data : null

  const getProductDetailUrl = (id: string | undefined) => {
    return `${window.location.origin}/vendor/${id}/store`
  }

  const handleOnClick = (open: boolean) => {
    setModal(open)
  }

  // const filterOutput = (
  //     <div style={{ width: "auto", position: "relative" }}>
  //         <Dropdown
  //             placement="bottom"
  //             suffixIcon={<FiChevronDown />}
  //             render={
  //                 <SortStyle>
  //                     <div style={{ display: "flex", alignItems: "center", flex: 1, gap: "10px" }}>
  //                         <FilterIcon />
  //                         <span>{activeSort ? activeSort : "Sort"}</span>
  //                     </div>
  //                     <FiChevronDown />
  //                 </SortStyle>
  //             }
  //             menus={sortMenu}
  //             tooltipContent={""}
  //         />
  //     </div>
  // );

  return (
    <>
      <HelmetHeader title="Store" description="Qlason Vendor Store" />
      <CollectionProvider>
        <StoreContainer>
          <Wrapper>
            <StoreTop>
              <div className="store-banner-container">
                <div className="banner-wrapper">
                  <img src={StoreBanner} alt="store banner" />
                </div>
              </div>
              <div className="store-content-container">
                <div className="store-logowrapper">
                  <div className="logo-container">
                    {result?.isLoading ? (
                      <Loader withWrapper size="large" />
                    ) : (
                      <Fragment>
                        {data?.seller?.image ? (
                          <img
                            src={data?.seller?.image}
                            alt={data?.seller?.business_name}
                          />
                        ) : (
                          <h1>{data?.seller?.business_name?.charAt(0)}</h1>
                        )}
                      </Fragment>
                    )}
                  </div>
                  <div className="store-profile-info">
                    <Typography as="h5" size="base" color="dark" heading>
                      {data ? data.seller.business_name : 'Store'}
                    </Typography>
                    <Typography as="p" size="smallest" color="dark" uiText>
                      {data ? data.seller.followers : 0} Followers
                    </Typography>
                    <Typography as="p" size="smallest" color="dark" uiText>
                      {data ? data.seller.phone_number : 0}
                    </Typography>
                  </div>
                </div>
                <div className="store-cta-btns">
                  <FollowButton
                    followStatus={0}
                    sellerId={'1'}
                    count={0}
                    className="store-follow-cta"
                    inline={true}
                    // callback={(count) => setCount(count)}
                  />
                  <Button
                    name="Share"
                    bgVariant=""
                    disabled={result?.isLoading}
                    textVariant="primary"
                    prefixIcon={<FiShare2 />}
                    className="store-share-cta"
                    type="button"
                    onClick={() => handleOnClick(true)}
                  />
                </div>
              </div>
            </StoreTop>
            <StoreBottom>
              <StoreAside>
                <SideFilter
                  queryParam={queryParam}
                  subCollectionId={null}
                  collectionData={null}
                  isCollectionLoading={false}
                  categoryId={null}
                  isSubCollection={null}
                  collectionId={null}
                  merchantId={merchantId}
                />
              </StoreAside>
              <StoreMain
                loading={result?.isLoading}
                isSuccess={result?.isSuccess}
                isError={result?.isError}
                data={result?.data}
                queryNo={_page}
              />
            </StoreBottom>
          </Wrapper>

          <CustomModal
            show={modal}
            size="sm"
            title="Share Link to"
            subtitle=""
            centered={false}
            showFooter={false}
            isLoading={false}
            isDisabled={false}
            headerClass="app-modalheader event-modalhead"
            modalClass="app-modalbody"
            onHide={() => handleOnClick(false)}
            render={
              <SocialShare
                url={getProductDetailUrl(merchantId)}
                title="Buy,Sell New and Used Product on Qlason"
              />
            }
          />
        </StoreContainer>
      </CollectionProvider>
    </>
  )
}

export default withDefaultLayout(Store)
