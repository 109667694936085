import React from 'react'
import styled from '@emotion/styled'
import { withDefaultLayout } from '@ekenta/hoc'
import { HelmetHeader, VoucherCard } from '@ekenta/components'
import { HomeBreadCrumb } from './common/BreadCrumb'
import { useGetAllAdminVouchersQuery } from '@ekenta/redux/store/features/voucher/voucherApiSlice'
import SectionCard from './common/Sectioncard'
import { Container, HomeStyle } from './styles'
import ProductListLoader from '../Collections/component/SkeletonLoader/ProductListLoader'

const AllVouchers = () => {
  const { data, isLoading } = useGetAllAdminVouchersQuery()
  const allVoucher = data?.data?.data ?? []

  return (
    <>
      <HelmetHeader title="All Vouchers" description="Qlason Vouchers" />
      <HomeStyle>
        <Container>
          <HomeBreadCrumb activePage="Discover All Vouchers" />
          <SectionCard title="Voucher">
            <ContainerWrapper>
              <ContentStyle>
                {isLoading ? (
                  <ProductListLoader />
                ) : (
                  <>
                    {allVoucher?.length == 0 ? <div>No Voucher</div> : null}
                    {allVoucher?.map((item, index) => (
                      <VoucherCard
                        key={item.id}
                        index={index + 1}
                        voucher_id={item.id}
                        cost={item.cost}
                      />
                    ))}
                  </>
                )}
              </ContentStyle>
            </ContainerWrapper>
          </SectionCard>
        </Container>
      </HomeStyle>
    </>
  )
}

const ContainerWrapper = styled.div`
  width: 100%;
  margin-bottom: 21px;
  max-height: auto;
  position: relative;
`
const ContentStyle = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  white-space: wrap;
  gap: 15px;
  row-gap: 30px;
  -ms-overflow-style: none;
  margin-top: 0 !important;
  animation: slide 20s linear infinite;

  .swiper-slide {
    width: 230px !important;
  }
`

export default withDefaultLayout(AllVouchers)
