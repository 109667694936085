import React from 'react'

const SvgArrowUpLeft = ({
    width = "1rem",
    height = "1rem",
    className
}: {
    className?: string,
    width?: string;
    height?: string
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none" viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        width={width}
        height={height}
        className={className}
    >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 19.5l-15-15m0 0v11.25m0-11.25h11.25" />
    </svg>
)

export default SvgArrowUpLeft;