/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Login,
  Register,
  EmailVerification,
  ForgotPassword,
  PasswordReset,
} from '../../pages/Authentication';

import {
  AllLiveCommerce,
  ViewLiveSeller,
  AllCollection,
  Ekemarket,
  Home,
  AllArrivals,
  AllJustForYou,
  AllTopRated,
  MarketDayEvent,
  //ProductDetail,
  Collection,
  SearchResult,
  SellerOnboarding,
  Store,
  Details,
  Blog,
  BlogPostDetail
} from '../../pages';

import {
  homePathUrl,
  allArrivalPathUrl,
  allJustForYouPathUrl,
  allTopRatedPathUrl,
  loginPathUrl,
  registerPathUrl,
  emailVerifyPathUrl,
  forgotPassPathUrl,
  resetPassPathUrl,
  mobileAccountIndexPath,
  allLivePathUrl,
  singleLivePathUrl,
  savePlanetPathUrl,
  //productDetailPathUrl,
  collectionPagePathUrl,
  collectionPathUrl,
  subCollectionPathUrl,
  SubCollectionCartegoryPathUrl,
  searchPathUrl,
  termsAndConditionsUrl,
  iKitchenCollectionUrl,
  privacyUrl,
  iKitchenComingSoonPathUrl,
  ekeMarketLivePathUrl,
  onboardingPolicyUrl,
  careersPathUrl,
  marketDayEventUrl,
  SellerOnboardingPathUrl,
  storePathUrl,
  allStorePathUrl,
  detailsPathUrl,
  privacyEyeshopperUrl,
  deleteEyeshopperUrl,
  blogPathUrl,
  blogDetailPathUrl,
  allVoucherPathUrl,
} from '../constant';
import SaveThePlanetHome from '@ekenta/pages/SaveThePlanet/SaveThePlanetHome';
import TermsAndCondition from '@ekenta/pages/Policy/TermsAndCondition';
import MobileScreenAccountIndex from '@ekenta/pages/Account/ManageAccount/MobileScreenAccountIndex';
import PrivacyPolicy from '@ekenta/pages/Policy/PrivacyPolicy';
import IKitchenComingSoon from '@ekenta/pages/SaveThePlanet/IKitchenComingSoon';
import Careers from '@ekenta/pages/Home/Careers';
import OnboardingPolicy from '@ekenta/pages/Policy/onboardingPolicy';
import iKitchenCollection from '@ekenta/pages/iKitchen/iKitchenCollection';
import AllVendors from '@ekenta/pages/Home/AllVendors';
import PrivacyPolicies from '@ekenta/pages/EyeShopper/PrivacyPolicy';
import DeleteAccountProcess from '@ekenta/pages/EyeShopper/DeleteAccountProcess';
import AllVouchers from '@ekenta/pages/Home/AllVouchers';




export const LandingPageRoutes: { path: string; component: any }[] = [
    { path: homePathUrl, component: Home },
    { path: allArrivalPathUrl, component: AllArrivals },
    { path: allJustForYouPathUrl, component: AllJustForYou },
    { path: allStorePathUrl, component: AllVendors },
    { path: allTopRatedPathUrl, component: AllTopRated },
    { path: allVoucherPathUrl, component: AllVouchers },
   // { path: productDetailPathUrl, component: ProductDetail },
   
    { path: blogPathUrl, component: Blog },
    { path: blogDetailPathUrl, component: BlogPostDetail },
    
    { path: detailsPathUrl, component: Details },
    { path: detailsPathUrl, component: Details },
    { path: marketDayEventUrl, component: MarketDayEvent },
    { path: collectionPathUrl, component: AllCollection },
    { path: collectionPagePathUrl, component: Collection },
    { path: subCollectionPathUrl, component: Collection },
    { path: SubCollectionCartegoryPathUrl, component: Collection },
    { path: allLivePathUrl, component: AllLiveCommerce },
    { path: ekeMarketLivePathUrl, component: Ekemarket },
    { path: singleLivePathUrl, component: ViewLiveSeller },
    { path: searchPathUrl, component: SearchResult },
    { path: savePlanetPathUrl, component: SaveThePlanetHome },
    { path: termsAndConditionsUrl, component: TermsAndCondition },
    //for mobilescreen accout sideview
    { path: mobileAccountIndexPath, component: MobileScreenAccountIndex },
    { path: iKitchenCollectionUrl, component: iKitchenCollection },
    { path: privacyUrl, component: PrivacyPolicy },
    { path: onboardingPolicyUrl, component: OnboardingPolicy },
    { path: iKitchenComingSoonPathUrl, component: IKitchenComingSoon },
    { path: careersPathUrl, component: Careers },
    { path: storePathUrl, component: Store },
    { path: privacyEyeshopperUrl, component: PrivacyPolicies },
    { path: deleteEyeshopperUrl, component: DeleteAccountProcess },
    
  ]; 
  


  export const AuthenticationRoutes: {
    path: string;
    component: (props?: any) => JSX.Element;
    displayName?: string;
  }[] = [
    { path: loginPathUrl, component: Login },
    { path: registerPathUrl, component: Register },
    { path: emailVerifyPathUrl, component: EmailVerification },
    { path: forgotPassPathUrl, component: ForgotPassword },
    { path: resetPassPathUrl, component: PasswordReset },
  ];
  

  
export const OnlyOnboardingPageRoute: { path: string; component: any }[] = [
  { path: SellerOnboardingPathUrl, component: SellerOnboarding },
];
