/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Badge, Col, Tab, Tabs } from "react-bootstrap";
import * as queryString from "query-string";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsEye } from "react-icons/bs";
import { withDashboardLayout } from "@ekenta/hoc";
import { Button, DataTable, HelmetHeader, PageTitle, Pagination } from "@ekenta/components";
import { VscArrowSmallLeft } from "react-icons/vsc";
import { DashboardSellers } from "@ekenta/routes/constant";
import moment from "moment";
import { useFetchAllSellerOrdersQuery } from "@ekenta/redux/store/services/vendorProductApi";
import { animateScroll } from "react-scroll";
import styled from "styled-components";
import StatusOrder from "./common/StatusOrder";

const Orders = () => {
    const query = queryString.parse(window.location.search);
    const { _status } = query;
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState<string | any>(_status || "PENDING");



    return (
        <>
            <HelmetHeader
                title="Orders"
                description="QlasonOrders Dashboard Page"
            />
            <Col className=" col-lg-12 _listing_product_ px-2">
                <div className="mt-0">
                    <div className="mx-1 mx-lg-0">
                        <PageTitle
                            title="Orders"
                            subTitle={
                                <span
                                    className="cursor-pointer d-flex w-auto align-items-center text-muted"
                                    onClick={() => navigate(-1)}>
                                    <VscArrowSmallLeft />
                                    Back to Dashboard
                                </span>
                            }
                        />
                    </div>
                    <TabContainer className="tab__">
                        <Tabs defaultActiveKey={activeTab} id="u__tab" className="mb-3"
                            onSelect={(value) => { setActiveTab(value); }}>
                            <Tab eventKey="PENDING" title="Pending">
                                <StatusOrder status="PENDING" activetab={activeTab} />
                            </Tab>
                            <Tab eventKey="PROCESSING" title="Processing">
                                <StatusOrder status="PROCESSING" activetab={activeTab} />
                            </Tab>
                            <Tab eventKey="COMPLETED" title="Completed">
                                <StatusOrder status="COMPLETED" activetab={activeTab} />
                            </Tab>
                            <Tab eventKey="CANCELLED" title="Cancelled">
                                <StatusOrder status="CANCELLED" activetab={activeTab} />
                            </Tab>
                        </Tabs>
                    </TabContainer>
                </div>
            </Col>
            <br />
            <br />
        </>
    );
}


const TabContainer = styled.div`
  position: relative;
  .nav-tabs{
    margin-bottom: 25px !important;
  }
`;
export default withDashboardLayout(Orders);
