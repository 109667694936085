/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import * as queryString from "query-string";
import * as Yup from 'yup';
import { getIn, useFormik } from "formik";
//import { animateScroll } from "react-scroll";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../redux/store/hook";
import { Button, CustomModal, DataTable, Dropdown, Field, HelmetHeader, PageTitle, Pagination, Typography } from "../../../../../components";
import { useLocation, useNavigate } from "react-router";
import { VscArrowSmallLeft } from "react-icons/vsc";
import { DashboardSellers, adminPayoutHistory, adminPayoutRequest } from "@ekenta/routes/constant";
import { withDashboardLayout } from "@ekenta/hoc";
import { ReactComponent as OrderIcon } from "@ekenta/assets/icons/order-value-icon.svg";
import { ReactComponent as DownloadIcon } from "@ekenta/assets/icons/download-icon.svg";
import { ReactComponent as DeliveryIcon } from "@ekenta/assets/icons/delivery-fee-icon.svg";
import { ReactComponent as DocIcon } from "@ekenta/assets/icons/doc-icon.svg";
import NoticeGif from "@ekenta/assets/images/notice-gif.gif";
import { useAcknowledgePayoutRequestMutation, useFetchPayoutRequestQuery, useInitializePayoutQuery } from "@ekenta/redux/store/services/adminSellerApi";
import { StatusIndicator, TextTruncate } from "@ekenta/pages/Dashboard/common/Resuable";
import { FiEye, FiMoreHorizontal } from "react-icons/fi";
import { ButtonGroupStyle, CardGroupStyle, DataTableWrapper, RenderCard, TopWrapper, TypographyWrapper } from "../../../common/common";
import { formatCurrency } from "@ekenta/utils";
import { AppBoard } from "@ekenta/pages/Dashboard/common/styles";
import styled from "styled-components";
import "./modal.css";
import { BASE_URL } from "@ekenta/constants";



const AdminPayoutRequest = () => {
  const query = queryString.parse(window.location.search);
  const _page = query.page;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { token } = useAppSelector((state) => state.auth.authentication);
  const [isOpen, setIsOpen] = useState(false);
  const [pageno, setPageno] = useState(1);
  const [skip, setSkip] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const result = useFetchPayoutRequestQuery({ pageno, token });
  const initalize = useInitializePayoutQuery(token, {
    skip: skip,
  });
  const [acknowledgePayoutRequest, { isLoading, isSuccess, isError, error }] = useAcknowledgePayoutRequestMutation();

  const approvedValidationText = "I just made the payout and I want to update the payment statuses of my sellers";
  const isCheckData = result?.data ? result?.data.payouts.data.length > 0 ? true : false : false;

  const validation = Yup.object({
    validText: Yup.string()
      .required('Text field is required')
      .test('text-match', 'Text does not match', function (value: string) {
        return value === approvedValidationText;
      }),
    password: Yup.string()
      .required('Password field is required'),
  })

  const formik = useFormik({
    initialValues: {
      validText: '',
      password: ''
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const data = {
        password: values.password
      }
      acknowledgePayoutRequest({ data, token })
    },
  });


  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      sortType: 'basic',
    },
    {
      Header: "Store Name",
      accessor: "store",
      sortType: "basic",
    },
    {
      Header: 'Phone No',
      accessor: 'phone',
      sortType: 'basic',
    },
    {
      Header: 'Email',
      accessor: 'email',
      sortType: 'basic',
    },
    {
      Header: "Amount",
      accessor: "ammount_due",
      disableSortBy: true,
    },
    {
      Header: "Discount Amount",
      accessor: "discounted_amount",
      disableSortBy: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
    },
    {
      Header: "Action",
      accessor: "option",
      disableSortBy: true,

    },
  ];

  useEffect(() => {
    if (initalize?.isSuccess) {
      setSkip(true);
      result?.refetch();
      toast.success("Initialize payout successfully", {
        toastId: "initalize-toast-success",
      });
    }

    if (result?.data) {
      if (result?.data.payouts.data.length > 0) {
        setDisabled(false);
      }
    }

    if (initalize?.isError) {
      setSkip(true);
      toast.error("Initialize payout failed", {
        toastId: "initalize-toast-error",
      });
    }

  }, [initalize, result?.data]);

  useEffect(() => {
    if (isSuccess) {
      setDisabled(true);
      handleOnClick(false);
      toast.success("Acknowledge payout successfully", {
        toastId: "acknowledge-toast-success",
      });
      result?.refetch();
    }

    if (isError && error.status === 401) {
      toast.error(`${error?.data.message} to Acknowledge`, {
        toastId: "acknowledge-401-toast-error",
      });
    }

    if (isError && error.status === "FETCH_ERROR"
    ) {
      toast.error("Acknowledge payout failed", {
        toastId: "acknowledge-toast-error",
      });
    }

  }, [
    isError,
    error,
    isSuccess
  ]);




  const mapData = (data: any) => {
    return data.length > 0 ? data.map((item: any) => ({
      ...item,
      name: (
        item.user ? (
          <TextTruncate>{item.user.first_name + " " + item.user.last_name}</TextTruncate>
        ) : ''
      ),
      store: (
        <TextTruncate>{item?.user ? item.user?.merchant_business?.business_name : ''}</TextTruncate>
      ),
      phone: item?.user ? item.user?.merchant_business?.business_phone_number : '',
      email: item.user ? item.user.email : '',
      ammount_due: formatCurrency(item.amount, '₦'),
      discounted_amount: formatCurrency(item.available_amount, '₦'),
      status: (
        item.status ? (
          <StatusIndicator status={item.status} />
        ) : ""
      ),
      option: (
        <Dropdown
          prefixIcon={<FiMoreHorizontal fontSize="1.5rem" color="#F8880A" />}
          placement="bottom"
          menus={[
            {
              name: "View Seller",
              icon: <FiEye fontSize="1rem" color="#4ADE80" />,
              active: false,
              key: "view-seller",
              handler: () =>
                navigate(`${adminPayoutRequest}/${item?.user?.id}`),
            },
          ]}
          tooltipContent={"More Option"}
        />
      ),
    })) : [];
  };

  const handleExportFile = () => {
    if (isCheckData) {
      downloadFile('QlasonSellers_Payout_List.xlsx');
    }
  }


  const downloadFile = async (fileName: string) => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/api/admin/generate-payout-sheet?status=PENDING&export=${true}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const { link } = data;
      // Create an anchor element to trigger the download
      const a = document.createElement('a');
      a.href = link;
      a.download = fileName;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      setLoading(false);
      toast.success("Exported file successfully", {
        toastId: "export-xlsx-id-toast-success",
      });
    } catch (error) {
      setLoading(false);
      toast.error("Unable to export file", {
        toastId: "export-to-xlsx-toast-error",
      });
    }
  }

  const handleClick = () => {
    setSkip(false);
  }

  const handleOnClick = (open: boolean) => {
    if (open === true && isCheckData) {
      setIsOpen(open)
    } else {
      setIsOpen(open)
    }
  }




  return (
    <>
      <HelmetHeader
        title="Payout Request"
        description="Qlason Payout Request Page"
      />
      <AppBoard className="_listing_product_">
        <div>
          <div className="AppEvents_col mx-1 mx-lg-0">
            <PageTitle
              title="Payout"
              margin={false}
              subTitle={
                <div>
                  <span
                    className="cursor-pointer d-flex w-lg-50  align-items-center text-muted"
                    onClick={() => navigate(DashboardSellers)}>
                    <VscArrowSmallLeft />
                    Back to Dashboard
                  </span>
                  <p className="mt-4">
                    Complete all seller’s due payments
                  </p>
                </div>
              }
            >
              <ButtonGroupStyle defaultStyle>
                <Button
                  name="Payout History"
                  bgVariant=""
                  className="outline_role_button"
                  type="button"
                  shadow={false}
                  onClick={() => navigate(`${adminPayoutHistory}`)}
                />
                <Button
                  name="Acknowledge Payout"
                  bgVariant="primary"
                  textVariant="light"
                  type="button"
                  disabled={disabled}
                  shadow={false}
                  onClick={() => handleOnClick(true)}
                />
                <Button
                  name="Payout"
                  bgVariant="primary"
                  textVariant="light"
                  type="button"
                  shadow={false}
                  onClick={handleClick}
                />
              </ButtonGroupStyle>
            </PageTitle>
          </div >

          <CardGroupStyle>
            <RenderCard
              icon={<OrderIcon />}
              loading={result?.isLoading || initalize?.isLoading}
              text="Total Orders Value"
              amount={`₦${new Intl.NumberFormat().format(result?.data ? result?.data?.totalOrderValue : 0)}`}
            />
            <RenderCard
              icon={<DeliveryIcon />}
              loading={result?.isLoading || initalize?.isLoading}
              text="Total Delivery Fees"
              amount={`₦${new Intl.NumberFormat().format(result?.data ? result?.data?.totalDeliveryValue : 0)}`}
            />
            <RenderCard
              icon={<DocIcon />}
              text="Total Admin Commission"
              loading={result?.isLoading || initalize?.isLoading}
              amount={`₦${new Intl.NumberFormat().format(result?.data ? result?.data?.totalAdminCommission : 0)}`}
            />
          </CardGroupStyle>

          <DataTableWrapper isCenter={true} className="mt-4 tab__ position-relative">
            <DataTable
              theme="simple"
              data={result?.data ? mapData(result?.data?.payouts.data) : []}
              loading={result?.isLoading
                || result?.isFetching
                || initalize?.isLoading
                || loading
              }
              isSelectable={true}
              columns={columns}
              showPagination={true}
              hasLink={true}
              thClassName=""
              trClassName=""
              theadClassName="DataTable_THead"
              tdClassName={`Row__col`}
              showSearch={false}
              renderTop={
                <>
                  <TypographyWrapper>
                    <Typography
                      as="h3"
                      color="default"
                      size="xlarge"
                      heading >
                      Sellers Payment
                    </Typography>
                  </TypographyWrapper>
                  <TopWrapper>
                    <Button
                      name="Export"
                      bgVariant=""
                      prefixIcon={<DownloadIcon />}
                      className="outline_role_button"
                      type="button"
                      disabled={loading || !isCheckData}
                      shadow={false}
                      onClick={() => handleExportFile()}
                    />
                  </TopWrapper>
                </>
              }
              renderPagnation={
                <div className="mt-4">
                  {result?.data && result?.data?.payouts?.total > 10 ? (
                    <Pagination
                      pathname={pathname}
                      itemPerPage={result?.data?.payouts?.per_page}
                      itemsLength={result?.data?.payouts?.total}
                      currentPage={pageno}
                      queryNo={_page}
                      callback={(value: any) => setPageno(value)}
                    />
                  )
                    : null}
                </div>
              }
            />
            <br />
          </DataTableWrapper>
        </div >
        <CustomModal
          show={isOpen}
          size="sm"
          title="Acknowledge Payout"
          centered={false}
          showFooter={false}
          isLoading={false}
          isDisabled={false}
          headerClass="app-modalheader"
          modalClass="app-modalbody"
          onHide={() => handleOnClick(false)}
          render={
            <FormPopupStyle>
              <form
                noValidate
                onSubmit={formik.handleSubmit}
                autoComplete="off">
                <div className="Notice_GifIcon__Style">
                  <img src={NoticeGif} alt="" />
                </div>
                <div>
                  <h5>Type the text below!</h5>
                  <p>
                    “I just made the payout and I want to update the payment statuses of my sellers”
                  </p>
                </div>
                <div className="FormField">
                  <Field
                    id="validText"
                    name="validText"
                    label=""
                    disabled={isLoading}
                    type="textarea"
                    required
                    size="large"
                    childOutsideLabel={false}
                    placeholder="Write..."
                    fieldClassName="Textarea_Input"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.validText}
                    isError={formik.touched.validText && formik.errors.validText ? true : false}
                    error={formik.errors.validText}
                  />
                  <Field
                    id="password"
                    name="password"
                    disabled={isLoading}
                    type="password"
                    required
                    size="large"
                    childOutsideLabel={false}
                    placeholder="Password"
                    fieldClassName=""
                    autoComplete="new-password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    isError={formik.touched.password && formik.errors.password ? true : false}
                    error={formik.errors.password}
                  />
                </div>
                <Button
                  name="Acknowledge Payout"
                  width="w-100"
                  bgVariant="primary"
                  textVariant="light"
                  type="submit"
                  className="ButtonSubmit_Class"
                  isLoading={isLoading}
                  isLoadingText="Loading"
                  disabled={
                    isLoading ||
                    !formik.values.validText
                    || !formik.values.password
                    || getIn(formik.errors, 'validText')
                    || getIn(formik.errors, 'password')
                  }

                  shadow={false}
                />
              </form>
            </FormPopupStyle>
          }
        />
      </AppBoard >
    </>
  );
}



const FormPopupStyle = styled.div`

    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 20px;;
    font-family: var(--eke-body-font-family);

    /* textarea{
          min-height: 30px !important;
        } */

    form{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      justify-content: center;

      .Notice_GifIcon__Style{
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;

          img{
            width: 152px;
            height:152px;
          }
      }
      h5{
        color: var(--greyscale-900, #212121);
        text-align: center;
        font-size: 22px;
        font-weight: var(--eke-font-weight-bold);
        line-height: 120%; /* 28.8px */
      }
  
      p{
          color: var(--greyscale-700, #616161);
          margin: 15px;
          font-style: italic;
          font-size: 14px;
          font-weight: var(--eke-font-weight-medium);
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.2px;
      }

      .FormField{
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
      }

        .InputField{
          width: 100% !important;
        }

        .Textarea_Input{
          width: 100%;
          min-height: 50px !important;
          padding: 12px 20px;
          border-radius: 8px;
          overflow-wrap: break-word;
          word-wrap: break-word;
          white-space: normal;
          background: var(--eke-color-white);
        }
      
      


      .ButtonSubmit_Class{
        padding: 16px 16px !important;
        span{
          text-align: center;
          text-shadow: 4px 8px 24px #EFDCC7;
          font-size: 15px !important;
          font-weight: var(--eke-font-weight-bold) !important;
          line-height: 140%; /* 22.4px */
          letter-spacing: 0.2px;
        }
      }
    }

`;




export default withDashboardLayout(AdminPayoutRequest);