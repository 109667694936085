/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useContext } from 'react';

import BrowseCollection from './subcomponent/BrowseCollection';
import {
  useFetchSingleSubCollectionCategoryQuery,
  useFetchSingleSubCollectionQuery,
} from '@ekenta/redux/store/services/collectionApi';
import { useFetchBrandsByCollectionIdQuery } from '@ekenta/redux/store/services/vendorProductApi';
import { CollectionContext } from '@ekenta/context/CollectionContext';
import PriceRange from './Filters/PriceRange/PriceRange';
import Rating from './Filters/Rating/Rating';
import Features from './Filters/Features/Features';
import BrandsFilter from './Filters/Features/Brands';
import styled from 'styled-components';

export default function CollectionSideBar(props: any) {
  const { configureFilterUrl } = useContext(CollectionContext);
  const [brandIds, setBrandIds] = useState<any>([]);


  const getCollection = useFetchSingleSubCollectionQuery(
    props.subCollectionId,
    {
      skip: !props.subCollectionId,
    },
  );

  //fetch subCollection category by it Id
  const getCategory = useFetchSingleSubCollectionCategoryQuery(
    props.categoryId,
    {
      skip: !props.categoryId,
    },
  );

  const getBrands = useFetchBrandsByCollectionIdQuery(props.collectionId, {
    skip: !props.collectionId,
  });

  useEffect(() => {
    if (brandIds.length > 0) {
      const data = { key: 'brands', value: brandIds.join(','), set: true };
      configureFilterUrl(data);
    } else if (brandIds.length <= 0 && props.queryParam.brands) {
      configureFilterUrl({ key: 'brands', value: '', set: false });
    }
  }, [brandIds]);

  const handleFilter = (value: { checked: boolean; id: string }) => {
    if (value.checked) {
      setBrandIds((prevState: any) => [...prevState, value.id]);
    } else {
      const brandId_ = brandIds.filter((_id: any) => _id !== value.id);
      setBrandIds(brandId_);
    }
  };

  const handleDiscounted = (checked: boolean) => {
    let data = { key: 'discounted', value: checked, set: true };
    if (checked) {
      data = { ...data, set: true };
    } else {
      data = { ...data, set: false };
    }
    configureFilterUrl(data);
  };

  const handleIsLive = (checked: boolean) => {
    let data = { key: 'is_live', value: checked, set: true };
    if (checked) {
      data = { ...data, set: true };
    } else {
      data = { ...data, set: false };
    }
    configureFilterUrl(data);
  };

  return (
    <Container className="d-none col-lg-2  d-lg-flex collection_sideBar">
      {/* <Scrollbars style={{ width: "100%" }}> */}
      <div className="collection_sidebar_component ">
        <div className="div" style={{ marginBottom: '0.2rem' }}>
          <BrowseCollection
            collectionData={props.collectionData}
            isCollectionLoading={props.isCollectionLoading}
            subsingleData={getCollection?.data}
            subIsLoading={getCollection?.isLoading}
            subIsSuccess={getCollection?.isSuccess}
            subCategoryData={getCategory?.data}
            subCategoryIsLoading={
              getCategory?.isLoading || getCategory?.isFetching
            }
            subCategoryIsSuccess={getCategory?.isSuccess}
          />
        </div>
        <div className="divider"></div>
        <div className="other_dynamic_f">
          <div>
            <PriceRange configureFilterUrl={configureFilterUrl} />
            <div className="divider"></div>
          </div>

          <div>
            <Features
              id="discount-id"
              name="discount"
              title="Discount"
              label="Only discount"
              onChange={(e) => handleDiscounted(e.target.checked)}
            />
            <div className="divider"></div>
          </div>

          {/* resultBrandsSubCollection */}
          {getBrands?.isSuccess && getBrands?.data ? (
            <>
              <BrandsFilter
                name="brand"
                title="Brands"
                id="brand"
                onChange={(value) => handleFilter(value)}
                data={{
                  list: getBrands?.data.collections.brand,
                }}
              />
              <div className="divider"></div>
            </>
          ) : null}

          <div>
            <Features
              id="live-id"
              name="live_items"
              title="Live"
              label="Only livestream"
              onChange={(e: any) => handleIsLive(e.target.checked)}
            />
            <div className="divider"></div>
          </div>
          <div>
            <Rating configureFilterUrl={configureFilterUrl} />
          </div>
          <br />
        </div>
      </div>
      {/* </Scrollbars> */}
    </Container>
  );
}

const Container = styled.div`
  position: sticky;
  top: 10px;
  height: auto;
  background-color: var(--eke-color-white);
  padding-left: 0 !important;
  padding-right: 0 !important;
  // border-right: 1px solid #E5E7EB !important;
  border: 1px solid #e4f4ff;
  border-radius: 12px;

  .collection_sidebar_component {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 0.5rem;
    height: auto;

    .all_list {
      // overflow-y: auto;
      // overflow-x: hidden;
      // height: 200px;
      padding-top: 0.6rem;

      .header_item {
        color: var(--eke-color-secondary) !important;
        font-size: 12.5px !important;
        letter-spacing: 0;
        font-family: var(--eke-body-font-family);
        font-weight: var(--eke-font-weight-bold);
        line-height: 17px;
        text-transform: uppercase;
      }

      .list-group {
        .list-group-item {
          font-size: 13px !important;
          background-color: transparent !important;
          border: none !important;
          color: var(--eke-color-secondary);
          font-family: var(--eke-body-font-family);
          font-weight: var(--eke-font-weight-normal);
          padding-top: 0.1rem;
          padding-bottom: 0.1rem;
          transition: background-color 0.15s ease-in-out,
            opacity 0.15s ease-in-out;
          border-radius: 4px;
          outline-offset: -2px;
          margin-bottom: 0.15rem;
          padding-left: 0;

          .item--child_div {
            text-decoration: unset;
            color: var(--eke-color-secondary);

            span {
              margin-top: 5.5px !important;
            }

            svg {
              font-size: 17px !important;
              margin-top: 2px !important;
            }

            &:hover {
              color: var(--eke-color-primary);
              text-decoration: underline;
            }
          }

          .single__child_ {
            text-decoration: none !important;

            .list-group-item {
              color: var(--eke-color-secondary);
              &:hover {
                color: var(--eke-color-primary) !important;
                text-decoration: underline !important;
              }
            }
          }

          .drop--list_sub {
            .list-group {
              .list-group-item {
                font-family: var(--eke-body-font-family);
                font-weight: var(--eke-font-weight-bold);
              }
            }
          }

          .dr_sub_bold__ {
            font-family: var(--eke-body-font-family);
            font-weight: var(--eke-font-weight-bold);
            span {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
`;
