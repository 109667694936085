/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Radio, StarRating, Typography } from '@ekenta/components'
import './Rating.scss'

const Rating = (props: {
  configureFilterUrl: (value: any) => void
  setShowPopover?: any
}) => {
  const { configureFilterUrl } = props

  const listRating = [
    {
      id: 'star-all',
      name: 'rating',
      rating: 0,
    },
    {
      id: 'star-5',
      name: 'rating',
      rating: 5,
    },
    {
      id: 'star-4',
      name: 'rating',
      rating: 4,
    },
    {
      id: 'star-3',
      name: 'rating',
      rating: 3,
    },
    {
      id: 'star-2',
      name: 'rating',
      rating: 2,
    },
    {
      id: 'star-1',
      name: 'rating',
      rating: 1,
    },
  ]

  const handleRating = (value: number) => {
    let data = { key: 'rating', value: 0, set: true }
    if (value) {
      data = { ...data, value: value, set: true }
    } else {
      data = { ...data, set: false }
    }
    configureFilterUrl(data)
    props.setShowPopover(() => ({ type: '', show: false }))
  }

  return (
    <div className="RatingDiv__Wrapper">
      <Typography as="h3" heading className="filtersHeading">
        Product Rating
      </Typography>
      <div>
        <div className="Rating__Checkbox">
          {listRating.map((item: any) => (
            <RatingCheck
              key={item.id}
              id={item.id}
              name={item.name}
              rating={item.rating}
              onChange={(value) => handleRating(value)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const RatingCheck = (props: {
  id: string
  name: string
  rating: number
  onChange: (value: number) => void
}) => {
  return (
    <div className="Rating__FlexItem">
      <Radio
        id={props.id}
        name={props.name}
        className="Radio mt-2"
        variant="primary"
        disabled={false}
        onChange={() => props.onChange(props.rating)}
      />
      <label htmlFor={props.id}>
        {props.id == 'star-all' ? (
          <div style={{ marginTop: '5px' }}>All Ratings</div>
        ) : (
          <StarRating
            name="rating"
            value={props.rating}
            starDimension="20px"
            starSpacing="1px"
            color="primary"
            numberOfStars={5}
          />
        )}
      </label>
    </div>
  )
}

export default Rating
