import React from 'react'
import { Card } from 'react-bootstrap'
import styled from 'styled-components'
import { collectionPathUrl } from '../../routes/constant'
import RefreshLink from '../RefreshLink/RefreshLink'
import Button from '../Button/Button'

export default function ErrorBox() {
  return (
    <Container>
      <Card>
        <Card.Body>
          <div className="icon_empty_">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="121.7px"
              height="122.881px"
              viewBox="0 0 121.7 122.881"
              enableBackground="new 0 0 121.7 122.881"
            >
              <g>
                <path d="M80.833,29.638c0.309-0.061,0.63-0.002,0.896,0.161c0.455,0.188,0.776,0.635,0.778,1.158l0.264,36.584 c0.021,0.449-0.198,0.895-0.611,1.141l-14.941,8.912c-0.211,0.16-0.475,0.254-0.759,0.254c-0.069,0-0.138-0.004-0.204-0.016 l-38.773-4.311c-0.667-0.033-1.198-0.586-1.198-1.262V35.057h0.002c-0.015-0.498,0.268-0.979,0.752-1.191l19.45-8.55l0.001,0.002 c0.203-0.09,0.433-0.126,0.669-0.096L80.833,29.638L80.833,29.638L80.833,29.638z M53.613,0c14.789,0,28.202,6.018,37.918,15.694 c9.716,9.716,15.694,23.089,15.694,37.918c0,10.817-3.226,20.927-8.732,29.343l23.207,25.293l-16.009,14.633L83.311,98.258 c-8.497,5.664-18.724,8.967-29.697,8.967c-14.79,0-28.203-6.018-37.918-15.693C5.979,81.814,0,68.441,0,53.612 c0-14.79,6.019-28.202,15.695-37.918C25.41,5.979,38.784,0,53.613,0L53.613,0z M87.283,19.942 c-8.614-8.614-20.532-13.964-33.67-13.964s-25.056,5.35-33.67,13.964S5.979,40.475,5.979,53.612 c0,13.138,5.349,25.056,13.963,33.671c8.614,8.613,20.533,13.963,33.67,13.963s25.056-5.35,33.67-13.963 c8.614-8.615,13.964-20.533,13.964-33.671C101.247,40.475,95.897,28.557,87.283,19.942L87.283,19.942L87.283,19.942z M68.174,40.325v33.136l11.626-6.57l-0.244-33.406L68.174,40.325L68.174,40.325L68.174,40.325z M64.293,73.824V40.677l-15.63-1.657 l-0.542,15.802l-5.806-3.954l-5.806,3.281l1.198-16.528l-7.994-1.135V70.23L64.293,73.824L64.293,73.824L64.293,73.824z M62.583,29.782L50.84,36.253l15.863,2.024l10.666-6.556L62.583,29.782L62.583,29.782L62.583,29.782z M39.712,34.833l11.373-6.559 l-3.899-0.511l-14.592,6.161L39.712,34.833L39.712,34.833z" />
              </g>
            </svg>
          </div>
          <div className="mb-4">
            <h5>We couldn&apos;t find what you were looking for.</h5>
            <p>
              Try searching for more general term or shop from all collections
              below
            </p>
          </div>
          <RefreshLink to={collectionPathUrl}>
            <Button
              name="All Collection"
              textVariant="light"
              bgVariant="primary"
            />
          </RefreshLink>
        </Card.Body>
      </Card>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .card {
    border: none !important;
    max-width: 28.75rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    margin-top: 1.5rem;

    .card-body {
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      text-align: center;
      padding: 2.875rem 2.075rem !important;

      .icon_empty_ {
        svg {
          width: 69px;
          height: auto;
          fill: #c6c3c3;
        }
      }

      h5 {
        margin: 1.5625rem 0 0.625rem;
        font-size: 15px;
        font-family: 'Noto Sans', sans-serif;
        font-weight: bold;
        color: #232f3e;
      }

      p {
        color: #404553;
        font-size: 0.855rem;
        font-family: 'Noto Sans', sans-serif;
        font-weight: 300;
        margin: 0;
      }
      a {
        text-decoration: none !important;
        .default_btn {
          margin: 0 auto;
          &:focus {
            box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1) !important;
          }
        }
      }
    }
  }
`
