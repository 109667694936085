import classnames from 'classnames';
import React from 'react'
import { Button,Modal, Spinner } from 'react-bootstrap'


interface PromptDialogPropsType {
    show:boolean;
    isLoading:boolean;
    contentClass?:string;
    onClose: () => void;
    onConfirm: () => void;
}


export default function CloseDialog(props:PromptDialogPropsType) {
  

  return (
        <Modal 
            dialogClassName="prompt__dialog close__dialog"
            centered
            show={props.show} 
            onHide={props.onClose}
            contentClassName={classnames(props.contentClass)}
        >
        <Modal.Header closeButton>
        <Modal.Title>Leave Stream</Modal.Title>
        </Modal.Header>
            <Modal.Body >
                <div className="mb-1 context_spa">
                    <h5 className="mb-title">Are you sure you want to end the livestream</h5>
                </div>
            </Modal.Body>
                <Modal.Footer className="mt-0 mb-closeDialog-footer">
                    <Button onClick={props.onClose} className="cancel_btn button__btn" variant="">Cancel</Button>
                    <Button onClick={props.onConfirm} className={`confirm_btn_close button__btn d-flex align-items-center justify-content-center ${props.isLoading ? 'disabled': ''}`} 
                        style={{backgroundColor:" rgb(255, 0, 0) !important"}}>
                         {props.isLoading && <Spinner className="me-1" size="sm" animation="border" variant="light" />}
                          Leave
                    </Button>
                </Modal.Footer>
    
       </Modal>
  )
}
