import React from 'react'
import styled from '@emotion/styled'
import BannerImg from '@ekenta/assets/images/think-banner.png'
import { TopSectionContainerStyle } from './styles'
import Rightside from './Rightside'
import Carousel from './Carousel'
import { device } from '@ekenta/utils/devices'

const TopSection = () => {
  return (
    <Flex>
      <div className="min-banner scetion-group">
        <img
          src={BannerImg}
          width="100%"
          height="auto"
          alt="Think like a Billionaire,Shop like a Billionaire..."
        />
      </div>
      <TopSectionContainerStyle>
        <Carousel />
        <Rightside />
      </TopSectionContainerStyle>
    </Flex>
  )
}

const Flex = styled.div`
  width: 100%;
  .min-banner {
    width: 100%;
    padding: 0 10px 0 10px;
    margin-bottom: 8px;
    margin-top: 8px;

    img {
      width: 100%;
      object-fit: contain;
    }

    @media ${device.lg} {
      padding: 0;
      margin-bottom: 8px;
      margin-top: 0px;
    }
  }
`

export default TopSection
