/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'
import { toast } from 'react-toastify'
import SVG from 'react-inlinesvg'
import { useFormik } from 'formik'
import { withAuthenticationLayout } from '@ekenta/hoc'
import {
  LoginContainerStyle,
  LoginAsideStyle,
  LoginMainContent,
} from './styles'
import Logo from '@ekenta/components/Logo'
import { useDispatch } from 'react-redux'
import { Button, Field, HelmetHeader, RefreshLink } from '@ekenta/components'
import GoogleSVG from '@ekenta/assets/icons/goole-icon.svg'
import { useAppSelector } from '@ekenta/redux/store/hook'
import loginFormValidation from '@ekenta/validations/schemas/account-login.schema'
import {
  useSignInUserSocialMutation,
  useSigninUserMutation,
} from '@ekenta/redux/store/services/authenticationApi'
import {
  DashboardPathUrl,
  DashboardSellers,
  forgotPassPathUrl,
  homePathUrl,
  registerPathUrl,
} from '@ekenta/routes/constant'
import axios from 'axios'
import { setUser } from '@ekenta/redux/store/states/reducerSlice/authenticationSlice'
import { checkIfAdmin, checkIfAdminOrSellerRoleExist } from '@ekenta/utils'
import { AuthFormGroupStyle } from '../common/auth-style'
import AsideSlider from './common/AsideSlider'
import { fetchCartItemsAsync } from '@ekenta/redux/store/features/cart/cartThunks'
import { emptyCart } from '@ekenta/redux/store/features/cart/cartSlice'
import { BASE_URL } from '@ekenta/constants'

const Login = () => {
  const { state }: any = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { cartItems } = useAppSelector((state) => state.cart.newCart)

  const [googleLoading, setGoogleLoading] = useState(false)
  const [signinUser, { data, isLoading, isSuccess }] = useSigninUserMutation()
  const [
    signInUserSocial,
    {
      data: dataSocial,
      isLoading: isLoadingSocial,
      isSuccess: isLoadingSuccess,
    },
  ] = useSignInUserSocialMutation()

  useEffect(() => {
    //Email Verification success
    if (state && state.status === 'success') {
      toast.success(`${state.statusMsg}`, {
        toastId: 'state-un-id-toast-success',
      })
    }
    //Email Verification error
    if (state && state.status === 'error') {
      toast.error(`${state.statusMsg}`, {
        toastId: 'state-un-id-toast-error',
      })
    }
  }, [state])

  useEffect(() => {
    if (isSuccess) {
      moveLocalCartToApi(data?.token)
    }
  }, [isSuccess])

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema: loginFormValidation(),
    onSubmit: (values) => {
      const objData = {
        login: values.login.toLowerCase(),
        password: values.password,
      }
      onSignin(objData)
    },
  })

  // DEFAULT AUTHENTICATION RESPONSE
  const onSignin = async (objData: any) => {
    try {
      const res = await signinUser(objData)
      if (res?.data?.status === true) {
        const data = res?.data
        handleDispatch(data)
        if (
          checkIfAdminOrSellerRoleExist(data.token, data.user.role) === true
        ) {
          if (checkIfAdmin(data.token, data.user.role)) {
            navigate(DashboardSellers)
          } else {
            navigate(DashboardPathUrl)
          }
        } else {
          navigate(homePathUrl)
        }

        toast.success(`You"ve successfully login`)
      } else if (res?.error?.status === 'FETCH_ERROR') {
        toast.error(`Something went wrong`)
      } else {
        toast.error(`${res?.error?.data?.message}`, {
          toastId: 'wrong-id-toast-error',
        })
      }
    } catch (error) {
      toast.error(`Something went wrong`)
    }
  }

  const moveLocalCartToApi = useCallback(
    (token: string) => {
      useAddLocalCartItem(
        {
          cart: cartItems?.map(
            (item: { product: { id: string; price: number } }) => ({
              productId: item?.product?.id,
              quantity: 1,
              price: item?.product?.price,
            })
          ),
        },
        token
      )
    },
    [cartItems]
  )

  const useAddLocalCartItem = async (
    data: { cart: { productId: string; quantity: number; price: number }[] },
    token: string
  ) => {
    if (token && data?.cart?.length > 0) {
      await axios.post(`${BASE_URL}/api/anonymous-add-to-cart`, data, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
    }
    dispatch(emptyCart())
    dispatch(fetchCartItemsAsync())
  }

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (credentialResponse) => respGoogleSuccess(credentialResponse),
    onError: (err) => console.log(err),
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const respGoogleSuccess = async (google_response: any) => {
    if (google_response.access_token) {
      setGoogleLoading(true)
      await axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${google_response.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${google_response.access_token}`,
              Accept: 'application/json',
            },
          }
        )
        .then(async (res) => {
          if (res.status === 200) {
            const objData = {
              email: res.data.email.toLowerCase(),
              password: res.data.email.toLowerCase(),
              first_name: res.data.given_name,
              last_name: res.data.family_name,
              google: true,
            }
            const response = await signInUserSocial(objData)
            if (response?.data?.status === true) {
              const data = response?.data
              handleDispatch(data)
            } else {
              // console.log(response?.error)
              toast.error(`${response?.error?.data?.message}`)
            }
          }
        })
        .catch((err) => {
          toast.error(`${err.data.message}`, {
            toastId: 'google-oauth-id-toast-error',
          })
        })
        .finally(() => {
          setGoogleLoading(false)
        })
    }
  }

  const handleDispatch = (data: any) => {
    dispatch(
      setUser({
        user: {
          id: data.user.id,
          username: data.user.username,
          firstName: data.user.first_name,
          lastName: data.user.last_name,
          email: data.user.email,
          role: data.user.role,
          verified: data.user.verified,
          phoneNumber: data.user.phone_number,
          gender: data.user.gender,
          merchant_business: data.user.merchant_business
            ? {
                business_name: data.user.merchant_business?.business_name,
                thumbnail_url: data.user.merchant_business?.thumbnail_url,
              }
            : null,
        },
        token: data.token,
        anonymousUser: false,
        ek_isAuth: true,
      })
    )
  }

  return (
    <>
      <HelmetHeader title="Sign In" description="Qlason Login Page" />
      <LoginContainerStyle>
        <LoginAsideStyle>
          <AsideSlider />
        </LoginAsideStyle>
        <LoginMainContent>
          <div className="ContentChildGroup__Style">
            <Logo width="40px" showDesc={false} />
            <div className="SignText_Style">
              <h1>Sign In to Qlason</h1>
              <p>Login to Qlason and continue shopping</p>
            </div>
            <div className="ContentForm__Style">
              <form
                noValidate
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <AuthFormGroupStyle>
                  <Field
                    id="login"
                    name="login"
                    label="Email Address or Username"
                    disabled={isLoading}
                    type="text"
                    required
                    size="large"
                    childOutsideLabel={false}
                    placeholder="Username or Email address"
                    fieldClassName=""
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.login}
                    isError={
                      formik.touched.login && formik.errors.login ? true : false
                    }
                    error={formik.errors.login}
                  />
                </AuthFormGroupStyle>
                <AuthFormGroupStyle>
                  <Field
                    id="password"
                    name="password"
                    label="Password"
                    disabled={isLoading}
                    type="password"
                    required
                    size="large"
                    childOutsideLabel={false}
                    placeholder="Password"
                    fieldClassName=""
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    isError={
                      formik.touched.password && formik.errors.password
                        ? true
                        : false
                    }
                    error={formik.errors.password}
                  />
                </AuthFormGroupStyle>
                <div className="ForgetPass__Style">
                  <RefreshLink to={forgotPassPathUrl}>
                    Forgot Password?
                  </RefreshLink>
                </div>
                <Button
                  name="Sign In"
                  bgVariant="primary"
                  className="Button-Call-to-action"
                  isLoading={isLoading || googleLoading}
                  disabled={isLoading || googleLoading}
                  type="submit"
                  shadow={false}
                />
              </form>

              <div className="RegisterDiv__Style">
                <span>Don’t have an account?</span>
                <RefreshLink to={registerPathUrl}>Sign Up</RefreshLink>
              </div>
              <div className="OtherProcess_div__Style">
                <div className="OtherDivider"></div>
                <span>or</span>
              </div>
              <div className="SocialAuth_method_style">
                <div className="Listauth_Style">
                  <Button
                    name="google"
                    type="button"
                    disabled={isLoading || googleLoading}
                    shadow={false}
                    defaultClass={false}
                    onClick={() => handleGoogleLogin()}
                    className="auth_Card"
                  >
                    <SVG src={GoogleSVG} />
                    Continue with Google
                  </Button>
                </div>
              </div>
              <div className="Terms_Conditions-Style">
                <div>
                  By continuing, you agree to{' '}
                  <RefreshLink to="/terms-and-conditions" target="_blank">
                    Conditions of Use
                  </RefreshLink>{' '}
                  and{' '}
                  <RefreshLink to="/privacy-policy" target="_blank">
                    Policy Notice
                  </RefreshLink>
                </div>
              </div>
            </div>
          </div>
        </LoginMainContent>
      </LoginContainerStyle>
    </>
  )
}

export default withAuthenticationLayout(Login)
