/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import { Button, Typography } from '@ekenta/components';
import classnames from 'classnames';
import SVG from "react-inlinesvg";
import outlineCheck from "@ekenta/assets/icons/outline-check.svg";

import styles from "../Profile.module.scss";
import EditProfile from './EditProfile/EditProfile';

const ListDetails = (props: {
    data: any;
    isEdit: boolean;
    isLoading: boolean;
    handleHideForm: () => void;
    handleSubmit: (data: any) => void;
}) => {

    const data = [
        {
            title: "Full name",
            text: ` ${props.data.first_name + " " + props.data.last_name}`,
            bottomLine: true,
            marginTop: false,
        },
        {
            title: "Phone number",
            text: ` ${props.data.phone_number ? props.data.phone_number : "Add phone number"}`,
            bottomLine: true,
            marginTop: true,
        },
        {
            title: "Gender",
            text: `${props.data.gender ? props.data.gender : "Add gender"}`,
            bottomLine: false,
            marginTop: true,
        },

    ]

    return (
        <div className={styles.MarginTop}>
            {props.isEdit ?
                <EditProfile
                    data={props.data}
                    isLoading={props.isLoading}
                    onCancel={props.handleHideForm}
                    handleSubmit={props.handleSubmit}
                />
                : (
                    <Fragment>
                        {data.map((item, index: number) => (
                            <Fragment key={index}>
                                <div className={classnames(styles.Section__Group, item.marginTop ? styles.MarginTop : null)}>
                                    <Typography
                                        as="span"
                                        size="smallest"
                                        color="dark"
                                        uiText>{item.title}</Typography>
                                    <div className={styles.Row}>
                                        <Typography
                                            as="span"
                                            size="medium"
                                            color="dark"
                                            className={styles.Row__Heading}
                                            uiText>
                                            {item.text}
                                        </Typography>
                                    </div>
                                </div>
                                {item.bottomLine ? (
                                    <div aria-hidden="true" className={styles.Hr_horizontal} />
                                ) : null}
                            </Fragment>

                        ))}
                    </Fragment>

                )}
            <div aria-hidden="true" className={styles.Hr_horizontal} />
            <div className={classnames(styles.Section__Group, styles.MarginTop)}>
                <Typography
                    as="span"
                    size="smallest"
                    color="dark"
                    uiText>Email</Typography>
                <div className={styles.Row}>
                    <Typography
                        as="span"
                        size="medium"
                        color="dark"
                        className={styles.Row__Heading}
                        uiText>{props.data.email}</Typography>
                    {props.data.verified ? (
                        <Button
                            aria-label="Edit email address"
                            name="Verified"
                            prefixIcon={<SVG src={outlineCheck} />}
                            as="text"
                            textVariant="success"
                            className={styles.Row__Button_Verified}
                        />
                    ) : null}
                </div>
            </div>
            <div aria-hidden="true" className={styles.Hr_horizontal} />
            <div className={classnames(styles.Section__Group, styles.MarginTop)}>
                <Typography
                    as="span"
                    size="smallest"
                    color="dark"
                    uiText>Location</Typography>
                <div className={styles.Row}>
                    <Typography
                        as="span"
                        size="medium"
                        color="dark"
                        className={styles.Row__Heading}
                        uiText>{props.data.location}</Typography>

                </div>
            </div>
        </div>
    )
}




export default ListDetails;