/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import * as queryString from 'query-string'
import axios from 'axios'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { VscArrowSmallLeft } from 'react-icons/vsc'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonGroupStyle, DataTableWrapper } from '../../common/common'
import {
  Button,
  CustomModal,
  DataTable,
  Field,
  HelmetHeader,
  PageTitle,
  Pagination,
} from '@ekenta/components'
import { AppBoard } from '../../common/styles'
import { PageSubTitle, TextTruncate } from '../../common/Resuable'
import { withDashboardLayout } from '@ekenta/hoc'
import { DashboardEventsPathUrl } from '@ekenta/routes/constant'
import { useAppSelector } from '@ekenta/redux/store/hook'
import { formatCurrency } from '@ekenta/utils'
import { BASE_URL } from '@ekenta/constants'
import { useFetchProductByEventIdQuery } from '@ekenta/redux/store/features/admin/eventApiSlice'
import { ProductDataType } from '@ekenta/redux/store/features/admin/eventDataType'

const AddProductToEvent = () => {
  const query = queryString.parse(window.location.search)
  const collectionId = query.colId
  const eventId = query.eventId
  const eventDiscount = query.discount
  const _page = query.page
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const params = useParams()
  const { token } = useAppSelector((state) => state.auth.authentication)

  const [pageno, setPageno] = useState(1)
  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState({ discount: 0, productId: '' })
  const [allProductData, setAllProductData] = useState<ProductDataType[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')

  const result = useFetchProductByEventIdQuery({
    eventId: eventId,
    keyword: debouncedSearchTerm,
    pageno,
  })

  const per_page = result?.data?.product?.per_page ?? 0
  const total = result?.data?.product?.total ?? 0

  const selectedRowsRef = React.useRef<any>([])
  const isCheckData = result?.data
    ? result?.data?.product?.data.length > 0
      ? true
      : false
    : false

  if (!params.id || !eventId || eventDiscount === '') {
    navigate(DashboardEventsPathUrl)
  }

  useEffect(() => {
    if (result?.data) {
      setAllProductData(result?.data?.product?.data)
    }
  }, [result?.data])

  const columns = [
    {
      Header: 'Image',
      accessor: 'image',
      sortType: 'basic',
      disableSortBy: true,
    },

    {
      Header: 'Name',
      accessor: 'name',
      sortType: 'basic',
    },
    {
      Header: 'Price',
      accessor: 'price',
      sortType: 'basic',
      disableSortBy: true,
    },
    {
      Header: 'Collection',
      accessor: 'collection',
      sortType: 'basic',
    },
    {
      Header: 'Discount %',
      accessor: 'discount',
      sortType: 'basic',
    },
    {
      Header: 'Qty',
      accessor: 'stock',
      disableSortBy: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      disableSortBy: true,
    },
  ]

  useEffect(() => {
    const delay = setTimeout(() => {
      setDebouncedSearchTerm(`&keyword=${search}`)
    }, 1000)

    return () => {
      clearTimeout(delay)
    }
  }, [search])

  const mapData = (data: any) => {
    return data.length > 0
      ? data.map((item: any) => ({
          id: item.id,
          image: (
            <div
              className="picture__thumb"
              style={{
                width: '50px',
                height: 'auto',
                maxHeight: '60px',
                overflow: 'hidden',
              }}
            >
              <img
                className="img-thumbnail rounded"
                width="100%"
                height="90%"
                style={{ maxHeight: '60px' }}
                src={item?.images.length > 0 ? item.images[0]?.image_url : ''}
              />
            </div>
          ),
          name: <TextTruncate>{item?.name}</TextTruncate>,
          discount: item?.event_discount
            ? item.event_discount
            : eventDiscount
            ? eventDiscount
            : 0,
          price: formatCurrency(item?.price),
          collection: item?.collection,
          stock: item?.stock,
          action: (
            <ButtonGroupStyle defaultStyle={false}>
              <Button
                name="Add Discount"
                bgVariant=""
                textColor="primary"
                className="text_role_button"
                textVariant="light"
                type="button"
                disabled={isLoading}
                shadow={false}
                onClick={() => {
                  handleOnClick(
                    true,
                    item?.id,
                    item?.event_discount
                      ? item.event_discount
                      : eventDiscount
                      ? eventDiscount
                      : 0
                  )
                }}
              />
            </ButtonGroupStyle>
          ),
        }))
      : []
  }

  const onSearch = (e: any) => {
    setSearch(e.target.value)
  }

  const handleOnClick = (open: boolean, id: string, discount: number) => {
    setModalData((prevState: any) => ({
      ...prevState,
      productId: id,
      discount: discount,
    }))
    setModal(open)
  }

  const handleModalSubmit = (data: { discount: number; productId: string }) => {
    setAllProductData((prevState: any) =>
      prevState.map((item: any) =>
        item?.id === data?.productId
          ? {
              ...item,
              event_discount: data.discount,
            }
          : item
      )
    )
    handleOnClick(false, '', 0)
  }

  const onSelectRow = (selectedRows: any) => {
    selectedRowsRef.current = selectedRows
  }

  const loopSelectedRow = (selectedRows: any) => {
    const result = selectedRows.map((row: any) => ({
      id: row.id,
      discount: row.discount,
    }))
    return result
  }

  const handleSubmitProduct = useCallback(async () => {
    const eventId = params.id
    if (!eventId) {
      return toast.error('No Event Id', {
        toastId: '-event-id-400-toast-error',
      })
    }
    const result = loopSelectedRow(selectedRowsRef.current)
    if (result.length === 0) {
      toast.error('No Product was selected', {
        toastId: '-sele--error-400-id-toast-error',
      })
      return
    }
    await triggerPost(result, eventId)
  }, [selectedRowsRef.current])

  const triggerPost = async (data: any, eventId: string) => {
    const config = {
      method: 'post',
      url: `${BASE_URL}/api/admin/add-product-to-event/${eventId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        products: data,
      },
    }
    setIsLoading(true)
    await axios(config)
      .then(function (data) {
        setIsLoading(false)
        toast.success(`${data?.data?.message}`, {
          toastId: '-product-event-success-id-toast',
        })

        navigate(
          `${DashboardEventsPathUrl}/${params.id}?_ev=true&colId=${collectionId}&discount=${eventDiscount}`,
          {
            replace: true,
          }
        )
      })
      .catch(function () {
        setIsLoading(false)
        toast.error(`Something went wrong`, {
          toastId: '-event-error-400-id-toast-error',
        })
      })
  }

  return (
    <>
      <HelmetHeader
        title="Add Product Event "
        description="Qlason Add Product Events Page"
      />
      <AppBoard className="_listing_product_">
        <div>
          <PageTitle
            title="Add Product"
            subTitle={
              <PageSubTitle>
                <span
                  onClick={() =>
                    navigate(
                      `${DashboardEventsPathUrl}/${params.id}?_ev=true&colId=${collectionId}&discount=${eventDiscount}`
                    )
                  }
                >
                  <VscArrowSmallLeft /> Go Back
                </span>
                <p>Add products to your created event</p>
              </PageSubTitle>
            }
          >
            <ButtonGroupStyle>
              <Button
                name="Add Product"
                bgVariant="primary"
                textVariant="light"
                type="button"
                isLoadingText=" "
                isLoading={isLoading}
                disabled={!isCheckData || isLoading || result?.isError}
                onClick={() => handleSubmitProduct()}
              />
            </ButtonGroupStyle>
          </PageTitle>
        </div>
      </AppBoard>

      <DataTableWrapper isCenter={true} className="mt-3 position-relative">
        <div className="event_tableWrapper">
          <DataTable
            theme="simple"
            data={allProductData ? mapData(allProductData) : []}
            loading={result?.isLoading}
            columns={columns}
            hasLink={true}
            isSelectable={true}
            showSearch={true}
            onSelectedRow={onSelectRow}
            showPagination={true}
            thClassName=""
            trClassName="w-"
            tdClassName={`Row__col event_col`}
            theadClassName="DataTable_THead"
            searchValue={search}
            onSearch={onSearch}
            renderPagnation={
              <div className="mt-4">
                {result?.data ? (
                  <Pagination
                    pathname={pathname}
                    itemPerPage={per_page}
                    itemsLength={total}
                    currentPage={pageno}
                    queryNo={_page}
                    callback={(value: any) => setPageno(value)}
                  />
                ) : null}
              </div>
            }
          />
        </div>
      </DataTableWrapper>

      <CustomModal
        show={modal}
        size="sm"
        title="Add Individual Discount"
        subtitle="Complete the fields to add discount"
        centered={false}
        showFooter={false}
        isLoading={false}
        isDisabled={false}
        headerClass="app-modalheader event-modalhead"
        modalClass="app-modalbody"
        onHide={() => handleOnClick(false, '', 0)}
        render={
          <FormPopupStyle>
            <Field
              id="discount"
              name="discount"
              label="Discount Value"
              disabled={false}
              type="number"
              size="large"
              numberFieldPrefix="% "
              childOutsideLabel={false}
              placeholder="Enter Discount Value"
              fieldClassName=""
              autoComplete="off"
              value={modalData.discount.toString()}
              onChange={(value) => {
                setModalData((prevState: any) => ({
                  ...prevState,
                  discount: value.value,
                }))
              }}
            />
            <Button
              name="Save"
              bgVariant="primary"
              textVariant="light"
              type="button"
              className="ButtonSubmit_Class"
              isLoading={false}
              disabled={modalData.discount <= 0 ? true : false}
              isLoadingText="Loading"
              shadow={false}
              onClick={() => handleModalSubmit(modalData)}
            />
          </FormPopupStyle>
        }
      />
    </>
  )
}

const FormPopupStyle = styled.div`
  width: 95%;
  margin: 3% auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
  font-family: var(--eke-body-font-family);

  .ButtonSubmit_Class {
    align-self: flex-end;
    justify-self: flex-end;
    padding: 15px 16px !important;
    min-width: 180px !important;
    margin-right: 0 !important;
    span {
      text-align: center;
      text-shadow: 4px 8px 24px #efdcc7;
      font-size: 15px !important;
      font-weight: var(--eke-font-weight-bold) !important;
      line-height: 140%; /* 22.4px */
      letter-spacing: 0.2px;
    }
  }
`

export default withDashboardLayout(AddProductToEvent)
