/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import * as queryString from 'query-string';
// import { animateScroll } from "react-scroll";

import CollectionMainBar from './component/CollectionMainBar';
//import CollectionSideBar from './component/CollectionSideBar';
import {
  useFetchSingleCollectionQuery,
  useFetchSingleSubCollectionCategoryQuery,
  useFetchSingleSubCollectionQuery,
} from '@ekenta/redux/store/services/collectionApi';
import { FiArrowLeft } from 'react-icons/fi';
import { collectionPathUrl } from '@ekenta/routes/constant';
import { FilterIcon, SortIcon } from '@ekenta/components/icons/icons';
import PopOver from './component/inner/PopoverBottom';
import styled from 'styled-components';
import withDefaultLayout from '@ekenta/hoc/Layout/withDefaultLayout';
import { Button, HelmetHeader, Loader } from '@ekenta/components';

import './Collection.scss';
import { CollectionProvider } from '@ekenta/context/CollectionContext';
import MobileSort from './component/Filters/Sort/MobileSort';
import MobileFilters from './component/Filters/MobileFilters';
import SideFilter from '@ekenta/components/Collection/SideFilter';

const Collection = () => {
  const navigate = useNavigate();
  const params = useParams();


  // get the query params from the url
  const queryParam = queryString.parse(window.location.search);
  const _page = queryParam.page;
  const [brandProduct, setBrandProduct] = useState<any>(null);
  const [showPopover, setShowPopover] = useState({
    type: '',
    show: false,
  });

  const collectionId = params['collectionslug']
    ? params.collectionslug!.slice(params.collectionslug!.lastIndexOf('-') + 1)
    : null;

  const subCollectionId = params['subslug']
    ? params.subslug!.slice(params.subslug!.lastIndexOf('-') + 1)
    : null;
  const categoryId = params['categoryslug']
    ? params.categoryslug!.slice(params.categoryslug!.lastIndexOf('-') + 1)
    : null;


  //Fetch collection by it Id
  const getCollection = useFetchSingleCollectionQuery(collectionId, {
    skip: !collectionId
  });

  //fetch subcollection by it Id
  const {
    data: subsingleData,
    isLoading: subsingle_isLoading,
    isFetching: subsingle_isFetching,
    isSuccess: subsingle_isSuccess,
  } = useFetchSingleSubCollectionQuery(subCollectionId, {
    skip: !subCollectionId,
  });

  //fetch subcollectioncategory by it Id
  const getCategory = useFetchSingleSubCollectionCategoryQuery(categoryId, {
    skip: !categoryId,
  });


  const handleDisplay = (type: string, show: boolean) => {
    setShowPopover({
      type: type,
      show: show,
    });
  };

  const headTitle = getCategory?.isSuccess
    ? getCategory?.data.sub_collection_category.name
    : getCollection?.isSuccess
      ? getCollection?.data.collection.name
      : subsingle_isSuccess ? subsingleData.sub_collection.name : 'Categories';

  return (
    <Fragment>
      <HelmetHeader
        title={headTitle}
        description="Qlason Categories Page"
      />
      <CollectionProvider>
        <div className="w-12 d_collection_wrapper mb_padd_">
          {/* <div
          className="pt-1 pb-3 d-none d-lg-block px-0 bg-white"
          style={{ borderBottom: "1px solid#e8eaef" }}
        >
          <BreadCrumbs
            subCollectionId={subCollectionId}
            categoryId={categoryId}
          />
        </div> */}

          <div className="collection_grid_v">
            <>
              <Row>
                {/* <CollectionSideBar
                  queryParam={queryParam}
                  subCollectionId={subCollectionId}
                  collectionData={getCollection?.isSuccess ? getCollection?.data : null}
                  isCollectionLoading={getCollection?.isLoading}
                  categoryId={categoryId}
                  isSubCollection={
                    subsingle_isSuccess
                      ? subsingleData?.sub_collection?.collection_id
                      : null
                  }
                  collectionId={
                    getCategory?.isSuccess
                      ? getCategory?.data.sub_collection_category?.collection_id
                      : collectionId
                  }
                // callbackMain={callbackMain}
                /> */}
                <SideFilter
                  queryParam={queryParam}
                  subCollectionId={subCollectionId}
                  collectionData={getCollection?.isSuccess ? getCollection?.data : null}
                  isCollectionLoading={getCollection?.isLoading}
                  categoryId={categoryId}
                  isSubCollection={
                    subsingle_isSuccess
                      ? subsingleData?.sub_collection?.collection_id
                      : null
                  }
                  collectionId={
                    getCategory?.isSuccess
                      ? getCategory?.data.sub_collection_category?.collection_id
                      : collectionId
                  }
                />

                <Col xs={12} lg={10} className="collection_mainBar pt-sm-0">
                  <Container className="d-block d-lg-none ">
                    <div className="pg_title_navig__btn">
                      <div className="_postion_center d-flex align-items-center">
                        <span onClick={() => navigate(collectionPathUrl)}>
                          <FiArrowLeft fontSize={18} />
                        </span>
                        <div className="d-flex align-items-center justify-content-center w-100">
                          {getCategory?.isLoading ||
                            getCategory?.isFetching ? (
                            <div className="ms-2">
                              <Loader />
                            </div>
                          ) : getCategory?.isSuccess ? (
                            <>
                              <h5>
                                {
                                  getCategory?.data.sub_collection_category
                                    ?.name
                                }
                              </h5>
                            </>
                          ) : null}

                          {subsingle_isFetching || subsingle_isLoading ? (
                            <div className="ms-2">
                              <Loader />
                            </div>
                          ) : subsingle_isSuccess ? (
                            <>
                              <h5>{subsingleData.sub_collection?.name}</h5>
                            </>
                          ) : null}


                          {getCollection?.isFetching || getCollection?.isLoading ? (
                            <div className="ms-2">
                              <Loader />
                            </div>
                          ) : getCollection?.isSuccess ? (
                            <>
                              <h5>{getCollection?.data.collection.name}</h5>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <HFlex>
                      <CustomButton
                        type="Sort"
                        icon={<SortIcon />}
                        onClick={() => handleDisplay('sort', true)}
                      />
                      <CustomButton
                        type="Filter"
                        icon={<FilterIcon />}
                        onClick={() => handleDisplay('filter', true)}
                      />
                    </HFlex>
                  </Container>

                  {/* <LazyLoad offset={50} className="main-lazy-load"> */}
                  <CollectionMainBar
                    collectionName={getCollection?.isSuccess ? getCollection?.data.collection.name : null}
                    collectionId={collectionId}
                    subCollectionId={subCollectionId}
                    categoryId={categoryId}
                    queryNo={_page}
                  />
                </Col>
              </Row>
            </>
          </div>
          <div className="popover_panel_container">
            <PopOver
              show={showPopover.show}
              type={showPopover.type}
              setOutside={() =>
                setShowPopover(() => ({ type: '', show: false }))
              }
              render={(onClose) => (
                <>
                  {showPopover.type === 'sort' ? (
                    <MobileSort setShowPopover={setShowPopover} onClose={onClose} />
                  ) : (
                    <MobileFilters
                      setShowPopover={setShowPopover}
                      onClose={onClose}
                      queryParam={queryParam}
                      collectionId={
                        getCategory?.isSuccess
                          ? getCategory?.data.sub_collection_category?.collection_id
                          : null
                      }
                    />
                  )}
                </>
              )}
            />
          </div>
        </div>
      </CollectionProvider>
    </Fragment>
  );
};

const CustomButton = (props: {
  icon: React.ReactElement;
  type: string;
  onClick: () => void;
}) => (
  <ButtonStyle>
    <Button
      name={props.type}
      prefixIcon={props.icon}
      defaultClass={false}
      className="sub_filter_sorting_"
      onClick={props.onClick}
    />
  </ButtonStyle>
);

const Container = styled.div`
  width: 98%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 15px;
  height: auto;
  padding: 11.99px 5px;
  background-color: var(--eke-color-white);

  .pg_title_navig__btn{
    position: relative;
    min-height: 20px;
    h5{
      color: var(--eke-color-secondary);
      font-family: var(--eke-body-font-family) !important;
      font-weight: var(--eke-font-weight-bold);
      font-size: 23px;
      line-height: 120%; /* 28.8px */
    }

    span{
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;

const HFlex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  margin-top: 15px;
  gap: 7px;
`;
const ButtonStyle = styled.div`
  button{
    width: 110px !important;
    padding: 9.172px 9.931px !important;
    border-radius: 62.069px !important;
    border: 2px solid  var(--eke-color-primary) !important;
    background-color: var(--eke-color-white) !important;
    box-shadow: 2.4827585220336914px 4.965517044067383px 14.896551132202148px 0px #EFDCC7 !important;

    .Button__icon{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6.207px !important;

      svg{
        fill: none !important;
        /* width: 20px !important;
        height: 20px !important; */
      }

      .BtnSpan{
        display: flex;
        justify-self: center;
        align-self:center;
        text-transform: capitalize;
        flex-shrink: 0;
        color:  var(--eke-color-primary) !important;
        text-align: center;
        font-family: var(--eke-body-font-family);
        font-size: 15px !important;
        font-weight: var(--eke-font-weight-bold) !important;
        line-height: 140% !important; /* 21px */
        letter-spacing: 0.124px;
      }

    }
  }
`;


export default withDefaultLayout(Collection);
