import React from 'react'
import styled from 'styled-components';
import { HelmetHeader } from '@ekenta/components';
import { withDefaultLayout } from '@ekenta/hoc';
import { device } from '@ekenta/utils/devices';
import BannerSection from './common/BannerSection';
import ContentSection from './common/ContentSection';
import { useFetchSingleEventQuery } from '@ekenta/redux/store/services/adminSellerApi';
import { useParams } from 'react-router-dom';
import { capitalize } from '@ekenta/utils';

const MarketDayEvent = () => {
  const params = useParams();
  const isParam = !params.id ? true : false;
  const result = useFetchSingleEventQuery({
    id: params.id,
  }, {
    skip: isParam,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    keepPreviousData: false,
  });

  const resultData = result?.data ? result?.data?.events : null;



  const resolveSlug = (slug: string) => {
    return capitalize(slug.split('-').join(' '));
  }

  const _eventTitle = params.slug ? `${resolveSlug(params.slug)}` : ''
  return (
    <>
      <HelmetHeader
        title={`${_eventTitle} Market Day`}
        description="Qlason Market Day Event Page"
      />
      <MarketDayStyle>
        {result?.data ? !result?.data?.events.is_active && (
          <NotActiveAlert>
            Event not active
          </NotActiveAlert>
        ) : null}
        <Container>
          <BannerSection
            title={_eventTitle}
            isLoading={result?.isLoading}
            imageUrl={resultData ? resultData.image_url : ""}
          />
          <ContentSection
            title={_eventTitle}
            isLoading={result?.isLoading}
            isFetching={result?.isFetching}
            isSuccess={result?.isSuccess}
            data={resultData}
          />
        </Container>
      </MarketDayStyle>
    </>
  )
}




const MarketDayStyle = styled.div`
  width: 100%;
	display: flex;
	align-items: center;
  flex-direction: column;
	height: auto;
  background: #F5FBFF;
  padding-bottom: 10em;

  @media ${device.lg}{
	  padding-top: 0.8em;
  }

`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  max-width: var(--eke-container-maxWidth);

@media ${device.lg}{
	width: 95%;
	margin: 0 auto;
}

  @media ${device.xl}{
	width: var(--eke-container-width);
	margin: 0 auto;
}
`;


const NotActiveAlert = styled.div`
  width: 100%;
  padding: 5px;
  position: sticky;
  top: 0%;
  margin-top: -0.7rem;
  text-align: center;
  background-color: #FDF7DF;
  border: 1px solid #FEEC6F;
  color: #C9971C;
  margin-bottom: 10px;
`;


export default withDefaultLayout(MarketDayEvent);