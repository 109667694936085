import React from 'react'
import "./UnAuthorizedCard.scss";

export const UnAuthorizedCard = () => {
  return (
    <div className="ncm-authorization-card">
      <div className="pmod-ifm-banner-tips">
        <div className="banner_">
          <div><h3>Want to list your products?</h3>

            <p className="d-block d-lg-none">We are reviewing your account</p>
            <p className="d-none d-lg-block">We are reviewing your account. Once your account is approved, you can get your products in front of buyers.</p>
          </div>
          <span>Reviewing Account</span>
        </div>
        <div className="tips_on_listing">
          <div className="tips_box">
            <h2 data-spm-anchor-id="2f.20654549">Increase sales with better optimized product listings.</h2>
            <p>The first step toward sales is high-quality title, images and video for product listings.
              Qlason.com provides easy-to-use tools and information so you know how to improve product listings for maximum conversions.
              <br />
              <br />
              Qlason provides the option to list both odd and new product, always know if your product listings are optimal.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
