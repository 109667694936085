import styled from "styled-components";

export const BlogStyle = styled.div`
    width: 100%;
    height: auto;
    padding-bottom: 100px;
    background: #fff;
`

export const PostStyle = styled.div`
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px 0;
`