import * as Yup from 'yup';
import "yup-phone-lite";
const alphabetRegex = /^[aA-zZ\s]+$/;
const spaceRegex = /^(.*)?\S+(.*)?$/;

const updateAccountFormValidation = () => Yup.object({

    firstName: Yup.string()
    .matches(alphabetRegex, 'Require only alphabet')
    .matches(spaceRegex, 'Space not required')
               .required('First name is required'),
    lastName: Yup.string()
    .matches(alphabetRegex, 'Require only alphabet')
    .matches(spaceRegex, 'Space not required')
               .required('Last name is required'),
    phoneNumber: Yup.string()
               .phone("NG", "Provide a valid phone number")
               .min(14, '*Phone number must be 14 characters')
               .max(14, '*Phone number must be 14 characters')
              .required('*Phone Number is required'),
});

export default updateAccountFormValidation;