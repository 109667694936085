import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "@ekenta/constants";
import { RootState } from "@ekenta/redux/store/hook";


const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    credentials: 'same-origin',
    prepareHeaders: (headers, { getState }) => {
      const {token} = (getState() as RootState).auth.authentication
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });


  export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQuery,
    keepUnusedDataFor: 4,
    tagTypes: ['Chat','Voucher-Admin'],
    endpoints: () => ({}),
  });
  