/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import { toast } from 'react-toastify';
import * as queryString from "query-string";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { VscArrowSmallLeft } from "react-icons/vsc";
import { useNavigate, useParams } from "react-router";
import { withDashboardLayout } from "@ekenta/hoc";
import { useAppSelector } from "@ekenta/redux/store/hook";
import {
    useFetchSellerDetailsQuery,
    useUpdateSellerProfileMutation,
    useUpdateSellerDetailsMutation,
} from "@ekenta/redux/store/services/adminSellerApi";

import { HelmetHeader, PageTitle, CustomAlert } from "@ekenta/components";

const AdminSellerDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const query = queryString.parse(window.location.search);
    const type = query.type;

    const { token } = useAppSelector((state) => state.auth.authentication);

    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState("PENDING");
    const merchantId = params.id;
    const [sellerInfo, setSellerInfo] = useState<any>(null);
    const [liveLink, setLiveLink] = useState<any>(null);

    const result = useFetchSellerDetailsQuery({ merchantId, token });
    const [response] = useUpdateSellerProfileMutation();
    const [updateSellerDetails, getResult] = useUpdateSellerDetailsMutation();

    const [initialValue, setInitialValue] = useState({
        business_name: "",
        address: "",
        bank_name: "",
        bank_account_name: "",
        bank_account_number: "",
        business_phone_number: "",
        status: "",
        business_registration_number: "",
        proof_of_work: ""
    })

    useEffect(() => {
        if (response?.isError) {
            console.log(response?.isError);
        }

        if (result?.isSuccess) {
            const data = result?.data;
            setSellerInfo(data);
            setLiveLink(data.liveLink);

            setInitialValue({
                ...initialValue,
                business_name: result.data?.seller?.business_name,
                address: result.data?.seller?.address,
                bank_name: result.data?.seller?.bank_name,
                bank_account_name: result.data?.seller?.bank_account_name,
                bank_account_number: result.data?.seller?.bank_account_number,
                business_phone_number: result.data?.seller?.business_phone_number,
                status: result.data?.seller?.seller_request_status,
                business_registration_number: result.data?.seller?.business_registration_number,
                proof_of_work: result.data?.seller?.proof_of_work


            })
        }
        if (result?.Error) {
            console.log("error");
        }

        if (getResult?.isError) {
            toast.error(`Something went wrong`, {
                toastId: 'error-id-toast-error',
            });
        }

        if (getResult?.isSuccess) {
            toast.success(`Updated Seller status Successfully`, {
                toastId: 'success-id-toast',
            });

        }


    }, [
        result?.isSuccess,
        response?.isError,
        response?.isSuccess,
        getResult?.isError,
        getResult?.isSuccess,
    ]);



    if (response?.isSuccess) {
        setIsLoading(false);

    }

    const formik = useFormik({
        initialValues: initialValue,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (status) {
                updateSellerDetails({
                    data: {
                        business_name: values?.business_name,
                        address: values?.address,
                        bank_name: values?.bank_name,
                        bank_account_name: values?.bank_account_name,
                        bank_account_number: values?.bank_account_number,
                        business_phone_number: values?.business_phone_number,
                        status: values?.status,
                        business_registration_number: values?.business_registration_number
                    },
                    merchantid: result.data?.seller.id,
                    token: token,
                });
            }

            // if (liveLink && sellerInfo) {
            //   const obj = {
            //     ...sellerInfo,
            //     liveLink,
            //   };

            //   updateSellerProfile({
            //     data: obj,
            //     merchantOwnerId: merchantId,
            //     token: token,
            //   });
            // }

            window.scrollTo(0, 0);
            // pass in the data
        },
    });

    return (
        <>
            <HelmetHeader
                title="Seller Details"
                description="QlasonSeller Detail Page"
            />
            <Col className=" col-lg-12 _listing_product_ dash_top px-2">
                <div className="mt-0">
                    <Col className="AppEvents_col mx-1 mx-lg-0">
                        <PageTitle
                            title="Seller Details"
                            subTitle={
                                <span
                                    className="cursor-pointer d-flex w-auto align-items-center 
                      text-muted"
                                    onClick={() => navigate(-1)}
                                >
                                    <VscArrowSmallLeft />
                                    Back to {
                                        type === "awaiting"
                                            ? "Awaiting Approval"
                                            : type === "blocked" ? "Blocked Sellers"
                                                : type === "all" ? "Total Sellers"
                                                    : "Active Sellers"}
                                </span>
                            }
                        />
                    </Col>

                    <Col className="mt-1">
                        <br />
                        {getResult?.isSuccess ? (
                            <CustomAlert
                                variant="success"
                                show={true}
                                message="Updated Seller status Successfully"
                            />
                        ) : null}

                        {getResult?.isError ? (
                            <CustomAlert
                                variant="danger"
                                show={true}
                                message="Something went wrong"
                            />
                        ) : null}

                        {response?.isSuccess ? (
                            <CustomAlert
                                variant="success"
                                show={true}
                                message="Seller Detail Updated Successfully"
                            />
                        ) : null}

                        {result.isSuccess ? (
                            <FormikProvider value={formik}>
                                <Form noValidate onSubmit={formik.handleSubmit}>
                                    <Row className="mt-2">
                                        <Col lg={12}>
                                            <h5 style={{ fontSize: "17px" }}>Personal Details</h5>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3 mt-1">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={
                                                        result.data?.seller?.user.first_name +
                                                        " " +
                                                        result.data?.seller?.user.last_name
                                                    }
                                                    placeholder="Enter Name"
                                                    readOnly
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Business Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="business_name"
                                                    value={formik.values.business_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Phone Number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={result.data?.seller?.business_phone_number}
                                                    readOnly
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={result.data?.seller?.user.email}
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className="mt-5">
                                        <Col lg={12}>
                                            <h5 style={{ fontSize: "17px" }}>Business Details</h5>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3 mt-1">
                                                <Form.Label>Address 1</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="address"
                                                    value={formik.values.address}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}

                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>District</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={
                                                        result.data?.seller?.address ?
                                                            result.data?.seller?.address
                                                            : ""
                                                    }
                                                    readOnly
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={result.data?.seller?.country}
                                                    readOnly
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Proof of Identification</Form.Label>
                                                <div>
                                                    <a target={'_blank'} href={result.data?.seller?.proof_of_work} rel="noreferrer">Proof</a>
                                                </div>

                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3" controlId="formBasictext">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={
                                                        result.data?.seller?.city
                                                    }
                                                    readOnly
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>State</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={
                                                        result.data?.seller?.state
                                                    }
                                                    readOnly
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Business Phone Number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="business_phone_number"
                                                    value={formik.values.business_phone_number}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}

                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Business Registration Number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="business_registration_number"
                                                    value={formik.values.business_registration_number}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}

                                                />
                                            </Form.Group>

                                        </Col>
                                    </Row>
                                    <hr />

                                    <Row className="mt-5">
                                        <Col lg={12}>
                                            <h5>Store Settings</h5>
                                        </Col>
                                        {/* <Col lg={6}>
                        <img src={} alt="" />
                        </Col> */}

                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Status</Form.Label>
                                                <Form.Select
                                                    name="status"
                                                    value={formik.values.status}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    <option value={"PENDING"}>Pending</option>
                                                    <option value={"APPROVED"}>Approve</option>
                                                    <option value={"BLOCKED"}>Block</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>



                                    {/* <>
                                        <Row className="mt-2 mb-4">
                                            <Col lg={6}>
                                                <h5>EkeLive</h5>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="EkeLive"
                                                    value={
                                                        result.data?.seller?.user.is_seller_live_for_ekemarket
                                                    }
                                                    readOnly
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-2">
                                            <Col lg={6}>
                                                <h5>Live Link</h5>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Live Stream Link"
                                                    value={liveLink}
                                                    readOnly
                                                />
                                            </Col>
                                        </Row>
                                    </> */}


                                    <hr />
                                    <Row className="mt-5">
                                        <Col lg={12} style={{ fontSize: "17px" }}>
                                            <h5>Bank Account</h5>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Bank </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Bank"
                                                    name="bank_name"
                                                    value={formik.values.bank_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}

                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Account Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Account Name"
                                                    name="bank_account_name"
                                                    value={formik.values.bank_account_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}

                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Account Number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Account Number"
                                                    name="bank_account_number"
                                                    value={formik.values.bank_account_number}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}

                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Button variant="outline-danger">Cancel</Button>
                                            {"     "}
                                            <Button variant="primary text-white" type="submit">
                                                {isLoading || getResult?.isLoading ? (
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <Spinner animation="grow" />
                                                        <span className="ms-1 text-sm">
                                                            <small>Please Wait...</small>
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <span>Update</span>
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>

                                    <br />
                                </Form>
                            </FormikProvider>

                        ) : (
                            "Loading"
                        )}
                    </Col>
                </div>
            </Col>
        </>
    );
};

export default withDashboardLayout(AdminSellerDetails);
