import React from 'react'
import { getIn, useFormikContext } from 'formik';
import { NewProductListingFormType } from '@ekenta/interfaces/ProductListing';
import { HStack, StepContainer, StepContentStyle, StepFooter, StepFormGroup, StepHeading } from '../style';
import { Button, Checkbox, Field, Label, Radio, Typography } from '@ekenta/components';
import styled from 'styled-components';

const OtherInfo = (props: { isLoading: boolean, type: string | null }) => {
    const { isLoading, type } = props;
    const { values, errors, setFieldValue, handleBlur } = useFormikContext<NewProductListingFormType>();
    return (
        <StepContainer>
            <StepHeading>
                <Typography
                    as="h3"
                    color="default"
                    size="xlarge"
                    heading >
                    Other Product Info.
                </Typography>
                <Typography
                    as="p"
                    color="default"
                    size="small">
                    Add other product info
                </Typography>
            </StepHeading>
            <StepContentStyle mb="30px">
                <HStack pt="3px">
                    <StepFormGroup>
                        <Field
                            id="returnpolicy"
                            name="returnpolicy"
                            label="Return Policy"
                            disabled={true}
                            type="number"
                            required
                            size="large"
                            numberFieldPrefix="days: "
                            thousandSeparator={true}
                            childOutsideLabel={false}
                            placeholder="Enter Default Price"
                            fieldClassName=""
                            autoComplete="off"
                            value={`${values.returnpolicy}`}
                        />
                    </StepFormGroup>
                    <StepFormGroup>
                        <Field
                            id="bulkprice"
                            name="bulkprice"
                            label="Bulk Price"
                            disabled={true}
                            type="number"
                            required
                            size="large"
                            numberFieldPrefix="₦ "
                            thousandSeparator={true}
                            childOutsideLabel={false}
                            placeholder="Enter Bulk Price"
                            fieldClassName=""
                            autoComplete="off"
                            value={`${values.bulkprice}`}
                        />
                    </StepFormGroup>
                </HStack>
                <HStack pt="8px" gap="50px">
                    <CheckSelect>
                        <Label
                            type="text"
                            text="Conditiion"
                            required={true}
                        />
                        <StepFormGroup>
                            <Radio
                                id="condition"
                                name="condition"
                                label="New"
                                className="Radio mt-2"
                                variant="primary"
                                disabled={isLoading}
                                checked={
                                    values.condition === "new"
                                        ? true
                                        : false
                                }
                                onBlur={handleBlur}
                                onChange={() => {
                                    setFieldValue("condition", "new")
                                }}
                            />
                            <Radio
                                id="used"
                                name="Used"
                                label="Used"
                                className="Radio mt-2"
                                variant="primary"
                                disabled={isLoading}
                                checked={
                                    values.condition === "used"
                                        ? true
                                        : false
                                }
                                onBlur={handleBlur}
                                onChange={() => {
                                    setFieldValue("condition", "used")
                                }}
                            />
                        </StepFormGroup>
                    </CheckSelect>
                    <CheckSelect>
                        <Label
                            type="text"
                            text="Warranty"
                            required={true}
                        />
                        <StepFormGroup>
                            <Radio
                                id="waranty"
                                name="waranty"
                                label="Yes"
                                className="Radio mt-2"
                                variant="primary"
                                disabled={isLoading}
                                value={values.waranty}
                                onBlur={handleBlur}
                                onChange={() => {
                                    setFieldValue("waranty", true)
                                }}
                            />
                            <Radio
                                id="waranty"
                                name="waranty"
                                label="No"
                                disabled={isLoading}
                                className="Radio mt-2"
                                variant="primary"
                                value={values.waranty}
                                onBlur={handleBlur}
                                onChange={() => {
                                    setFieldValue("waranty", false)
                                }}
                            />
                        </StepFormGroup>
                    </CheckSelect>
                    <CheckSelect>
                        <Label
                            type="text"
                            text="Product Made"
                            required={true}
                        />
                        <StepFormGroup className="selectbox">
                            <Checkbox
                                id="madeInNig"
                                name="madeInNig"
                                className="checkBox__Filter"
                                disabled={isLoading}
                                title="Made In Nigeria"
                                label="Made In Nigeria"
                                value={values.madeInNig}
                                onBlur={handleBlur}
                                onChange={(e: { target: { checked: boolean; }; }) => {
                                    setFieldValue("madeInNig", e.target.checked)
                                }}
                            />
                        </StepFormGroup>
                    </CheckSelect>
                </HStack>

            </StepContentStyle>
            <StepFooter>
                <Button
                    name={
                        type === "edit"
                            ? "Update Product"
                            : "Create Product"
                    }
                    textVariant="light"
                    width="w-100"
                    bgVariant="primary"
                    className="PageStep__Btn"
                    isLoading={isLoading}
                    isLoadingText=' '
                    disabled={
                        isLoading ||
                        !values.collectionId ||
                        !values.condition ||
                        !values.defaultprice ||
                        getIn(errors, 'collectionId') ||
                        getIn(errors, 'condition') ||
                        getIn(errors, 'defaultprice')
                    }
                    shadow={false}
                    type="submit"
                />
            </StepFooter>
        </StepContainer>

    )
}

const CheckSelect = styled.div`
    width: fit-content;
    height: auto;

    .selectbox{
        width: auto !important;
    }
`;

export default OtherInfo