import React from "react"
import { Accordion as BAccordion, } from "react-bootstrap";
import styles from "./Accordion.module.scss";

interface AccordionPropsType {
    title: string | JSX.Element;
    eventKey?: string;
    children: React.ReactNode;
    defaultActiveKey?: string[];
    alwaysOpen?: boolean;
}

const Accordion: React.FC<AccordionPropsType> = ({ title, defaultActiveKey, alwaysOpen, eventKey = "0", children }) => {
    return (
        <div className={styles.AccordionCustom_Wrapper}>
            <BAccordion defaultActiveKey={defaultActiveKey} alwaysOpen={alwaysOpen} className={styles.AccordionCustom_Wrapper__Container}>
                <BAccordion.Item eventKey={eventKey}>
                    <BAccordion.Header className={styles.AccordionCustom_Wrapper__Header}>
                        {title}
                    </BAccordion.Header>
                    <BAccordion.Body className={styles.AccordionCustom_Wrapper__Body}>
                        {children}
                    </BAccordion.Body>
                </BAccordion.Item>
            </BAccordion>
        </div>
    )
}

export default Accordion;