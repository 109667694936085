import classnames from 'classnames';
import React from 'react'
import { FiSlash } from 'react-icons/fi';
import styles from "./StockEmpty.module.scss";


const StockEmpty = (props:{className?:string}) => {
  return (
    <div className={classnames(styles.StockEmptyWrapper,props.className)}>
        <div className={styles.StockEmptyWrapper__sub}>
            <FiSlash />
            <span className={styles.StockEmptySpan}>Out of Stock</span>
        </div>
    </div>
  )
}

export default StockEmpty;