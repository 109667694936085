/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import Tippy from '@tippyjs/react'
import classnames from 'classnames'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-toward.css'

import { Placement } from 'tippy.js'

import dropdownStyles from './Dropdown.module.scss'
import { FiCheck } from 'react-icons/fi'
import Loader from '../Loader/Loader'

interface DropdownPropType {
  name?: string
  placement: Placement | undefined
  prefixIcon?: JSX.Element
  suffixIcon?: JSX.Element
  render?: JSX.Element
  onlyIcon?: boolean
  className?: string
  menus: any
  tooltipContent: string
}

const Dropdown = ({
  name,
  prefixIcon,
  suffixIcon,
  placement,
  onlyIcon,
  className,
  menus,
  render,
}: DropdownPropType) => {
  const [isVisible, setIsVisible] = useState(false)

  const dropdownMenu = (
    <ul className={dropdownStyles.dropdown_menu}>
      {menus.length > 0
        ? menus.map((menu: any, index: number) => (
            <>
              <li
                key={`drop-${index}`}
                className={dropdownStyles.DropdownMenu__item}
                onClick={() => {
                  setIsVisible(false)
                  menu.handler()
                }}
              >
                <div className={dropdownStyles.DropdownMenu__Flex}>
                  <span className="DropdownMenu__icon">
                    {menu.active ? <FiCheck /> : menu.icon}
                  </span>
                  <span className={dropdownStyles.DropdownMenu__text}>
                    {menu.name}
                  </span>
                </div>
                {menu.loading ? (
                  <span>
                    <Loader />
                  </span>
                ) : null}
              </li>
              {index === 3 ? (
                <li className={dropdownStyles.divider}></li>
              ) : null}
            </>
          ))
        : null}
    </ul>
  )

  return (
    <Tippy
      arrow={true}
      visible={isVisible}
      interactive={true}
      onClickOutside={() => setIsVisible(false)}
      content={dropdownMenu}
      placement={placement}
      theme="light"
      animation="shift-toward"
      className={dropdownStyles.tippy_custom_styles}
    >
      {render ? (
        <div onClick={() => setIsVisible((prev) => !prev)}>{render}</div>
      ) : (
        <button
          onClick={() => setIsVisible((prev) => !prev)}
          type="button"
          className={classnames(
            'Button Button--icon w-auto btn-icon Button--iconOnly',
            {
              [dropdownStyles.btn]: !onlyIcon,
              [dropdownStyles.Dropdown_Button]: !onlyIcon,
              [dropdownStyles.Dropdown_ButtonIcon]: onlyIcon,
            },
            className
          )}
        >
          <span className="Button__icon d-flex align-items-center gap-1">
            <>
              {prefixIcon}
              {name}
            </>
            {suffixIcon}
          </span>
        </button>
      )}
    </Tippy>
  )
}

export default Dropdown
