/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAdminCreateVoucherMutation } from '@ekenta/redux/store/features/admin/vouchersApiSlice'
import { Button, ButtonGroup, CustomModal, Field } from '@ekenta/components'
import { ModalContent } from './style'
import toast from 'react-hot-toast'

interface FormValue {
  voucherName: string
  cost: string
}

const CreateVoucher = (props: { isOpen: boolean; onClose: () => void }) => {
  const { isOpen, onClose } = props

  const [adminCreateVoucher, { isLoading }] = useAdminCreateVoucherMutation()

  const validationSchema = Yup.object().shape({
    voucherName: Yup.string().trim().required('Voucher Name is required'),
    cost: Yup.string().required('Voucher Amount is required'),
  })

  const formik = useFormik<FormValue>({
    initialValues: {
      voucherName: 'Gift Voucher',
      cost: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        cost: values.cost,
      }
      onCreate(data)
    },
  })

  const onCreate = async (data: { cost: string }) => {
    await adminCreateVoucher({
      cost: Number(data.cost),
    })
      .unwrap()
      .then(() => {
        toast.success('Voucher created successfully', {
          position: 'bottom-center',
        })
        formik.resetForm()
        onClose()
      })
      .catch((error) => {
        const message = error?.data?.detail || 'Something went wrong'
        toast.error(message, { position: 'bottom-center' })
      })
  }

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <CustomModal
      show={isOpen}
      size="sm"
      title="Create Voucher"
      subtitle="Complete the fields to create voucher"
      centered={false}
      showFooter={false}
      isLoading={false}
      isDisabled={false}
      headerClass="app-modalheader event-modalhead"
      modalClass="app-modalbody"
      onHide={handleClose}
      render={
        <ModalContent>
          <div className="modal--body">
            <form className="form-field" onSubmit={formik.handleSubmit}>
              <Field
                id="voucherName"
                name="voucherName"
                label="Voucher Name"
                disabled={true}
                type="text"
                required
                size="large"
                childOutsideLabel={false}
                placeholder="Voucher Name"
                fieldClassName=""
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.voucherName}
                isError={
                  formik.touched.voucherName && formik.errors.voucherName
                    ? true
                    : false
                }
                error={formik.errors.voucherName}
              />
              <Field
                id="cost"
                name="cost"
                label="Voucher Amount"
                disabled={isLoading}
                type="number"
                required
                size="large"
                thousandSeparator={true}
                numberFieldPrefix="₦ "
                childOutsideLabel={false}
                placeholder="Voucher Amount"
                fieldClassName=""
                autoComplete="off"
                onBlur={formik.handleBlur}
                value={formik.values.cost}
                isError={formik.errors.cost ? true : false}
                error={formik.errors.cost}
                onChange={(value: any) => {
                  formik.setFieldValue('cost', value.value)
                }}
              />
              <ButtonGroup className="cta-group">
                <Button
                  name="Create Voucher"
                  type="submit"
                  className="default"
                  bgVariant="primary"
                  isLoading={isLoading}
                  isLoadingText=" "
                  disabled={isLoading || !formik.values.cost}
                  defaultClass={false}
                />
              </ButtonGroup>
            </form>
          </div>
        </ModalContent>
      }
    />
  )
}

export default CreateVoucher
