import * as Yup from 'yup';

const checkoutFormValidation = () => Yup.object().shape({

    address: Yup.string()
            .required('Address is required'),
    deliveryChannel:Yup.string()
            .required('*Delivery method is required'),
    deliveryCharge: Yup.string()
                  .required(),
    deliveryDate: Yup.string(),
    paymentChannel: Yup.string()
});

export default checkoutFormValidation;