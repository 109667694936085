/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import StarRatings from 'react-star-ratings';
import "./StarRating.scss";

interface StarRatingPropsType {
    name: string;
    value: number
    starDimension: string;
    starSpacing: string;
    color: string;
    numberOfStars: number,
    starHoverColor?: string;
    onChange?: (value: any) => void;
}

const StarRating = (props: StarRatingPropsType) => {
    const {
        name,
        value,
        starDimension,
        starSpacing,
        color,
        numberOfStars,
        starHoverColor,
        onChange
    } = props;

    const colorStar = color === "primary"
        ? "#F8880A"
        : color === "secondary"
            ? "#1D4057" : color;

    const hoverColorStar = starHoverColor === "primary"
        ? "#F8880A"
        : starHoverColor === "secondary"
            ? "#1D4057" : starHoverColor;

    return (
        <StarRatings
            rating={value}
            starDimension={starDimension}
            starSpacing={starSpacing}
            starRatedColor={colorStar}
            starHoverColor={hoverColorStar}
            numberOfStars={numberOfStars}
            svgIconViewBox="0 0 20 20"
            svgIconPath="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
            name={name}
            changeRating={(value) => {
                if (onChange) {
                    onChange(value);
                }
            }}
        />
    )
}

export default StarRating;