/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react'
import { getIn, useFormikContext } from 'formik';
import { useFetchAllCollectionsQuery } from '@ekenta/redux/store/services/collectionApi';
import { toast } from 'react-toastify';
import { ProductListingFormType } from '@ekenta/interfaces/ProductListing';
import { HStack, StepContainer, StepContentStyle, StepFooter, StepFormGroup, StepHeading } from '../style';
import { Button, Label, Typography } from '@ekenta/components';
import CustomSelect from '@ekenta/components/Select/CustomSelect';
import { VscArrowSmallRight } from 'react-icons/vsc';


const mapCollectionData = (data: any) => {
    return data && data.map((item: any) => (
        {
            value: item.id,
            label: item.name
        }
    ));

}



const ProductCollection = (props: { nextPage: () => void; isLoading: boolean }) => {
    const { nextPage } = props;

    const { values, errors, setFieldValue, handleBlur } = useFormikContext<ProductListingFormType>();

    const [allCollectionData, setAllCollectionData] = useState<any>(null);
    const [allSubCategoryData, setAllSubCategoryData] = useState<any>(null);
    const [firstTimeChange, setFirstTimeChange] = useState<any>(false);
    const [mainOptions, setMainOption] = useState(null);
    const [subCategoryOptions, setSubCategoryOptions] = useState<any | null>(null);
    const [subCollectionOptions, setSubCollectionOptions] = useState(null);

    const selectSubCategRef = useRef<any>(null);

    const { data, isLoading, isError, error } = useFetchAllCollectionsQuery();


    useEffect(() => {
        if (data) {
            setAllCollectionData(data.collections);
            const objs = mapCollectionData(data.collections);
            setMainOption(objs);
        }
        if (values.collectionId && allCollectionData) {
            handleCollectionChange(values.collectionId)
        }

        if (values.subcategorycollectionId && allSubCategoryData) {
            handleSubCategoryCollectionChange(values.subcategorycollectionId)
        }


    }, [isLoading, values.collectionId, allCollectionData, allSubCategoryData])

    useEffect(() => {
        if (isError && error.status === "FETCH_ERROR") {
            toast.error(`Something went wrong`, {
                toastId: "collection-fetch-id-toast-error",
            });
        }
    }, [isError, error]);

    const handleCollectionChange = (value: string) => {
        setSubCategoryOptions(null);
        setSubCollectionOptions(null);
        if (firstTimeChange) {
            setFieldValue("subcategorycollectionId", "");
            setFieldValue("subcollectionId", "");
        }
        if (!allCollectionData) return null;
        const result = allCollectionData.filter((item: any) => item.id === parseInt(value));
        if (!result) return null;
        const mapData = mapCollectionData(result[0]?.sub_collection_category);
        //selectSubCategRef.current.select.clearValue();
        setAllSubCategoryData(result[0]?.sub_collection_category);
        setSubCategoryOptions(mapData);

    }

    const handleSubCategoryCollectionChange = (value: string) => {
        if (firstTimeChange) {
            setFieldValue("subcollectionId", "");
        }

        if (!allSubCategoryData) return null;
        const result = allSubCategoryData.filter((item: any) => item.id === parseInt(value));
        if (!result) return null;
        const mapData = mapCollectionData(result[0]?.sub_collection);
        setSubCollectionOptions(mapData);
        setFirstTimeChange(true)

    }



    const defaultValue = (options: any, val: string) => {
        return options ? options.find((option: any) => option.value === parseInt(val)) : "";
    }



    return (
        <StepContainer>
            <StepHeading>
                <Typography
                    as="h3"
                    color="default"
                    size="xlarge"
                    heading >
                    Add Product Collection
                </Typography>
                <Typography
                    as="p"
                    color="default"
                    size="small">
                    Choose a main, sub, sub-category
                </Typography>
            </StepHeading>
            <StepContentStyle>
                <HStack>
                    <StepFormGroup>
                        <Label
                            type="text"
                            text="Main Collection"
                            required={true}
                        />
                        <CustomSelect
                            name="collectionId"
                            className="custom-select border-danger disabled"
                            placeholder="Select Collection"
                            isDisabled={
                                isLoading || props.isLoading
                            }
                            isLoading={isLoading}
                            noOptionsMessage={() => isError ? "Could not load, refresh" : "No Collection Found"}
                            value={allCollectionData ? defaultValue(mainOptions, values.collectionId) : ""}
                            options={mainOptions ? mainOptions : []}
                            onBlur={handleBlur}
                            isError={errors.collectionId ? true : false}
                            error={errors.collectionId}
                            onChanged={(value: any) => {
                                setFieldValue("collectionId", value.value)
                                handleCollectionChange(value.value);
                            }}
                        />
                    </StepFormGroup>
                    <StepFormGroup>
                        <Label
                            type="text"
                            text="Sub Category Collection"
                            required={true}
                        />
                        <CustomSelect
                            name="subcategorycollectionId"
                            isDisabled={subCategoryOptions || props.isLoading ? false : true}
                            reference={selectSubCategRef}
                            className="custom-select"
                            placeholder="Select Sub Category Collection"
                            isLoading={isLoading}
                            noOptionsMessage={() => isError ? "Could not load, refresh" : "No Sub Category Collection Found"}
                            value={subCategoryOptions ? defaultValue(subCategoryOptions, values.subcategorycollectionId) : ""}
                            options={subCategoryOptions ? subCategoryOptions : []}
                            isError={getIn(errors, "subcategorycollectionId") ? true : false}
                            error={errors.subcategorycollectionId}
                            onBlur={handleBlur}
                            onChanged={(value: any) => {
                                setFieldValue("subcategorycollectionId", value.value);
                                handleSubCategoryCollectionChange(value.value);
                            }}
                        />
                    </StepFormGroup>
                    <StepFormGroup>
                        <Label
                            type="text"
                            text="Sub Collection"
                            required={true}
                        />
                        <CustomSelect
                            name="subcollectionId"
                            isDisabled={subCollectionOptions || props.isLoading ? false : true}
                            className="custom-select"
                            placeholder="Select Sub Collection"
                            isLoading={isLoading}
                            noOptionsMessage={() => isError ? "Could not load, refresh" : "No Sub Collection Found"}
                            value={subCollectionOptions ? defaultValue(subCollectionOptions, values.subcollectionId) : ""}
                            options={subCollectionOptions ? subCollectionOptions : []}
                            onBlur={handleBlur}
                            isError={getIn(errors, "subcollectionId") ? true : false}
                            error={errors.subcollectionId}
                            onChanged={(value: any) => {
                                setFieldValue("subcollectionId", value.value)
                            }}
                        />
                    </StepFormGroup>
                </HStack>

            </StepContentStyle>
            <StepFooter>
                <Button
                    name="Proceed"
                    textVariant="light"
                    width="w-100"
                    bgVariant="primary"
                    className="PageStep__Btn"
                    disabled={
                        isError ||
                        props.isLoading ||
                        !values.collectionId ||
                        !values.subcollectionId ||
                        !values.subcategorycollectionId ||
                        getIn(errors, 'collectionId') ||
                        getIn(errors, 'subcollectionId') ||
                        getIn(errors, 'subcategorycollectionId')
                    }
                    shadow={false}
                    suffixIcon={<VscArrowSmallRight />}
                    type="button"
                    onClick={nextPage}
                />

            </StepFooter>
        </StepContainer>
    )
}


export default ProductCollection