/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { ArrowContainer, Popover, PopoverPosition } from 'react-tiny-popover'

import classnames from 'classnames'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-toward.css'

//import { Placement } from 'tippy.js'

import dropdownStyles from './Dropdown.module.scss'
import { FiCheck } from 'react-icons/fi'
import Loader from '../Loader/Loader'
import styled from 'styled-components'

interface DropdownPropType {
  name?: string
  placement: 'bottom' | 'top' | 'right' | 'left'
  prefixIcon?: JSX.Element
  suffixIcon?: JSX.Element
  render?: JSX.Element
  onlyIcon?: boolean
  className?: string
  menus: any
  tooltipContent: string
}

const Dropdown = ({
  name,
  prefixIcon,
  suffixIcon,
  placement = 'bottom',
  onlyIcon,
  className,
  menus,
  render,
}: DropdownPropType) => {
  const [isVisible, setIsVisible] = useState(false)

  const dropdownMenu = (
    <ul className={dropdownStyles.dropdown_menu}>
      {menus.length > 0
        ? menus.map((menu: any, index: number) => (
            <>
              <li
                key={`item${index}`}
                className={dropdownStyles.DropdownMenu__item}
                onClick={() => {
                  setIsVisible(false)
                  menu.handler()
                }}
              >
                <div className={dropdownStyles.DropdownMenu__Flex}>
                  <span className="DropdownMenu__icon">
                    {menu.active ? <FiCheck /> : menu.icon}
                  </span>
                  <span className={dropdownStyles.DropdownMenu__text}>
                    {menu.name}
                  </span>
                </div>
                {menu.loading ? (
                  <span>
                    <Loader />
                  </span>
                ) : null}
              </li>
              {index === 3 ? (
                <li className={dropdownStyles.divider}></li>
              ) : null}
            </>
          ))
        : null}
    </ul>
  )

  const positions: PopoverPosition[] | undefined = []
  positions.push(placement)
  return (
    <>
      <Popover
        isOpen={isVisible}
        padding={10}
        positions={positions}
        onClickOutside={() => setIsVisible(false)}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={'white'}
            arrowSize={10}
            arrowStyle={{ opacity: 0.9 }}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <ContentPopStyle>{dropdownMenu}</ContentPopStyle>
          </ArrowContainer>
        )}
      >
        {render ? (
          <div onClick={() => setIsVisible((prev) => !prev)}>{render}</div>
        ) : (
          <button
            onClick={() => setIsVisible((prev) => !prev)}
            type="button"
            className={classnames(
              'Button Button--icon w-auto btn-icon Button--iconOnly',
              {
                [dropdownStyles.btn]: !onlyIcon,
                [dropdownStyles.Dropdown_Button]: !onlyIcon,
                [dropdownStyles.Dropdown_ButtonIcon]: onlyIcon,
              },
              className
            )}
          >
            <span className="Button__icon d-flex align-items-center gap-1">
              <>
                {prefixIcon}
                {name}
              </>
              {suffixIcon}
            </span>
          </button>
        )}
      </Popover>
    </>
  )
}

export const ContentPopStyle = styled.div`
  position: relative;
  z-index: 99999;
  padding: 0px 12px 0px 12px;
  min-width: 100px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  background-color: var(--eke-color-white);
  box-shadow: 0px 4px 8px 0px rgba(4, 6, 15, 0.08);
`

export default Dropdown
