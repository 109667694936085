import React from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ReactComponent as Qlasonholder } from '@ekenta/assets/icons/qlason-placeholder.svg';


export const AllLiveCardLoader = () => {
  return (
    <>
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
    </>
  );
};

const SkeletonCard = () => {
  return (
    <ColStyle as={Col} sm={12} md={6} lg={3} className="mt-1 mb-1">
      <TopHeader className="image mb-1">
        <Skeleton height={150} className="w-100" />
        <Qlasonholder />
      </TopHeader>
      <div className="name">
        <Skeleton width={150} height={17} />
        <Skeleton width={50} height={11} />
      </div>
    </ColStyle>
  );
};

const ColStyle = styled.div`
    .react-loading-skeleton{
    --base-color: #E3F3FF !important;
    --highlight-color: #F5FBFF !important;
  }
`;

const TopHeader = styled.div`
  position: relative;
  width: auto;
  height: auto;

  .react-loading-skeleton{
    --base-color: #E3F3FF !important;
    --highlight-color: #F5FBFF !important;
  }

  svg{
    position: absolute;
    width: 42%;
    top: 30%;
    left: 28%;
    display: flex;
    justify-self: center;
    align-self: center;
    margin: 0 auto;
    z-index: 9;
  }
`;