import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { toast } from "react-toastify";

import {
  accountProfilePathUrl, addressesPathUrl, cartPathUrl, DashboardPathUrl, DashboardSellers, loginPathUrl,
  ordersPathUrl, returnRequestPathUrl, savedItemPathUrl, SellerOnboardingPathUrl
} from "@ekenta/routes/constant";
import { VscAccount, VscChevronRight, VscDashboard, VscDebugStepBack, VscHeart, VscHistory, VscQuestion } from "react-icons/vsc";
import { googleLogout } from "@react-oauth/google";
import { LocationIcon, LogoutIcon, SellIcon, ShoppingCartIcon, } from "@ekenta/components/icons/icons";
import avatar from "@ekenta/assets/images/AVAPRO-240-240.png";
import { useAppDispatch, useAppSelector } from "@ekenta/redux/store/hook";
import { removeUser } from "@ekenta/redux/store/states/reducerSlice/authenticationSlice";
import { checkIfAdmin, checkIfAdminOrSellerRoleExist, logoutHandler } from "@ekenta/utils";
import { removeLive } from "@ekenta/redux/store/states/reducerSlice/livecomSlice";
import { HelmetHeader } from "@ekenta/components";
import { withDefaultLayout } from "@ekenta/hoc";
import { emptyCart } from "@ekenta/redux/store/features/cart/cartSlice";


const MobileScreenAccountIndex = () => {
  const { account, ek_isAuth, token } = useAppSelector(state => state.auth.authentication);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isAdmin = account.user ? checkIfAdmin(token, account.user.role) : null;


  const logoutHandlerFunc = () => {
    logoutHandler(
      navigate,
      dispatch,
      googleLogout,
      loginPathUrl,
      removeUser,
      emptyCart,
      removeLive
    );
    toast.success(`You"ve successfully logout`, {
      toastId: "logout-mobile-success-id-toast-success",
    });
  }

  return (
    <>
      <HelmetHeader title="Manage Account" description="Qlason Account Profile Page" />
      <div className="acc-mob-user-container">
        <div className="acc-m-pro-row">
          <ListGroup>

            <div className=" mb-3 w-100">
              {ek_isAuth && token ? (
                <div className=" member-login signal-resp">
                  <div className="_display-header">
                    <h5 className="_wrapper_h5">
                      <Link to={accountProfilePathUrl}>
                        Hi, {ek_isAuth && account.user && account.user.firstName}
                      </Link>
                    </h5>
                    <p>Thanks for being Qlason customer</p>
                  </div>
                </div>
              ) : (
                <div className="rv_ member-login signal-resp">
                  <div className="avatar__log_">
                    <img src={avatar} alt="" />
                  </div>
                  <Link to={loginPathUrl} className="wrap__sign">
                    <div className="rv _signOrRegister">
                      <span className="rv _signOrRegisterText ">Sign In or Register</span>
                    </div>
                  </Link>
                </div>
              )}
            </div>
            <div className="_list_itm_group">
              {!token && ek_isAuth && (
                <ListGroup.Item as={Link} to={SellerOnboardingPathUrl}><div className="sD_m_ ms-2 me-auto"><div className="rv_">
                  <SellIcon className="icon__acc_2" /><span>Sell anything</span> </div>
                  <VscChevronRight fontSize={18} /></div></ListGroup.Item>
              )}
              {token && checkIfAdminOrSellerRoleExist(token, account.user.role) === true ? (
                <ListGroup.Item
                  as={Link} to={isAdmin ? DashboardSellers : DashboardPathUrl}>
                  <div className="sD_m_ ms-2 me-auto">
                    <div className="rv_">
                      <VscDashboard className="icon__acc_dash" />
                      <span> Go To Dashboard</span>
                    </div>
                    <VscChevronRight fontSize={18} />
                  </div>
                </ListGroup.Item>
              ) : (
                <ListGroup.Item
                  as={Link} to={SellerOnboardingPathUrl}>
                  <div className="sD_m_ ms-2 me-auto">
                    <div className="rv_">

                      <SellIcon className="icon__acc_2" />
                      <span>Sell anything</span>
                    </div>
                    <VscChevronRight fontSize={18} />
                  </div>
                </ListGroup.Item>
              )}
            </div>

            <div className="_list_itm_group">
              <ListGroup.Item
                as={Link} to={accountProfilePathUrl}>
                <div className=" rv_ sD_m_ ms-2">
                  <div className="rv_">
                    <VscAccount className="icon__acc_" />
                    <span>Manage Profile</span>
                  </div>
                  <VscChevronRight fontSize={18} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item
                as={Link} to={addressesPathUrl}>
                <div className="sD_m_ ms-2 me-auto">
                  <div className="rv_">
                    <LocationIcon className="icon__acc_2" />
                    <span>Addresses</span>
                  </div>
                  <VscChevronRight fontSize={18} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item
                as={Link} to={cartPathUrl}>
                <div className="sD_m_ ms-2 me-auto">
                  <div className="rv_">
                    <ShoppingCartIcon className="icon__acc_2" />
                    <span>Shopping Cart</span>
                  </div>
                  <VscChevronRight fontSize={18} />
                </div>
              </ListGroup.Item>


            </div>
            <div className="_list_itm_group">
              <ListGroup.Item
                as={Link} to={ordersPathUrl}>
                <div className="sD_m_ ms-2 me-auto">
                  <div className="rv_">
                    <VscHistory className="icon__acc_" />
                    <span>Orders</span>
                  </div>
                  <VscChevronRight fontSize={18} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item
                as={Link} to={savedItemPathUrl}>
                <div className="sD_m_ ms-2 me-auto">
                  <div className="rv_">
                    <VscHeart className="icon__acc_" />
                    <span>Saved Items</span>
                  </div>
                  <VscChevronRight fontSize={18} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item
                as={Link} to={returnRequestPathUrl}>
                <div className="sD_m_ ms-2 me-auto">
                  <div className="rv_">
                    <VscDebugStepBack className="icon__acc_" />
                    <span>Return Request</span>
                  </div>
                  <VscChevronRight fontSize={18} />
                </div>
              </ListGroup.Item>

            </div>


            <div className="_list_itm_group">
              <ListGroup.Item
                onClick={() => { window.location.href = "mailto:support@ekenta.com" }}>
                <div className="sD_m_ ms-2 me-auto">
                  <div className="rv_">
                    <VscQuestion className="icon__acc_" />
                    <span>Help Center</span>
                  </div>
                  <VscChevronRight fontSize={18} />
                </div>
              </ListGroup.Item>

              {ek_isAuth && token && (
                <div className="mt-1">
                  <ListGroup.Item
                    as={Link} to={"#"} onClick={logoutHandlerFunc}>
                    <div className="sD_m_ ms-2 me-auto">
                      <div className="rv_">
                        <LogoutIcon className="icon__acc_2" />
                        <span>Log Out</span>
                      </div>
                      <VscChevronRight fontSize={18} />
                    </div>
                  </ListGroup.Item>
                </div>
              )}
            </div>

          </ListGroup>
        </div>
      </div>
    </>
  )
}

export default withDefaultLayout(MobileScreenAccountIndex);