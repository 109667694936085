import { device } from '@ekenta/utils/devices'
import styled from 'styled-components'

export const WrapContainer = styled.div`
  position: relative;
  height: auto;

  a {
    text-decoration: none !important;
  }
  /* width: 45%;

  @media ${device.md} {
    width: 22%;
  }

  @media ${device.xl} {
    width: 18%;
  } */
`

export const ProductCardItem = styled.div`
  display: block;
  position: relative;
  //margin: 20px 30px;
  transition: transform 0.5s ease-in-out;
  font-family: var(--font-family);
  border: 1.6px solid rgba(248, 248, 248, 1);
  border-radius: 8px;
  font-size: 12px;
  //width: 18%;
  width: 100%;
  /* @media screen and (max-width: 950px) {
    max-width: 150px;
    font-size: 15px;
  } */
`

export const Image = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  transition: all 0.4s ease-in-out;
  border-radius: 10px;
  background-color: rgb(245, 251, 255);

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
`

export const CenterPlay = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .center-button {
    position: relative;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 23%;
    max-width: 120px;
    height: 100%;

    .ek-icon.play {
      position: absolute;
      width: 18px;
      height: 18px;
      z-index: 2;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
    .ek-icon.circle {
      position: absolute;
      width: 40px;
      height: 40px;
      z-index: 1;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
  }
`
export const Content = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 15px 10px 10px 10px;
`

export const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  width: 100% !important;
  display: block;
  display: -webkit-box;
  text-decoration: none;
  text-transform: capitalize;
  padding-bottom: 0.2rem;
  max-width: 100%;
  height: 30px; /* Fallback for non-webkit */
  margin-bottom: 0rem;
  line-height: 1.5;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px !important;
  font-family: var(--eke-body-font-family);
  color: var(--eke-color-greyscale);
  font-weight: var(--eke-font-weight-bold);
  line-height: 140%; /* 27.404px */
  letter-spacing: 0.241px;

  &:hover {
    color: var(--eke-body-text-primary-color) !important;
    text-decoration: none !important;
  }
`

export const NOStock = styled.p`
  color: var(--eke-color-greyscale);
  font-family: var(--eke-body-font-family);
  font-size: 13.536px !important;
  font-weight: var(--eke-font-weight-medium);
  letter-spacing: 0.241px;
  margin: 0 !important;
`

export const WrapRating = styled.div`
  display: flex;
  align-items: center;
  gap: 9.634px;
  align-self: stretch;
  padding: 7px 0;
`

export const FlexRate = styled.div`
  display: flex;
  align-items: center;
  gap: 9.787px;

  span {
    color: var(--greyscale-700, #616161) !important;
    font-family: var(--eke-body-font-family);
    font-size: 15.06px;
    font-weight: var(--eke-font-weight-medium);
    line-height: 140%; /* 23.604px */
    letter-spacing: 0.241px;
  }
`

export const Divider = styled.div`
  width: 1px;
  height: 13px;
  border-right: 1.5px solid #616161;
`
export const ConditionBadge = styled.div`
  span {
    display: flex;
    width: 50px !important;
    padding: 7.226px 12.043px;
    justify-content: center;
    align-items: center;
    color: var(--eke-color-secondary);
    font-family: var(--eke-body-font-family);
    font-size: 10px;
    font-weight: var(--eke-font-weight-semibold);
    letter-spacing: 0.2px;
    border-radius: 7.226px;
  }
  .span__new {
    background: var(--transparent-blue, #335ef714) !important;
  }

  .span__used {
    background: var(--transparent-blue, #335ef714) !important;
  }
`

export const WrapPrices = styled.div`
  position: relative !important;
  display: flex;
  align-items: flex-start;
  gap: 2px;

  .card-image-wrapper {
    width: 0px;
    height: 40px;
  }
`

export const PricePerKg = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4px;
  span.main-price:nth-child(1) {
    margin-bottom: 0rem !important;
    margin-right: 0.5rem;
    font-family: var(--eke-body-font-family);
    color: var(--greyscale-900, #212121);
    font-weight: var(--eke-font-weight-bold);
    font-size: 18.486px !important;
    line-height: 120%; /* 24.583px */
  }
  span:nth-child(2) {
    font-size: 13.5px !important;
    color: var(--eke-body-text-secondary-color);
    opacity: 0.6;
    font-weight: normal !important;
    text-decoration: line-through;
    font-family: var(--eke-body-font-family);
    font-weight: (--eke-font-weight-light);
  }
`
export const NoDiscount = styled.div`
  position: relative;
  padding-top: 3px;
  /* top: -5%;
  right: 30%; */

  .Discount_percent {
    position: static !important;
    left: 50% !important;
  }
`
