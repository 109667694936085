import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row, Form } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import { FaArrowLeft } from 'react-icons/fa';
import { useAppSelector } from '../../../../redux/store/hook';
import {
  AdminDashboardSubCollectionCategoriesPathUrl,
  AdminDashboardSubCollectionPathUrl,
  adminManageBanner,
} from '../../../../routes/constant';
import sent from '@ekenta/assets/images/sent.gif';

import {
  useAddNewSubCollectionCategoryMutation,
  useFetchAllActiveCollectionsQuery,
} from '../../../../redux/store/services/collectionApi';
import { BASE_URL } from '../../../../constants';
import withDashboardLayout from '../../../../hoc/Layout/withDashboardLayout';
import { HelmetHeader } from '@ekenta/components';

const AddSubCollectionCategory = () => {
  const { token } = useAppSelector((state) => state.auth.authentication);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const result = useFetchAllActiveCollectionsQuery();
  const [
    addCollectionData,
    { data: addCollection_data, isError: addCollection_isError },
  ] = useAddNewSubCollectionCategoryMutation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [details, setDetails] = useState({
    name: '',
    description: '',
    visibility: 'false',
    thumbnailUrl: '',
    file: '',
    collectionId: '',
  });
  const handleImage = (e: any) => {
    // console.log(e.target.files[0])
    setDetails({ ...details, file: e.target.files[0] });
  };

  const handleSubmit = async () => {
    setLoading(true);
    console.log(details);

    const data = new FormData();
    data.append('name', details?.name);
    data.append('description', details?.description);
    data.append('visibility', details?.visibility);
    data.append('image', details?.file);
    data.append('collection_id', details?.collectionId);

    const config = {
      method: 'POST',
      url: `${BASE_URL}/api/admin/create-sub-collection-category`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        handleShow();
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });

    // const data = {
    //   name: details?.name,
    //   description: details?.description,
    //   visibility: details?.visibility == "true" ? true : false,
    //   collection_id: parseInt(details?.collectionId),
    // };
    // addCollectionData({ data, token });
  };

  const uploadThumbnail = async () => {
    await axios
      .get(
        `${BASE_URL}/api/sub-collection-category/thumbnail/${addCollection_data?.id}`,
      )
      .then((resp: any) => {
        setLoading(false);
      })
      .catch(function (error: any) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (addCollection_data) {
      uploadThumbnail();
      handleShow();
    }

    if (addCollection_isError) {
      setLoading(false);
      toast.error(<div className="cus_t">An error occured !</div>);
      //toastDisplay(true);
    }

    // navigate(adminManageBanner)
  }, [addCollection_data, addCollection_isError]);

  return (
    <>
      <HelmetHeader
        title="Add Subcollection Category Dashboard"
        description="QlasonAdd Subcollection Category Dashboard Page"
      />

      <Col className=" col-lg-11 mx-auto _listing_product_">
        <div className="mt-0">
          <h3 className="display_lg_header">
            {/* <FiChevronLeft /> */}
            Add Sub-Collection Category
          </h3>
          <Col className="mt-3">
            <Button
              className="mb-3 text-white"
              onClick={() =>
                navigate(AdminDashboardSubCollectionCategoriesPathUrl)
              }
            >
              <FaArrowLeft /> Back
            </Button>
            <Card>
              <Card.Body>
                <Form.Label htmlFor="inputPassword5">
                  Sub-Collection Category Name *{' '}
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setDetails({ ...details, name: e.target.value })
                  }
                />
                <br />
                <Form.Label htmlFor="inputPassword5">Description * </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setDetails({ ...details, description: e.target.value })
                  }
                />
                <br />
                <Form.Label htmlFor="inputPassword5">
                  Thumbnail Image *
                </Form.Label>
                <Form.Control type="file" onChange={(e) => handleImage(e)} />
                <br />
                <Form.Label htmlFor="inputPassword5">Visibility</Form.Label>
                <Form.Select
                  onChange={(e) =>
                    setDetails({ ...details, visibility: e.target.value })
                  }
                >
                  <option>Select Visibility</option>
                  <option value={'true'}>True</option>
                  <option value={'false'}>False</option>
                </Form.Select>
                <br />
                <br />
                <Form.Label htmlFor="inputPassword5">Collection *</Form.Label>
                <Form.Select
                  onChange={(e) =>
                    setDetails({ ...details, collectionId: e.target.value })
                  }
                >
                  <option>Select Collection</option>
                  {!result.isSuccess ? (
                    <option>Loading...</option>
                  ) : (
                    result?.data?.collections?.map((e: any, i: any) => (
                      <option key={i} value={e?.id}>
                        {e?.name}
                      </option>
                    ))
                  )}
                </Form.Select>
                <br />
                <Button
                  variant="danger"
                  disabled={
                    loading ||
                    details?.collectionId == '' ||
                    details?.name == '' ||
                    details?.description == ''
                  }
                  onClick={handleSubmit}
                >
                  {loading ? 'Loading...' : 'Submit'}
                </Button>{' '}
                <Button variant="outline-danger">Cancel</Button>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </Col>

      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row>
            <img src={sent} alt="" height={300} width={'100%'} />
            <Button
              variant="success"
              className="text-white text-center mx-auto"
              onClick={() =>
              (window.location.href =
                AdminDashboardSubCollectionCategoriesPathUrl)
              }
            >
              Done
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withDashboardLayout(AddSubCollectionCategory);
