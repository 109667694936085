/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Breadcrumb, Spinner } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import {
  useFetchSingleSubCollectionCategoryQuery,
  useFetchSingleSubCollectionQuery,
} from '../../../../redux/store/services/collectionApi';
import {
  categoryBaseUrl,
  collectionBaseUrl,
  collectionPagePathUrl,
  collectionPathUrl,
  SubCollectionCartegoryPathUrl,
  subCollectionPathUrl,
} from '../../../../routes/constant';
import { slugifyText, useMatchPath } from '../../../../utils';
import { PageTitle, RefreshLink } from '@ekenta/components';

export default function BreadCrumbs(props: any) {
  const { pathname } = useLocation();
  //fetch sub collection by it Id
  const getSubCollection = useFetchSingleSubCollectionQuery(props.subCollectionId, {
    skip: !props.subCollectionId,
  });

  //fetch subcollectioncategory by it Id
  const {
    data: subsinglecategory_data,
    isLoading: subsinglecategory_isLoading,
    isFetching: subsinglecategory_isFetching,
    isSuccess: subsinglecategory_isSuccess,
  } = useFetchSingleSubCollectionCategoryQuery(props.categoryId, {
    skip: !props.categoryId,
  });

  const _slugCollection = subsinglecategory_data
    ? slugifyText(
      subsinglecategory_data?.sub_collection_category.collectionName.toLowerCase(),
      subsinglecategory_data?.sub_collection_category.collection_id
    )
    : '/';

  const _slugsubCollection = getSubCollection?.isSuccess
    ? slugifyText(
      getSubCollection?.data.sub_collection.collectionName.toLowerCase(),
      getSubCollection?.data.sub_collection.collection_id,
    )
    : '/';

  const _slugCategory = getSubCollection?.isSuccess
    ? slugifyText(
      getSubCollection?.data.sub_collection.name.toLowerCase(),
      getSubCollection?.data.sub_collection.sub_collection_category_id,
    )
    : '/';

  return (
    <div className=" d-none d-lg-block container_bread_crumb">
      <Breadcrumb className="_p_bread_crumb">
        <Breadcrumb.Item className="pl-0">
          <Link to={collectionPathUrl}>All Categories</Link>
        </Breadcrumb.Item>

        {props.collectionName ? (
          <>
            <Breadcrumb.Item className="active_breadcrumb">
              {props.collectionName}
            </Breadcrumb.Item>
          </>
        ) : null}


        {subsinglecategory_isLoading || subsinglecategory_isFetching ? (
          <div className="ms-2">
            <Spinner size="sm" animation="border" variant="secondary" />
          </div>
        ) : subsinglecategory_isSuccess ? (
          <>
            <Breadcrumb.Item>
              <Link to={`${collectionBaseUrl}/${_slugCollection}`}>
                {subsinglecategory_data.sub_collection_category.collectionName}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item className="active_breadcrumb">
              {subsinglecategory_data.sub_collection_category.name}
            </Breadcrumb.Item>
          </>
        ) : null}

        {getSubCollection?.isLoading ? (
          <div className="ms-2">
            <Spinner size="sm" animation="border" variant="secondary" />
          </div>
        ) : getSubCollection?.isSuccess ? (
          <>
            <Breadcrumb.Item>
              <Link to={`${collectionBaseUrl}/${_slugsubCollection}`}>
                {getSubCollection?.data.sub_collection.collectionName}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${categoryBaseUrl}/${_slugCategory}`}>
                {getSubCollection?.data.sub_collection.sub_collection_category.name}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item className="active_breadcrumb">
              {getSubCollection?.data.sub_collection.name}
            </Breadcrumb.Item>
          </>
        ) : null}


      </Breadcrumb>




      <div className="collection-title d-none d-lg-block">
        {getSubCollection?.data &&
          pathname === useMatchPath(subCollectionPathUrl, pathname) ? (
          <>
            <h5 className="display_lg_header p-0">{getSubCollection?.data.sub_collection.name}</h5>{' '}
          </>
        ) : null}

        {subsinglecategory_data &&
          pathname === useMatchPath(SubCollectionCartegoryPathUrl, pathname) ? (
          <PageTitle
            title={subsinglecategory_data.sub_collection_category.name}
            margin={false}
          />
        ) : null}

        {props.collectionName &&
          pathname === useMatchPath(collectionPagePathUrl, pathname) ? (
          <PageTitle
            title={props.collectionName}
            margin={false}
          />
        ) : null}
      </div>
    </div>
  );
}
