import React from 'react'
import { FiMinus, FiPlus, FiTrash2 } from 'react-icons/fi'
import styled from 'styled-components'

const Counter = (props: {
  incrementLimit?: number
  disabled?: boolean
  initialValue?: number
  value: number
  className?: string
  showDelete?: boolean
  onDelete?: () => void
  onAdd?: () => void
  onCountChange?: ({
    count,
    type,
  }: {
    count: number
    type: 'increase' | 'decrease'
  }) => void
}) => {
  const {
    incrementLimit = 100,
    disabled = false,
    initialValue = 0,
    value,
    showDelete,
    onDelete,
    onAdd,
    onCountChange,
  } = props

  const increment = () => {
    if (!disabled && value < incrementLimit) {
      onCountChange && onCountChange({ count: value + 1, type: 'increase' })
    }
  }

  const decrement = () => {
    if (!disabled && value > initialValue) {
      onCountChange && onCountChange({ count: value - 1, type: 'decrease' })
    }
  }

  return (
    <Container className={props.className}>
      <HStack>
        {value === 1 && showDelete && onDelete ? (
          <CountButton type="button" onClick={onDelete}>
            <FiTrash2 fontSize={15} />
          </CountButton>
        ) : (
          <CountButton
            type="button"
            onClick={decrement}
            disabled={disabled || value === initialValue}
          >
            <FiMinus fontSize={15} />
          </CountButton>
        )}
        <CountValue className="count-value">{value}</CountValue>
        <CountButton
          type="button"
          onClick={value === 0 && onAdd ? onAdd : increment}
          disabled={disabled || value >= incrementLimit}
        >
          <FiPlus fontSize={15} />
        </CountButton>
      </HStack>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;

  .count-value {
    min-width: 20px;
    text-align: center;
    margin: 0px 5px;
    color: var(--text-black) !important;
    font-family: var(--eke-body-font-family);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
  }

  button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #a8a8a8;
    transition: all 0.5s ease-in-out;
    svg {
      color: gray;
    }

    &:hover {
      border: 1px solid #222;
      svg {
        color: #222;
      }
    }

    &:disabled {
      border: 1px solid #ddd;
      pointer-events: none;
      svg {
        color: #ddd;
      }
    }
  }
`

const CountValue = styled.div`
  font-size: 1rem;
`

const HStack = styled.div`
  display: flex;
  align-items: center;
`

const CountButton = styled.button`
  cursor: pointer;
  padding: 0.5rem;
  color: #fff;
  border: none;
  border-radius: 4px;
  margin: 0 0.5rem;
  font-size: 1rem;
  outline: none;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.8;
  }
`
export default Counter
