import React from 'react'
import SectionCard from './common/Sectioncard'
import styled from 'styled-components'
import { HelmetHeader, RefreshLink } from '@ekenta/components'
import { useGetAllVendorsQuery } from '@ekenta/redux/store/features/vendor/vendorApiSlice'
import { withDefaultLayout } from '@ekenta/hoc'
import { Container, HomeStyle } from './styles'
import { HomeBreadCrumb } from './common/BreadCrumb'

const AllVendors = () => {
  const { data } = useGetAllVendorsQuery(null)
  const vendors = data?.vendors?.data ?? []
  return (
    <>
      <HelmetHeader
        title="Just For You Deals at Best Price"
        description="Qlason Just For You Deals"
      />
      <HomeStyle>
        <Container>
          <HomeBreadCrumb activePage="Discover Vendors shop" />
          <SectionCard title="Discover Vendors shop" size="small" url="">
            <ContainerWrapper>
              <ContentStyle>
                {vendors?.map(
                  (item: {
                    id: string
                    thumbnail_url: string | null
                    business_name: string
                    user: {
                      id: string
                    }
                  }) => (
                    <FlexItem
                      key={item.id}
                      as={RefreshLink}
                      to={`/vendor/${item?.user?.id}/store`}
                    >
                      <Image>
                        {item?.thumbnail_url ? (
                          <img
                            src={item?.thumbnail_url}
                            alt={item.business_name}
                          />
                        ) : (
                          <h1>{item.business_name.charAt(0)}</h1>
                        )}
                      </Image>
                      <h3>{item.business_name}</h3>
                    </FlexItem>
                  )
                )}
              </ContentStyle>
            </ContainerWrapper>
          </SectionCard>
        </Container>
      </HomeStyle>
    </>
  )
}

const ContainerWrapper = styled.div`
  width: 100%;
  margin-bottom: 21px;
  max-height: auto;
  position: relative;
`

const FlexItem = styled.div`
  width: auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-decoration: none !important;

  h3 {
    color: var(--greyscale-900, #212121);
    text-align: center;
    font-family: var(--eke-body-font-family);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
    letter-spacing: 0.241px;
    text-transform: capitalize;
  }
`
const Image = styled.div`
  width: 170px;
  height: 170px;
  border-radius: 179px;
  background-color: var(--eke-color-primary);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 5rem;
    color: #fff;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 179px;
    object-fit: cover;
  }
`

const ContentStyle = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: wrap;
  gap: 15px;
  row-gap: 30px;
  -ms-overflow-style: none;
  margin-top: 0 !important;
  animation: slide 20s linear infinite;

  .swiper-slide {
    width: 230px !important;
  }
`

export default withDefaultLayout(AllVendors)
